import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash, FaPlus, FaPrint } from 'react-icons/fa';
import {  useNavigate } from 'react-router-dom';
import { ApiService } from 'src/components/utilities/api.service';
import { getErrorMsg, getFormattedDate, getItem } from 'src/components/utilities/utils';
import { IReceipt } from 'src/models/receipt';
import { TenantAdminModel } from 'src/models/usersModel';

const Receipt: React.FC = () => {
  const [receipt, setReceipt] = useState<IReceipt[]>([]);
  const navigate = useNavigate();
  const [userDetails,setUserDetails]=useState<TenantAdminModel | null>(null);
  
  useEffect(()=>{
    setUserDetails(JSON.parse(getItem('userData')));
    getData();
  },[]);


  const getData = async (): Promise<void> => {
    try {
      const result: AxiosResponse<{ data: IReceipt[] }> = await ApiService.getData(
        `service-srv/receipt`
      );
      setReceipt(result.data?.data);
    } catch (error) {
      console.error('Error getting services:', error);
    }
  };

  const handleEdit = (id: string) => {
    navigate(`/addreceipt/${id}`);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Do you want to delete?')) {
      try {
        const response:AxiosResponse=await ApiService.deleteData(`service-srv/receipt/${id}`);
        if(response.data && response.data?.code === 200 && response.data?.success) {
        getData();
        alert('Data Deleted Successfully');
        }
        else{
          alert('Failed to Delete the data')
        }
      } catch (error) {
        console.error('Error deleting item:', error);
      }
    }
    else{
    alert('Delete Action Cancel')
    }
  };
  const handleGeneratePrintPDF = async (id: string) => {
    try {
      const response: AxiosResponse = await ApiService.getData(
        `service-srv/receipt/${id}`
      );
      if (!response || !response?.data || response?.data?.data == null) {
        alert('invalid receipt id passed');
      }

      const header_response: AxiosResponse = await ApiService.getData(
       `tenant-srv/tenant/service/tenantId/${userDetails?.tenant_id}`
      );
      if (!header_response || !header_response?.data || !header_response?.data?.data) {
        alert('Invalid  tenant id passed');
      }
      const headerData = header_response?.data?.data;
      const window_height = window.screen.height;
      const window_width = window.screen.width;
      const receipt_data: IReceipt = response?.data?.data;
      const rootUrl = process.env.REACT_APP_BASE_API_URL;
      let report_html = '';
     

      //render section header
      report_html += `<h3 class='text-center mt-5' > Receipt</h3>
         <div class='col-md-6'>
          <p>Receipt No: ${receipt_data.receipt_no}</p>
          </div>
          <div class='col-md-6'>
           <p style="position: absolute; bottom: 0; right: 50px;">Date: ${getFormattedDate(receipt_data.date.toString())}</p>
           </div>
           <div class='col-md-12 mt-3'>
           <p> ${receipt_data.description}</p>
          </div>
          <div class='col-md-6 mt-5'>
           <p><strong>Amount: ${receipt_data.amount}
            </strong>
           </p>
           </div>
           <div class='col-md-6 text-right'> 
           <p>Signature</p>
            ${receipt_data.signed_by ? `<img src="${receipt_data.signed_by}" alt="Signature" style="max-height: 100px;">` : ""}
           </div>
       
         </div>`;
      const mywindow = window.open(
        '',
        'PRINT',
        'height=' + window_height + ',width=' + window_width
      );
      if (!mywindow || mywindow == null) {
        alert('Something went wrong');
      } else {
        mywindow.document.write('<html><head>');
        mywindow.document.write(
          '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css">' +
            '<script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js" > </script>' +
            '<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" > </script>' +
            '<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js" > </script>'
        );
        mywindow.document.write(
          '</head><body style="padding-left: 20px; padding-right: 20px; padding-bottom:20px">'
        );
        mywindow.document.write(
          '<div class="row"  id = "print-header" >' +
            ' <div className="col-md-4 " class="mt-3 mr-5" > ' +
            '<img src = "' +headerData.logo +
            '" alt = "company logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
            '</div>' +
            '<div className="col-md-8" class="ml-5">' +
            '<h1 style = "margin-bottom: 0px !important; font-size:70px ; color:#090265">' +
            headerData.name +
            '</h1>' +
            '<h3 style="color:090265;  margin-left: 100px;">' +
            headerData.first_name +
            ' ' +
            headerData.last_name +
            '</h3>' +
            '</div>' +
            '</div>' +
            '<div class="row " " id = "print-header"  >' +
            '<div  class="col-md-12 text-center ms-5" style="bac-color:#e1ddff; color:#090265" >' +
            '<ul class="d-flex justify-content-around" style="font-size:20px ; margin-top:10px;">' +
            '<li>' +
            'PLANINIG' +
            '</li>' +
            '<li>' +
            'ELEVATION' +
            '</li>' +
            '<li>' +
            'DESIGN' +
            '</li>' +
            '<li>' +
            'CONSTRUCTION' +
            '</li>' +
            '</ul>' +
            '</div>' +
            '<div  class="col-md-12 text-center">' +
            '<h4 style="color:090265">' +
            headerData.location +
            '</h4>' +
            '</div>' +
            '<div  class="col-md-12 text-center p-2 d-flex justify-content-between pl-4" style="background-color:#090265; color:white; "   >' +
            '<h4 class="pl-5">' +
            'Contact : ' +
            headerData.contact_number +
            '</h4>' +
            '<h4 class="pr-5">' +
            'Email : ' +
            headerData.email +
            '</h4>' +
            '</div>' +
            '</div>'
        );
        mywindow.document.write(report_html);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10
      }
      if(mywindow != null) {
        setTimeout(function () {
          mywindow.print();
          mywindow.close();
        }, 1000);
      }
      
      return true;
    } catch (error) {
      const err = error as AxiosError;
      alert(getErrorMsg(err));
    }
  };
  const handleAddReceipt = () => {
    navigate('/addreceipt');
  };

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Receipt</h2>
        <button
          className="flex items-center bg-blue-500 text-white px-4 py-2 rounded shadow hover:bg-blue-700"
          onClick={handleAddReceipt}>
          <FaPlus className="mr-2" /> Add Receipt
        </button>
      </div>
      <div className="flex-grow overflow-auto">

      <table className="table-auto w-full border">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-2 text-center">S.No</th>
            <th className="p-2 text-center whitespace-nowrap">Date</th>
            <th className="p-2 text-center whitespace-nowrap">Amount</th>
            <th className="p-2 text-center whitespace-nowrap">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {receipt.map((item, index) => (
            <tr key={index}>
              <td className="p-2 text-center">{index + 1}</td>
              <td className="p-2 text-center whitespace-nowrap">{new Date(item.date).toDateString()}</td>
              <td className="p-2 text-center whitespace-nowrap">{item.amount}</td>
              <td className="p-2 text-center flex justify-center ">
                <button
                  className="text-blue-500 hover:text-blue-700 mr-2"
                  onClick={() => handleEdit(item.id)}>
                  <FaEdit />
                </button>
                <button
                  className="text-red-500 hover:text-red-700 mr-2"
                  onClick={() => handleDelete(item.id)}>
                  <FaTrash />
                </button>
                <button
                className="text-blue-500 hover:text-blue-700 "
                onClick={() =>handleGeneratePrintPDF(item.id) }>
                <FaPrint />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default Receipt;
