import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/loader';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { useTenantActions } from 'src/store/tenants/actions';
import { FaEdit, FaEllipsisV } from 'react-icons/fa';

const Tenants: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const tenantAction = useTenantActions(dispatch);
  const {
    tenants,
    isTenantSaved,
    isTenantDeleted,
    isTenantUpdated,
    success,
    error,
    loading,
    message
  } = useSelector((state: RootState) => {
    return {
        tenants: state.tenant.tenantsInfoModel,
        isTenantSaved: state.tenant.isTenantSaved,
        isTenantDeleted: state.tenant.isTenantDeleted,
        isTenantUpdated: state.tenant.isTenantUpdated,
        success: state.tenant.success,
        error: state.tenant.error,
        loading: state.tenant.loading,
        message: state.tenant.message,
    };
  });
  const menuRef = useRef<HTMLDivElement>(null);

  const handleSaveTenant = (id: string, action: string) => {
    if (action === 'edit') navigate(`/addTenant/${id}`);
    else if (action === 'add') navigate(`/addTenant`);
  };

  const handleAddTenantRole = (id: string) => {
    navigate(`/tenantroles/${id}`)
  }
  
  const handleMenuToggle = (id: string) => {
    setOpenMenuId(openMenuId === id ? null : id);
  };

  const handleAddTenantAdmin = (id: string) => {
    navigate(`/tenantadmins/${id}`, { replace: true })
  }

  const handleAddTenantService = (tenant_id:string) => {
    navigate(`/serviceadd/${tenant_id}`, { replace: true })
  }

  useEffect(() => {
    tenantAction.getTenantsRequest();
  }, [isTenantDeleted, isTenantSaved, isTenantUpdated]);


  return (
    <>
      <div className="mt-4 members-list">
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Tenants</h2>
                <div className="lg:flex items-center text-center">
                  <button
                    onClick={() => {
                        handleSaveTenant('', 'add');
                    }}
                    type="button"
                    className="btn-primary w-auto whitespace-nowrap mt-0 mb-1 mx-auto">
                    Add New
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr key={'headers'}>
                        <th className="p-2">
                          <div className="font-semibold text-left">Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Tenant key</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Tenant Code</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {loading ? (
                        <tr key={'load'}>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {tenants.length ? (
                            <>
                              {tenants &&
                                tenants.map((tenant, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            <div className="text-left">{`${tenant.name}`}</div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="text-left">{`${tenant.tenant_key}`}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                          {`${tenant.tenant_code}`}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                        <div className="text-left">
                                        <button className="text-blue-500"  onClick={() => handleSaveTenant(tenant._id, 'edit')}
                                        >
                  <FaEdit className="inline mr-3" />
                </button>
                
                                        <button
                          className="text-gray-500"
                          onClick={() => handleMenuToggle(tenant._id)}>
                          <FaEllipsisV className="inline mr-1 cursor-pointer text-right" />
                        </button>
                        {openMenuId === tenant._id && (
                          <div
                            ref={menuRef}
                            className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10">
                                  <button
                              onClick={() => handleAddTenantRole(tenant._id)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                              View Roles
                            </button>
                            <button
                              onClick={() => handleAddTenantAdmin(tenant._id)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                             View Admins
                            </button>
                            <button
                              onClick={() => handleAddTenantService(tenant._id)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                             View Services
                            </button>
                        </div>
                        
                          )}
                          </div>
                        </td>                    
                                      </tr>
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <tr key={'no_records'}>
                              <td>No Data Found</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tenants;
