import React, { memo, useEffect, useState } from 'react';
import './styles.scss';
import { TenantAdminModel } from 'src/models/usersModel';
import { allMonths, getErrorMsg, getItem, getYears } from 'src/components/utilities/utils';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import LineChart from '../charts/linechart/LineChart';
interface DashboardCountResponse {
  ongoing_projects: number;
  completed_projects: number;
  total_incomes: number;
  total_usages: number;
}

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<TenantAdminModel | null>(null);
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [dashboardCounts, setDashboardCounts] = useState<DashboardCountResponse>({
    ongoing_projects: 0,
    completed_projects: 0,
    total_incomes: 0,
    total_usages: 0
  });
  const [totalIncomingsData, setTotalIncomingsData] = useState({
    labels: allMonths(),
    datasets: [
    {
      label: 'My First dataset',
      fill: false,
      backgroundColor: 'rgba(75,192,192,0.4)',
      borderColor: 'rgba(75,192,192,1)',
      pointBackgroundColor: 'rgba(75,192,192,1)',
      pointBorderColor: '#fff',
      pointHoverBackgroundColor: '#fff',
      pointHoverBorderColor: 'rgba(220,220,220,1)',
      data: [65, 59, 80, 81, 56, 55, 40],
    },
  ]
  });

  useEffect(() => {
    setUserDetails(JSON.parse(getItem('userData')));
    fetchDashboardCounts()
  }, []);

  useEffect(() => {
    if(userDetails?.roles?.[0] == "Super_Admin") {
      navigate('/tenants')
    }
  }, [userDetails])

  useEffect(() => {
    getTotalIncomesByYear();
  }, [selectedYear])

  const fetchDashboardCounts = async () => {
    try {
      const response = await ApiService.getData('project-srv/report/dashboard_counts');
      if(response.data?.data) {
        setDashboardCounts(response.data?.data)
      }
    } catch (error) {
      const err = error as AxiosError;
      alert(getErrorMsg(err))
    }
  }

  const getTotalIncomesByYear = async () => {
    try {
      const response = await ApiService.postData('project-srv/report/total_incomes', { year: selectedYear });
      if(response.data?.data && response.data.success && response.data.code === 200) {
        setTotalIncomingsData({
          labels: allMonths(),
          datasets: [
          {
            label: 'Total incomes',
            fill: false,
            backgroundColor: 'rgba(75,192,192,0.4)',
            borderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: 'rgba(75,192,192,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            data: response.data.data.map((e: number) => e),
          }]
        })
      }
    } catch (error) {
      const err = error as AxiosError;
      alert(getErrorMsg(err))
    }
  }

  return (
    <>

      <div className="dashboard">
        <div className="flex flex-wrap mt-5 dashboard-tiles">
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                    {dashboardCounts?.ongoing_projects}
                  </span>
                  <span className="mb-2">Ongoing projects</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-1 rounded-full`}>
                        <i className="fa-solid fa-bars-progress fa-xl"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                  {dashboardCounts?.completed_projects}
                  </span>
                  <span className="mb-2">Completed projects</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-2 rounded-full`}>
                        <i className="fa-regular fa-handshake fa-xl"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pr-5 xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                  &#8377; {dashboardCounts?.total_incomes}
                  </span>
                  <span className="mb-2">Total Incomes</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-4 rounded-full`}>
                      
                        <i className="fa-solid fa-chart-line fa-xl"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="xl:w-1/4 lg:w-1/2 md:w-1/2 w-full mb-5">
            <div className="dashboard-card flex-items justify-center">
              <div className="flex-items w-full p-4">
                <div className="flex flex-col">
                  <span className="text-2xl font-semibold">
                  &#8377; {dashboardCounts?.total_usages}
                  </span>
                  <span className="mb-2">Other Expenditure</span>
                </div>
                <div>
                  <div className="rounded-full">
                    <div
                      className={`w-10 h-10 flex items-center justify-center bg-light-3 rounded-full`}>
                        <i className="fa-solid fa-comments-dollar fa-xl"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xl:flex h-full">
          <div className="pr-5 xl:w-9/12 w-full">
            <div className="bg-white rounded-lg shadow-card dark:bg-gray-800 w-full xl:mb-0 mb-4 h-full">
              <div className="lg:flex h-full justify-center">
              
                <div className="lg:border-r lg:w-4/5">
                  <div className="mb-8" style={{ width: "100%" }}>
                    <label className="block mb-1">Select Year</label>
                    <select className="input-style" value={selectedYear} onChange={(e) => setSelectedYear(Number(e.target.value))}>
                      {getYears().map((year) => (
                        <option key={year} value={year}>
                          {year}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="p-4">
                    <div className="text-center">
                      <LineChart chartData={totalIncomingsData}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    </>
  );
};

export default memo(Dashboard);
