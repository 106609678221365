import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { IProjectInfo } from 'src/models/project';
import { ApiService } from 'src/components/utilities/api.service';
import { AxiosResponse } from 'axios';
import Breadcrumbs from '../BreadCrumbsComponent';
import { getItem } from 'src/components/utilities/utils';
import { TenantAdminModel } from 'src/models/usersModel';

// interface ServiceType {
//   name: string | number | readonly string[] | undefined;
//   _id: string;
//   service_name: string;
// }

const AddProject: React.FC = () => {
  const [userDetails, setUserDetails] = useState<TenantAdminModel | null>(null);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  // const { project_id } = useParams<{ project_id: string }>();
  const [serviceName, setServiceName] = useState<string>('');
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<IProjectInfo>();

  useEffect(() => {
    setUserDetails(JSON.parse(getItem('userData')));
  }, []);

  // useEffect(() => {
  //   if (userDetails && userDetails.service_id) {
  //     const fetchServiceName = async () => {
  //       try {
  //         const response: AxiosResponse = await ApiService.getData(
  //           `services-srv/service/${userDetails.service_id}`
  //         );
  //         const servicename = response.data?.data.name;
  //         setServiceName(servicename);
  //         setValue('service_id', servicename);
  //       } catch (error) {
  //         alert(error);
  //       }
  //     };
  //     fetchServiceName();
  //   }
  // }, [userDetails, setValue]);

  // Fetch the data by id and update
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getData(`project-srv/project/${id}`);
        const projectData = response.data.data;

        reset(projectData);
        setValue('service_id', serviceName);
        if (projectData.start_date) {
          setValue('start_date', projectData.start_date.split('T')[0]);
        }
        if (projectData.end_date) {
          setValue('end_date', projectData.end_date.split('T')[0]);
        }
        if (projectData.date) {
          setValue('date', projectData.date.split('T')[0]);
        }
      } catch (error) {
        alert(error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, reset, setValue]);

  // Submit form data
  const onSubmit: SubmitHandler<IProjectInfo> = async (data) => {
    try {
      if (userDetails) {
        data.service_id = userDetails.service_id;
      }
      if (id) {
        data.id = id;
        data._id = id;
      }
      const response: AxiosResponse = await ApiService.postData(`project-srv/project`, data);
      if (response.data && response.data?.code === 200 && response.data?.success) {
        alert('Project form saved');
        reset();
        navigate('/projectinfo');
      }
    } catch (error) {
      alert(error);
    }
  };

  
  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="flex">
        <Breadcrumbs
          items={[
            { label: 'Project ', path: `/projectinfo/${id}` },
            { label: id ? 'Edit' : 'Add' }
          ]}
        />
      </div>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid md:grid-cols-1  sm:grid-cols-1 gap-4 mb-8">
          {/* <div className="mb-4">
            <label className="block mb-1"> Service Name*</label>

            <input type="text" {...register('service_id')} className="input-style" />
            {errors.service_id && <p className="text-red-700">{errors.service_id.message}</p>}
          </div> */}

          <div className="">
            <label className="block mb-1">Project Name <span className="text-red-700 ml-1">*</span></label>
            <input
              type="text"
              {...register('name', { required: 'Project name is required' })}
              className="input-style"
            />
            {errors.name && <p className="text-red-700">{errors.name.message}</p>}
          </div>
        </div>

        {/* Responsive Grid Layout */}
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div className="mb-4">
            <label className="block mb-1">Scope</label>
            <input type="text" {...register('scope')} className="input-style" />
            {errors.scope && <p className="text-red-700">{errors.scope.message}</p>}
          </div>

          <div className="mb-4">
            <label className="block mb-1">Start Date <span className="text-red-700 ml-1">*</span></label>
            <input type="date" {...register('start_date', { required: 'Start Date is required' })} className="input-style" />
            {errors.start_date && <p className="text-red-700">{errors.start_date.message}</p>}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div className="mb-4">
            <label className="block mb-1">End Date <span className="text-red-700 ml-1">*</span></label>
            <input type="date" {...register('end_date', { required: 'End date is required' })} className="input-style" />
            {errors.end_date && <p className="text-red-700">{errors.end_date.message}</p>}
          </div>

          <div className="mb-4">
            <label className="block mb-1">Project Creation Date </label>
            <input type="date" {...register('date')} 
            
            className="input-style" 
            defaultValue={new Date().toISOString().split('T')[0]} 
            />
            
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div className="mb-4">
            <label className="block mb-1">GST</label>
            {/* <input type="text" {...register('gst')} className="input-style" /> */}
            <select {...register('gst')}
            className='input-style'
            >
              <option>Select Gst Rate</option>
              <option>0%</option>
              <option>5%</option>
              <option>12%</option>
              <option>18%</option>
              <option>28%</option>
            </select>
          </div>

          <div className="mb-4">
            <label className="block mb-1">Total Cost <span className="text-red-700 ml-1">*</span></label>
            <input
              type="number"
              {...register('total_cost', { required: 'Total cost is required' })}
              className="input-style"
            />
            {errors.total_cost && <p className="text-red-700">{errors.total_cost.message}</p>}
          </div>
        </div>

        <div className="grid gap-2 mb-4">
          <div className="form-group">
            <label className="block mb-2" htmlFor="description">
              Description
            </label>
            <textarea
              id="description"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('description')}
            />
           
          </div>
        </div>

        <div className="flex justify-start sm:justify-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-4 mt-3">
            {id ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            className="px-6 bg-gray-200 rounded-md w-28 mr-2 ml-0 mt-3"
            onClick={() => navigate('/projectinfo')}
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddProject;
