import React, { useEffect, useState } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import { useParams, useNavigate } from 'react-router-dom';
import { IMaterial, IMaterialInfo, OptionType } from 'src/models/materialModels';
import Breadcrumbs from '../BreadCrumbsComponent';

const MaterialUsageForm: React.FC = () => {
  const { id } = useParams<string>();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
    getValues
  } = useForm<IMaterialInfo>();

  const initialCategoryOptions: OptionType[] = [];

  const [materialOptions, setMaterialOptions] = useState<OptionType[]>(initialCategoryOptions);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getData(`services-srv/asset/${id}`);
        reset(response.data.data);
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id, reset, setValue]);

  const onSubmit: SubmitHandler<IMaterialInfo> = async (data) => {
    try {
      if (id) {
        data.id = id;
        data._id = id;

        const response: AxiosResponse = await ApiService.postData('services-srv/asset', data);

        if (response.data && response.data?.code === 200 && response.data?.success) {
          alert('Material Updated successfully');
        }
      } else {
        const materials: IMaterial[] = data.materialOption.map((option) => ({
          _id:data._id,
          category: data.category,
          material_name: option.label,
          unit:data.unit
        }));

        const response: AxiosResponse = await ApiService.postData(
          'services-srv/asset/bulkupload',
          materials
        );
        if (response.data && response.data?.code === 200 && response.data?.success) {
          alert('Material Saved successfully');
        }
      }

      reset();

      navigate('/materialinfo');
    } catch (error) {
      alert(error);
    }
  };

  const handleMaterialChange = (selectedOptions: OptionType[]) => {
    setValue('materialOption', selectedOptions);
  };

  const handleMaterialCreate = (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue };
    setMaterialOptions((prevOptions) => [...prevOptions, newOption]);
    setValue('materialOption', [...(getValues('materialOption') || []), newOption]);
  };

  const handleBackClick = () => {
    navigate('/materialinfo');
  };

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="flex">
        <Breadcrumbs
          items={[{ label: 'Material', path: `/materialinfo` }, { label: id ? 'Edit' : 'Add' }]}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label className="block mb-2 " htmlFor="name">
            Category Name <span className="text-red-700 ml-1">*</span>
          </label>
          <input
            type="text"
            id="name"
            className="border border-gray-300 p-2 rounded w-full"
            {...register('category', { required: 'Name is required' })}
          />
          {errors.category && <p className="text-red-700 mt-1">Name is required</p>}
        </div>

        {!id && (
          <div className="mb-4">
            <label className="block mb-2 " htmlFor="categories">
              Material Name <span className="text-red-700 ml-1">*</span>
            </label>
            <Controller
              name="materialOption"
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  options={materialOptions}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    handleMaterialChange(selectedOptions as OptionType[])
                  }
                  onCreateOption={handleMaterialCreate}
                  isMulti
                  placeholder="Select or add new categories..."
                />
              )}
            />
            {errors.materialOption && (
              <p className="text-red-700 mt-1">At least one Material is required</p>
            )}
          </div>
        )}

        <div className="mb-4">
          <label className="block mb-2 " htmlFor="name">
          Unit <span className="text-red-700 ml-1">*</span>
          </label>
          <input
            type="text"
            
            className="border border-gray-300 p-2 rounded w-full"
            {...register('unit', { required: 'Unit is required' })}
          />
          {errors.category && <p className="text-red-700 mt-1">Name is required</p>}
        </div>
        
        {id && (
          <div className="mb-4">
            <label className="block mb-2 font-semibold" htmlFor="name">
              Material Name <span className="text-red-700 ml-1">*</span>
            </label>
            <input
              type="text"
              id="material_name"
              className="border border-gray-300 p-2 rounded w-full"
              placeholder="Material Name"
              {...register('material_name', { required: 'Name is required' })}
            />
            {errors.material_name && <p className="text-red-700 mt-1">Name is required</p>}
          </div>
        )}

        <div className="flex justify-center ">
          <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
            {id ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            className="px-6 btn-primary discard-btn w-28 mr-2 ml-0 mt-3"
            onClick={handleBackClick}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default MaterialUsageForm;
