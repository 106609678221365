import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import Breadcrumbs from 'src/components/admin/BreadCrumbsComponent';
import { ISalaryConfig, ISaslaryConfigManpowerInfo } from '.';
import { formatRupeeValue, getErrorMsg, getFormattedDate } from 'src/components/utilities/utils';
import moment from 'moment';
import { FaCheck } from "react-icons/fa";
import Select from 'react-select';
import { IRoleInfo } from 'src/models/role';

interface OptionType {
  
  label: string;
  value: string;
}

const AddSalaryConfig: React.FC = () => {
  const { project_id } = useParams<string>();
  const { id } = useParams<string>();
  const [manpowerInfo, setManpowerInfo] = useState<ISaslaryConfigManpowerInfo[]>([]);
  const [editedManpowerRow, setEditedManpowerRow] = useState<number | null>(null);
  const [addManpowerRow, setaddManpowerRow] = useState<null | number>(null);
  const [totalSalary, setTotalSalary] = useState<number>(0);
  const [selectedRole, setSelectedRole] = useState<OptionType | null>(null);
  const [roleOptions, setRoleOptions] = useState<OptionType[]>([]);


  const manpowerForm = useForm<ISaslaryConfigManpowerInfo>();
  const currentpage='salaryconfig';	
  
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<ISalaryConfig>();


  const handleBackClick = () => {
    navigate(`/salaryconfig/${project_id}`);
  };

  const onSubmit: SubmitHandler<ISalaryConfig> = async (data) => {
    if(manpowerInfo && manpowerInfo.length == 0) {
      alert("Add some data");
      return false;
    }
    if(!project_id) {
      alert("Something went wrong");
      return false;
    }
    if(confirm("Do you want to save item")) {
      const payload: ISalaryConfig = {} as ISalaryConfig;
      payload.total_manpower_salary = totalSalary,
      payload.manpower_info = manpowerInfo
      if(project_id) payload.project_id = project_id;
      if(id) payload.id,payload._id = id; 
      const result: AxiosResponse = await ApiService.postData('project-srv/project/salaryconfig', payload);
      if(result.data && result.data.success && result.data.code) {
        toast.success('Salary config submitted');
        navigate(`/salaryconfig/${project_id}`);
        reset();
      }
    }
    
    
  };

  const  fetchSingleManpower = async () => {
    try {
      const reponse: AxiosResponse = await ApiService.getData(`project-srv/project/salaryconfig/${id}`);
      if(reponse.data && reponse.data?.data) {
        setManpowerInfo(reponse.data?.data?.manpower_info)
        
        setTotalSalary(reponse.data?.data?.total_manpower_salary)
      }
        
    } catch(error) {
      const err = error as AxiosError;
      alert(getErrorMsg(err));
    }
  }

  useEffect(() => {
    if(id)
      fetchSingleManpower();
    // setValue('expiryDate', moment(singleDiscountCode.expiryDate).format('YYYY-MM-DD'))
  }, [id])

  const handleDeleteMaterialRow = (index: number) => {
    const manpowerDetails = manpowerInfo.filter((e:any, element_index: number) => index != element_index);
    const deleteMaterial = manpowerInfo.find((e:any, element_index: number) => index == element_index);
    deleteMaterial && deleteMaterial?.total_salary && setTotalSalary((prevState) => prevState - Number(deleteMaterial?.total_salary) );
    setManpowerInfo(manpowerDetails);
  }

  const fetchRoles = async () => {
    try {
          const result: AxiosResponse<{ data: IRoleInfo[] }> = await ApiService.getData(
            `services-srv/manpower-role`
          );

          const options = result.data.data.map((role) => ({
            value: role.id,
            label: role.role_name 
          }));
          setRoleOptions(options);
        } catch (error) {
      alert(error)
    }
  };

  useEffect(() => {
    fetchRoles();
 
  }, []);

  const handleRoleChange = (selectedOption: OptionType | null) => {
    setSelectedRole(selectedOption);
    setValue('role', selectedOption ? selectedOption.label : '');
    setValue('role_id', selectedOption ? selectedOption.value : '');
  };


  const handleaddManpowerRow = () => {
    setaddManpowerRow(manpowerInfo.length + 1);
    manpowerForm.reset({
        role_id: "",
        from_date: new Date(),
        to_date: new Date(),
        salary_per_head: 0,
        count: 0,
        total_salary: 0
    })
  }

  const saveMaterialMap = (data: ISaslaryConfigManpowerInfo) => {

    // setValue('role_id',data.role_id)
    data.role = selectedRole ? selectedRole.label : "";
    data.role_id=selectedRole? selectedRole.value: "";
    setManpowerInfo((manpowerInfo) => [...manpowerInfo, data])
    setTotalSalary((prevState) => Number(prevState) + Number(data.total_salary));
    setaddManpowerRow(null);
  }

  const handleUpdateMaterialRow = () => {
    const totalAmount = manpowerInfo.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(currentValue.total_salary);
    }, 0);
    setTotalSalary(totalAmount);
    setEditedManpowerRow(null)
  }

  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };

  return (
    <div className="mt-4 max-w-xxl mx-auto shadow-lg rounded-lg bg-white">
       <div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto mb-10">   
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">  
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className="hover:underline whitespace-nowrap px-2"
    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className="hover:underline whitespace-nowrap  px-2"
    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      // className="hover:underline whitespace-nowrap px-2"
      className={`hover:underline whitespace-nowrap px-2 ${currentpage==='salaryconfig' ? 'text-base font-bold' : '' }`}

    >
      Salary Info
    </button>
  </div>
</div>
      <div className='container'>
        <p className='font-extrabold text-xl text-center mb-4 h4'>Add Salary</p>
      </div>
      <div className='p-2'>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="flex-col justify-center">
            <div className="overflow-x-auto">
                <table className="table-auto w-full border">
                <thead className="text-xs font-semibold uppercase bg-gray-50">
                    <tr key={'headers'}>
                        <th className="p-2">
                        <div className="font-semibold text-left whitespace-nowrap">S No.</div>
                        </th>
                        <th className="p-2">
                        <div className="font-semibold text-left whitespace-nowrap">Manpower Role</div>
                        </th>
                        <th className="p-2">
                        <div className="font-semibold text-left whitespace-nowrap">From Date</div>
                        </th>
                        <th className="p-2">
                        <div className="font-semibold text-left whitespace-nowrap">To Date</div>
                        </th>
                        <th className="p-2">
                        <div className="font-semibold text-left whitespace-nowrap">Salary Per head</div>
                        </th>
                        <th className="p-2">
                        <div className="font-semibold text-left whitespace-nowrap">Person Count</div>
                        </th>
                        <th className="p-2">
                        <div className="font-semibold text-left whitespace-nowrap">Total salary</div>
                        </th>
                        <th className="p-2">
                        <div className="font-semibold text-center whitespace-nowrap ">Actions</div>
                        </th>
                        </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                    {manpowerInfo && manpowerInfo.map((manpower, index) => {
                    return (
                        <>
                            <tr key={index}>
                            <td className="p-2">
                                <div className="flex items-center">
                                    <div className="text-left">{`${index + 1}`}</div> 
                                </div>
                            </td>
                            <td className="p-2 whitespace-nowrap grid sm:grid-cols-1">
                          
                            {editedManpowerRow === index ? (
                                  <Select
                                    value={roleOptions.find(option => option.label === manpower.role) || null}
                                    placeholder="Select Role"
                                    isClearable
                                    onChange={(option) => 
                                      setManpowerInfo((prevMaterialMapInfo) =>
                                        prevMaterialMapInfo.map((v, i) => 
                                          i === index ? { ...v, role: option ? option.label : '' } : v 
                                        )
                                      )
                                    }
                                    options={roleOptions}
                                    className='input-style'
                                    id={`role${index}`}
                                    required
                                  />
                                ) : (
                                  <div className="text-left whitespace-nowrap">{`${manpower.role}`}</div>
                                )}
                               
                            </td>
                          
                            <td className="p-2">
                                    <div className="flex items-center">
                                      {editedManpowerRow === index ? (
                                        <input
                                          type="date"
                                          onChange={(e) =>
                                            setManpowerInfo((prevMaterialMapInfo) =>
                                              prevMaterialMapInfo.map((v, i) =>
                                                i === index ? { ...v, from_date: new Date(e.target.value) } : v
                                              )
                                            )
                                          }
                                          id={`from_date${index}`}
                                          className="input-style"
                                          value={manpower.from_date ? new Date(manpower.from_date).toISOString().split('T')[0] : ''}
                                        />
                                      ) : (
                                        <div className="text-left">
                                          {getFormattedDate(manpower.from_date.toString())}
                                        </div>
                                      )}
                                    </div>
                                  </td>

                            <td className="p-2">
                                <div className="flex items-center">
                                  {editedManpowerRow === index ? (
                                    <input
                                      type="date"
                                      onChange={(e) =>
                                        setManpowerInfo((prevMaterialMapInfo) =>
                                          prevMaterialMapInfo.map((v, i) =>
                                            i === index ? { ...v, to_date: new Date(e.target.value) } : v
                                          )
                                        )
                                      }
                                      id={`to_date${index}`}
                                      className="input-style"
                                      value={manpower.to_date ? new Date(manpower.to_date).toISOString().split('T')[0] : ''}
                                    />
                                  ) : (
                                    <div className="text-left">{getFormattedDate(manpower.to_date.toString())}</div>
                                  )}
                                </div>
                              </td>

                            <td className="p-2">
                                <div className="flex items-center text-center">
                                {editedManpowerRow === index ? <input type='number' 
                                onChange={(e) => 
                                    {setManpowerInfo((prevMaterialMapInfo) =>
                                        prevMaterialMapInfo.map((v, i) => 
                                            i === index ? { ...v, salary_per_head : Number(e.target.value) } : v 
                                            ))

                                    setManpowerInfo((prevMaterialMapInfo) =>
                                      prevMaterialMapInfo.map((v, i) => 
                                          i === index ? { ...v, total_salary : (Number(e.target.value) * Number(v.count)) } : v 
                                          ))}
                                    }
                                id={`salary_per_head${index}`} className='input-style' value={`${manpower.salary_per_head}`} placeholder='Enter Salary per head'/> 
                                :
                                <div className="text-left">{formatRupeeValue(manpower.salary_per_head)}</div> 
                                }
                                </div>
                            </td>
                            <td className="p-2">
                                <div className="flex items-center ">
                                {editedManpowerRow === index ? <input type='number' 
                                onChange={(e) => 
                                    {setManpowerInfo((prevMaterialMapInfo) =>
                                        prevMaterialMapInfo.map((v, i) => 
                                            i === index ? { ...v, count : Number(e.target.value) } : v 
                                            )
                                )
                                setManpowerInfo((prevMaterialMapInfo) =>
                                  prevMaterialMapInfo.map((v, i) => 
                                      i === index ? { ...v, total_salary : (Number(e.target.value) * Number(v.salary_per_head)) } : v 
                                      ))
                              }}
                                id={`count${index}`} className='input-style' value={`${manpower.count}`} placeholder='Enter Count'/> 
                                :
                                <div className="text-left">{`${manpower.count}`}</div> 
                                }
                                </div>
                            </td>
                            <td className="p-2">
                                <div className="flex items-center">
                                {editedManpowerRow === index ? <input type='number' 
                                onChange={(e) => 
                                    setManpowerInfo((prevMaterialMapInfo) =>
                                        prevMaterialMapInfo.map((v, i) => 
                                            i === index ? {
                                               ...v, total_salary : Number(e.target.value) } : v 
                                            )
                                )} className='input-style' value={`${manpower.total_salary}`} placeholder='Enter total salary'/> 
                                :
                                <div className="text-left">{formatRupeeValue(manpower.total_salary)}</div> 
                                }
                                </div>
                            </td>
                            <td className="p-2">
                                <div className="flex items-center">
                                        {editedManpowerRow === null &&
                                            <button
                                            type="button"
                                            onClick={() => setEditedManpowerRow(index)}
                                            className="action-menu w-auto">
                                            <i className="fas fa-edit w-6 text-left"></i>
                                            <span>Edit</span>
                                          </button>
                                        }
                                        {editedManpowerRow !== null && editedManpowerRow === index &&
                                            <button
                                            type="button"
                                            onClick={handleUpdateMaterialRow}
                                            className="action-menu w-auto">
                                            <i className="fas fa-edit w-6 text-left"></i>
                                            <span>Update</span>
                                          </button>
                                        }
                                      <button
                                        type="button"
                                        onClick={() => handleDeleteMaterialRow(index)}
                                        className="action-menu w-auto">
                                        <i className="fas fa-trash w-6 text-left"></i>
                                        <span>Delete</span>
                                      </button>
                                </div>
                            </td>
                          </tr>
                        </> 
                    )
                })}
                {addManpowerRow &&
                    <>
                    <tr key={addManpowerRow}>
                        <td className="p-2">
                            <div className="flex items-center">
                                <div className="text-left">{`${addManpowerRow}`}</div> 
                            </div>
                        </td>
                        <td className="p-2 whitespace-nowrap">
                            {/* <div className="flex items-center">
                                <input type='text' {...manpowerForm.register('role', { required: true })} className='input-style' placeholder='Enter role'/> 
                            </div> */}
                                                      
                              <Select
                                    value={selectedRole}  
                                    placeholder="Select Role"
                                    isClearable
                                    {...manpowerForm.register('role')}
                                    onChange={(option) => handleRoleChange(option as OptionType)}  
                                    options={roleOptions} 
                                    className=""
                                    required
                                  />

                                
                            
                        </td>
                        <td className="p-2">
                            <div className="flex items-center">
                                <input type='date' {...manpowerForm.register('from_date')} className='input-style'/> 
                            </div>
                        </td>
                        <td className="p-2">
                            <div className="flex items-center">
                                <input type='date' {...manpowerForm.register('to_date')} className='input-style'/> 
                            </div>
                        </td>
                        <td className="p-2">
                            <div className="flex items-center">
                                <input type='number' {...manpowerForm.register('salary_per_head',  { required: true })}
                                onChange={(e: any) => manpowerForm.setValue("total_salary", Number(e.target.value) * Number(manpowerForm.getValues('count')))}
                                 className='input-style' placeholder='Enter salary per head'/> 
                            </div>
                        </td>
                        <td className="p-2">
                            <div className="flex items-center">
                                <input type='number' {...manpowerForm.register('count', { required: true })} 
                                onChange={(e: any) => manpowerForm.setValue("total_salary", Number(manpowerForm.getValues('salary_per_head')) * Number(e.target.value))}
                                className='input-style' placeholder='Enter count'/> 
                            </div>
                        </td>
                        <td className="p-2">
                            <div className="flex items-center">
                                <input type='number' {...manpowerForm.register('total_salary')} className='input-style' placeholder='Enter total salary'/> 
                            </div>
                        </td>
                        <td className="p-2">
                            <div className="flex items-center">
                                <button
                                    type="button"
                                    onClick={manpowerForm.handleSubmit(saveMaterialMap)}
                                    className="action-menu w-auto">
                                    {/* <i className="fas fa-add w-6 text-left"></i> */}
                                   
                                   <div className='flex gap-2'> <FaCheck className='mt-1'/>Save</div>
                                    
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setaddManpowerRow(null);
                                    }}
                                    className="action-menu w-auto">
                                    <i className="fas fa-trash w-6 text-left"></i>
                                    <span>Delete</span>
                                </button>
                            </div>
                        </td>
                    </tr>
                    </>
                } 
                {manpowerInfo.length &&
                    <tr key={manpowerInfo.length + 1}>
                        <td className="p-2" colSpan={6}>
                            <div className="items-center">
                                <div className="text-right"><strong>Total</strong></div> 
                            </div>
                        </td>
                        <td className="p-2" colSpan={2}>
                            <div className="items-center">
                                <div className="text-left">{formatRupeeValue(totalSalary)}</div> 
                            </div>
                        </td>
                    </tr>
                }  
                </tbody>
                </table>
            </div>
            <br/>
            <div className="lg:flex items-center text-center m-autoflex-wrap p-4 pb-6 pt-0 rounded-b-md">
                <button
                type="button"
                disabled={addManpowerRow == null ? false : true}
                onClick={handleaddManpowerRow}
                className="btn-primary w-auto whitespace-nowrap mt-0 mb-1 mx-auto">
                Add new row
                </button>
            </div>
        </div>
        <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
          <button
            type="submit"
            className="px-6 btn-primary w-28 mr-2 ml-0 mt-0"
            onClick={handleSubmit(onSubmit)}>
            Save
          </button>
          <button
            onClick={handleBackClick}
            type="button"
            className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
            data-bs-dismiss="modal">
            Back
          </button>
        </div>
      </form>
      </div>

      {/* <ToastContainer /> */}
    </div>
  );
};

export default AddSalaryConfig;
function setValue(arg0: string, arg1: string) {
  throw new Error('Function not implemented.');
}

