import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import { IMaterialUsage } from 'src/models/materialUsageModel';
import Loader from 'src/components/loader';

const MaterialUsageTable: React.FC = () => {
  const { project_id } = useParams<string>();
  const navigate = useNavigate();
  const [materials, setMaterials] = useState<IMaterialUsage[]>([]);
  const [loading, setLoading] = useState(true);
  const currentPage='materialUsage_info';

  const handleEdit = (usage_id: string) => {
    navigate(`/addMaterialUsage/${project_id}/${usage_id}`);
  };

  const getMaterialUsage = async (): Promise<void> => {
    try {
      const result: AxiosResponse = await ApiService.getData(
        `project-srv/material-usage/getall/${project_id}`
      );
      if (result.data && result.data?.code === 200 && result.data?.success) {
        setMaterials(result?.data?.data || []);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getMaterialUsage();
  }, []);

  // delete material

  const handleDelete = async (id: string) => {
    if (confirm('Do you want to delete this item')) {
      try {
        const response = await ApiService.deleteData(`project-srv/material-usage/${id}`);
        if (response.data && response.data?.data) {
          alert('Material Usage deleted sucessfully');
          getMaterialUsage();
        }
      } catch (error) {
        alert(error);
      }
    } else {
      return false;
    }
  };

  const handleAddMaterialUsage = () => {
    navigate(`/addMaterialUsage/${project_id}`);
  };

  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };
  
  return (
    <div className="max-w-xxl mx-auto mt-8 shadow-lg rounded-lg bg-white">
        <div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto">
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className="hover:underline whitespace-nowrap px-2"
    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className="hover:underline whitespace-nowrap px-2"

    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className={`hover:underline whitespace-nowrap  px-2 ${currentPage==='materialUsage_info' ? 'font-bold text-base' : ''}`}
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Salary Info
    </button>
  </div>
</div>  
      {/* <ol className="breadcrumb">
        <li>
          <Link to={`/projectinfo`}>Project</Link>
        </li>
        <li>
          <Link to={`/materialUsage_info/${project_id}`}>Material Usage</Link>
        </li>
      </ol> */}
          <div className='p-4'>
      <div className="flex justify-between items-center mb-4 mt-5">
        <h2 className="font-semibold ">Material Usage </h2>

        <button
          onClick={handleAddMaterialUsage}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
          <FaPlus className="mr-2" />
          <span className="hidden sm:inline"> Add Material Usage</span>
        </button>
      </div>
      <div className="overflow-x-auto">

        <table className="table-auto w-full border">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 text-center">S.No</th>
              <th className="p-2 text-center whitespace-nowrap">Material Name</th>
              <th className="p-2 text-center whitespace-nowrap">From Date</th>
              <th className="p-2 text-center whitespace-nowrap">To Date</th>
              <th className="p-2 text-center whitespace-nowrap">Quantity</th>

              <th className="p-2 text-center">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {loading ? (
              <tr>
                <td colSpan={4} className="text-center p-4">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {materials && materials.length > 0 ? (
                  materials.map((data, i) => (
                    <tr key={data._id}>
                      <td className="p-2 text-center">{i + 1}</td>
                      <td className="p-2 text-center whitespace-nowrap">{data.material_name}</td>
                      <td className="p-2 text-center">
                        {new Date(data.from_date).toLocaleDateString()}
                      </td>
                      <td className="p-2 text-center">
                        {new Date(data.to_date).toLocaleDateString()}
                      </td>
                      <td className="p-2 text-center">{data.no_of_quantity + ' -' + data.unit}</td>

                      <td className="p-2 text-center">
                        <button className="text-blue-500" onClick={() => handleEdit(data._id)}>
                          <FaEdit className="inline " />
                        </button>
                        <button className="text-red-500 ml-2" onClick={() => handleDelete(data._id)}>
                          <FaTrash className="inline mr-2" />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center p-4">
                      No Materials found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
        </div>

      </div>
     
    </div>
  );
};

export default MaterialUsageTable;
