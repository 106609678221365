import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { TenantAdminInfoModel } from 'src/models/tenants/tenantsModel';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { useTenantActions } from 'src/store/tenants/actions';
import { TenantAdminModel } from 'src/models/usersModel';
import Loader from 'src/components/loader';
import { Link, useNavigate, useParams } from 'react-router-dom';

const AddTenantAdmin: React.FC = () => {
  const { tenantId, id } = useParams<string>();
  const dispatch = useDispatch();
  const tenantActions = useTenantActions(dispatch);
  const btnRef = useRef<HTMLButtonElement>(null);
  const animatedComponents = makeAnimated();
  const [selectedRoles, setselectedRoles] = useState<any[]>([]);
  const [errorsOnRoles, setErrorsOnRoles] = useState<boolean>(false);
  const [allowedRoles, setAllowedRoles] = useState<any[]>([]);
  const navigate = useNavigate();
  const {
    tenantRoleInfo,
    tenantAdminsinfo,
    isTenantAdminSaved,
    isTenantAdminLoading,
    isTenantAdminUpdated,
    message
  } = useSelector((state: RootState) => {
    return {
      tenantRoleInfo: state.tenant.tenantRolesInfo,
      tenantAdminsinfo: state.tenant.tenantAdminsInfo,
      isTenantAdminSaved: state.tenant.isTenantAdminSaved,
      isTenantAdminLoading: state.tenant.isTenantAdminLoading,
      isTenantAdminUpdated: state.tenant.isTenantAdminUpdated,
      message: state.tenant.message
    };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm<TenantAdminInfoModel>();

  //on click of Add/Update Button
//   const onSubmit = (data: TenantAdminInfoModel) => {
//     if(tenantId) {
//       selectedRoles.length == 0 ? setErrorsOnRoles(true) : setErrorsOnRoles(false);
//       data.roles = selectedRoles.map(e => e.value);
//       data.tenant_id = tenantId;
//       tenantActions.saveTenantAdminRequest(data);
//     }
//     if(tenantId && id) {
//       selectedRoles.length == 0 ? setErrorsOnRoles(true) : setErrorsOnRoles(false);
//       data.roles = selectedRoles.map(e => e.value);
//       data.tenant_id = tenantId;
//       data._id = id;
//       tenantActions.saveTenantAdminRequest(data);
//     }
// };
//on click of Add/Update Button
const onSubmit = async (data: TenantAdminInfoModel) => {
  try {
    if (tenantId) {
      selectedRoles.length === 0 ? setErrorsOnRoles(true) : setErrorsOnRoles(false);
      data.roles = selectedRoles.map(e => e.value);
      data.tenant_id = tenantId;
      if (id) {
        data._id = id;
      }
      await tenantActions.saveTenantAdminRequest(data);
    }
  } catch (error: any) {
    console.error('Error saving tenant admin:', error);
    alert(`An error occurred: ${error?.message || 'Unknown error'}`);
  }
};

const handleSelectRoles = (data: any) => {
    setselectedRoles(data);
};

useEffect(() => {
    if(!id && tenantRoleInfo.length) {
      const roles = tenantRoleInfo.map((e) => 
        { return {"label": e.role_key, "value": e._id}});
      setAllowedRoles(roles);
    } 
    if(tenantAdminsinfo.length && id && tenantAdminsinfo.length) {
      const roles = tenantRoleInfo.map((e) => 
        { return {"label": e.role_key, "value": e._id}});
      setAllowedRoles(roles);
      const tenantAdminRole = tenantAdminsinfo.find((e) => e._id == id);
      if(tenantAdminRole?.roles && tenantAdminRole?.roles.length) {
        const roles = [];
        for(const tenantAdminRoleId of tenantAdminRole?.roles) {
          const tenantRole = tenantRoleInfo.find(e => e._id == tenantAdminRoleId)?.role_key;
          roles.push({ label: tenantRole, value: tenantAdminRoleId })
        }
        setselectedRoles(roles)
        reset(tenantAdminRole)
      }
    }
}, [tenantRoleInfo, tenantAdminsinfo])

useEffect(() => {
  if(tenantId) {
    tenantActions.getTenantRolesByTenantIdRequest(tenantId);
  }
  if(tenantId && id) {
    tenantActions.getTenantAdminsByTenantIdRequest(tenantId)
  }
}, [tenantId, id])

useEffect(() => {
  if (isTenantAdminSaved || isTenantAdminUpdated) {
    alert("Tenant admin sucessfully");
    reset({} as TenantAdminModel)
    navigate(`/tenantadmins/${tenantId}`)
  }
}, [isTenantAdminSaved || isTenantAdminUpdated]);
useEffect(() => {
  if (message) {
    
    alert(message);
    
  }
}, [message]);

const handleBackClick = () => {
    setselectedRoles([]);
    navigate(`/tenantadmins/${tenantId}`)
  };

  return (
    <>
    <div className="shadow p-5 border">
        <div className="mb-8">
          <h2 className="font-semibold my-1">Tenant Admin</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/tenants">Home</Link>
            </li>
            <li>
              <Link to={`/tenantadmins/:${tenantId}`}>Tenants Admins info</Link>
            </li>
            <li className="active">
              {id ? (<strong>Edit</strong>) : (<strong>Add</strong>)}
            </li>
          </ol>
        </div>
        <div className="flex flex-col justify-center h-full">
          <div className="xl:flex">
            {
              isTenantAdminLoading ? (
                <Loader />
              ) : (
                <>
                {' '}
                <form>
                  <div className="grid grid-cols-4 xl:gap-8 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1">Name <code>*</code></label>
                      <input
                        type="text"
                        className="input-style"
                        id="name"
                        {...register('name', {
                          required: true,
                          maxLength: 500
                        })}
                      />
                      {errors.name?.type === 'required' && <p className="text-red-700">Required Field</p>}
                      {errors.name?.type === 'maxLength' && <p className="text-red-700">Max. length (500 letters) reached</p>}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">First name <code>*</code></label>
                      <input
                        type="text"
                        className="input-style"
                        id="first_name"
                        {...register('first_name', {
                          required: true,
                          maxLength: 500
                        })}
                      />
                      {errors.first_name?.type === 'required' && <p className="text-red-700">Required Field</p>}
                      {errors.first_name?.type === 'maxLength' && <p className="text-red-700">Max. length (500 letters) reached</p>}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">Last name <code>*</code></label>
                      <input
                        type="text"
                        className="input-style"
                        id="last_name"
                        {...register('last_name', {
                          required: true,
                          maxLength: 500
                        })}
                      />
                      {errors.first_name?.type === 'required' && <p className="text-red-700">Required Field</p>}
                      {errors.first_name?.type === 'maxLength' && <p className="text-red-700">Max. length (500 letters) reached</p>}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">Email</label>
                      <input
                        type="text"
                        className="input-style"
                        id="email"
                        {...register('email')}
                      />
                    </div>
                    
                  </div>
                  <div className="grid grid-cols-4 xl:gap-8 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1">Phone  no <code>*</code></label>
                      <input
                        type="number"
                        className="input-style"
                        id="phone_no"
                        {...register('phone_no', {
                          required: true,
                          maxLength: 10
                        })}
                      />
                      {errors.first_name?.type === 'required' && <p className="text-red-700">Required Field</p>}
                      {errors.first_name?.type === 'maxLength' && <p className="text-red-700">Max. length (10 letters) reached</p>}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">Username <code>*</code></label>
                      <input
                        type="string"
                        className="input-style"
                        id="user_name"
                        {...register('auth_config.user_name', {
                          required: true,
                          maxLength: 10
                        })}
                      />
                      {errors.auth_config?.user_name?.type === 'required' && <p className="text-red-700">Required Field</p>}
                      {errors.auth_config?.user_name?.type === 'maxLength' && <p className="text-red-700">Max. length (10 letters) reached</p>}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">Password <code>*</code></label>
                      <input
                        type="string"
                        className="input-style"
                        id="password_hash"
                        {...register('auth_config.password_hash', {
                          required: true,
                          maxLength: 10
                        })}
                      />
                      {errors.auth_config?.password_hash?.type === 'required' && <p className="text-red-700">Required Field</p>}
                      {errors.auth_config?.password_hash?.type === 'maxLength' && <p className="text-red-700">Max. length (10 letters) reached</p>}
                    </div>
                    <div className={`form-group`}>
                        <label className="block mb-1">Roles <code>*</code></label>
                        {allowedRoles && (
                            <Select
                            options={allowedRoles}
                            components={animatedComponents}
                            value={selectedRoles}
                            className="w-full mr-2"
                            id="roles"
                            isMulti
                            onChange={handleSelectRoles}
                            isSearchable={true}
                            isLoading={allowedRoles.length ? false : true}
                            />
                        )}
                        {errorsOnRoles && <p className="text-red-700">Required Field</p>}
                    </div>
                  </div>
                  <div className="grid grid-cols-4 xl:gap-8 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1">Address lane <code>*</code></label>
                      <textarea
                        className="input-style"
                        id="address_lane"
                        {...register('address.address_lane')}
                      ></textarea>
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">City </label>
                      <input
                        type="string"
                        className="input-style"
                        id="city"
                        {...register('address.city')}
                      />
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">District</label>
                      <input
                        type="string"
                        className="input-style"
                        id="district"
                        {...register('address.district')}
                      />
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">State</label>
                      <input
                        type="string"
                        className="input-style"
                        id="state"
                        {...register('address.state')}
                      />
                    </div>
                    
                  </div>
                  <div className="grid grid-cols-4 xl:gap-8 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1">Pincode</label>
                      <input
                        type="string"
                        className="input-style"
                        id="pincode"
                        {...register('address.pincode')}
                      />
                    </div>
                  </div>
                  <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                    <button                  
                      className="px-6 btn-primary w-28 mr-2 ml-0 mt-0"
                      onClick={handleSubmit(onSubmit)} >
                      Save
                    </button>
                    <button
                      ref={btnRef}
                      onClick={handleBackClick}
                      type="button"
                      className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                      data-bs-dismiss="modal">
                      Close
                    </button>
                  </div>
                </form>
                </>
              )
            }
          </div>
        </div>
      </div>
    </>
  );
};
export default AddTenantAdmin;