import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { ApiService } from 'src/components/utilities/api.service';
import { IServiceInfo } from 'src/models/service';
import Breadcrumbs from '../BreadCrumbsComponent';
import { getUploadedFile } from 'src/components/utilities/utils';

const UpdateSetting: React.FC = () => {
  const { tenant_id } = useParams<string>();
  const [serviceId, setServices] = useState<string>();
  const [isGSTApplicable, setIsGSTApplicable] = useState<boolean>(false);


  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IServiceInfo>();

  const [fileUrl, setFileUrl] = useState('');

  // fetch the data by id and update
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getData(`tenant-srv/tenant/service/tenantId/${tenant_id}`);
        setFileUrl(response.data.data.logo);        
        reset(response.data.data);
        setServices(response.data.data._id);
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };

    if (tenant_id) {
      fetchData();
    }
  }, [tenant_id]);

  const handleBackClick = () => {
    navigate('/');
  };

  const handleGSTApplicableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsGSTApplicable(isChecked);
    if (!isChecked) {
      setValue('gst', 0); // Reset GST field if checkbox is unchecked
    }
  };

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const result: AxiosResponse = await ApiService.uploadImage(
          'tenant-srv/tenant/upload',
          file
        );

        setFileUrl(result.data.data);
        // toast.success("image uploaded")
      } catch (error) {
        console.error('Error uploading image:', error);
        // toast.error('Failed to upload image');
      }
    }
  };
  const [imgUrl, setImageUrl] = useState('');

  // submit form data
  const onSubmit: SubmitHandler<IServiceInfo> = async (data) => {
    // data.logo = 'logo.j'peg';
    data.logo = fileUrl;
    if (serviceId) {
      data.id, (data._id = serviceId);
    }
    const result: AxiosResponse = await ApiService.postData('tenant-srv/tenant/service', data);
    navigate('/');

    alert('Form submited');

    reset();
  };

  return (
    <>
      <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
        <div className="flex">
          <Breadcrumbs items={[{ label: 'Profile', path: `/` }]} />
          {/* <h2 className="font-semibold mb-4">Service </h2>
          {id ? <h2 className="font-block"> /Edit</h2> : <h2 className="font-block"> /Add</h2>} */}
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid  sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
            <div className="form-group">
              <label className="block mb-1">
                 Name<span className="text-red-700 ml-1">*</span>
              </label>
              <input
                type="text"
                className="input-style"
                {...register('first_name', { required: true, maxLength: 20 })}
              />
              {errors.first_name?.type === 'required' && (
                <p className="text-red-700">Required field</p>
              )}
              {errors.first_name?.type === 'maxLength' && (
                <p className="text-red-700">Max length exceeded</p>
              )}
            </div>
            <div className="form-group">
              <label className="block mb-1">Qualification</label>
              <input type="text" className="input-style" {...register('last_name')} />
              {errors.last_name && <p className="text-red-700">{errors.last_name.message}</p>}
            </div>
          </div>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
            <div className="form-group">
              <label className="block mb-1">
                Company Name<span className="text-red-700 ml-1">*</span>
              </label>
              <input
                type="text"
                className="input-style"
                {...register('name', { required: true })}
              />
              {errors.name?.type === 'required' && <p className="text-red-700">Required field</p>}
            </div>
            <div className="form-group">
              <label className="block mb-1">Contact Number</label>
              <input type="number" className="input-style" {...register('contact_number')} />
              {errors.contact_number && (
                <p className="text-red-700">{errors.contact_number.message}</p>
              )}
            </div>
          </div>
          <div className="grid  sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
            {/* <div className="form-group">
              <label className="block mb-1">GST</label>
              <input type="text" className="input-style" {...register('gst_number')} />
              {errors.gst_number?.type === 'required' && (
                <p className="text-red-700">Required field</p>
              )}
              {errors.gst_number?.type === 'maxLength' && (
                <p className="text-red-700">Max length exceeded</p>
              )}
              </div> */}
            <div className="form-group">
              <label className="block mb-1">GST No.</label>
              <input type="text" className="input-style" {...register('gst_number')} />
              {errors.gst_number?.type === 'required' && (
                <p className="text-red-700">Required field</p>
              )}
              {errors.gst_number?.type === 'maxLength' && (
                <p className="text-red-700">Max length exceeded</p>
              )}
              </div>

          
            <div className="form-group">
              <label className="block mb-1">Email</label>
              <input type="email" className="input-style" {...register('email')} />
            </div>
          </div>
          <div className="grid  sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
            <div className="form-group">
              <label className="block mb-1">TIN Number</label>
              <input type="text" className="input-style" {...register('tin_number')} />
            </div>
            <div className="form-group">
              <label className="block mb-1">Address</label>
              <textarea className="input-style" {...register('location')} />
            </div>
          </div>
          <div className="grid  sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
            <div className="form-group">
              <label className="block mb-1">Nature of Work</label>
              <input type="text" className="input-style" {...register('nature_of_work')} />
            </div>
            {/* Example of file input (uncomment if needed) */}
            <div className="form-group">
              <label className="block mb-1">Logo</label>
              <input
                type="file"
                className="input-style"
                {...register('logo')}
                onChange={handleFileUpload}
              />

              {fileUrl && (
                <div>
                  <img
                    src={fileUrl}
                    alt="Logo"
                    className="mb-2 mt-3"
                    style={{ maxHeight: '100px' }}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="flex justify-center ">
            <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
              {tenant_id ? 'Update' : 'Save'}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default UpdateSetting;
function setValue(arg0: string, arg1: number) {
  throw new Error('Function not implemented.');
}

