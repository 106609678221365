import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'src/store/reducers/state';
import { TenantRole } from 'src/models/tenants/tenantsModel';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { useTenantActions } from 'src/store/tenants/actions';
interface IProps {
    tenantId: string;
}
const TenantRoleModel: React.FC<IProps> = ({ tenantId }) => {
  const dispatch = useDispatch();
  const tenantActions = useTenantActions(dispatch);
  const btnRef = useRef<HTMLButtonElement>(null);
  const animatedComponents = makeAnimated();
  const [selectedPermissions, setselectedPermissions] = useState<any[]>([]);
  const [errorsOnPermissions, setErrorsOnPermissions] = useState<boolean>(false);
  const {
    isTenantRoleAdded,
    isTenantROleUpdated
  } = useSelector((state: RootState) => {
    return {
      isTenantRoleAdded: state.tenant.isTenantRoleSaved,
      isTenantROleUpdated: state.tenant.isTenantROleUpdated,
    };
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm<TenantRole>();

  //on click of Add/Update Button
  const onSubmit = (data: TenantRole) => {
    selectedPermissions.length == 0 ? setErrorsOnPermissions(true) : setErrorsOnPermissions(false);
    data.permission = selectedPermissions.map(e => e.value);
    data.tenant_id = tenantId;
    tenantActions.saveTenantRoleRequest(data);
  };

  useEffect(() => {
    handleCloseClick()
    if (btnRef.current) {
        btnRef.current.click();
    }
  }, [isTenantROleUpdated, isTenantRoleAdded]);
const allowedPermissions = [
    { value: "service_read", label: "service_read" },
    { value: "service_create", label: "service_create" },
    { value: "theme_read", label: "theme_read" },
    { value: "theme_create", label: "theme_create" },
]

const handleSelectPermissions = (data: any) => {
    setselectedPermissions(data);
};

const handleCloseClick = () => {
    reset({
        role_key: '',
        role_description: ''
    });
    setselectedPermissions([]);
  };

  return (
    <div
      className={`modal fade fixed top-0 left-0 hidden w-full h-full outline-none overflow-x-hidden overflow-y-auto modal-open`}
      id="addTenantRoleModel"
      aria-labelledby="addTenantRoleModelLabel"
      aria-hidden="true">
      <div className="modal-dialog relative w-auto pointer-events-none max-w-xl">
        <div className="modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
          <div className="relative modal-header flex flex-shrink-0 items-center justify-between p-4 pb-2 rounded-t-md">
            <h5
              className="text-xl font-semibold leading-normal text-center w-full" id="addTenantRoleModelLabel">
              Add Tenant Role
            </h5>
            <button
              type="button"
              className="dismiss-btn"
              data-bs-dismiss="modal"
              aria-label="Close" onClick={handleCloseClick}></button>
          </div>
          <div className="modal-body relative py-4 px-10">

            <form>
              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <label className="block mb-1">Role key <code>*</code></label>
                  <input
                    type="text"
                    className="input-style"
                    id="role_key"
                    {...register('role_key', {
                      required: true,
                      maxLength: 500
                    })}
                  />
                  {errors.role_key?.type === 'required' && <p className="text-red-700">Required Field</p>}
                  {errors.role_key?.type === 'maxLength' && <p className="text-red-700">Max. length (500 letters) reached</p>}
                </div>
                <div className={`form-group`}>
                <label className="block mb-1">Permissions <code>*</code></label>
                    {allowedPermissions && (
                        <Select
                        options={allowedPermissions}
                        components={animatedComponents}
                        value={selectedPermissions}
                        className="w-full mr-2"
                        id="permission"
                        isMulti
                        onChange={handleSelectPermissions}
                        isSearchable={true}
                        isLoading={allowedPermissions.length ? false : true}
                        />
                    )}
                    {errorsOnPermissions && <p className="text-red-700">Required Field</p>}
                </div>
              </div>
              <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <label className="block mb-1">Description</label>
                  <textarea
                    className="input-style"
                    id="module"
                    {...register('role_description')}
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                <button                  
                  className="px-6 btn-primary w-28 mr-2 ml-0 mt-0"
                  onClick={handleSubmit(onSubmit)} >
                  Save
                </button>
                <button
                  ref={btnRef}
                  onClick={handleCloseClick}
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                  data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TenantRoleModel;