import React,{ useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import Loader from 'src/components/loader';
import { TenantInfoModel } from 'src/models/tenants/tenantsModel';
import { useTenantActions } from 'src/store/tenants/actions';
import { ApiService } from 'src/components/utilities/api.service';
import CreatableSelect from 'react-select/creatable';
import { OptionType } from 'src/models/materialModels';

const AddTenant = () => {
  //Input Parameter
  const { id } = useParams<string>();
  //Dispatches
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //Actions
  const tenantActions = useTenantActions(dispatch);
  const initialCategoryOptions: OptionType[] = [];
  const [domainsOptions, setDomainsOptions] = useState<OptionType[]>(initialCategoryOptions);
  const [domainsValue, setDomainsValue] = useState<OptionType[]>([]);
  //Getting values from RootState
  const { tenants, isTenantSaved, isTenantUpdated, message, loading } = useSelector(
    (state: RootState) => {
      return {
        tenants: state.tenant.tenantsInfoModel,
        isTenantSaved: state.tenant.isTenantSaved,
        isTenantUpdated: state.tenant.isTenantUpdated,
        message: state.tenant.message,
        loading: state.tenant.loading
      };
    }
  );

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
    reset
  } = useForm<TenantInfoModel>();

  const handleBackClick = () => {
    navigate('/tenants');
  };

  const onSubmit =  (data: TenantInfoModel) => {
    try{
    if (id) {
      data._id = id;
      data.id=id;
      data.allowed_domains =  domainsValue.map((e) => e.value)
      tenantActions.saveTenantRequest(data);
    }
    else{
      data.allowed_domains =  data.allowed_domains_options.map((e) => e.value)
      tenantActions.saveTenantRequest(data);
    }
    
  }
  catch(error){
    alert(error)
  }
  };

  const handleDomainsChange = (selectedOptions: OptionType[]) => {
    setDomainsValue(selectedOptions);
    setValue('allowed_domains_options', selectedOptions);
  };

  const handleDomainsCreate = (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue };
    setDomainsOptions((prevOptions) => [...prevOptions, newOption]);
    setDomainsValue((prevOptions) => [...prevOptions, newOption]);
    setValue('allowed_domains_options', [...(getValues('allowed_domains_options') || []), newOption]);
  };

  useEffect(() => {
    if (id) {
      tenantActions.getTenantsRequest();
    }
    const tenant_info = tenants.find((e) => e._id == id);
    if (!tenant_info && id) {
      handleBackClick();
    }
    const domainsOptionsVal = tenant_info?.allowed_domains.map(e => {
      return { label: e, value: e }
    })
    if(domainsOptionsVal && domainsOptionsVal != undefined) {
      setDomainsValue(domainsOptionsVal);
      // setValue('allowed_domains_options', [...(getValues('allowed_domains_options') || domainsOptionsVal)]);
      // setValue("allowed_domains_options", domainsOptionsVal);
    }
    reset(tenant_info);
  }, [id]);

  useEffect(() => {
    if (isTenantSaved || isTenantUpdated) {
      alert("Tenant saved successfully");
      reset({} as TenantInfoModel);
      navigate('/tenants');
    }
  }, [isTenantSaved, isTenantUpdated]);

  useEffect(()=>{
    if(message){
      alert(message)
    }
  },[message])
  return (
    <>
      <div className="shadow p-5 border">
        <div className="mb-8">
          <h2 className="font-semibold my-1">Tenant</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/tenants">Home</Link>
            </li>
            <li>
              <Link to="/tenants">Tenants</Link>
            </li>
            <li className="active">{id ? <strong>Edit</strong> : <strong>Add</strong>}</li>
          </ol>
        </div>
        <div className="flex flex-col justify-center h-full">
          <div className="xl:flex">
            {loading ? (
              <Loader />
            ) : (
              <>
                {' '}
                <form>
                  <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>Name</strong>
                      </label>
                      <input
                        type="text"
                        className="input-style"
                        placeholder="Enter Name"
                        id="name"
                        {...register('name', {
                          required: true,
                          maxLength: 100
                        })}
                      />
                      {errors.name?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                      {errors.name?.type === 'maxLength' && (
                        <p className="text-red-700"> Exceeded the limit</p>
                      )}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>Tenant key</strong>
                      </label>
                      <input
                        type="text"
                        className="input-style"
                        placeholder="Enter Tenant key"
                        id="tenant_key"
                        {...register('tenant_key', {
                          required: true,
                          maxLength: 10
                        })}
                      />
                      {errors.tenant_key?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                      {errors.tenant_key?.type === 'maxLength' && (
                        <p className="text-red-700"> Exceeded the limit</p>
                      )}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>Tenant code</strong>
                      </label>
                      <input
                        type="text"
                        className="input-style"
                        placeholder="Enter Tenant code"
                        id="tenant_code"
                        {...register('tenant_code', {
                          required: true
                        })}
                      />
                      {errors.tenant_code?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                    </div>
                  </div>
                  <div className="grid grid-cols-3 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                    <div className="mb-4">
                      <label className="block mb-2 ">
                        Allowed Domains
                      </label>
                      <Controller
                        name="allowed_domains_options"
                        control={control}
                        render={({ field }) => (
                          <CreatableSelect
                            {...field}
                            options={domainsOptions}
                            value={domainsValue}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selectedOptions) =>
                              handleDomainsChange(selectedOptions as OptionType[])
                            }
                            onCreateOption={handleDomainsCreate}
                            isMulti
                            placeholder="Add allowed domains"
                          />
                        )}
                      />
                      {errors.allowed_domains && (
                        <p className="text-red-700 mt-1">At least one domain is selected</p>
                      )}
                    </div>
                    </div>
                  </div>
                  <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                    <button
                      type="submit"
                      className="px-6 btn-primary w-28 mr-2 ml-0 mt-0"
                      onClick={handleSubmit(onSubmit)}>
                      Save
                    </button>
                    <button
                      onClick={handleBackClick}
                      type="button"
                      className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                      data-bs-dismiss="modal">
                      Back
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AddTenant;
