import { all, put, takeEvery } from 'redux-saga/effects';
import { LoginActions } from './actions';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import * as Constants from './constants';
import { setItem } from 'src/components/utilities/utils';
import { URLS } from 'src/components/utilities/endpoints';
import { TenantAdminModel } from 'src/models/usersModel';

function* asyncLogin(data: LoginActions.sendLoginRequestAction) {
  try {
    const response: AxiosResponse = yield ApiService.postLoginData(URLS.LOGIN.url, data.payload);
    if (response.data.code === 200 && response.data.success) {
      setItem('token', response.data.data?.access_token);
      const userData: TenantAdminModel = {} as TenantAdminModel;
      (userData.first_name = response.data.data.first_name),
        (userData.last_name = response.data.data.last_name),
        (userData.email = response.data.data.email),
        (userData.roles = response.data.data.roles),
        (userData.logo = response.data.data.logo);
      userData.tenant_id = response.data.data.tenant_id;
      userData.service_id = response.data.data.service_id;
      userData.userLogo = response.data.data.userLogo;
      userData.permissions = response.data.data.permissions;
      const date = new Date();

      const updateDate = new Date(date.getTime() + response.data.data.expires_in * 1000);
      document.cookie =
        'RefreshCookie  = ' + updateDate.toString() + '; expires = ' + updateDate.toString();
      localStorage.setItem('userData', JSON.stringify(userData));

      // const expiryRefreshTokenDate = new Date(date.getTime());
      // expiryRefreshTokenDate.setDate(expiryRefreshTokenDate.getDate() + response.data.Result.RefreshTokenExpiresInDays);
      // document.cookie = 'RefreshTokenExpiryCookie = ' + expiryRefreshTokenDate.toString() + "; expires = " + expiryRefreshTokenDate.toString();
      // const expiryRefreshTokenDate = new Date(date.getTime());
      // expiryRefreshTokenDate.setDate(expiryRefreshTokenDate.getDate() + response.data.Result.RefreshTokenExpiresInDays);
      // document.cookie = 'RefreshTokenExpiryCookie = ' + expiryRefreshTokenDate.toString() + "; expires = " + expiryRefreshTokenDate.toString();

      // setItem('refreshToken', response.data.Result.RefreshToken)
      yield put(LoginActions.sendLoginRequestSuccess(response.data));
    }
  } catch (error) {
    const err = error as AxiosError;
    yield put(LoginActions.sendLoginRequestFailure(error));
    if (err.response) {
      yield put(LoginActions.sendLoginRequestFailure(err.response.data.error.error_description));
    } else {
      yield put(LoginActions.sendLoginRequestFailure(error));
    }
  }
}

function* asyncDomainList(data: LoginActions.sendLoginRequestAction) {
  try {
    const response: AxiosResponse = yield ApiService.getData(URLS.GET_CHANNELS.url);
    if (response.status === 200) {
      yield put(LoginActions.getDomainListRequestSuccess(response.data.result));
    }
  } catch (error) {
    yield put(LoginActions.getDomainListRequestFailure());
  }
}

export function* loginSaga() {
  yield all([
    takeEvery(Constants.SendLoginRequestActionType, asyncLogin),
    takeEvery(Constants.GetDomainListRequestActionType, asyncDomainList)
  ]);
}
