import React, { useState, useEffect, useRef } from 'react';
import { FaEdit, FaTrash, FaEllipsisV, FaPlus } from 'react-icons/fa';
import Loader from 'src/components/loader';
import { ApiService } from 'src/components/utilities/api.service';
import { AxiosResponse } from 'axios';
import { IProjectInfo } from 'src/models/project';
import { Link, useNavigate, useParams } from 'react-router-dom';

const ProjectInfo: React.FC = () => {
  const [openMenuId, setOpenMenuId] = useState<string | null>(null);
  const [projects, setProject] = useState<IProjectInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const menuRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const getProject = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData('project-srv/project');
      setProject(result?.data?.data);
      setLoading(false);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getProject();
  }, []);

  const handleEdit = (id: string) => {
    navigate(`/projectadd/${id}`);
  };

  const handleDelete = async (id: any) => {
    if (window.confirm('Do you want to delete?')) {
      try {
        const response: AxiosResponse = await ApiService.deleteData(`project-srv/project/${id}`);
        if (response.data && response.data?.data) {
          alert('Project Deleted Successfully');

          getProject();
        }
      } catch (error) {
        alert(error);
      }
    } else {
      return false;
    }
  };

  const handleAddProject = () => {
    navigate('/projectadd');
  };

  // const handleMaterialInfo = (id: string) => {
  //   window.open(`/materialmappinginfo/${id}`);
  // };
  // const handleMaterialUsage = (id: string) => {
  //   window.open(`/materialUsage_info/${id}`);
  // };

  // const handleMenuToggle = (id: string) => {
  //   setOpenMenuId(openMenuId === id ? null : id);
  // };

  // const handleClientInfo = (id: string) => {
  //   window.open(`/clientinfo/${id}`);
  // };

  // const handleSalaryInfo = (id: string) => {
  //   window.open(`/salaryexpendituretabel/${id}`);
  // };

  // const handleSalaryConfig = (id: string) => {
  //   window.open(`/salaryconfig/${id}`);
  // };


  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpenMenuId(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-semibold">Projects</h2>

        <button
          onClick={handleAddProject}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
          <FaPlus className="mr-2" />
          
          <span className="hidden sm:inline">Add New Project</span>
        </button>
      </div>
      <div className="flex-grow overflow-auto">
        <table className="table-auto w-full border h-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 text-center">S.no</th>
              <th className="p-2 text-center">Name</th>
              <th className="p-2 text-center whitespace-nowrap">Total Cost</th>
              <th className="p-2 text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {loading ? (
              <tr key={'load'}>
                <td>
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {projects && projects.length > 0 ? (
                  projects.map((data, index) => (
                    <tr key={data._id}>
                      <td className="p-2 text-center">{index + 1}</td>
                      <td className="p-2 text-center whitespace-nowrap">
                      <Link
                        to={`/moreaction/${data.id}`}
                        className="text-blue-500 hover:underline" >
                        {data.name}
                        </Link></td>
                      <td className="p-2 text-center">
                 
                        {data.total_cost}
                        </td>
                      <td className="p-2 flex items-center justify-center gap-2 relative">
                        <button className="text-blue-500" onClick={() => handleEdit(data._id)}>
                          <FaEdit className="inline mr-1" />
                        </button>
                        <button className="text-red-500" onClick={() => handleDelete(data._id)}>
                          <FaTrash className="inline mr-1" />
                        </button>
                        {/* <button
                          className="text-gray-500"
                          onClick={() => handleMenuToggle(data._id)}>
                          <FaEllipsisV className="inline mr-1 cursor-pointer text-right" />
                        </button>
                        {openMenuId === data._id && (
                          <div
                            ref={menuRef}
                            className="absolute right-0 mt-2 w-48 bg-white border rounded shadow-lg z-10"
                            style={{
                              bottom: '100%',
                              marginBottom: '1rem',
                              transform: 'translateY(100%)'
                            }}>
                            <button
                              onClick={() => handleClientInfo(data._id)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                              Client Info
                            </button>
                            <button
                              onClick={() => handleMaterialInfo(data._id)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                              Material Mapping
                            </button>
                            <button
                              onClick={() => handleMaterialUsage(data._id)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                              Material Usage
                            </button>

                            <button
                              onClick={() => handleSalaryInfo(data._id)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                              Expenditure
                            </button>
                            <button
                              onClick={() => handleSalaryConfig(data._id)}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                              Salary Config
                            </button> */}
                           {/* </div> */}
                        {/* )} */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center p-4">
                      No projects found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProjectInfo;
