import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IClientInfo } from 'src/models/clientModel';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import Breadcrumbs from 'src/components/admin/BreadCrumbsComponent';

const Client: React.FC = () => {
  const { client_id } = useParams<string>();
  const { project_id } = useParams<string>();
  const [fileUrl, setFileUrl] = useState('');
  const currentpage='clientinfo';	
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<IClientInfo>();

  // fetch the data by id and update
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getData(`project-srv/client/${client_id}`);
        reset(response.data.data);
      } catch (error) {
        alert(error);
      }
    };

    if (client_id) {
      fetchData();
    }
  }, [client_id]);

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const result: AxiosResponse = await ApiService.uploadImage(
          'tenant-srv/tenant/upload',
          file
        );

        setFileUrl(result.data.data.url);
      } catch (error) {
        alert(error);
      }
    }
  };

  const handleBackClick = () => {
    navigate(`/clientinfo/${project_id}`);
  };

  const onSubmit: SubmitHandler<IClientInfo> = async (data) => {
    try {
      data.photo = fileUrl;
      data.project_id = project_id as string;

      if (client_id) {
        data.id, (data._id = client_id);
      }
      const result: AxiosResponse = await ApiService.postData('project-srv/client', data);

      if (result.data && result.data?.code === 200 && result.data?.success) {
        alert('Client saved successfully');
        navigate(`/clientinfo/${project_id}`);
      }
      reset();
    } catch (error) {
      alert(error);
    }
  };
  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };

  return (
    <div className="mt-8 max-w-xxl mx-auto shadow-lg rounded-lg bg-white">      
      <div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto mb-10">   
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">  
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className={`hover:underline whitespace-nowrap px-2 ${currentpage==='clientinfo' ? 'text-base font-bold' : '' }`}


    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className="hover:underline whitespace-nowrap  px-2"
    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Salary Info
    </button>
  </div>
</div>
      <div className='container'>
        <p className='font-extrabold text-xl text-center mb-4 h4'>Add Client</p>
      </div>
      <div className='p-3 mb-5 '>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div className="form-group mb-4">
            <label className="block mb-2 " htmlFor="clientName">
              Client Name<span className="text-red-700 ml-1">*</span>
            </label>
            <input
              type="text"
              id="clientName"
              className="input-style"
              {...register('name', { required: 'Client Name is required' })}
            />
            {errors.name && <p className="text-red-700 mt-1">{errors.name.message}</p>}
          </div>

          <div className="form-group mb-4">
            <label className="block mb-2 " htmlFor="phoneNumber">
              Phone Number<span className="text-red-700 ml-1">*</span>
            </label>
            <input
              type="text"
              id="phone_no"
              className="input-style"
              {...register('phone_no', { required: true, maxLength: 10 })}
            />
            {errors.phone_no?.type === 'required' && <p className="text-red-700">Required field</p>}
            {errors.phone_no?.type === 'maxLength' && (
              <p className="text-red-700">Max length exceeded</p>
            )}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div className="form-group mb-4">
            <label className="block mb-2 " htmlFor="email">
              Email
            </label>
            <input type="email" id="email" className="input-style" {...register('email')} />
            {errors.email && <p className="text-red-700 mt-1">{errors.email.message}</p>}
          </div>
          <div className="form-group mb-4">
            <label className="block mb-1" htmlFor="photo">
              Photo
            </label>
            <input
              type="file"
              id="photo"
              className="input-style"
              {...register('photo')}
              onChange={handleFileUpload}
            />
            {errors.photo && <p className="text-red-700 mt-1">{errors.photo.message}</p>}
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div className="form-group mb-4">
            <label className="block mb-2" htmlFor="address">
              Address
            </label>
            <textarea id="address" className="input-style" {...register('address')} />
            {errors.address && <p className="text-red-700 mt-1">{errors.address.message}</p>}
          </div>

          <div className="form-group mb-4">
            <label className="block mb-2 " htmlFor="description">
              Description
            </label>
            <textarea id="description" className="input-style" {...register('description')} />
            {errors.description && (
              <p className="text-red-700 mt-1">{errors.description.message}</p>
            )}
          </div>
        </div>

        <div className="flex justify-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
            {client_id ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            className="px-6 btn-primary discard-btn w-28 mr-2 ml-0 mt-3"
            onClick={handleBackClick}>
            Cancel
          </button>
        </div>
      </form>
      </div>

      <ToastContainer />
    </div>
  );
};

export default Client;
