import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';

import Select from 'react-select';
import { ISalaryReportPayload, ISalaryReportResponse } from 'src/models/salaryReport';

import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import Loader from 'src/components/loader';

interface OptionType {
  value: string;
  label: string;
}

const SalaryReport: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = useForm<ISalaryReportPayload>();

  const [manpowerOption, setManpowerOptions] = useState<OptionType[]>([]);
  const [projectOption, setProjectOptions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(true);
  const [salary, setSalary] = useState<ISalaryReportResponse[]>([]);
  const [selectedManpower, setSelectedManpower] = useState<OptionType>();
  const [selectedProject, setSelectedProject] = useState<OptionType>();
  const fetchProject = async () => {
    try {
      const projectResponse: AxiosResponse = await ApiService.getData(`project-srv/project`);
      const project = projectResponse.data?.data.map((e: any) => {
        return { label: e.name, value: e._id };
      });

      setProjectOptions(project);
    } catch (error) {
      alert(error);
    }
  };

  const fetchManpower = async () => {
    try {
      const materialResponse: AxiosResponse = await ApiService.getData(`project-srv/manpower`);

      const materials = materialResponse.data?.data.map((e: any) => {
        return { label: e.name, value: e._id };
      });
      setManpowerOptions(materials);
      setValue('manpower_id', '');
    } catch (error) {
      alert(error);
    }
  };

  const handleSelectManpower = (data: any) => {
    setSelectedManpower(data);
  };
  const handleSelectProject = (data: any) => {
    setSelectedProject(data);
  };

  useEffect(() => {
    fetchProject();
    fetchManpower();
  }, []);

  const onSubmit = async (data: ISalaryReportPayload) => {
    if (data.project_id == 'all' || selectedManpower?.value == 'all') {
      (data.project_id = ''), (data.manpower_id = '');
    } else {
      data.project_id = selectedProject?.value as string;
      data.manpower_id = selectedManpower?.value as string;
    }

    const salaryResponse: AxiosResponse = await ApiService.postData(
      `project-srv/report/salary`,
      data
    );
    setLoading(false);
    const salarydata = salaryResponse.data?.data;
    setSalary(salarydata);
  };

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <h2 className="font-semibold mb-4">Salary Report</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">

          <div className="mb-4">
            <label className="block mb-1">Select Project<span className="text-red-700 ml-1">*</span></label>
            <Controller
              control={control}
              name="project_id"
              rules={{ required: 'Project is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={[{ value: 'all', label: 'All Project' }, ...projectOption]}
                  value={selectedProject}
                  onChange={(value) => {
                    handleSelectProject(value);
                    field.onChange(value?.value || '');
                  }}
                  isSearchable
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              )}
            />
            {errors.project_id && <p className="text-red-700">{errors.project_id.message}</p>}
          </div>

          <div className="mb-4">
            <label className="block mb-1">Select Manpower</label>

            <Select
              options={[{ value: 'all', label: 'All Manpower' }, ...manpowerOption]}
              value={selectedManpower}
              onChange={handleSelectManpower}
              isSearchable={true}
              className="react-select-container"
              classNamePrefix="react-select"
            />
            
          </div>
        </div>

        
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div>
            <label className="block mb-2 font-semibold" htmlFor="from_date">
              From_Date
            </label>
            <input
              type="date"
              id="from_date"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('from_date')}
            />
            
          </div>

          <div>
            <label className="block mb-2 font-semibold" htmlFor="to_date">
              To_Date
            </label>
            <input
              type="date"
              id="to_date"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('to_date')}
            />
            
          </div>
        </div>

        <div className="flex justify-center ">
          <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
            Search
          </button>
        </div>
      </form>

      {salary.length > 0 ? (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 flex-grow overflow-auto">   
          <table className=" text-sm text-center  text-gray-500 dark:text-gray-400 w-full border mb-8 table-auto">
            <thead className="text-xs text-gray-100 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <td className="text-gray-700  text-center font-semibold  uppercase bg-gray-100  border ">
                  Project details
                </td>
                <td className="text-l text-center font-semibold text-gray-700 uppercase bg-gray-100 dark:bg-gray-00 dark:text-gray-400">
                  Manpower Details
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr key={'load'}>
                  <td colSpan={salary.length} className="text-center p-4">
                    <Loader />
                  </td>
                </tr>
              ) : (
                <>
                  {salary && salary.length > 0 ? (
                    salary.map((data, index) => (
                      <React.Fragment key={data.project_id}>
                        <tr className="bg-white border  dark:border-gray-700 text-center  width-full">
                          <td className="p-2 w-1/12 border">
                            {data.project_name}
                            <br></br>
                            <br></br>
                            <b className="text-green-600 ">
                              Total Project Salary : {data.total_project_salary}
                            </b>
                          </td>
                          {data.salary_manpower_info.map((manpower) => (
                            <React.Fragment key={manpower.manpower_id}>
                              <td className="border">
                                <table className="w-full boder mb-8 mt-0">
                                  <thead className="position margin-top-0">
                                    <tr className="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400 bg-gray-200 border">
                                      <th colSpan={3} className="p-2  border">
                                        {manpower.manpower_name}
                                      </th>
                                    </tr>
                                    <tr className="border">
                                      <th className="text-center text-red-600 border">From-Date</th>
                                      <th className="  text-center  text-red-600 border  ">
                                        To-Date
                                      </th>
                                      <th className=" m-6 text-center  text-red-600  border">
                                        Salary
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="border">
                                    {manpower.salary_info.map((salary, idx) => (
                                      <tr key={idx} className="border">
                                        <td className="border-gray-300">
                                          {new Date(salary.from_date).toLocaleDateString()}
                                        </td>
                                        <td className="border-gray-300">
                                          {new Date(salary.to_date).toLocaleDateString()}
                                        </td>
                                        <td className="text-center p-2 boder border-gray-300">
                                          {salary.salary}
                                        </td>
                                      </tr>
                                    ))}
                                    <tr className="dark:text-gray-400 bg-gray-200 font-semibold ">
                                      <td></td>
                                      <td></td>
                                      <td className="text-center text-green-600 p-2">
                                        Total Manpower Salary ₹ {manpower.total_manpower_salary}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </td>
                            </React.Fragment>
                          ))}
                        </tr>
                      </React.Fragment>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={3} className="text-center p-4">
                        No Data found
                      </td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      ) : (
        <h3 className="text-semibold">No Data Found</h3>
      )}
    </div>
  );
};

export default SalaryReport;
