import { RootState } from "../reducers/state";
import { Actions } from "src/models/tenants/index";
import * as Constants from './constants';
import { TenantAdminInfoModel, TenantInfoModel, TenantRole } from "src/models/tenants/tenantsModel";
import { TenantStateModel } from "src/models/tenants";

const initialState: RootState.TenantModelState = {
    tenantsInfoModel: [] as TenantInfoModel[],
    tenantRolesInfo: [] as TenantRole[],
    tenantAdminsInfo: [] as TenantAdminInfoModel[],
    isTenantAdminSaved: false,
    isTenantAdminUpdated: false,
    isTenantAdminLoading: false,
    isTenantRoleSaved: false,
    isTenantROleUpdated: false,
    isTenantRoleDeleted: false,
    isTenantRoleLoading: false,
    isTenantDeleted: false,
    isTenantSaved: false,
    isTenantUpdated: false,
    isTenantAdminDeleted: false,
    success: false,
    error: false,
    loading: false,
    message: '',
};

function tenantReducer(state: TenantStateModel = initialState, action: Actions): TenantStateModel {
    switch (action.type) {
        case Constants.getTenantsRequestActionType: return getTenantsAction(state);
        case Constants.getTenantsRequestSuccessActionType: return getTenantsSuccessAction(state, action);
        case Constants.getTenantsRequestFailureActionType: return getTenantsFailureAction(state);

        case Constants.saveTenantRequestActionType: return saveTenantAction(state);
        case Constants.saveTenantRequestSuccessActionType: return saveTenantSuccessAction(state, action);
        case Constants.saveTenantRequestFailureActionType: return saveTenantFailureAction(state, action);

        case Constants.deleteTenantAdminRequestActionType: return deleteTenantAdminAction(state);
        case Constants.deleteTenantAdminRequestSuccessActionType: return deleteTenantAdminSuccessAction(state);
        case Constants.deleteTenantAdminRequestFailureActionType: return deleteTenantAdminFailureAction(state);

        case Constants.getTenantRolesByTenantIdActionType: return getTenantRolesByTenantIdAction(state);
        case Constants.getTenantRolesByTenantIdSuccessActionType: return getTenantRolesByTenantIdSuccessAction(state, action);
        case Constants.getTenantRolesByTenantIdFailureActionType: return getTenantRolesByTenantIdFailureAction(state);

        case Constants.saveTenantRoleActionType: return saveTenantRoleAction(state);
        case Constants.saveTenantRoleSuccessActionType: return saveTenantRoleSuccessAction(state, action);
        case Constants.saveetTenantRoleFailureActionType: return saveTenantRoleFailureAction(state, action);
        
        case Constants.deleteTenantRoleRequestActionType: return deleteTenantRoleAction(state);
        case Constants.deleteTenantRoleRequestSuccessActionType: return deleteTenantRoleSuccessAction(state);
        case Constants.deleteTenantRoleRequestFailureActionType: return deleteTenantRoleFailureAction(state);

        case Constants.getTenantAdminsByTenantIdActionType: return getTenantAdminsAction(state);
        case Constants.getTenantAdminsByTenantIdSuccessActionType: return getTenantAdminsSuccessAction(state, action);
        case Constants.getTenantAdminsByTenantIdFailureActionType: return getTenantAdminsFailureAction(state);

        case Constants.saveTenantAdminActionType: return saveTenantAdminsAction(state);
        case Constants.saveTenantAdminSuccessActionType: return saveTenantAdminsSuccessAction(state, action);
        case Constants.saveTenantAdminFailureActionType: return saveTenantAdminsFailureAction(state, action);

        // case Constants.resetCourseStateActionType: return resetCourseStateAction(state);

        default: return state;
    }

    function getTenantsAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            loading: true,
            isTenantSaved: false,
            isTenantUpdated: false
        };
    }
    function getTenantsSuccessAction(state: TenantStateModel, action: any): TenantStateModel {
        if (action.payload) {
            return {
                ...state,
                tenantsInfoModel: action.payload.data,
                loading: false
            };
        }
        return state;
    }
    function getTenantsFailureAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            success: false,
            loading: false,
            //message: action.payload.message,
        }
    }
    
    function saveTenantAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            loading: true,
            isTenantSaved: false,
        };
    }
    function saveTenantSuccessAction(state: TenantStateModel, action: any): TenantStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                isTenantSaved: true,
                message: "Tenant added successfully",
            };
        }
        return state;
    }
    function saveTenantFailureAction(state: TenantStateModel, action: any): TenantStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                isTenantSaved: false,
                message: action.payload,
            }
        }
        return state;
    }
    

    function deleteTenantAdminAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantAdminLoading: true,
        };
    }
    function deleteTenantAdminSuccessAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantAdminDeleted: true,
            isTenantAdminLoading: false,
        };
    }
    function deleteTenantAdminFailureAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantAdminDeleted: false,
            isTenantAdminLoading: false
        }
    }

    function getTenantRolesByTenantIdAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantRoleLoading: true,
        };
    }
    function getTenantRolesByTenantIdSuccessAction(state: TenantStateModel, action: any): TenantStateModel {
        if(action.payload) {
            return {
                ...state,
                tenantRolesInfo: action.payload.data,
                isTenantRoleLoading: false,
                isTenantRoleSaved: false,
                isTenantROleUpdated: false,
                isTenantRoleDeleted: false,
                message: ''
            };
        }
        return state;
        
    }
    function getTenantRolesByTenantIdFailureAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantAdminDeleted: false,
            isTenantRoleLoading: false
        }
    }

    function saveTenantRoleAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantRoleLoading: true,
            isTenantRoleSaved: false,
        };
    }
    function saveTenantRoleSuccessAction(state: TenantStateModel, action: any): TenantStateModel {
        if (action.payload) {
            return {
                ...state,
                isTenantRoleLoading: false,
                isTenantRoleSaved: true,
                message: "Tenant Role added successfully",
            };
        }
        return state;
    }
    function saveTenantRoleFailureAction(state: TenantStateModel, action: any): TenantStateModel {
        if (action.payload) {
            return {
                ...state,
                isTenantRoleLoading: false,
                isTenantRoleSaved: false,
                message: action.payload
            }
        }
        return state;
    }


    function deleteTenantRoleAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantRoleLoading: true,
        };
    }
    function deleteTenantRoleSuccessAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantRoleDeleted: true,
            isTenantRoleLoading: false,
            message: "Tenant role deleted successfully"
        };
    }
    function deleteTenantRoleFailureAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantRoleDeleted: false,
            isTenantRoleLoading: false
        }
    }

    function getTenantAdminsAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantAdminLoading: true,
            isTenantAdminSaved: false,
        };
    }
    function getTenantAdminsSuccessAction(state: TenantStateModel, action: any): TenantStateModel {
        if (action.payload) {
            return {
                ...state,
                tenantAdminsInfo: action.payload.data,
                isTenantAdminLoading: false
            };
        }
        return state;
    }
    function getTenantAdminsFailureAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantAdminLoading: false,
        }
    }
    
    function saveTenantAdminsAction(state: TenantStateModel): TenantStateModel {
        return {
            ...state,
            isTenantAdminLoading: true,
            isTenantAdminSaved: false,
        };
    }
    function saveTenantAdminsSuccessAction(state: TenantStateModel, action: any): TenantStateModel {
        if (action.payload) {
            return {
                ...state,
                isTenantAdminLoading: false,
                isTenantAdminSaved: true,
                message: "Tenant Admin added successfully",
            };
        }
        return state;
    }
    function saveTenantAdminsFailureAction(state: TenantStateModel, action: any): TenantStateModel {
        if (action.payload) {
            return {
                ...state,
                isTenantAdminLoading: false,
                isTenantAdminSaved: false,
                message: action.payload
            }
        }
        return state;
    }
    // function resetCourseStateAction(state: CourseStateModel): CourseStateModel {
    //     return {
    //         ...state,
    //         isCourseSaved: false,
    //         isCourseUpdated: false,
    //         isCourseOverviewUpdated: false,
    //         isCourseEducationUpdated: false,
    //         isCourseProgressUpdated: false,
    //         isCourseVideoSequenceUpdated: false,
    //         message: ''
    //     }
    // }
}

export default tenantReducer
