import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/loader';
// import { IQuotation } from 'src/models/Quotation';
import { ApiService } from '../utilities/api.service';
import { AxiosError, AxiosResponse } from 'axios';
import { getErrorMsg, getFormattedDate } from '../utilities/utils';
import { FaEdit, FaPrint, FaTrash } from 'react-icons/fa';
import { IInvoice } from 'src/models/Invoice';

const InvoiceInfo: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [invoices, setInvoices] = useState<IInvoice[]>([]);

  const handleSaveInovice = (id: string, action: string) => {
    if (action === 'edit') navigate(`/addInvoice/${id}`);
    else if (action === 'add') navigate(`/addInvoice`);
  };

  const handletDeleteInvoice = async (id: string) => {
    if (confirm('Do you want to delete this item')) {
      try {
        const response = await ApiService.deleteData(`project-srv/generate-invoice/${id}`);
        if (response.data && response.data?.data) {
          alert('Invoice deleted sucessfully');
          getInvoicesInfo();
        }
      } catch (error) {
        alert(error);
      }
    } else {
      return false;
    }
  };

  const handleGeneratePrintPDF = async (invoice_id: string, project_id: string) => {
    try {
      const response: AxiosResponse = await ApiService.getData(
        `project-srv/generate-invoice/${invoice_id}`
      );
      if (!response || !response?.data || response?.data?.data == null) {
        alert('invalid id passed');
      }

      const header_response: AxiosResponse = await ApiService.getData(
        `project-srv/project/getservice/${project_id}`
      );
      if (!header_response || !header_response?.data || !header_response?.data?.data) {
        alert('Invalid id passed');
      }
      const headerData = header_response?.data?.data;
      const window_height = window.screen.height;
      const window_width = window.screen.width;
      const invoice_data: IInvoice = response?.data?.data;
      const rootUrl = process.env.REACT_APP_BASE_API_URL;
      let report_html = '';
      // let invoice_from = ' ';
      // let invoice_to = '';
      let invoice_details = '';
      let material_info = '';
      // let manpower_info = '';
      let additional_info = '';
      let footer_info = '';

      //render section header
      report_html += `<div class='row' style="margin-top: 10px;">
        <div class='col-md-12'>
        <div style="font-size: 22px;">
          <h2 class="text-center">Invoice for ${headerData.project_name}</h2>
          <p>Invoice No.: ${invoice_data.invoice_no}</p>
           <p>Invoice Date: ${getFormattedDate(invoice_data.invoice_date.toString())}</p>
           <p>Invoice Due Date: ${getFormattedDate(invoice_data.invoice_date.toString())}</p>
        </div>
        </div>
        </div>

    
      `;

      invoice_details += `<div class='row d-flex justify-content-between' style="margin-top: 20px;">
      <div className='col-md-6'>
        <h2>Invoice From</h2>
        <div style="font-size: 20px;">
          <p>${invoice_data.invoice_from?.name?invoice_data.invoice_from?.name:""}</p>
          <p>${invoice_data.invoice_from?.gst?invoice_data.invoice_from?.gst:""}</p>
          <p>${invoice_data.invoice_from?.address?invoice_data.invoice_from?.address:""}</p>
          <p>${invoice_data.invoice_from?.phone_no?invoice_data.invoice_from?.phone_no:""}</p>
          
        </div>
      </div>
      <div className='col-md-6'>
      <div>
        <h3>Invoice To</h3>
        <div style="font-size: 22px;">
        <p>${invoice_data.invoice_to?.name?invoice_data.invoice_to?.name:""}</p>
          <p>${invoice_data.invoice_to?.gst?invoice_data.invoice_to?.gst:""}</p>
          <p>${invoice_data.invoice_to?.address?invoice_data.invoice_to?.address:""}</p>
          <p>${invoice_data.invoice_to?.phone_no?invoice_data.invoice_to?.phone_no:""}</p>
        </div>
        </div>
      </div>
    </div>`;

      // Material Info Table
      material_info += `<div class='row' style="margin-top: 20px;">
      <div class='col-md-12'>
        <h4>Material Information</h4>
        <table class='table mx-auto border' style="width: 90%;">
          <thead>
            <tr style="background-color:#f1f0f3">
              <th>Name</th>
              <th>Quantity</th>
              <th>Unit</th>
              <th>Amount</th>
              <th>GST</th>
              <th>Final Amount</th>
            </tr>
          </thead>
          <tbody>
            ${
              invoice_data.matertial_info?.material_details.length > 0
                ? invoice_data.matertial_info.material_details
                    .map(
                      (material) => `
                <tr>
                  <td>${material.name}</td>
                  <td>${material.quantity}</td>
                  <td>${material.unit}</td>
                  <td>${material.amount}</td>
                  <td>${material.gst || ''}</td>
                  <td>${material.final_amount}</td>
                </tr>`
                    )
                    .join('')
                : '<tr><td colspan="6" class="text-center">No Materials found</td></tr>'
            }
          </tbody>
          <tfoot style=" font-size: 22px background-color:#f1f0f3">
            <tr style="background-color:#f1f0f3">
            <td></td>
            <td></td>
            <td class='ml-3'> Total Amount : </td>

              <td class='ml-3'> ${invoice_data.matertial_info.total_amount}</td>
            <td class='ml-3'>Total Final Amount  : </td>
              <td class='ml-3'>  ${invoice_data.matertial_info.total_final_amount}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>`;

      // Manpower Info Table
    //   manpower_info += `<div class='row' style="margin-top: 20px;">
    //   <div class='col-md-12'>
    //     <h4>Manpower Information</h4>
    //     <table class='table mx-auto border' style="width: 90%;">
    //       <thead>
    //         <tr style="background-color:#f1f0f3">
    //           <th>Name</th>
    //           <th>Roles</th>
    //           <th>Amount</th>
    //         </tr>
    //       </thead>
    //       <tbody>
    //         ${
    //           invoice_data.manpower_info?.manpower_details.length > 0
    //             ? invoice_data.manpower_info.manpower_details
    //                 .map(
    //                   (manpower) => `
    //             <tr>
    //               <td>${manpower.name}</td>
    //               <td>${manpower.roles}</td>
    //               <td>${manpower.amount}</td>
    //             </tr>`
    //                 )
    //                 .join('')
    //             : '<tr><td colspan="3" class="text-center">No Manpower found</td></tr>'
    //         }
    //       </tbody>
    //       <tfoot style="background-color:#f1f0f3">
    //            <tr>
    //            <td></td>
    //            <td> </td>
    //             <td > Total Amount : ${invoice_data.manpower_info.total_amount}</td>
    //            </tr>
    //         </tfoot>
    //     </table>
    //   </div>
    // </div>`;

      // Additional Info Table
      additional_info += `<div class='row' style="margin-top: 20px;">
      <div class='col-md-12'>
        <h4>Additional Information</h4>
        <table class='table mx-auto border' style="width: 90%;">
          <thead>
            <tr style="background-color:#f1f0f3">
              <th>Name</th>
              <th>Description</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            ${
              invoice_data.additional_info?.additional_info_details.length > 0
                ? invoice_data.additional_info.additional_info_details
                    .map(
                      (info) => `
                <tr>
                  <td>${info.name}</td>
                  <td>${info.description}</td>
                  <td>${info.value}</td>
                </tr>`
                    )
                    .join('')
                : '<tr><td colspan="3" class="text-center">No Additional Information found</td></tr>'
            }
          </tbody>
          <tfoot style="background-color:#f1f0f3">
               <tr>
               <td></td>
               <td></td>
                <td >Total Amount : ${invoice_data.additional_info.total_value}</td>
               </tr>
            </tfoot>
        </table>
      </div>
    </div>`;

      footer_info += `
      <div class='row mt-5 d-flex justify-content-between'>
        <div class='col-md-6'>
          <p><b>Total Amount: ${invoice_data.total}</b></p>
          <p>Date: ${getFormattedDate(invoice_data.signed_date.toString())}</p>
        </div>
        <div class='col-md-6 text-right'>
          <p>Signature</p>
          ${invoice_data.signature ? `<img src="${invoice_data.signature}" alt="Signature" style="max-height: 100px;">` : ""}
        </div>
      </div>`;
      const mywindow = window.open(
        '',
        'PRINT',
        'height=' + window_height + ',width=' + window_width
      );
      if (!mywindow || mywindow == null) {
        alert('Something went wrong');
      } else {
        mywindow.document.write('<html><head>');
        mywindow.document.write(
          '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css">' +
            '<script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js" > </script>' +
            '<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" > </script>' +
            '<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js" > </script>'
        );
        mywindow.document.write(
          '</head><body style="padding-left: 20px; padding-right: 20px; padding-bottom:20px">'
        );
        mywindow.document.write(
          '<div class="row"  id = "print-header" >' +
            ' <div className="col-md-4" class="mt-3" > ' +
            '<img src = "' +headerData.logo +'" alt = "company logo" style = "width: 125px !important; height: 100px !important; max-width: none; float: left;"> ' +
            '</div>' +
            '<div className="col-md-8 ml-5" class="ml-5" > ' +
            '<h1 style = " margin-bottom: 0px !important; font-size:70px ; color:#090265" > ' +
            headerData.service_name +
            '</h1>' +
            '<h3 style="color:090265;  margin-left: 100px;">' +
            headerData.first_name +
            ' ' +
            headerData.last_name +
            '</h3>' +
            '</div>' +
            '</div>' +
            '<div class="row " " id = "print-header"  >' +
            '<div  class="col-md-12 text-center ms-5" style="background-color:#e1ddff; color:#090265" >' +
            '<ul class="d-flex justify-content-around" style="font-size:20px ; margin-top:10px;">' +
            '<li>' +
            'PLANINIG' +
            '</li>' +
            '<li>' +
            'ELEVATION' +
            '</li>' +
            '<li>' +
            'DESIGN' +
            '</li>' +
            '<li>' +
            'CONSTRUCTION' +
            '</li>' +
            '</ul>' +
            '</div>' +
            '<div  class="col-md-12 text-center">' +
            '<h4 style="color:090265">' +
            headerData.address +
            '</h4>' +
            '</div>' +
            '<div  class="col-md-12 text-center p-2 d-flex justify-content-between pl-4" style="background-color:#090265; color:white; "   >' +
            '<h4 class="pl-5">' +
            'Contact : ' +
            headerData.contact_number +
            '</h4>' +
            '<h4 class="pr-5">' +
            'Email : ' +
            headerData.email +
            '</h4>' +
            '<h4 class="pr-5">' +
            'GST : ' +
            headerData.gst_in +
            '</h4>' +
            '</div>' +
            '</div>'
        );
        mywindow.document.write(report_html);
        mywindow.document.write(invoice_details);
        mywindow.document.write(material_info);
        // mywindow.document.write(manpower_info);
        mywindow.document.write(additional_info);
        mywindow.document.write(footer_info);
        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10
      }

      if (mywindow != null) {
        setTimeout(function () {
          mywindow.print();
          mywindow.close();
        }, 1000);
      }

      return true;
    } catch (error) {
      const err = error as AxiosError;
      alert(getErrorMsg(err));
    }
  };

  const getInvoicesInfo = async () => {
    setLoading(true);
    try {
      const response: AxiosResponse = await ApiService.getData('project-srv/generate-invoice');
      setInvoices(response?.data.data);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getInvoicesInfo();
  }, []);

  return (
    <>
      <div className="mt-4 members-list">
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 flex justify-between items-center">
                <h2 className="font-semibold ">Invoices</h2>
                <div className="lg:flex items-center text-center">
                  <button
                    onClick={() => {
                      handleSaveInovice('', 'add');
                    }}
                    type="button"
                    className="btn-primary w-auto whitespace-nowrap mt-0 mb-1 mx-auto">
                    Generate
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="overflow-x-auto flex-grow overflow-auto">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr key={'headers'}>
                        <th className="p-2">
                          <div className="font-semibold text-left whitespace-nowrap">S No.</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left whitespace-nowrap">Invoice No.</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left whitespace-nowrap">Invoice Date</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left whitespace-nowrap">Total cost</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {loading ? (
                        <tr key={'load'}>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {invoices.length ? (
                            <>
                              {invoices &&
                                invoices.map((invoice, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            <div className="text-left">{`${index + 1}`}</div>
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            <div className="text-left">{`${invoice.invoice_no}`}</div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="text-left">
                                            {getFormattedDate(invoice.invoice_date.toString())}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{`${invoice.total}`}</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                          <button
                                            className="text-blue-500 hover:text-blue-700 mr-2"
                                            onClick={() => handleSaveInovice(invoice._id, 'edit')}>
                                            <FaEdit />
                                          </button>
                                          <button
                                            className="text-red-500 hover:text-red-700 mr-2"
                                            onClick={() => handletDeleteInvoice(invoice._id)}>
                                            <FaTrash />
                                          </button>
                                          <button
                                            className="text-blue-500 hover:text-red-700 mr-2"
                                            onClick={() =>
                                              handleGeneratePrintPDF(
                                                invoice._id,
                                                invoice.project_id
                                              )
                                            }>
                                            <FaPrint />
                                          </button>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <tr key={'no_records'}>
                              <td colSpan={5} className='text-center'>No Data Found</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvoiceInfo;
