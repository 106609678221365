import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { ApiService } from '../utilities/api.service';
import {  useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { FaPlus } from 'react-icons/fa';

import Breadcrumbs from '../admin/BreadCrumbsComponent';

import { IPurchaseMaterial } from 'src/models/materialmap';
import { IMaterialInfo } from 'src/models/material';
import { IVendorInfo } from 'src/models/vendorModel';

interface OptionType {
  label: string;
  unit :string;
  value: string;
}

interface OptionType1 {
  _id: string;
  vendor_address: string;
  label: string;
  value: string;
}


interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { materialName: string; categoryName: string;unitName:string }) => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onSubmit }) => {
  const [materialName, setMaterialName] = useState('');
  const [categoryName, setCategoryName] = useState('');
  const [unitName,setUnitName] = useState('')
  const handleSaveAndClose = () => {
    onSubmit({ materialName, categoryName,unitName });
    resetField()
    onClose();
  };

  
  const resetField = ()=>{
    setMaterialName('')
    setCategoryName('')
    setUnitName('')
  }
  
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-4 rounded shadow-md w-96">
        <div className='flex'>
        
          <h2 className="font-semibold mb-4">
            Add New Material
          </h2>
          </div>
        <div className="mb-4">
          <label className="block mb-2 font-medium">Material Name</label>
          <input
            type="text"
            value={materialName}
            onChange={(e) => setMaterialName(e.target.value)}
            className="border border-gray-300 p-2 rounded w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block mb-2 font-medium">Category Name</label>
          <input
            type="text"
            value={categoryName}
            onChange={(e) => setCategoryName(e.target.value)}
            className="border border-gray-300 p-2 rounded w-full"
          />
        </div>
        <div>
            <label className="block mb-2 font-medium">Unit</label>
            <input
              type="text"
              value={unitName}
              onChange={(e) => setUnitName(e.target.value)}
              className="border border-gray-300 p-2 rounded w-full"
            />
            
          </div>
        <div className="flex justify-end space-x-2">
          <button onClick={onClose} className="px-4 py-2 bg-gray-200 text-gray-700 rounded">
            Close
          </button>
          <button onClick={handleSaveAndClose} className="px-4 py-2 bg-blue-500 text-white rounded">
            Save and Close
          </button>
        </div>
      </div>
    </div>
  );
};


const MaterialMapping: React.FC = () => {
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors }
  } = useForm<IPurchaseMaterial>();
  const [materialOptions, setMaterialOptions] = useState<OptionType[]>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<OptionType | null>(null);
  const [isGSTApplicable, setIsGSTApplicable] = useState<boolean>(false);
  const [vendorOptions, setVendorOptions] = useState<OptionType1[]>([]);
  const [selectedVendor, setSelectedVendor] = useState<OptionType1 | null>(null);


  const [isModalOpen, setIsModalOpen] = useState(false);
  const currentPage='materialmappinginfo';

  const navigate = useNavigate();

  const { project_id } = useParams<string>();
  const { id } = useParams<string>();


  const getMaterialOptions = async (): Promise<void> => {
    try {
      const result: AxiosResponse<{ data: IPurchaseMaterial[] }> = await ApiService.getData(
        `services-srv/purchasematerial/purchase/${project_id}`
      );

      const options = result.data.data.map((material) => ({
        value: material.id,
        label: material.material_name,
        unit:material.unit
      }));
      setMaterialOptions(options);
    } catch (error) {
     alert(error)
    }
  };

  useEffect(() => {
    fetchMaterials();
    // getMaterialOptions();
 
  }, [id, setValue]);

  const fetchMaterials = async () => {
    try {
      const result: AxiosResponse<{ data: IMaterialInfo[] }> = await ApiService.getData(
        `services-srv/asset`
      );

      const options = result.data.data.map((material) => ({
        value: material.id,
        label: material.category + '-' + material.material_name,
        unit:material.unit
      }));
      setMaterialOptions(options);
    
    } catch (error) {
      alert(error)
    }
  };

   const handleMaterialChange = (selectedOption: OptionType | null) => {
    setSelectedMaterial(selectedOption);
    setValue('material_id', selectedOption ? selectedOption.value : '');
  
    // Find the selected material based on the selected option's value
    const selectedMaterialData = materialOptions.find(
      (material) => material.value === selectedOption?.value
    );
  
    // If the material has a unit, set it in the form
    if (selectedMaterialData && selectedMaterialData.unit) {
      setValue('unit', selectedMaterialData.unit);
    } else {
      setValue('unit', ''); // Clear the unit if no material is selected or if the selected material has no unit
    }
  };
  
  // get vendor
  const getVendorNameOptions = async (): Promise<void> => {
    try {
      const result: AxiosResponse<{ data: IVendorInfo[] }> = await ApiService.getData(
        `services-srv/vendor-details`
      );

      const option: OptionType1[] = result.data.data.map((vendor) => ({
      _id: vendor.id, 
      value: vendor.id,
      label: vendor.vendor_name,
      vendor_address: vendor.vendor_address
    }));
    
    setVendorOptions(option);
  }
    catch (error) {
     alert(error)
    }
  };

  useEffect(() => {
    getVendorNameOptions();
 
  }, []);


  const handleVendorChange = (selectedOption: OptionType1 | null) => {
    setSelectedVendor(selectedOption);
    setValue('vendor_id', selectedOption ? selectedOption.value : '');
    
    if (selectedOption) {
      setValue('vendor_address', selectedOption.vendor_address);
    } else {
      setValue('vendor_address', '');
    }
  };

  const watchFields = useWatch({
    control,
    name: ['purchase_amount', 'no_of_quantity', 'gst']
  });

  useEffect(() => {
    const [purchaseAmount, quantity, gst] = watchFields;
    if (purchaseAmount || quantity || gst) {
      const finalAmount = calculateFinalAmount(purchaseAmount || 0, quantity || 0, gst || 0);
      setValue('final_amount', finalAmount);
    }
  }, [watchFields, setValue]);

  useEffect(() => {    
    const fetchData = async () => {
      try {
       
        const response = await ApiService.getData(`services-srv/purchasematerial/${id}`);
        const projectData = response.data.data;
        reset(projectData);
        const [purchaseAmount, quantity, gst] = watchFields;
        const finalAmount = calculateFinalAmount(purchaseAmount || 0, quantity || 0, gst || 0);   
        setValue('final_amount', finalAmount);

        if (projectData.purchase_date) {
          setValue('purchase_date', projectData.purchase_date.split('T')[0]);
        }
        

        if (projectData.material_id) {
          const materialToEdit = materialOptions.find(
            (option) => option.value === projectData.material_id
          );
          if (materialToEdit) {
            setSelectedMaterial(materialToEdit);
            setValue('material_id', materialToEdit.value);
            setValue('material_name', materialToEdit.label);
          }
        }
      } catch (error) {
        alert(error)
      }
    };
    if (id) {
      fetchData();
    }
  }, [ setValue, reset, materialOptions, id]);

  const calculateFinalAmount = (amount: number, quantity: number, gst: number): number => {
    return parseFloat((amount * quantity * (1 + gst / 100)).toFixed(2));
  };

  const onSubmit: SubmitHandler<IPurchaseMaterial> = async (data) => {
    try {
      data.project_id = project_id || '';
      data.material_id = selectedMaterial ? selectedMaterial.value : '';

      data.material_name = selectedMaterial ? selectedMaterial.label : '';

      if (id) {
        data.id = id;
        const response:AxiosResponse= await ApiService.postData(`services-srv/purchasematerial`, data); // Assuming it's an update
        if(response.data && response.data?.code === 200 && response.data?.success) {
        alert('Form Updated');
        navigate(`/materialmappingInfo/${project_id}`);
        }
      } else {
        const response:AxiosResponse= await ApiService.postData('services-srv/purchasematerial', data);
        if(response.data && response.data?.code === 200 && response.data?.success) {
        alert('Form Submitted');
        navigate(`/materialmappingInfo/${project_id}`);
        toast.success('Material information submitted successfully!');
        }
      }
      reset();
    } catch (error) {
      alert(error)
    }
  };

  const handleCancel = () => {
    setSelectedMaterial(null);
    navigate(`/materialmappinginfo/${project_id}`);
    reset();
  };

  const handleGSTApplicableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsGSTApplicable(isChecked);
    if (!isChecked) {
      setValue('gst', 0); // Reset GST field if checkbox is unchecked
    }
  };

  const handleAddNew = () => {
    setIsModalOpen(true);
  };

  const handleModalSubmit = async (data: { materialName: string; categoryName: string;unitName:string }) => {
    try {
      // Save the new material to the backend
      await ApiService.postData('services-srv/asset', {
    
        material_name: data.materialName,
        category: data.categoryName,
        unit:data.unitName,
      });

      // Fetch updated materials list
      await fetchMaterials();
      setIsModalOpen(false);
      
    } catch (error) {
      
      alert(error)
    }
  };

  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };

  return (
    <div className="mt-8 max-w-xxl mx-auto zshadow-lg rounded-lg bg-white">
      <div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto mb-10">   
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">  
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className="hover:underline whitespace-nowrap px-2"
    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      // className="hover:underline whitespace-nowrap  px-2"
      className={`hover:underline whitespace-nowrap  px-2 ${currentPage==='materialmappinginfo' ? 'font-bold text-base' : ''}`}

    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Salary Info
    </button>
  </div>
</div>
<div className="mt-8 max-w-xxl mx-auto shadow-lg rounded-lg bg-white">      


      <div className='flex justify-between'>
        <p className='font-extrabold md:text-xl sm:text-base text-center mb-4 h4 ml-3'>Add Material Purchase</p>
        <button
            onClick={handleAddNew}
            className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
            <FaPlus className="mr-2" />
               <span className='hidden sm:inline'>Add New</span>
            {/* Add New */}
          </button>
      </div>
      
      <div className="flex justify-between items-center mb-4">
        {/* <Breadcrumbs
          items={[
            { label: 'Project', path: `/projectinfo` },
            { label: `Material Mapping`, path: `/materialmappinginfo/${project_id}` },
            { label: id ? 'Edit' : 'Add' }
          ]}
        /> */}
        
        
      </div>
      <div className='p-5'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div>
            <label className="block mb-2 font-medium">Select Material</label>
            <Select
              value={selectedMaterial}
               placeholder="Select Material"
               isClearable
              {...register('material_id',{ required: 'Material Name is required' })}

               onChange={(option) => handleMaterialChange(option as OptionType)}

               options={materialOptions}
             
            />
            {errors.material_id
             && <span className="text-red-500">Material  is required</span>}

          </div>
     
          <div>
            <label className="block mb-2 font-medium">Select vendor</label>
            <Select
              value={selectedVendor}
               placeholder="Select Vendor"
               isClearable
               onChange={(option) => handleVendorChange(option as OptionType1)}

               options={vendorOptions}
             />

          </div>

          <div>
            <label className="block mb-2 font-medium">Vendor Address</label>
            <textarea
              {...register('vendor_address')}
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.vendor_address && <span className="text-red-500">This field is required</span>}
          </div>
          <div>
            <label className="block mb-2 font-medium">Purchase Date</label>
            <input
              type="date"
              {...register('purchase_date')}
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.purchase_date && <span className="text-red-500">This field is required</span>}
          </div>
        </div>
        <div>
          <label className="block mb-2 font-medium">Description</label>
            <textarea
              {...register('description')}
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.description && <span className="text-red-500">This field is required</span>}
        </div>
        <div className="mb-6">
          <h2 className="font-semibold mb-4">Purchase</h2>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div>
            <label className="block mb-2 font-medium">Number of Quantity</label>
            <input
              type="number"
              {...register('no_of_quantity',{ required: 'No.of quantity is required' })}
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.no_of_quantity && <span className="text-red-500">This field is required</span>}
          </div>
          <div>
            <label className="block mb-2 font-medium">Unit</label>
            <input
              type="text"
              {...register('unit',{ required: 'unit is required' })}
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.unit && <span className="text-red-500">This field is required</span>}
          </div>
          {/* <div>
            <label className="block mb-2 font-medium">GST</label>
            <input
              type="number"
              {...register('gst')}
              disabled={!isGSTApplicable}
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.gst && <span className="text-red-500">This field is required</span>}
          </div> */}

        <div>
            <label className="block mb-2 font-medium">Select GST Rate</label>

            <select
              className="input-style"
              disabled={!isGSTApplicable}
              {...register('gst')}
            >
              <option value="">Select GST Rate</option>
              <option value="0">0%</option>
              <option value="5">5%</option>
              <option value="12">12%</option>
              <option value="18">18%</option>
              <option value="28">28%</option>
            </select>

            </div>


          <div>
            <label className="block mb-2 font-medium">Amount</label>
            <input
              type="number"
              {...register('purchase_amount',{ required: 'Amount is required' })}
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.purchase_amount && <span className="text-red-500">This field is required</span>}
          </div>
          <div>
            <label className="block mb-2 font-medium">GST Applicable</label>
            <input
              type="checkbox"
              checked={isGSTApplicable}
              onChange={handleGSTApplicableChange}
              className="mr-2"
            />
          </div>
          <div>
            <label className="block mb-2 font-medium">Final Amount</label>
            <input
              type="number"
              {...register('final_amount')}
              className="border border-gray-300 p-2 rounded"
              readOnly
            />
          </div>
        </div>
        <div className="flex justify-center space-x-4 mt-4">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
            {id ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded">
            Cancel
          </button>
        </div>
      </form>
      </div>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={handleModalSubmit}
      />
    </div>
    </div>
  );
};

export default MaterialMapping;
