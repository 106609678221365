import React, { memo } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getItem } from 'src/components/utilities/utils';
const useAuth = () => {
  const _token = getItem('token');
  const _role = getItem('role');

  if (_token) {
    return {
      auth: true,
      role: _role
    };
  } else {
    return {
      auth: false,
      role: null
    };
  }
};

//protected Route state
type ProtectedRouteType = {
  roleRequired?: 'Admin' | 'Employee';
};

const ProtectedRoutes = (props: ProtectedRouteType) => {
  const { auth, role } = useAuth();
  //if the role required is there or not
  if (props.roleRequired) {
    return auth ? (
      props.roleRequired === role ? (
        <Outlet />
      ) : (
        <Navigate to="/login" />
      )
    ) : (
      <Navigate to="/login" />
    );
  } else {
    return auth ? <Outlet /> : <Navigate to="/login" />;
  }
};

export default memo(ProtectedRoutes);
