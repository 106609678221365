import { Actions, LoginErrorModel, LoginStateModel } from 'src/models/loginModel';
import { RootState } from '../reducers/state';
import { LoginActions } from './actions';
import * as Constants from './constants';
import { removeItem } from 'src/components/utilities/utils';
const initialState: RootState.LoginModelState = {
    isLoggedIn: false,
    loading: false,
    success: false,
    domainList: [],
    error: {} as LoginErrorModel,
    isCallbackLink: false,
    redirectLink: "/login",
};

function loginReducer(state: LoginStateModel = initialState, action: Actions): LoginStateModel {
    switch (action.type) {
        case Constants.SendLoginRequestActionType: return sendLoginAction(state);
        case Constants.SendLoginRequestSuccessActionType: return sendLoginSuccessAction(state, action);
        case Constants.SendLoginRequestFailureActionType: return sendLoginFailureAction(state, action);
        case Constants.SendLogoutRequestActionType: return sendLogoutAction();
        case Constants.ResetLoginStateRequestActionType: return resetLoginStateAction(state);
        case Constants.SetCallbackLinkActionType: return setCallbackLinkAction(state);
        case Constants.RemoveCallbackLinkActionType: return removeCallbackLinkAction(state);

        case Constants.GetDomainListRequestActionType:return getDomainListRequestAction(state)
        case Constants.GetDomainListRequestSuccessActionType :return getDomainListRequestSuccessAction(state,action);
        case Constants.GetDomainListRequestFailureActionType:return getDomainListRequestFailureAction(state)

        default:
            return state;
    }
    // Domain List
    function getDomainListRequestAction(state:LoginStateModel):LoginStateModel{
        return {
            ...state,
            loading:true
        };
    }
    function getDomainListRequestSuccessAction(state: LoginStateModel, action: LoginActions.getDomainListRequestSuccessAction): LoginStateModel {
        if (action.payload) {
            return {
                ...state,
                domainList: action.payload,
                loading: false
            };
        }
        return state;
    }
    function getDomainListRequestFailureAction(state: LoginStateModel):LoginStateModel {
        return {
            ...state,
            success: false,
            loading: false,
        }
    }

    // Log In
    function sendLoginAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            isLoggedIn: false,
            loading: true,
            success: false,
            redirectLink: "/login",
        };
    }

    function sendLoginSuccessAction(state: LoginStateModel, action: LoginActions.sendLoginRequestSuccessAction): LoginStateModel {
        if (action.payload) {
            return {
                ...state,
                isLoggedIn: true,
                loading: false,
                redirectLink: action.payload.data.permissions[0],
            };
        }
        return state;
    }

    function sendLoginFailureAction(state: LoginStateModel, action: LoginActions.sendLoginRequestFailureAction): LoginStateModel {
        if (action.payload) {
            return {
                ...state,
                loading: false,
                error: action.payload,
                isLoggedIn: false,
                success: false,
                redirectLink: "/login"
            };
        }
        return state;
    }

    // setting callback link
    function setCallbackLinkAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            isCallbackLink: true
        };
    }

    // removing callback link
    function removeCallbackLinkAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            isCallbackLink: false
        };
    }

    // Log Out
    function sendLogoutAction(): LoginStateModel {
        removeItem('token');
        // removeItem('refreshToken');
        localStorage.removeItem('userData')
        return initialState;
    }


    function resetLoginStateAction(state: LoginStateModel): LoginStateModel {
        return {
            ...state,
            isLoggedIn: false
        };
    }

}

export default loginReducer;
