import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import {  useNavigate, useParams } from 'react-router-dom';

import { ApiService } from 'src/components/utilities/api.service';
import { IReceipt } from 'src/models/receipt';
import Breadcrumbs from '../BreadCrumbsComponent';
import { AxiosResponse } from 'axios';

const AddReceipt: React.FC = () => {
  const {
    register,
    reset,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<IReceipt>();
  const navigate = useNavigate();
  const { id } = useParams<string>();
  const [signUrl, setSignUrl] = useState('');

  const fetchData = async () => {
    try {
      const response = await ApiService.getData(`service-srv/receipt/${id}`);
      setSignUrl(response.data.data.signature);        

      const projectData = response.data.data;
      reset(projectData);

      if (projectData.date) {
        setValue('date', projectData.date.split('T')[0]);
      }
    } catch (error) {
      console.error('Error fetching project:', error);
    }
  };
  useEffect(() => {
    if (id) {
      fetchData();
    }
  }, [setValue, reset]);

  const handleSignUpload = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const result: AxiosResponse = await ApiService.uploadImage(
          'tenant-srv/tenant/upload',
          file
        );
        if (result.data && result.data?.code === 200 && result.data?.success) {
          setSignUrl(result.data.data);
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  const onSubmit: SubmitHandler<IReceipt> = async (data) => {
    try {
      data.signed_by = signUrl;
      if (id) {
        data.id = id;
        const response:AxiosResponse= await ApiService.postData(`service-srv/receipt`, data); // Assuming it's an update
        if(response.data && response.data?.code === 200 && response.data?.success) {
        alert('Receipt  Updated');
        
        navigate('/receipt');
        }
      } else {
        const response:AxiosResponse=await ApiService.postData('service-srv/receipt', data);
        if(response.data && response.data?.code === 200 && response.data?.success) {
        alert('Receipt  submitted');
        navigate('/receipt');
      
        }
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleBackClick = () => {
    navigate('/receipt');
  };

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <Breadcrumbs
        items={[
          { label: 'Receipt', path: `/receipt` },
          { label: id ? 'Edit' : 'Add' }
        ]}
      />
    
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">

          <div className="form-group">
            <label className="block mb-2 font-medium">Receipt No</label>
            <input
              type="text"
              {...register('receipt_no')}
              placeholder="Receipt No"
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.receipt_no && <span className="text-red-500">{errors.receipt_no.message}</span>}
          </div>
          <div>
            <label className="block mb-2 font-medium">Date<span className="text-red-700 ml-1">*</span></label>
            <input
              type="date"
              {...register('date', { required: 'This field is required' })}
              placeholder="Select date"
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.date && <span className="text-red-500">{errors.date.message}</span>}
          </div>
          <div>
            <label className="block mb-2 font-medium">Amount<span className="text-red-700 ml-1">*</span></label>
            <input
              type="text"
              {...register('amount', { required: 'This field is required' })}
              placeholder="Amount"
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.amount && <span className="text-red-500">{errors.amount.message}</span>}
          </div>
          <div>
            <label className="block mb-2 font-medium">Description</label>
            <textarea
              {...register('description')}
              placeholder="Description"
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.description && (
              <span className="text-red-500">{errors.description.message}</span>
            )}
          </div>
          <div className="flex items-center mb-2">
            <label className="block mb-2 font-medium">Signature</label>
            <input
              type="file"
              {...register('signed_by')}
              onChange={handleSignUpload}
              className="border border-gray-300 p-2 rounded w-full"
            />
             {signUrl && (
                <div>
                  <img
                    src={signUrl}
                    alt="Signature"
                    className="mb-2 mt-3"
                    style={{ maxHeight: '100px' }}
                  />
                </div>
            )}
           
          </div>
        </div>
        <div className="flex justify-center space-x-4 mt-4">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
            {id ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded"
            onClick={handleBackClick}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddReceipt;
