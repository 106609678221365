import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import { AxiosResponse } from 'axios';
import { IPurchaseMaterial } from 'src/models/materialmap';
import { ApiService } from '../utilities/api.service';
import { current } from '@reduxjs/toolkit';
// import Breadcrumbs from '../admin/BreadCrumbsComponent';

interface OptionType {
  value: string;
  label: string;
}

const MaterialMappingInfo: React.FC = () => {
  const navigate = useNavigate();
  const [datamap, setDatamap] = useState<IPurchaseMaterial[]>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<OptionType[]>([]);
  const { project_id } = useParams<string>();
  const { id } = useParams<string>();
  const currentPage='materialmappinginfo';

  const getDatamap = async (): Promise<void> => {
    try {
      const result: AxiosResponse<{ data: IPurchaseMaterial[] }> = await ApiService.getData(
        `services-srv/purchasematerial/purchase/${project_id}`
      );
      setDatamap(result.data.data);
    } catch (error) {
      console.error('Error getting services:', error);
    }
  };

  const getMaterialOptions = async (): Promise<void> => {
    try {
      const result: AxiosResponse<{ data: IPurchaseMaterial[] }> = await ApiService.getData(
        `services-srv/purchasematerial/purchase/${project_id}`
      );
      const options: OptionType[] = result.data?.data.map((material) => ({
        value: material.id,
        label: material.material_name
      }));
      setSelectedMaterial(options);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getDatamap();
    getMaterialOptions();
  }, []);

  const handleAddNew = () => {
    navigate(`/materialmapping/${project_id}`);
  };

  const handleEdit = (id: string) => {
    navigate(`/materialmapping/${project_id}/${id}`);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Do you want to delete?')) {
      try {
        const response:AxiosResponse=await ApiService.deleteData(`services-srv/purchasematerial/${id}`);
        if(response.data && response.data?.code === 200 && response.data?.success) {
        getDatamap();
        alert('Data Deleted Successfully');
        }
        else{
          alert('Failed to Delete the data')
        }
      } catch (error) {
        alert(error);
      }
    }
    else{
    alert('Delete Action Cancel')   
    }
  };

  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };

  return (
<div className="mt-8 flex flex-col max-w-xxl mx-auto shadow-lg rounded-lg bg-white">
  <div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto">
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className="hover:underline whitespace-nowrap px-2"
    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className={`hover:underline whitespace-nowrap  px-2 ${currentPage==='materialmappinginfo' ? 'font-bold text-base' : ''}`}
    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Salary Info
    </button>
  </div>
</div>     
  {/* <Breadcrumbs
        items={[
          { label: 'Project ', path: `/projectinfo/${project_id}` },
          { label: 'Material Purchase ', path: `/materialmappinginfo/${id}` }
        ]}
      /> */}
          <div className='p-4'>
      <div className="flex justify-between items-center mb-4  mt-5">
        <h2 className="font-semibold sm:text-sm ">Material Purchase</h2>
        <button
          onClick={handleAddNew}
          className="flex items-center p-2 bg-blue-500 text-white rounded">
          <FaPlus className="mr-2" />
          <span className="hidden sm:inline">Add Material</span>
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="table-auto w-full border">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 ">S.No</th>
              <th className="p-2 whitespace-nowrap">Material Name</th>
              <th className='p-2'>Quantity</th>
              <th className="p-2 ">Amount</th>
              <th className="p-2 whitespace-nowrap">Purchase Date</th>

              <th className="p-2 text-center">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {datamap.map((item, index) => (
              <tr key={index}>
                <td className="p-2 text-center">{index + 1}</td>
                <td className="p-2 text-center whitespace-nowrap">{item.material_name}</td>
                <td className="p-2 text-center whitespace-nowrap">{item.no_of_quantity}</td>
                <td className="p-2 text-center whitespace-nowrap ">{item.final_amount}</td>
                <td className="p-2 text-center whitespace-nowrap">{new Date(item.purchase_date).toDateString()}</td>

                <td className="p-2 text-center">
                  <button className="text-blue-500" onClick={() => handleEdit(item.id)}>
                    <FaEdit className="inline" />
                  </button>
                  <button className="text-red-500 ml-2" onClick={() => handleDelete(item.id)}>
                    <FaTrash className="inline mr-2" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
          <tr>
            <td></td>
            <td  className="py-2 px-4 text-center font-semibold">Total Material Purchase:</td>
            <td className="py-2 px-4 text-center font-semibold">
              {datamap.reduce((total, data) => total + data.final_amount, 0)}
            </td>
            <td></td>
          </tr>
        </tfoot>
        </table>
      </div>
      </div>
    </div>
  );
};

export default MaterialMappingInfo;
