import { AxiosError, AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ApiService } from 'src/components/utilities/api.service';
import { IServiceInfo } from 'src/models/service';
import Breadcrumbs from '../BreadCrumbsComponent';
import { getErrorMsg, getUploadedFile } from 'src/components/utilities/utils';

const AddService: React.FC = () => {
  const { tenant_id } = useParams<string>();
  const { id } = useParams<string>();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IServiceInfo>();
  const [fileUrl, setFileUrl] = useState('');

  const fetchServiceData = async () => {
    try {
      const response = await ApiService.getData(`tenant-srv/tenant/service/tenantId/${tenant_id}`);
      if(response.data && response.data.data !== null)
        reset(response.data.data);
        setFileUrl(response.data.data.logo)
    } catch (error) {
      const err = error as AxiosError;
      alert(getErrorMsg(err));
    }
  };

  // fetch the data by id and update
  useEffect(() => {
    if (tenant_id) {
      fetchServiceData();
    } else {
      navigate("/tenants")
    }
  }, [tenant_id]);

  const handleBackClick = () => {
    navigate("/tenants")
  };

  const handleFileUpload = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const result: AxiosResponse = await ApiService.uploadImage(
          'tenant-srv/tenant/upload',
          file
        );
        if (result.data && result.data?.code === 200 && result.data?.success) {
          setFileUrl(result.data.data.url);
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  // submit form data
  const onSubmit: SubmitHandler<IServiceInfo> = async (data) => {
    try {
      data.logo = fileUrl;
      if (id) {
        data.id, (data._id = id);
      }
      if(!tenant_id) {
        navigate("/tenants")
      }
      if(tenant_id) data.tenant_id = tenant_id;
      const result: AxiosResponse = await ApiService.postData('tenant-srv/tenant/service', data);
      if (result.data && result.data?.code === 200 && result.data?.success) {
        alert('Service saved successfully');
        navigate('/tenants');
      }
      reset();
    } catch (error) {
      const err = error as AxiosError;
      alert(getErrorMsg(err));
    }
  };

  return (
    <>
      <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
        
        <ol className="breadcrumb mb-4">
            <li>
              <Link to="/tenants">Home</Link>
            </li>
            <li>
              <Link to="/tenants">Tenants</Link>
            </li>
        
            <li>
            <Link to={`/tenantroles/${tenant_id}`}>Roles</Link>

            </li>
            <li>
            <Link to={`/tenantadmins/${tenant_id}`}>Admin</Link>

            </li>
            <li>
            <Link to={`/serviceadd/${tenant_id}`}>Service</Link>

            </li>
          </ol>
      
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="form-group">
              <label className="block mb-1">
                Name<span className="text-red-700 ml-1">*</span>
              </label>
              <input
                type="text"
                className="input-style"
                {...register('first_name', { required: true, maxLength: 20 })}
              />
              {errors.first_name?.type === 'required' && (
                <p className="text-red-700">Required field</p>
              )}
              {errors.first_name?.type === 'maxLength' && (
                <p className="text-red-700">Max length exceeded</p>
              )}
            </div>
            <div className="form-group">
              <label className="block mb-1">Qualification</label>
              <input type="text" className="input-style" {...register('last_name')} />
              {errors.last_name && <p className="text-red-700">{errors.last_name.message}</p>}
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="form-group">
              <label className="block mb-1">
                Company Name<span className="text-red-700 ml-1">*</span>
              </label>
              <input
                type="text"
                className="input-style"
                {...register('name', { required: true, maxLength: 20 })}
              />
              {errors.name?.type === 'required' && <p className="text-red-700">Required field</p>}
              {errors.name?.type === 'maxLength' && (
                <p className="text-red-700">Max length exceeded</p>
              )}
            </div>
            <div className="form-group">
              <label className="block mb-1">Contact Number</label>
              <input type="number" className="input-style" {...register('contact_number')} />
              {errors.contact_number && (
                <p className="text-red-700">{errors.contact_number.message}</p>
              )}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="form-group">
              <label className="block mb-1">GST</label>
              <input type="text" className="input-style" {...register('gst_number')} />
              {errors.gst_number?.type === 'required' && (
                <p className="text-red-700">Required field</p>
              )}
              {errors.gst_number?.type === 'maxLength' && (
                <p className="text-red-700">Max length exceeded</p>
              )}
            </div>
            <div className="form-group">
              <label className="block mb-1">Email</label>
              <input type="email" className="input-style" {...register('email')} />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="form-group">
              <label className="block mb-1">TIN Number</label>
              <input type="text" className="input-style" {...register('tin_number')} />
            </div>
            <div className="form-group">
              <label className="block mb-1">Address</label>
              <textarea className="input-style" {...register('location')} />
            </div>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="form-group">
              <label className="block mb-1">Nature of Work</label>
              <input type="text" className="input-style" {...register('nature_of_work')} />
            </div>
            {/* Example of file input (uncomment if needed) */}
            <div className="form-group">
              <label className="block mb-1">Logo</label>
              <input
                type="file"
                className="input-style"
                {...register('logo')}
                onChange={handleFileUpload}
              />
            </div>
            {fileUrl && (
                <div>
                  <img
                    src={getUploadedFile(fileUrl)}
                    alt="Logo"
                    className="mb-2 mt-3"
                    style={{ maxHeight: '100px' }}
                  />
                </div>
              )}
          </div>

          <div className="flex justify-center ">
            <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
              {id ? 'Update' : 'Save'}
            </button>
            <button
              type="button"
              className="px-6 btn-primary discard-btn w-28 mr-2 ml-0 mt-3"
              onClick={handleBackClick}>
              cancel
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default AddService;
