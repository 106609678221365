
import { useMemo } from 'react';
import { bindActionCreators, Dispatch } from "redux";
import * as Constants from './constants';
import { Action, ActionCreator } from 'src/models/actions';
import { TenantAdminInfoModel, TenantInfoModel, TenantRole } from 'src/models/tenants/tenantsModel';
export namespace TenantActions {

    export type getTenantsRequestAction = Action<typeof Constants.getTenantsRequestActionType, null>
    export type getTenantsRequestSuccessAction = Action<typeof Constants.getTenantsRequestSuccessActionType, TenantInfoModel[]>;
    export type getTenantsRequestFailureAction = Action<typeof Constants.getTenantsRequestFailureActionType, null>;

    export function getTenantsRequest(): getTenantsRequestAction {
        return ActionCreator(Constants.getTenantsRequestActionType, null);
    }
    export function getTenantsRequestSuccess(P: TenantInfoModel[]): getTenantsRequestSuccessAction {
        return ActionCreator(Constants.getTenantsRequestSuccessActionType, P);
    }
    export function getTenantsRequestFailure(): getTenantsRequestFailureAction {
        return ActionCreator(Constants.getTenantsRequestFailureActionType, null);
    }

    export type saveTenantRequestAction = Action<typeof Constants.saveTenantRequestActionType, TenantInfoModel>
    export type saveTenantRequestSuccessAction = Action<typeof Constants.saveTenantRequestSuccessActionType, any>;
    export type saveTenantRequestFailureAction = Action<typeof Constants.saveTenantRequestFailureActionType, any>;

    export function saveTenantRequest(P: TenantInfoModel): saveTenantRequestAction {
        return ActionCreator(Constants.saveTenantRequestActionType, P);
    }
    export function saveTenantRequestSuccess(R: any): saveTenantRequestSuccessAction {
        return ActionCreator(Constants.saveTenantRequestSuccessActionType, R);
    }
    export function saveTenantRequestFailure(P: any): saveTenantRequestFailureAction {
        return ActionCreator(Constants.saveTenantRequestFailureActionType, P);
    }

    export type deleteTenantAdminRequestAction = Action<typeof Constants.deleteTenantAdminRequestActionType, string>
    export type deleteTenantAdminRequestSuccessAction = Action<typeof Constants.deleteTenantAdminRequestSuccessActionType, null>;
    export type deleteTenantAdminRequestFailureAction = Action<typeof Constants.deleteTenantAdminRequestFailureActionType, null>;

    export function deleteTenantAdminRequest(P: string): deleteTenantAdminRequestAction {
        return ActionCreator(Constants.deleteTenantAdminRequestActionType, P);
    }
    export function deleteTenantAdminRequestSuccess(): deleteTenantAdminRequestSuccessAction {
        return ActionCreator(Constants.deleteTenantAdminRequestSuccessActionType, null);
    }
    export function deleteTenantAdminRequestFailure(): deleteTenantAdminRequestFailureAction {
        return ActionCreator(Constants.deleteTenantAdminRequestFailureActionType, null);
    }

    export type getTenantRolesByTenantIdAction = Action<typeof Constants.getTenantRolesByTenantIdActionType, string>
    export type getTenantRolesByTenantIdSuccessAction = Action<typeof Constants.getTenantRolesByTenantIdSuccessActionType, TenantRole[]>;
    export type getTenantRolesByTenantIdFailuresAction = Action<typeof Constants.getTenantRolesByTenantIdFailureActionType, null>;

    export function getTenantRolesByTenantIdRequest(P: string): getTenantRolesByTenantIdAction {
        return ActionCreator(Constants.getTenantRolesByTenantIdActionType, P);
    }
    export function getTenantRolesByTenantIdRequestSuccess(P: TenantRole[]): getTenantRolesByTenantIdSuccessAction {
        return ActionCreator(Constants.getTenantRolesByTenantIdSuccessActionType, P);
    }
    export function getTenantRolesByTenantIdRequestFailure(): getTenantRolesByTenantIdFailuresAction {
        return ActionCreator(Constants.getTenantRolesByTenantIdFailureActionType, null);
    }


    export type saveTenantRoleRequestAction = Action<typeof Constants.saveTenantRoleActionType, TenantRole>
    export type saveTenantRoleRequestSuccessAction = Action<typeof Constants.saveTenantRoleSuccessActionType, any>;
    export type saveTenantRoleRequestFailureAction = Action<typeof Constants.saveetTenantRoleFailureActionType, any>;

    export function saveTenantRoleRequest(P: TenantRole): saveTenantRoleRequestAction {
        return ActionCreator(Constants.saveTenantRoleActionType, P);
    }
    export function saveTenantRoleRequestSuccess(R: any): saveTenantRoleRequestSuccessAction {
        return ActionCreator(Constants.saveTenantRoleSuccessActionType, R);
    }
    export function saveTenantRoleRequestFailure(P: any): saveTenantRoleRequestFailureAction {
        return ActionCreator(Constants.saveetTenantRoleFailureActionType, P);
    }

    export type deleteTenantRoleRequestAction = Action<typeof Constants.deleteTenantRoleRequestActionType, string>
    export type deleteTenantRoleRequestSuccessAction = Action<typeof Constants.deleteTenantRoleRequestSuccessActionType, null>;
    export type deleteTenantRoleRequestFailureAction = Action<typeof Constants.deleteTenantRoleRequestFailureActionType, null>;

    export function deleteTenantRoleRequest(P: string): deleteTenantRoleRequestAction {
        return ActionCreator(Constants.deleteTenantRoleRequestActionType, P);
    }
    export function deleteTenantRoleRequestSuccess(): deleteTenantRoleRequestSuccessAction {
        return ActionCreator(Constants.deleteTenantRoleRequestSuccessActionType, null);
    }
    export function deleteTenantRoleRequestFailure(): deleteTenantRoleRequestFailureAction {
        return ActionCreator(Constants.deleteTenantRoleRequestFailureActionType, null);
    }

    export type getTenantAdminsByTenantIdAction = Action<typeof Constants.getTenantAdminsByTenantIdActionType, string>
    export type getTenantAdminsByTenantIdSuccessAction = Action<typeof Constants.getTenantAdminsByTenantIdSuccessActionType, TenantAdminInfoModel[]>;
    export type getTenantAdminsByTenantIdFailuresAction = Action<typeof Constants.getTenantAdminsByTenantIdFailureActionType, null>;

    export function getTenantAdminsByTenantIdRequest(P: string): getTenantAdminsByTenantIdAction {
        return ActionCreator(Constants.getTenantAdminsByTenantIdActionType, P);
    }
    export function getTenantAdminsByTenantIdRequestSuccess(P: TenantAdminInfoModel[]): getTenantAdminsByTenantIdSuccessAction {
        return ActionCreator(Constants.getTenantAdminsByTenantIdSuccessActionType, P);
    }
    export function getTenantAdminsByTenantIdRequestFailure(): getTenantAdminsByTenantIdFailuresAction {
        return ActionCreator(Constants.getTenantAdminsByTenantIdFailureActionType, null);
    }

    export type saveTenantAdminRequestAction = Action<typeof Constants.saveTenantAdminActionType, TenantAdminInfoModel>
    export type saveTenantAdminRequestSuccessAction = Action<typeof Constants.saveTenantAdminSuccessActionType, any>;
    export type saveTenantAdminRequestFailureAction = Action<typeof Constants.saveTenantAdminFailureActionType, any>;

    export function saveTenantAdminRequest(P: TenantAdminInfoModel): saveTenantAdminRequestAction {
        return ActionCreator(Constants.saveTenantAdminActionType, P);
    }
    export function saveTenantAdminRequestSuccess(R: any): saveTenantAdminRequestSuccessAction {
        return ActionCreator(Constants.saveTenantAdminSuccessActionType, R);
    }
    export function saveTenantAdminRequestFailure(P: any): saveTenantAdminRequestFailureAction {
        return ActionCreator(Constants.saveTenantAdminFailureActionType, P);
    }

}

export type TenantActions = Omit<typeof TenantActions, 'Type'>;
export const useTenantActions = (dispatch: Dispatch) => {
    const { ...actions } = TenantActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as TenantActions;
};