import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import { IMaterialInfo } from 'src/models/materialModels';
import Loader from 'src/components/loader';
import Pagination from '../pagination';

const MaterialTable: React.FC = () => {
  const navigate = useNavigate();
  const [materials, setMaterials] = useState<IMaterialInfo[]>([]);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const countPerPage = 4; 

  const handleEdit = (id: string) => {
    navigate(`/material/${id}`);
  };

  const getMaterials = async (): Promise<void> => {
    try {
      const result: AxiosResponse = await ApiService.getData('services-srv/asset');
      if (result.data && result.data?.code === 200 && result.data?.success) {
        setMaterials(result?.data?.data || []);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getMaterials();
  }, []);

  const handleDelete = async (id: string) => {
    if (confirm('Do you want to delete this item')) {
      try {
        const response = await ApiService.deleteData(`services-srv/asset/${id}`);

        if (response.data && response.data?.data) {
          alert('Material deleted sucessfully');
          getMaterials();
        }
      } catch (error) {
        alert(error);
      }
    } else {
      return false;
    }
  };

  const handleAddMaterial = () => {
    navigate('/material');
  };

   // Pagination Logic
   const indexOfLast = currentPage * countPerPage;
   const indexOfFirst = indexOfLast - countPerPage;
   const currentMaterils = materials.slice(indexOfFirst, indexOfLast);
 
   const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Materials</h2>
        <button
          onClick={handleAddMaterial}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
          <FaPlus className="mr-2" />
          <span className="hidden sm:inline">Add Material</span>
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="table-auto w-full border h-full">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 text-center">S.No</th>
              <th className="p-2 text-center whitespace-nowrap">CATEGORY NAME</th>
              <th className="p-2 text-center whitespace-nowrap">MATERIAL NAME</th>
              <th className="p-2 text-center">ACTIONS</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {loading ? (
              <tr>
                <td colSpan={4} className="text-center p-4">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {currentMaterils && materials.length > 0 ? (
                  currentMaterils.map((data, i) => (
                    <tr key={data._id} className="text-sm">
                    <td className="p-2 text-center">{indexOfFirst + i + 1}</td>
                    <td className="p-2 text-center">{data.category}</td>
                      <td className="p-2 text-center">{data.material_name}</td>
                      <td className="p-2 text-center flex justify-center">
                        <button className="text-blue-500" onClick={() => handleEdit(data._id)}>
                          <FaEdit className="inline mr-2" />
                        </button>
                        <button className="text-red-500 ml-2" onClick={() => handleDelete(data._id)}>
                          <FaTrash className="inline mr-2" />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center p-4">
                      No Materials found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>           
        </table>
        {/* Add Pagination Component Here */}
      <div className="flex justify-center mt-4">
      {!loading && (
        <Pagination
          currentPage={currentPage}
          totalPages={Math.ceil(materials.length / countPerPage)}
          onPageChange={paginate}
        />
      )}
    </div>
      </div>
    </div>
  );
};
export default MaterialTable;
