import React from 'react';

const Footer = () => {
  return (
    <footer id="siteFooter" className="fixed inset-x-0 bottom-0 p-4">
      <div className="text-right">
        <strong>Copyright </strong> &copy; {new Date().getFullYear()} CoolDreamers . All rights
        reserved.
      </div>
    </footer>
  );
};

export default Footer;
