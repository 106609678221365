import React, { useEffect, useState } from 'react';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { RootState } from 'src/store/reducers/state';
import { useTenantActions } from 'src/store/tenants/actions';

const TenantAdmins: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tenantId } = useParams<string>();
  const [tenantRoleId, setTenantRoleId] = useState<string>();
  const tenantAction = useTenantActions(dispatch);
  const {
    tenantAdminsInfo,
    tenantRolesInfo,
    isTenantAdminSaved,
    isTenantAdminLoading,
    isTenantAdminUpdated,
    isTenantAdminDeleted,
    message,
  } = useSelector((state: RootState) => {
    return {
        tenantAdminsInfo: state.tenant.tenantAdminsInfo,
        tenantRolesInfo: state.tenant.tenantRolesInfo,
        isTenantAdminSaved: state.tenant.isTenantAdminSaved,
        isTenantAdminLoading: state.tenant.isTenantAdminLoading,
        isTenantAdminUpdated: state.tenant.isTenantAdminUpdated,
        isTenantAdminDeleted: state.tenant.isTenantAdminDeleted,
        message: state.tenant.message
    };
  });
  
  useEffect(() => {
    if(tenantId) {
        tenantAction.getTenantAdminsByTenantIdRequest(tenantId);
        tenantAction.getTenantRolesByTenantIdRequest(tenantId);
    }
    setTenantRoleId('');
  }, [isTenantAdminSaved, isTenantAdminUpdated, isTenantAdminDeleted, tenantId]);

  const handleDeleteTenantAdmin = (id : string) => {
    const msg = "Do you want to delete this item?"
    if(confirm(msg)) {
        tenantAction.deleteTenantAdminRequest(id)
    } else {
        return false;
    }
    
  }

  const handleTenantAdmin = (id: string, action: string) => {
    if (action === 'edit') navigate(`/addTenantAdmin/${tenantId}/${id}`);
    else if (action === 'add') navigate(`/addTenantAdmin/${tenantId}`);
  };

  const getRolesName = (roleIds: string[]) => {
    const roleKeys = [];
    for(const roleId of roleIds) {
      const roleKey = tenantRolesInfo.find((e) => e._id == roleId)?.role_key;
      roleKeys.push(roleKey);
    }
    return roleKeys;
  }

  return (
    <>
      <div className="mt-4 members-list">
        <div>
        <div className="mb-8">
          <h2 className="font-semibold my-1">Tenant</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/tenants">Home</Link>
            </li>
            <li>
              <Link to="/tenants">Tenants</Link>
            </li>
        
            <li>
            <Link to={`/tenantroles/${tenantId}`}>Roles</Link>

            </li>
            <li>
            <Link to={`/tenantadmins/${tenantId}`}>Admin</Link>

            </li>
            <li>
            <Link to={`/serviceadd/${tenantId}`}>Service</Link>

            </li>
          </ol>
        </div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Tenant Admins</h2>
                <div className="lg:flex items-center text-center">
                  <div className="form-group md:flex items-center text-center">
                    <label className="mr-1 font-semibold">Search:</label>
                    <input
                      // onChange={(e) => handleSearch(e)}
                      className="input-style w-48 mr-3 mb-1"
                      type="text"
                      placeholder="Search"
                    />
                  </div>
                  <button
                    onClick={() => {
                      handleTenantAdmin('', 'add');
                    }}
                    type="button"
                    className="btn-primary w-auto whitespace-nowrap mt-0 mb-1 mx-auto">
                    <i className="fas fa-plus pr-1"></i>Add
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Name</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Phone no.</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Roles</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {isTenantAdminLoading ? (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {tenantAdminsInfo.length ? (
                            <>
                              {tenantAdminsInfo &&
                                tenantAdminsInfo.map((tenantAdmin, index) => {
                                  return (
                                    <>
                                      <tr
                                        key={index}
                                        style={{ cursor: 'pointer' }}>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            <div className="text-left">{`${tenantAdmin.name}`}</div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="text-left">{`${tenantAdmin.phone_no}`}</div>
                                        </td>
                                        <td>
                                          <div className="text-left">{getRolesName(tenantAdmin.roles).toString()}</div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">
                                            {/* <div className="dropdown relative">
                                              <button
                                                className="dropdown-toggle"
                                                onClick={(e: any) => {
                                                  e.stopPropagation();
                                                }}
                                                type="button"
                                                id="action-dropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <img
                                                  src="../../assets/img/dots-vertical.svg"
                                                  className="w-5 h-5"
                                                  onClick={(e: any) => {
                                                    e.stopPropagation();
                                                  }}
                                                />
                                              </button> */}
                                              {/* <ul
                                                className="dropdown-menu dropdown-menu-ul"
                                                aria-labelledby="action-dropdown">
                                                <li>
                                                  <button
                                                    type="button"
                                                    onClick={() => handleTenantAdmin(tenantAdmin._id, 'edit')}
                                                    className="action-menu flex items-center"
                                                    ><i className="fas fa-edit w-6 text-left"></i>
                                                    <span>Edit</span>
                                                  </button>
                                                </li>
                                                <li>
                                                  <span
                                                    className="action-menu flex items-center cursor-pointer text-red-500"
                                                    onClick={() => handleDeleteTenantAdmin(tenantAdmin._id)}
                                                    >
                                                    <i className="fas fa-trash w-6 text-left"></i>
                                                    <span>Delete</span>
                                                  </span>
                                                </li>
                                                
                                              </ul> */}
                                              <button className="text-blue-500" onClick={() => handleTenantAdmin(tenantAdmin._id, 'edit')}>
                  <FaEdit className="inline mr-3" />
                </button>
                <button className="text-red-500 ml-2"  onClick={() => handleDeleteTenantAdmin(tenantAdmin._id)}>
                  <FaTrash className="inline mr-2" />
                </button>
                                            {/* </div> */}
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <tr>
                              <td>No Data Found</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TenantAdmins;
