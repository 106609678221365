import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { ApiService } from 'src/components/utilities/api.service';
import { useParams, useNavigate } from 'react-router-dom';
import { IRoleInfo } from 'src/models/role';
import { AxiosResponse } from 'axios';
import Breadcrumbs from '../BreadCrumbsComponent';

const VendorTable: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IRoleInfo>();

  // fetch the data by id and update

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getData(`services-srv/manpower-role/${id}`);
        reset(response.data.data);
      } catch (error) {
        alert(error);
      }
    };

    if (id) {
      fetchData();
    }
  }, [id]);

  // submit form data
  const onSubmit: SubmitHandler<IRoleInfo> = async (data) => {
    try {
      if (id) {
        data.id, (data._id = id);
      }
      const response: AxiosResponse = await ApiService.postData('services-srv/manpower-role', data);
      if (response.data && response.data?.code === 200 && response.data?.success) {
        alert('Role form saved');
        reset();
        navigate('/roleinfo');
      }
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="flex">
        <Breadcrumbs
          items={[{ label: 'Role', path: `/roleinfo` }, { label: id ? 'Edit' : 'Add' }]}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="form-group">
            <label className="block mb-1">Name</label>
            <input
              type="text"
              className="input-style"
              {...register('role_name', { required: true, maxLength: 20 })}
            />
            {errors.role_name?.type === 'required' && (
              <p className="text-red-700">Required field</p>
            )}
            {errors.role_name?.type === 'maxLength' && (
              <p className="text-red-700">Max length exceeded</p>
            )}
          </div>

          <div className="form-group">
            <label className="block mb-2 " htmlFor="description">
              Description
            </label>
            <textarea
              id="description"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('description')}
            />
            {errors.description && (
              <p className="text-red-700 mt-1">{errors.description.message}</p>
            )}
          </div>
        </div>

        <div className="flex justify-center ">
          <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
            {id ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            className="px-6 btn-primary discard-btn  w-28 mr-2 ml-0 mt-3"
            onClick={() => navigate('/roleinfo')}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default VendorTable;
