import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import Loader from 'src/components/loader';
import { ApiService } from 'src/components/utilities/api.service';
import { AxiosResponse } from 'axios';
import { ITransactionInfo } from 'src/models/transactionModel';

const TransactionTable: React.FC = () => {
  const navigate = useNavigate();
  const { project_id } = useParams<string>();
  const { client_id } = useParams<string>();

  const [clientName, setClientName] = useState<string>();
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [transaction, setTransaction] = useState<ITransactionInfo[]>([]);
  const [loading, setLoading] = useState(true);
  const currentpage='clientinfo';	
  const handleAddTransaction = () => {
    navigate(`/add_transaction/${project_id}/${client_id}`);
  };

  const handleDelete = async (id: string) => {
    if (confirm('Do you want to delete this item')) {
      try {
        const response = await ApiService.deleteData(`project-srv/amount-transaction/${id}`);
        if (response.data && response.data?.data) {
          alert('Transaction deleted successfully');
          getTransactionInfo();
        }
      } catch (error) {
        alert(error);
      }
    } else {
      return false;
    }
  };

  const handleEdit = (transaction_id: string) => {
    navigate(`/update_transaction/${project_id}/${client_id}/${transaction_id}`);
  };

  const getTransactionInfo = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData(
        `project-srv/amount-transaction/${project_id}/${client_id}`
      );
      if (result.data && result.data?.code === 200 && result.data?.success) {
        setTransaction(result?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  const getClientName = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData(`project-srv/client/${client_id}`);
      setClientName(result?.data?.data.name);
    } catch (error) {
      alert(error);
    }
  };

  const getProjectAmount = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData(`project-srv/project/${project_id}`);
      setTotalAmount(result?.data.data.total_cost);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getTransactionInfo();
    getClientName();
    getProjectAmount();
  }, []);

  const calculateRemainingAmount = (index: number) => {
    let remainingAmount = totalAmount;
    for (let i = 0; i <= index; i++) {
      remainingAmount -= transaction[i].given_amount;
    }
    return remainingAmount;
  };

  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };

  return (
    <>
      <div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto mb-10">   
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">  
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className={`hover:underline whitespace-nowrap px-2 ${currentpage==='clientinfo' ? 'text-base font-bold' : '' }`}
     
      
    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className="hover:underline whitespace-nowrap  px-2"
    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Salary Info
    </button>
  </div>
</div>
      
      <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white  text-center grid md:grid-cols-2 sm:grid-cols-1">
    
        <span className="font-semibold text-gray m-4 ">Client Name : <span className='text-blue-500'>{clientName}</span></span>
        <span className="font-semibold text-gray m-4 ">Total Budget : <span className='text-green-500'>{totalAmount}</span></span>
      </div>
         <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
        <div className="flex justify-between items-center mb-4">
          <div className="flex justify-between items-center mb-4 gap-3">
            {/* <div>
              <ol className="breadcrumb ">
                <li>
                  <Link to={`/projectinfo`}>Project</Link>
                </li>
                <li>
                  <Link to={`/clientinfo/${project_id}`}>Client</Link>
                </li>
                <li>
                  <Link to={`/transactions/${project_id}/${client_id}`}>Transaction</Link>
                </li>
              </ol>
            </div> */}
          </div>
          <button
            onClick={handleAddTransaction}
            className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
            <FaPlus className="mr-2" />
            <span  className="hidden sm:inline">
             New Transaction
             </span>
          </button>
        </div>
        <div className='flex-grow overflow-auto'>
        <table className="table-auto w-full border">
          <thead className="bg-gray-100">
            <tr>
              <th className="p-2 text-center">S.No</th>
              <th className="p-2 text-center whitespace-nowrap">Transaction Date</th>
              <th className="p-2 text-center">Amount</th>
              <th className="p-2 text-center whitespace-nowrap">Remaining Amount</th>
              <th className="p-2 text-center">Description</th>
              <th className="p-2 text-center">Action</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {loading ? (
              <tr key={'load'}>
                <td colSpan={6} className="text-center p-4">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {transaction && transaction.length > 0 ? (
                  transaction.map((data, index) => (
                    <tr key={data.id}>
                      <td className="text-center p-2">{index + 1}</td>
                      <td className="text-center p-2">
                        {new Date(data.date).toLocaleDateString()}
                      </td>
                      <td className="text-center p-2 whitespace-nowrap">{data.given_amount}</td>
                      <td className="text-center p-2 whitespace-nowrap">{calculateRemainingAmount(index)}</td>
                      <td className="text-center p-2 whitespace-nowrap">{data.description}</td>
                      <td className="text-center p-2">
                        <button className="text-blue-500" onClick={() => handleEdit(data._id)}>
                          <FaEdit className="inline mr-2" />
                        </button>
                        <button className="text-red-500" onClick={() => handleDelete(data._id)}>
                          <FaTrash className="inline mr-2" />
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center p-4">
                      No Transactions Found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
          {!loading && transaction && transaction.length > 0 && (
            <tfoot className="border">
              <tr>
                <td></td>
                <td className="text-center p-2 font-semibold whitespace-nowrap">
                  <br />
                  Given Amount
                  <br />
                  <br />
                  Remaining Amount
                </td>
                <td className="text-center p-2 font-semibold">
                  <br></br>
                  {transaction.reduce((sum, t) => sum + t.given_amount, 0)}
                  <br />
                  <br /> {totalAmount - transaction.reduce((sum, t) => sum + t.given_amount, 0)}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
        </div>
      </div>

    </>
  );
};

export default TransactionTable;
