import { all, put, takeEvery } from 'redux-saga/effects';
import { TenantActions } from './actions';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiService } from '../../components/utilities/api.service';
import * as Constants from './constants'
import { URLS } from 'src/components/utilities/endpoints';

function* asyncGetTenants() {
    try {
        const response: AxiosResponse = yield ApiService.getData(URLS.GET_TENANTS.url);
        if (response.data.code === 200 && response.data.success) {
            yield put(TenantActions.getTenantsRequestSuccess(response.data));
        }
    } catch (error) {
        console.log(error);
        yield put(TenantActions.getTenantsRequestFailure());
    }
}
function* asyncSaveTenant(data: TenantActions.saveTenantRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.SAVE_TENANT.url, data.payload);
        if (response.data.code === 200) {
            yield put(TenantActions.saveTenantRequestSuccess(response.data));
        }
    } catch (error) {
        const err = error as AxiosError
        yield put(TenantActions.saveTenantRequestFailure(error));
        if (err.response) {
            yield put(TenantActions.saveTenantRequestFailure(err.response.data.error.error_description));
        } else {
            yield put(TenantActions.saveTenantRequestFailure(error));
        }
    }
}
function* asyncGetTenantRolesInfo(data: TenantActions.getTenantRolesByTenantIdAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.TENANT_ROLES.url}/${data.payload}`);
        if (response.data.code === 200) {
            yield put(TenantActions.getTenantRolesByTenantIdRequestSuccess(response.data));
        }
    } catch (error) {
        yield put(TenantActions.getTenantRolesByTenantIdRequestFailure());
    }
}
function* asyncDeleteTenantAdmin(data: TenantActions.deleteTenantAdminRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.TENANT_ADMINS.url}/${data.payload}`);
        if (response.data.code === 200) {
            yield put(TenantActions.deleteTenantAdminRequestSuccess());
        }
    } catch (error) {
        yield put(TenantActions.deleteTenantAdminRequestFailure());
    }
}

function* asyncSaveTenantRole(data: TenantActions.saveTenantRoleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(URLS.TENANT_ROLES.url, data.payload);
        if (response.data.code === 200) {
            yield put(TenantActions.saveTenantRoleRequestSuccess(response.data));
        }
    } catch (error) {
        const err = error as AxiosError
        yield put(TenantActions.saveTenantRoleRequestFailure(error));
        if (err.response) {
            yield put(TenantActions.saveTenantRoleRequestFailure(err.response.data.error.error_description));
        } else {
            yield put(TenantActions.saveTenantRoleRequestFailure(error));
        }
    }
}
function* asyncDeleteTenantRole(data: TenantActions.deleteTenantRoleRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.deleteData(`${URLS.TENANT_ROLES.url}/${data.payload}`);
        if (response.data.code === 200) {
            yield put(TenantActions.deleteTenantRoleRequestSuccess());
        }
    } catch (error) {
        console.log(error);
        yield put(TenantActions.deleteTenantAdminRequestFailure());
    }
}
function* asyncGetTenantAdmins(data: TenantActions.getTenantAdminsByTenantIdAction) {
    try {
        const response: AxiosResponse = yield ApiService.getData(`${URLS.TENANT_ADMINS.url}/${data.payload}`);
        if (response.data.code === 200) {
            yield put(TenantActions.getTenantAdminsByTenantIdRequestSuccess(response.data));
        }
    } catch (error) {
        yield put(TenantActions.getTenantAdminsByTenantIdRequestFailure());
    }
}

function* asyncSaveTenantAdmin(data: TenantActions.saveTenantAdminRequestAction) {
    try {
        const response: AxiosResponse = yield ApiService.postData(`${URLS.TENANT_ADMINS.url}`, data.payload);
        if (response.data.code === 200) {
            yield put(TenantActions.saveTenantAdminRequestSuccess(response.data));
        }
    } catch (error) {
        const err = error as AxiosError
        yield put(TenantActions.saveTenantAdminRequestFailure(error));
        if (err.response) {
            yield put(TenantActions.saveTenantAdminRequestFailure(err.response.data.error.error_description));
        } else {
            yield put(TenantActions.saveTenantAdminRequestFailure(error));
        }
    }
}


export function* tenantSaga() {
    yield all([
        takeEvery(Constants.getTenantsRequestActionType, asyncGetTenants),
        takeEvery(Constants.saveTenantRequestActionType, asyncSaveTenant),
        takeEvery(Constants.deleteTenantAdminRequestActionType, asyncDeleteTenantAdmin),
        takeEvery(Constants.getTenantRolesByTenantIdActionType, asyncGetTenantRolesInfo),
        takeEvery(Constants.saveTenantRoleActionType, asyncSaveTenantRole),
        takeEvery(Constants.deleteTenantRoleRequestActionType, asyncDeleteTenantRole),
        takeEvery(Constants.getTenantAdminsByTenantIdActionType, asyncGetTenantAdmins),
        takeEvery(Constants.saveTenantAdminActionType, asyncSaveTenantAdmin),
    ]);
}
