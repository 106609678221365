// import React, { useEffect, useState } from 'react';
// import { useForm, SubmitHandler } from 'react-hook-form';
// import { useNavigate, useParams } from 'react-router-dom';
// import { ApiService } from 'src/components/utilities/api.service';
// import { ISalaryExpenditureModel } from 'src/models/salaryExpenditureModel';
// import { AxiosResponse } from 'axios';
// import Breadcrumbs from '../BreadCrumbsComponent';

// export enum ExpentitureType {
//   SALARY_EXPENDITURE = 'SALARY_EXPENDITURE',
//   OTHER_EXPENDITURE = 'OTHER_EXPENDITURE'
// }

// interface ManpowerType {
//   name: string;
//   id: string;
//   _id: string;
//   value: string;
//   label: string;
// }

// const AddSalaryExpenditure: React.FC = () => {
//   const {
//     register,
//     handleSubmit,
//     control,
//     formState: { errors },
//     reset,
//     setValue,
//     watch
//   } = useForm<ISalaryExpenditureModel>();
//   const navigate = useNavigate();
//   const { project_id } = useParams<string>();
//   const { salary_id } = useParams<string>();
//   const [manpowerOptions, setManpowerOptions] = useState<ManpowerType[]>([]);
//   const expenditureType = watch('expenditure_type');

//   const fetchManpower = async () => {
//     try {
//       const result: AxiosResponse = await ApiService.getData('project-srv/manpower');
//       const manpowers = result.data?.data;
//       setManpowerOptions(manpowers);
//     } catch (error) {
//       alert(error);
//     }
//   };

//   useEffect(() => {
//     fetchManpower();
//   }, []);

//   useEffect(() => {
//     if (salary_id) {
//       const fetchData = async () => {
//         try {
//           const response = await ApiService.getData(`project-srv/salary-expenditure/${salary_id}`);
//           const expenditureData = response.data?.data;

//           const formatDate = (dateString: string) => {
//             const date = new Date(dateString);
//             return date.toLocaleDateString('en-CA');
//           };

//           expenditureData.from_date = formatDate(expenditureData.from_date);
//           expenditureData.to_date = formatDate(expenditureData.to_date);
//           reset(expenditureData);
//         } catch (error) {
//           alert(error);
//         }
//       };

//       fetchData();
//     }
//   }, [salary_id, setValue, reset]);

//   const onSubmit: SubmitHandler<ISalaryExpenditureModel> = async (data) => {
//     data.project_id = project_id as string;
//     try {
//       const selectedManpower = manpowerOptions.find(
//         (manpower) => manpower._id === data.manpower_id
//       );
//       if (selectedManpower) {
//         data.manpower_name = selectedManpower.name;
//       }

//       if (salary_id) {
//         data.id = salary_id;
//         await ApiService.postData(`project-srv/salary-expenditure`, data);
//         alert('Form updated');
//       } else {
//         await ApiService.postData(`project-srv/salary-expenditure`, data);
//         alert('Form Submitted');
//       }
//       reset();
//       navigate(`/salaryexpendituretabel/${project_id}`);
//     } catch (error) {
//       alert('Failed to submit form');
//     }
//   };
//   const handleNavigation = (path:string) => {
//     navigate(`/${path}/${project_id}`);
//   };

//   return (
//     <div className="mt-8 max-w-xxl mx-auto shadow-lg rounded-lg bg-white">
//       {/* <Breadcrumbs
//         items={[
//           { label: 'Project', path: `/projectinfo/${project_id}` },
//           { label: 'Expenditure', path: `/salaryexpendituretabel/${project_id}` },
//           { label: salary_id ? 'Edit' : 'Add' }
//         ]}
//       /> */}
//       <div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto mb-10">   
//     <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">  
  
//     <button
//       onClick={() => handleNavigation(`projectinfo`)}
//       className="hover:underline whitespace-nowrap px-2"
//     >
//       Project Info
//     </button>
//     <button
//       onClick={() => handleNavigation(`clientinfo`)}  
//       className="hover:underline whitespace-nowrap px-2"
//     >
//       Client Info
//     </button>
//     <button
//       onClick={() => handleNavigation(`materialmappinginfo`)}
//       className="hover:underline whitespace-nowrap  px-2"
//     >
//       Material Purchase
//     </button>
//     <button
//       onClick={() => handleNavigation(`materialUsage_info`)}
//       className="hover:underline whitespace-nowrap px-2"
//     >
//       Material Usage
//     </button>
//     <button
//       onClick={() => handleNavigation(`salaryexpendituretabel`)}
//       className="hover:underline whitespace-nowrap px-2"
//     >
//       Expenditure
//     </button>
//     <button
//       onClick={() => handleNavigation(`salaryconfig`)}
//       className="hover:underline whitespace-nowrap px-2"
//     >
//       Salary Info
//     </button>
//   </div>
// </div>
//       <div className='container'>
//         <p className='font-extrabold text-xl text-center mb-4 h4'>Add Expenditure </p>
//       </div>
// <div className='p-4'>
//       <form onSubmit={handleSubmit(onSubmit)}>
//         <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 mb-4">
//           <div className="mb-4">
//             <label className="block mb-1">Expenditure Type <span className="text-red-700 ml-1">*</span></label>
//             <select
//               className="input-style"
//               {...register('expenditure_type', { required: 'Expenditure Type is required' })}>
//               <option value="">Select Expenditure Type</option>
//               <option value={ExpentitureType.SALARY_EXPENDITURE}>Salary</option>
//               <option value={ExpentitureType.OTHER_EXPENDITURE}>Other</option>
//             </select>
//             {errors.expenditure_type && (
//               <p className="text-red-700">{errors.expenditure_type.message}</p>
//             )}
//           </div>
//           {expenditureType === ExpentitureType.OTHER_EXPENDITURE ? (
//             <div className="mb-4">
//               <label className="block mb-1">Expenditure Name <span className="text-red-700 ml-1">*</span></label>
//               <input
//                 className="input-style"
//                 {...register('expenditure_name', { required: 'Expenditure Name is required' })}
//               />
//               {errors.expenditure_name && (
//                 <p className="text-red-700">{errors.expenditure_name.message}</p>
//               )}
//             </div>
//           ) : 
//           (
//             <div className="mb-4">
//               <label className="block mb-1">Select Manpower <span className="text-red-700 ml-1">*</span></label>
//               <select
//                 className="input-style"
//                 {...register('manpower_id', { required: 'Manpower is required' })}>
//                 <option value="">Select Manpower</option>
//                 {manpowerOptions.map((manpower) => (
//                   <option key={manpower._id} value={manpower._id}>
//                     {manpower.name}
//                   </option>
//                 ))}
//               </select>
//               {errors.manpower_id && <p className="text-red-700">{errors.manpower_id.message}</p>}
//             </div>
//           )}
//           <div className="mb-4">
//             <label className="block mb-2">From Date <span className="text-red-700 ml-1">*</span></label>
//             <input
//               type="date"
//               id="from_date"
//               className="border border-gray-300 p-2 rounded w-full"
//               {...register('from_date', { required: 'From Date is required' })}
//             />
//             {errors.from_date && <p className="text-red-700 mt-1">{errors.from_date.message}</p>}
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2" htmlFor="to_date">
//               To Date <span className="text-red-700 ml-1">*</span>
//             </label>
//             <input
//               type="date"
//               id="to_date"
//               className="border border-gray-300 p-2 rounded w-full"
//               {...register('to_date', { required: 'To Date is required' })}
//             />
//             {errors.to_date && <p className="text-red-700 mt-1">{errors.to_date.message}</p>}
//           </div>
//           <div className="mb-4">
//             <label className="block mb-2" htmlFor="amount">
//               Amount <span className="text-red-700 ml-1">*</span>
//             </label>
//             <input
//               type="number"
//               id="amount"
//               className="border border-gray-300 p-2 rounded w-full"
//               {...register('salary')}
//             />
//           </div>
//           <div className="grid rows gap-4 mb-4">
//             <div className="mb-4">
//               <label className="block mb-2" htmlFor="description">
//                 Description 
//               </label>
//               <textarea
//                 id="description"
//                 className="border border-gray-300 p-2 rounded w-full"
//                 {...register('description')}
//               />
//               {errors.description && (
//                 <p className="text-red-700 mt-1">{errors.description.message}</p>
//               )}
//             </div>
//           </div>
//         </div>
//         <div className="flex justify-center">
//           <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
//             {project_id ? 'Update' : 'Save'}
//           </button>
//           <button
//             type="button"
//             className="px-6 bg-gray-200 rounded-md w-28 mr-2 ml-0 mt-3"
//             onClick={() => navigate(`/salaryexpendituretabel/${project_id}`)}>
//             Cancel
//           </button>
//         </div>
//       </form>
//     </div>
//     </div>
//   );
// };

// export default AddSalaryExpenditure;


import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiService } from 'src/components/utilities/api.service';
import { ISalaryExpenditureModel } from 'src/models/salaryExpenditureModel';
import { AxiosResponse } from 'axios';
import Breadcrumbs from '../BreadCrumbsComponent';

export enum ExpentitureType {
  SALARY_EXPENDITURE = 'SALARY_EXPENDITURE',
  OTHER_EXPENDITURE = 'OTHER_EXPENDITURE'
}

interface ManpowerType {
  name: string;
  id: string;
  _id: string;
  value: string;
  label: string;
}

const AddSalaryExpenditure: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm<ISalaryExpenditureModel>();
  const navigate = useNavigate();
  const { project_id } = useParams<string>();
  const { salary_id } = useParams<string>();
  const { id } = useParams<{ id: string }>();
  const [manpowerOptions, setManpowerOptions] = useState<ManpowerType[]>([]);
  const expenditureType = watch('expenditure_type');
  const currentpage='salaryexpendituretabel';	

  const fetchManpower = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData('project-srv/manpower');
      const manpowers = result.data?.data;
      setManpowerOptions(manpowers);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchManpower();
  }, []);

  useEffect(() => {
    if (salary_id) {
      const fetchData = async () => {
        try {
          const response = await ApiService.getData(`project-srv/salary-expenditure/${salary_id}`);
          const expenditureData = response.data?.data;

          const formatDate = (dateString: string) => {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-CA');
          };

          expenditureData.from_date = formatDate(expenditureData.from_date);
          expenditureData.to_date = formatDate(expenditureData.to_date);
          reset(expenditureData);
        } catch (error) {
          alert(error);
        }
      };

      fetchData();
    }
  }, [salary_id, setValue, reset]);

  const onSubmit: SubmitHandler<ISalaryExpenditureModel> = async (data) => {
    data.project_id = project_id as string;
    try {
      const selectedManpower = manpowerOptions.find(
        (manpower) => manpower._id === data.manpower_id
      );
      if (selectedManpower) {
        data.manpower_name = selectedManpower.name;
      }

      if (salary_id) {
        data.id = salary_id;
        await ApiService.postData(`project-srv/salary-expenditure`, data);
        alert('Form updated');
      } else {
        await ApiService.postData(`project-srv/salary-expenditure`, data);
        alert('Form Submitted');
      }
      reset();
      navigate(`/salaryexpendituretabel/${project_id}`);
    } catch (error) {
      alert('Failed to submit form');
    }
  };
  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };

  return (
    <div className="mt-8 max-w-xxl mx-auto shadow-lg rounded-lg bg-white">
      {/* <Breadcrumbs
        items={[
          { label: 'Project', path: `/projectinfo/${project_id}` },
          { label: 'Expenditure', path: `/salaryexpendituretabel/${project_id}` },
          { label: salary_id ? 'Edit' : 'Add' }
        ]}
      /> */}
      <div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto mb-10">   
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">  
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className="hover:underline whitespace-nowrap px-2"
    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className="hover:underline whitespace-nowrap  px-2"
    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      // className="hover:underline whitespace-nowrap px-2"
      className={`hover:underline whitespace-nowrap px-2 ${currentpage==='salaryexpendituretabel' ? 'text-base font-bold' : '' }`}

    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Salary Info
    </button>
  </div>
</div>
      <div className='container'>
        <p className='font-extrabold text-xl text-center mb-4 h4'>Add Expenditure </p>
      </div>
<div className='p-4'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 mb-4">
          <div className="mb-4">
            <label className="block mb-1">Expenditure Type <span className="text-red-700 ml-1">*</span></label>
            <select
              className="input-style"
              {...register('expenditure_type', { required: 'Expenditure Type is required' })}
              defaultValue={ExpentitureType.OTHER_EXPENDITURE} >
              {/* <option value="">Select Expenditure Type</option>  */}
              {/* <option value={ExpentitureType.SALARY_EXPENDITURE}>Salary</option> */}
              <option value={ExpentitureType.OTHER_EXPENDITURE}>Other</option> 
            </select>
            {errors.expenditure_type && (
              <p className="text-red-700">{errors.expenditure_type.message}</p>
            )}
          </div>
          {/* {expenditureType === ExpentitureType.OTHER_EXPENDITURE ? ( */}
            <div className="mb-4">
              <label className="block mb-1">Expenditure Name <span className="text-red-700 ml-1">*</span></label>
              <input
                className="input-style"
                {...register('expenditure_name', { required: 'Expenditure Name is required' })}
              />
              {errors.expenditure_name && (
                <p className="text-red-700">{errors.expenditure_name.message}</p>
              )}
            </div>
          {/* ) :  */}
          {/* (
            <div className="mb-4">
              <label className="block mb-1">Select Manpower <span className="text-red-700 ml-1">*</span></label>
              <select
                className="input-style"
                {...register('manpower_id', { required: 'Manpower is required' })}>
                <option value="">Select Manpower</option>
                {manpowerOptions.map((manpower) => (
                  <option key={manpower._id} value={manpower._id}>
                    {manpower.name}
                  </option>
                ))}
              </select>
              {errors.manpower_id && <p className="text-red-700">{errors.manpower_id.message}</p>}
            </div>
          ) */}
          {/* } */}
          <div className="mb-4">
            <label className="block mb-2">From Date <span className="text-red-700 ml-1">*</span></label>
            <input
              type="date"
              id="from_date"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('from_date', { required: 'From Date is required' })}
            />
            {errors.from_date && <p className="text-red-700 mt-1">{errors.from_date.message}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="to_date">
              To Date <span className="text-red-700 ml-1">*</span>
            </label>
            <input
              type="date"
              id="to_date"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('to_date', { required: 'To Date is required' })}
            />
            {errors.to_date && <p className="text-red-700 mt-1">{errors.to_date.message}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="amount">
              Amount <span className="text-red-700 ml-1">*</span>
            </label>
            <input
              type="number"
              id="amount"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('salary')}
            />
          </div>
          <div className="grid rows gap-4 mb-4">
            <div className="mb-4">
              <label className="block mb-2" htmlFor="description">
                Description 
              </label>
              <textarea
                id="description"
                className="border border-gray-300 p-2 rounded w-full"
                {...register('description')}
              />
              {errors.description && (
                <p className="text-red-700 mt-1">{errors.description.message}</p>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
            {id ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            className="px-6 bg-gray-200 rounded-md w-28 mr-2 ml-0 mt-3"
            onClick={() => navigate(`/salaryexpendituretabel/${project_id}`)}>
            Cancel
          </button>
        </div>
      </form>
    </div>
    </div>
  );
};

export default AddSalaryExpenditure;
