import React, { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiService } from 'src/components/utilities/api.service';
import { IPersonalExpenditureModel } from 'src/models/personalExpenditureModel';
import Breadcrumbs from '../BreadCrumbsComponent';
import { AxiosResponse } from 'axios';

const AddPersonalExpenditure: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue
  } = useForm<IPersonalExpenditureModel>();

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await ApiService.getData(`project-srv/personal-expenditure/${id}`);
          const expenditureData = response.data?.data;
          // Convert dates to YYYY-MM-DD format using toLocaleDateString
          const formatDate = (dateString: string) => {
            const date = new Date(dateString);
            return date.toLocaleDateString('en-CA'); // YYYY-MM-DD format
          };

          expenditureData.from_date = formatDate(expenditureData.from_date);
          expenditureData.to_date = formatDate(expenditureData.to_date);
          reset(expenditureData);
        } catch (error) {
          alert(error);
        }
      };

      fetchData();
    }
  }, [id, setValue, reset]);

  const onSubmit: SubmitHandler<IPersonalExpenditureModel> = async (data) => {
    try {
      if (id) {
        data.id = id;
        data._id = id;
      }
      const response: AxiosResponse = await ApiService.postData(
        `project-srv/personal-expenditure`,
        data
      );
      if (response.data && response.data?.code === 200 && response.data?.success) {
        alert('Personal expenditure Saved');
      }

      reset();
      navigate('/personalexpendituretable');
    } catch (error) {
      alert(error);
    }
  };

  return (
    <div className="max-w-xxl mx-auto p-4 shadow border rounded">
      <div className="flex">
        <Breadcrumbs
          items={[
            { label: 'Personal Expenditure', path: `/personalexpendituretable` },
            { label: id ? 'Edit' : 'Add' }
          ]}
        />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">

          <div className="mb-4">
            <label className="block mb-2 ">From Date <span className="text-red-700 ml-1">*</span></label>
            <input
              type="date"
              id="from_date"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('from_date', { required: 'From Date is required' })}
            />
            {errors.from_date && <p className="text-red-700 mt-1">{errors.from_date.message}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 " htmlFor="to_date">
              To Date <span className="text-red-700 ml-1">*</span>
            </label>
            <input
              type="date"
              id="to_date"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('to_date', { required: 'To Date is required' })}
            />
            {errors.to_date && <p className="text-red-700 mt-1">{errors.to_date.message}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-2 " htmlFor="amount">
              Amount<span className="text-red-700 ml-1">*</span>
            </label>
            <input
              type="number"
              id="amount"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('amount')}
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2" htmlFor="description">
              Description
            </label>
            <textarea
              id="description"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('description', { required: 'Description is required' })}
            />
            {errors.description && (
              <p className="text-red-700 mt-1">{errors.description.message}</p>
            )}
          </div>
        </div>
        <div className="flex justify-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
            {id ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            className="px-6 bg-gray-200 rounded-md w-28 mr-2 ml-0 mt-3"
            onClick={() => navigate('/personalexpendituretable')}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddPersonalExpenditure;
