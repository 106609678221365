import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/loader';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import { IPersonalExpenditureModel } from 'src/models/personalExpenditureModel';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';

const PersonalExpenditureTable: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [expenditure, setExpenditure] = useState<IPersonalExpenditureModel[]>([]);

  const getExpenditure = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData('project-srv/personal-expenditure');
      setExpenditure(result?.data?.data);
      setLoading(false);
    } catch (error) {
      alert(error);
    }
  };
  useEffect(() => {
    getExpenditure();
  }, []);

  const handleEdit = (id: string) => {
    navigate(`/addpersonalexpenditure/${id}`);
  };

  const handleDelete = async (id: any) => {
    if (window.confirm('Do you want to delete?')) {
      try {
        const response: AxiosResponse = await ApiService.deleteData(
          `project-srv/personal-expenditure/${id}`
        );
        if (response.data && response.data?.data) {
          alert('Personal Expenditure Deleted Successfully');

          getExpenditure();
        }
      } catch (error) {
        alert(error);
      }
    } else {
      return false;
    }
  };

  const handleAddExpenditure = () => {
    navigate('/addpersonalexpenditure');
  };

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-semibold">Personal Expenditure</h2>

        <button
          onClick={handleAddExpenditure}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
          <FaPlus className="mr-2" />
          <span className="hidden sm:inline">Add New Expenditure</span>
        </button>
      </div>
      <div className="flex-grow overflow-auto">

        <table className="table-auto w-full border">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-2 px-4 border-b text-center">S.No</th>
            <th className="py-2 px-4 border-b text-center whitespace-nowrap">From Date</th>
            <th className="py-2 px-4 border-b text-center whitespace-nowrap">To Date</th>
            <th className="py-2 px-4 border-b text-center whitespace-nowrap">Description</th>
            <th className="py-2 px-4 border-b text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {loading ? (
            <tr key={'load'}>
              <td>
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {expenditure && expenditure.length > 0 ? (
                expenditure.map((data, index) => (
                  <tr key={data._id}>
                    <td className="p-2 text-center">{index + 1}</td>
                    <td className="p-2 text-center">
                      {new Date(data.from_date).toLocaleDateString()}
                    </td>
                    <td className="p-2 text-center">
                      {new Date(data.to_date).toLocaleDateString()}
                    </td>
                    <td className="p-2 text-center">{data.description}</td>
                    <td className="p-2 flex items-center justify-center gap-2 relative">
                      <button className="text-blue-500" onClick={() => handleEdit(data._id)}>
                        <FaEdit className="inline mr-1" />
                      </button>
                      <button className="text-red-500" onClick={() => handleDelete(data._id)}>
                        <FaTrash className="inline mr-1" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center p-4">
                    No Data found
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
        </table>
      </div>

    </div>
  );
};

export default PersonalExpenditureTable;
