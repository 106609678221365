import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaEye, FaPlus } from 'react-icons/fa';
import { ApiService } from 'src/components/utilities/api.service';
import { AxiosResponse } from 'axios';
// import Loader from 'src/components/loader';
import { IVendorInfo } from 'src/models/vendorModel';

const VendorTable: React.FC = () => {
  const [vendor, setVendor] = useState<IVendorInfo[]>();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleaAddVendors = () => {
    navigate('/addvendor');
  };

  const handleEdit = (id: string) => {
    navigate(`/addvendor/${id}`);
  };

  const handleDelete = async (id: any) => {
    if (window.confirm('Do you want to delete?')) {
      try {
        const response = await ApiService.deleteData(`services-srv/vendor-details/${id}`);
        if(response.data && response.data?.code === 200 && response.data?.success) {
          alert('Vendor Deleted Successfully');
          getVendor();

        }
      } catch (error) {
        alert(error);
      }
    } else {
      return false;
    }
  };

 

  const getVendor = async () => {
    try {
      const response: AxiosResponse = await ApiService.getData('services-srv/vendor-details');
      setVendor(response?.data?.data);
      setLoading(false);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getVendor();
  }, []);

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="flex justify-between items-center mb-4 ">
        <h2 className="text-xl font-semibold">Vendors</h2>
        <button
          onClick={handleaAddVendors}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
          <FaPlus className="mr-2" />
          Add New Vendor
        </button>
      </div>
      <table className="table-auto w-full border">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-2 text-center">S. No.</th>
            <th className="p-2 text-center">Name</th>
            <th className="p-2 text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {loading ? (
            <tr>
              <td colSpan={4} className="text-center p-4">
                {/* <Loader /> */}
              </td>
            </tr>
          ) : (
            <>
              {vendor && vendor.length > 0 ? (
                vendor.map((data, index) => (
                  <tr key={data._id}>
                    <td className="p-2 text-center">{index + 1}</td>
                    <td className="p-2 text-center">{data.vendor_name}</td>
                    <td className="p-2 text-center">
                      <button className="text-blue-500" onClick={() => handleEdit(data._id)}>
                        <FaEdit className="inline mr-2" />
                      </button>
                      <button className="text-red-500 ml-2" onClick={() => handleDelete(data._id)}>
                        <FaTrash className="inline mr-2" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center p-4">
                    No Vendor found
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default VendorTable;
