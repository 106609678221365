import React, { useState, useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { MultiValue } from 'react-select';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import { IManpowerInfo } from 'src/models/manpower';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { IRoleInfo } from 'src/models/role';

interface OptionType {
  value: string;
  label: string;
}

const Manpower: React.FC = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue
  } = useForm<IManpowerInfo>();
  const [roleOptions, setRoleOptions] = useState<OptionType[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<OptionType[]>([]);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roleResponse: AxiosResponse<{ data: IRoleInfo[] }> = await ApiService.getData(
          'services-srv/manpower-role'
        );
        const roles = roleResponse.data.data.map((role) => ({
          value: role.id,
          label: role.role_name
        }));
        setRoleOptions(roles);

        if (id) {
          const response = await ApiService.getData(`project-srv/manpower/${id}`);
          const projectData = response.data.data;
          reset(projectData);

          if (projectData.role_id) {
            const selectedRoles = roles.filter((option) =>
              projectData.role_id.includes(option.value)
            );
            setSelectedRoles(selectedRoles);
          }
        }
      } catch (error) {
        alert(error);
      }
    };

    fetchData();
  }, [id, reset]);

  const onSubmit: SubmitHandler<IManpowerInfo> = async (data) => {
    try {
      data.photo = 'image.jpeg'; // Handle file upload correctly in practice
      data.role_id = selectedRoles.map((role) => role.value); // Ensure role_id is updated

      if (id) {
        data.id = id;
       const response:AxiosResponse= await ApiService.postData(`project-srv/manpower`, data);
       if(response.data && response.data?.code === 200 && response.data?.success) {
        alert('Form Updated');
        navigate('/manpowerInfo');
       }
      } else {
        const response:AxiosResponse=await ApiService.postData('project-srv/manpower', data);
        if(response.data && response.data?.code === 200 && response.data?.success) {
          navigate('/manpowerInfo');
          alert('Form submitted');
          reset();
        }
      }
     
    } catch (error) {
      alert(error)
    }
  };

  const handleCancel = () => {
    navigate('/manpowerinfo');
  };

  const handleRoleChange = (selectedOptions: MultiValue<OptionType>) => {
    setSelectedRoles(Array.from(selectedOptions));
    setValue(
      'role_id',
      selectedOptions.map((option) => option.value)
    );
  };

  const handleRoleCreate = async (inputValue: string) => {
    const newOption = { value: inputValue, label: inputValue };

    try {
      // Add new role to backend
      await ApiService.postData('services-srv/manpower-role', { role_name: inputValue });

      // Update local role options and selected roles
      setRoleOptions((prevOptions) => [...prevOptions, newOption]);
      setSelectedRoles((prevSelected) => [...prevSelected, newOption]);
    } catch (error) {
      
      alert(error)
    }
  };

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="mb-8">
        <ol className="breadcrumb">
          <li>
            <Link to="/manpowerinfo">Manpower</Link>
          </li>
          <li className="active">{id ? <strong>Edit</strong> : <strong>Add</strong>}</li>
        </ol>
      </div>
     
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 mb-4">
          <div className="mb-4 form-group">
            <label className="block mb-2 " htmlFor="name">
              Name *
            </label>
            <input
              type="text"
              id="name"
              className="input-style"
              {...register('name', { required: 'Name is required' })}
            />
            {errors.name && <p className="text-red-700 mt-1">{errors.name.message}</p>}
          </div>

          <div className="mb-4">
            <label className="block mb-2 " htmlFor="role">
              Role *
            </label>
            <Controller
              name="role_id"
              control={control}
              render={() => (
                <CreatableSelect
                  options={roleOptions}
                  className="basic-single"
                  classNamePrefix="select"
                  onChange={handleRoleChange}
                  onCreateOption={handleRoleCreate}
                  isMulti
                  placeholder="Select Roles"
                  value={selectedRoles}
                />
              )}
            />
            {errors.role_id && <p className="text-red-700 mt-1">Role is required</p>}
          </div>
        </div>

        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 mb-4">
          <div className="mb-4">
            <label className="block mb-2 " htmlFor="photo">
              Photo
            </label>
            <input
              type="file"
              id="photo"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('photo')}
            />
            {errors.photo && <p className="text-red-700 mt-1">{errors.photo.message}</p>}
          </div>

          <div>
            <label className="block mb-2 " htmlFor="address">
              Address
            </label>
            <textarea
              id="address"
              className="border border-gray-300 p-2 rounded w-full"
              placeholder="Address"
              {...register('address')}
            />
            {errors.address && <p className="text-red-700 mt-1">{errors.address.message}</p>}
          </div>
        </div>

        <div className="grid md:grid-cols-2 sm:grid-cols-1 gap-4 mb-4">
          <div>
            <label className="block mb-2 " htmlFor="mobile_number">
              Phone Number
            </label>
            <input
              type="text"
              id="mobile_number"
              className="border border-gray-300 p-2 rounded w-full"
              placeholder="Phone Number"
              {...register('mobile_number', { required: true, maxLength: 10 })}
            />
            {errors.mobile_number && (
              <p className="text-red-700 mt-1">{errors.mobile_number.message}</p>
            )}
          </div>

          <div>
            <label className="block mb-2 " htmlFor="description">
              Description
            </label>
            <textarea
              id="description"
              className="border border-gray-300 p-2 rounded w-full"
              placeholder="Description"
              {...register('description')}
            />
            {errors.description && (
              <p className="text-red-700 mt-1">{errors.description.message}</p>
            )}
          </div>
        </div>

        <div className="flex justify-center space-x-4 mt-4">
          <button type="submit" className="px-4 py-2 bg-blue-500 text-white rounded">
            {id ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            onClick={handleCancel}
            className="px-4 py-2 bg-gray-200 text-gray-700 rounded">
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default Manpower;
