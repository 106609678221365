export const getTenantsRequestActionType = 'GET_TENANTS_REQUEST'
export const getTenantsRequestSuccessActionType = 'GET_TENANTS_REQUEST_SUCCESS'
export const getTenantsRequestFailureActionType = 'GET_TENANTS_REQUEST_FAILURE'

export const saveTenantRequestActionType = 'SAVE_TENANT_REQUEST'
export const saveTenantRequestSuccessActionType = 'SAVE_TENANT_REQUEST_SUCCESS'
export const saveTenantRequestFailureActionType = 'SAVE_TENANT_REQUEST_FAILURE'

export const deleteTenantAdminRequestActionType = 'DELETE_TENANT_REQUEST'
export const deleteTenantAdminRequestSuccessActionType = 'DELETE_TENANT_REQUEST_SUCCESS'
export const deleteTenantAdminRequestFailureActionType = 'DELETE_TENANT_REQUEST_FAILURE'

export const getTenantRolesByTenantIdActionType = 'GET_TENANT_ROLES_BY_TENANT_ID_REQUEST'
export const getTenantRolesByTenantIdSuccessActionType = 'GET_TENANT_ROLES_BY_TENANT_ID_SUCCESS'
export const getTenantRolesByTenantIdFailureActionType = 'GET_TENANT_ROLES_BY_TENANT_ID_FAILURE'

export const saveTenantRoleActionType = 'SAVE_TENANT_ROLE_REQUEST'
export const saveTenantRoleSuccessActionType = 'SAVE_TENANT_ROLE_REQUEST_SUCCESS'
export const saveetTenantRoleFailureActionType = 'SAVE_TENANT_ROLE_REQUEST_FAILURE'

export const deleteTenantRoleRequestActionType = 'DELETE_TENANT_ROLE_REQUEST'
export const deleteTenantRoleRequestSuccessActionType = 'DELETE_TENANT_ROLE_REQUEST_SUCCESS'
export const deleteTenantRoleRequestFailureActionType = 'DELETE_TENANT_ROLE_REQUEST_FAILURE'

export const getTenantAdminsByTenantIdActionType = 'GET_TENANT_ADMINS_BY_TENANT_ID_REQUEST'
export const getTenantAdminsByTenantIdSuccessActionType = 'GET_TENANT_ADMINS_BY_TENANT_ID_SUCCESS'
export const getTenantAdminsByTenantIdFailureActionType = 'GET_TENANT_ADMINS_BY_TENANT_ID_FAILURE'

export const saveTenantAdminActionType = 'SAVE_TENANT_ADMIN_REQUEST'
export const saveTenantAdminSuccessActionType = 'SAVE_TENANT_ADMIN_REQUEST_SUCCESS'
export const saveTenantAdminFailureActionType = 'SAVE_TENANT_ADMIN_REQUEST_FAILURE'