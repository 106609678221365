// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { ApiService } from 'src/components/utilities/api.service';
import { AxiosResponse } from 'axios';
import { IManpowerMapModel } from 'src/models/manpowerMapModel';
import { log } from 'console';

interface ManpowerType {
  _id: string;
  id: string;
  name: string;
  manpower_name: string;
  amount: number;
}
interface RoleType {
  id: string;
  _id: string;
  role_name: string;
}

const AddManpowerMapping: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch
  } = useForm<IManpowerMapModel>();

  const navigate = useNavigate();
  const { id } = useParams<string>();
  const { project_id } = useParams<string>();
  const [roleOptions, setRoleOptions] = useState<RoleType[]>([]);
  const [manpowerOptions, setManpowerOptions] = useState<ManpowerType[]>([]);
  const selectedRoleId = watch('role_id');

  // Fetch the roles for the dropdown
  const fetchRoles = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData('services-srv/manpower-role');
      const roleOptions: RoleType[] = result.data.data.map((e: any) => ({
        _id: e?._id,
        id: e?.id,
        role_name: e?.role_name
      }));
      setRoleOptions(roleOptions);
    } catch (error) {
      console.error('Error fetching roles:', error);
    }
  };
  useEffect(() => {
    fetchRoles();
  }, []);

  // Fetch the manpower for the dropdown based on selected role
  const fetchManpower = async (roleId: string) => {
    try {
      const result: AxiosResponse = await ApiService.getData(
        `project-srv/manpower/roles/${roleId}`
      );
      const manpowerOptions: ManpowerType[] = result.data.data.map((e: any) => ({
        _id: e?._id,
        id: e?.id,
        name: e?.name
      }));
      setManpowerOptions(manpowerOptions);
    } catch (error) {
      console.error('Error fetching manpower:', error);
    }
  };

  // Fetch the manpower when a role is selected
  useEffect(() => {
    if (selectedRoleId) {
      fetchManpower(selectedRoleId);
    } else {
      setManpowerOptions([]); // Clear manpower options if no role is selected
    }
  }, [selectedRoleId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getData(`project-srv/project/manpower_map/${id}`);
        const manpowerMapData = response.data.data;

        // Set the form values for editing
        setValue('role_id', manpowerMapData.role_id);
        setValue('manpower_id', manpowerMapData.manpower_id);
        setValue('payable_amount', manpowerMapData.payable_amount);
        setValue('description', manpowerMapData.description);

        // Fetch manpower options based on the previously selected role
        if (manpowerMapData.role_id) {
          await fetchManpower(manpowerMapData.role_id);
        }

        reset(manpowerMapData);
      } catch (error) {
        console.error('Error fetching project:', error);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id, reset, setValue]);

  const onSubmit: SubmitHandler<IManpowerMapModel> = async (data) => {
    try {
      // Find the selected role name and manpower name
      const selectedRole = roleOptions.find((role) => role._id === data.role_id);
      const selectedManpower = manpowerOptions.find(
        (manpower) => manpower._id === data.manpower_id
      );

      if (selectedRole) {
        data.role_name = selectedRole.role_name;
      }
      if (selectedManpower) {
        data.manpower_name = selectedManpower.name;
      }

      data.project_id = project_id as string;
      if (id) {
        data.id = id;
        await ApiService.postData(`project-srv/project/manpower_map`, data);
        alert('Form updated');
      } else {
        await ApiService.postData('project-srv/project/manpower_map', data);
        alert('Form submitted');
      }
      reset();
      navigate(`/manpowermappingtable/${project_id}`);
      // Reload the page to fetch the updated data
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Failed to submit form');
    }
  };

  return (
    <div className="max-w-xxl mx-auto p-4 shadow border rounded">
      <div className="flex">
        <h2 className="text-xl font-semibold mb-4">Manpower Mapping </h2>

        {id ? (
          <h2 className="font-block text-xl font-semibold mb-4 text-gray-400"> / Edit</h2>
        ) : (
          <h2 className="font-block text-xl font-semibold mb-4 text-gray-400"> / Add</h2>
        )}
      </div>{' '}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="mb-4">
            <label className="block mb-1">Select Role *</label>
            <select
              className="input-style"
              {...register('role_id', { required: 'Role is required' })}>
              <option value="">Select Role</option>
              {roleOptions.map((role) => (
                <option key={role._id} value={role._id}>
                  {role.role_name}
                </option>
              ))}
            </select>
            {errors.role_id && <p className="text-red-700">{errors.role_id.message}</p>}
          </div>
          <div className="mb-4">
            <label className="block mb-1">Select Manpower *</label>
            <select
              className="input-style"
              {...register('manpower_id', { required: 'Manpower is required' })}>
              <option value="">Select Manpower</option>
              {manpowerOptions.map((manpower) => (
                <option key={manpower._id} value={manpower._id}>
                  {manpower.name}
                </option>
              ))}
            </select>
            {errors.manpower_id && <p className="text-red-700">{errors.manpower_id.message}</p>}
          </div>
        </div>

        <div className="mb-4">
          <label className="block mb-2 " htmlFor="amount">
            Payable Amount
          </label>
          <input
            type="number"
            id="amount"
            className="border border-gray-300 p-2 rounded w-full"
            {...register('payable_amount', { required: 'Amount is required' })}
          />
          {errors.payable_amount && (
            <p className="text-red-700 mt-1">{errors.payable_amount.message}</p>
          )}
        </div>

        <div className="mb-4">
          <label className="block mb-2 " htmlFor="description">
            Description
          </label>
          <textarea
            id="description"
            className="border border-gray-300 p-2 rounded w-full"
            {...register('description')}
          />
          {/* {errors.description && <p className="text-red-700 mt-1">{errors.description.message}</p>} */}
        </div>

        <div className="flex justify-center ">
          <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
            Save
          </button>
          <button
            type="button"
            className="px-6 bg-gray-200 rounded-md w-28 mr-2 ml-0 mt-3"
            onClick={() => navigate(`/manpowermappingtable/${project_id}`)}>
            Cancel
          </button>
        </div>
      </form>
      {/* <ToastContainer /> */}
    </div>
  );
};

export default AddManpowerMapping;
