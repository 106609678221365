// import React, { useEffect, useState } from 'react';
// import { useNavigate, useParams } from 'react-router-dom';
// import { FaPlus, FaEdit, FaTrash, FaEye, FaEllipsisV,FaPrint } from 'react-icons/fa';
// import { AxiosError, AxiosResponse } from 'axios';
// import { ApiService } from 'src/components/utilities/api.service';
// import { IClientInfo } from 'src/models/clientModel';
// import Loader from 'src/components/loader';
// import Breadcrumbs from 'src/components/admin/BreadCrumbsComponent';
// import { formatDate, formatRupeeValue, getErrorMsg, getFormattedDate } from 'src/components/utilities/utils';
// export interface ISalaryConfig {
//     _id: string;
//   id: string;
// project_id: string;
//   total_manpower_salary: number;
//   manpower_info: ISaslaryConfigManpowerInfo[];
  
  

//   // db defaults
//   migration_flag: string;
//   createdAt: Date;
//   updatedAt: Date;
// }
// export interface ISaslaryConfigManpowerInfo {
  
//     from_date: Date;
//     to_date: Date;
//     role: string;
//     count: number;
//     salary_per_head: number;
//     total_salary: number; 
//   }
// const SalaryConfig: React.FC = () => {
//   const navigate = useNavigate();
//   const { project_id } = useParams<string>();
//   const [salaryConfigs, setSalaryConfig] = useState<ISalaryConfig[]>();
//   const [loading, setLoading] = useState(true);
//   const [openDropdown, setOpenDropdown] = useState<string | null>(null);
//   const currentpage='salaryconfig';

//   //  Update client info
//   const handleEdit = (salaryConfigId: string) => {
//     navigate(`/add_salaryConfig/${project_id}/${salaryConfigId}`);
//   };

//   // delete client info
//   const handleDelete = async (id: any) => {
//     try {
//       if (window.confirm('Do you want to delete?')) {
//         await ApiService.deleteData(`project-srv/project/salaryconfig/${id}`);
//         getSalaryConfigByProjectId();
//         alert('Data Deleted Successfully');
//       }
      
//     } catch (error) {
//       console.error('Error deleting salary:', error);
//       alert('failed to delete salary');
//     }
//   };

//   //  add new client
//   const handleAddClient = () => {
//     navigate(`/add_salaryConfig/${project_id}`);
//   };


//   // get all clients by project
//   const getSalaryConfigByProjectId = async () => {
//     try {
//       const result: AxiosResponse = await ApiService.getData(
//         `project-srv/project/salaryconfig/project/${project_id}`
//       );

//       setSalaryConfig(result?.data?.data);
//       setLoading(false);
//     } catch (error) {
//       setLoading(false);
//       console.error('Error getting Clients:', error);
//     }
//   };

//   useEffect(() => {
//     getSalaryConfigByProjectId();
//   }, []);

//   const handleNavigation = (path:string) => {
//     navigate(`/${path}/${project_id}`);
//   };



//   const handleGeneratePrintPDF = async (salaryConfigId: string) => {
//     try {
//       const response: AxiosResponse = await ApiService.getData(
//         `project-srv/project/salaryconfig/${salaryConfigId}`
//       );
//       if (!response || !response?.data || response?.data?.data == null) {
//         alert('invalid id passed');
//       }

//       const header_response: AxiosResponse = await ApiService.getData(
//         `project-srv/project/getservice/${project_id}`
//       );
//       if (!header_response || !header_response?.data || !header_response?.data?.data) {
//         alert('Invalid id passed');
//       }
//       const headerData = header_response?.data?.data;
//       const window_height = window.screen.height;
//       const window_width = window.screen.width;
//       const rootUrl = process.env.REACT_APP_BASE_API_URL;
//       const salaryinfo_data : ISalaryConfig = response?.data.data
     
    
//       let salary_info = '';
//       let footer_info = '';

    
//       // Material Info Table
//       salary_info += `<div class='row'>
//           <div class='col-md-12'>
//             <h4 class='ml-3 mt-5 mb-5'>Salary Information</h4>
//             <table class='table border mx-auto' style="width: 90%;">
//               <thead style="background-color:#f1f0f3">
//                 <tr>
//                   <th>Manpower Role</th>
//                   <th>From Date</th>
//                   <th>To Date</th>
//                   <th>Person Count</th>
//                   <th>Salary Per Head</th>
//                   <th>Total Salary</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 ${
//                   salaryinfo_data.manpower_info.length > 0
//                     ? salaryinfo_data.manpower_info
//                         .map(
//                           (manpower_info) => `
//                           <tr>
//                             <td>${manpower_info.role}</td>
//                             <td>${getFormattedDate(manpower_info.from_date.toString())}</td>
//                             <td>${getFormattedDate(manpower_info.to_date.toString())}</td>
//                             <td>${manpower_info.count}</td>
//                             <td>${manpower_info.salary_per_head || ''}</td>
//                             <td>${manpower_info.total_salary}</td>
//                           </tr>`
//                         )
//                         .join('')
//                     : `
//                       <tr>
//                         <td colspan="6">No Materials found</td>
//                       </tr>`
//                 }
//               </tbody>
//               <tfoot style="background-color:#f1f0f3">
//                 <tr>
//                   <td colspan="4"></td>
//                   <td>Total Total Salary:</td>
//                   <td>${salaryinfo_data.total_manpower_salary}</td>
//                 </tr>               
//               </tfoot>
//             </table>
//           </div>
//         </div>`;

      
//       footer_info += `<div class='row mt-5 d-flex justify-content-between' >
//                         <div className='col-md-6'>
                        
//                                 <p class='ml-5'> Date:</p>
                          
//                          </div>
//                          <div className='col-md-6 ' class='mr-5>
//                                   <p class='mr-5'>Signature </p>
                                  
//                             </div>
                                                           
//                           </div>`;

//       const mywindow = window.open(
//         '',
//         'PRINT',
//         'height=' + window_height + ',width=' + window_width + 10
//       );
//       if (!mywindow || mywindow == null) {
//         alert('Something went wrong');
//       } else {
//         mywindow.document.write('<html><head>');
//         mywindow.document.write(
//           '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css">' +
//             '<script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js" > </script>' +
//             '<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" > </script>' +
//             '<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js" > </script>'
//         );
//         mywindow.document.write(
//           '</head><body style="padding-left: 20px; padding-right: 20px; padding-bottom:20px">'
//         );
//         mywindow.document.write(
//           '<div class="row"  id = "print-header" >' +
//             ' <div className="col-md-4 " class="mt-3 mr-5 ml-3" > ' +
//             '<img src = "' +
//             rootUrl +
//             '/' +
//             headerData.logo +
//             '" alt = "company logo" style = "width: 150px !important; height: 100px !important; max-width: none; float: left;"> ' +
//             '</div>' +
//             '<div className="col-md-8" class="ml-5">' +
//             '<h1 style = "margin-bottom: 0px !important; font-size:70px ; color:#090265">' +
//             headerData.service_name +
//             '</h1>' +
//             '<h3 style="color:090265;  margin-left: 100px;">' +
//             headerData.first_name +
//             ' ' +
//             headerData.last_name +
//             '</h3>' +
//             '</div>' +
//             '</div>' +
//             '<div class="row " " id = "print-header"  >' +
//             '<div  class="col-md-12 text-center ms-5" style="bac-color:#e1ddff; color:#090265" >' +
//             '<ul class="d-flex justify-content-around" style="font-size:20px ; margin-top:10px;">' +
//             '<li>' +
//             'PLANINIG' +
//             '</li>' +
//             '<li>' +
//             'ELEVATION' +
//             '</li>' +
//             '<li>' +
//             'DESIGN' +
//             '</li>' +
//             '<li>' +
//             'CONSTRUCTION' +
//             '</li>' +
//             '</ul>' +
//             '</div>' +
//             '<div  class="col-md-12 text-center">' +
//             '<h4 style="color:090265">' +
//             headerData.address +
//             '</h4>' +
//             '</div>' +
//             '<div  class="col-md-12 text-center p-2 d-flex justify-content-between pl-4" style="background-color:#090265; color:white; "   >' +
//             '<h4 class="pl-5">' +
//             'Contact : ' +
//             headerData.contact_number +
//             '</h4>' +
//             '<h4 class="pr-5">' +
//             'Email : ' +
//             headerData.email +
//             '</h4>' +
//             '</div>' +
//             '</div>'
//         );

       
//         mywindow.document.write(salary_info);
//         mywindow.document.write(footer_info);

//         mywindow.document.write('</body></html>');
//         mywindow.document.close(); // necessary for IE >= 10
//         mywindow.focus(); // necessary for IE >= 10
//       }
//       if (mywindow != null) {
//         setTimeout(function () {
//           mywindow.print();
//           mywindow.close();
//         }, 1000);
//       }
//       return true;
//     } catch (error) {
//       const err = error as AxiosError;
//       alert(getErrorMsg(err));
//     }
//   };



//   return (
// <div className="mt-8 flex flex-col max-w-xxl mx-auto shadow-lg rounded-lg bg-white">
// <div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto">
//     <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">
  
//     <button
//       onClick={() => handleNavigation(`projectinfo`)}
//       className="hover:underline whitespace-nowrap px-2"
//     >
//       Project Info
//     </button>
//     <button
//       onClick={() => handleNavigation(`clientinfo`)}  
//       className="hover:underline whitespace-nowrap px-2"
//     >
//       Client Info
//     </button>
//     <button
//       onClick={() => handleNavigation(`materialmappinginfo`)}
//       className="hover:underline whitespace-nowrap  px-2"
//     >
//       Material Purchase
//     </button>
//     <button
//       onClick={() => handleNavigation(`materialUsage_info`)}
//       className="hover:underline whitespace-nowrap px-2"
//     >
//       Material Usage
//     </button>
//     <button
//       onClick={() => handleNavigation(`salaryexpendituretabel`)}
//       className="hover:underline whitespace-nowrap px-2"
//     >
//       Expenditure
//     </button>
//     <button
//       onClick={() => handleNavigation(`salaryconfig`)}
//       className={`hover:underline whitespace-nowrap px-2 ${currentpage==='salaryconfig' ? 'text-base font-bold' : '' }`}
//     >
//       Salary Info
//     </button>
//   </div>
// </div> 
//       {/* <Breadcrumbs
//         items={[
//           { label: 'Project', path: `/projectinfo` },
//           { label: ' SalaryConfig' }
//         ]}
//       /> */}
//           <div className='p-4'>
//       <div className="flex justify-between items-center mb-4 mt-5">
//         <h2 className="font-semibold ">Salary Config </h2>
//         <button
//           onClick={handleAddClient}
//           className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
//           <FaPlus className="mr-2" />
//           Add
//         </button>
//       </div>
//       <div className='overflow-x-auto'>
//       <table className="table-auto w-full border">
//         <thead className="bg-gray-100">
//           <tr>
//             <th className="py-2 px-4 border-b text-center">S.No</th>
//             <th className="py-2 px-4 border-b text-center">Date</th>
//             <th className="py-2 px-4 border-b text-center whitespace-nowrap">Total Salary</th>
//             <th className="py-2 px-4 border-b text-center">ACTIONS</th>
//           </tr>
//         </thead>
//         <tbody className="divide-y">
//           {loading ? (
//             <tr key={'load'}>
//               <td colSpan={3} className="text-center p-4">
//                 <Loader />
//               </td>
//             </tr>
//           ) : (
//             <>
//               {salaryConfigs && salaryConfigs.length > 0 ? (
//                 salaryConfigs.map((salary, index) => (
//                   <tr key={salary.id}>
//                     <td className="  py-2 px-4 border-b text-center">{index + 1}</td>
//                     <td className=" py-2 px-4 border-b text-center">{getFormattedDate(salary.createdAt.toString())}</td>
//                     <td className=" py-2 px-4 border-b text-center">{formatRupeeValue(salary.total_manpower_salary)}</td>
//                     <td className="  py-2 px-4 border-b text-center">
//                       <button
//                         className="text-blue-500 hover:text-blue-700 mr-2"
//                         onClick={() => handleEdit(salary._id)}>
//                         <FaEdit />
//                       </button>
//                       <button
//                         className="text-red-500 hover:text-red-700 mr-2"
//                         onClick={() => handleDelete(salary._id)}>
//                         <FaTrash />
//                       </button>
//                       <button
//                         className="text-blue-500 hover:text-red-700 mr-2"
//                         onClick={() =>
//                         handleGeneratePrintPDF(
//                           salary._id
//                         )
//                         }>
//                         <FaPrint />
//                         </button>
//                     </td>
//                   </tr>
//                 ))
//               ) : (
//                 <tr>
//                   <td colSpan={3} className="text-center p-4">
//                     No Data found
//                   </td>
//                 </tr>
//               )}
//             </>
//           )}
//         </tbody>
//       </table>
//       </div>
//       </div>
//     </div>
//   );
// };

// export default SalaryConfig;




import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash, FaEye, FaEllipsisV,FaPrint } from 'react-icons/fa';
import { AxiosError, AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import Loader from 'src/components/loader';
import { formatDate, formatRupeeValue, getErrorMsg, getFormattedDate } from 'src/components/utilities/utils';
export interface ISalaryConfig {
    _id: string;
  id: string;
  project_id: string;
  total_manpower_salary: number;
  manpower_info: ISaslaryConfigManpowerInfo[];
  
  

  // db defaults
  migration_flag: string;
  createdAt: Date;
  updatedAt: Date;
}
export interface ISaslaryConfigManpowerInfo {
  
    from_date: Date;
    to_date: Date;
    role: string;
    role_id:string;
    count: number;
    salary_per_head: number;
    total_salary: number; 
  }
const SalaryConfig: React.FC = () => {
  const navigate = useNavigate();
  const { project_id } = useParams<string>();
  const [salaryConfigs, setSalaryConfig] = useState<ISalaryConfig[]>();
  const [loading, setLoading] = useState(true);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const currentpage='salaryconfig';

  //  Update client info
  const handleEdit = (salaryConfigId: string) => {
    navigate(`/add_salaryConfig/${project_id}/${salaryConfigId}`);
  };

  // delete client info
  const handleDelete = async (id: any) => {
    try {
      if (window.confirm('Do you want to delete?')) {
        await ApiService.deleteData(`project-srv/project/salaryconfig/${id}`);
        getSalaryConfigByProjectId();
        alert('Data Deleted Successfully');
      }
      
    } catch (error) {
      console.error('Error deleting salary:', error);
      alert('failed to delete salary');
    }
  };

  //  add new client
  const handleAddClient = () => {
    navigate(`/add_salaryConfig/${project_id}`);
  };


  // get all clients by project
  const getSalaryConfigByProjectId = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData(
        `project-srv/project/salaryconfig/project/${project_id}`
      );
      setSalaryConfig(result?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error getting Clients:', error);
    }
  };

  useEffect(() => {
    getSalaryConfigByProjectId();
  }, []);

  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };


  const getSalaryConfigById = async (salaryConfigId: string): Promise<ISalaryConfig> => {
    const response: AxiosResponse = await ApiService.getData(`project-srv/project/salaryconfig/${salaryConfigId}`);
    return response?.data?.data;
  };
  
  const getAllSalaryConfigs = async (): Promise<ISalaryConfig[]> => {
    const response: AxiosResponse = await ApiService.getData(`project-srv/project/salaryconfig/project/${project_id}`);
    return response?.data?.data;
  };
  
  const getHeaderData = async (): Promise<any> => {
    const header_response: AxiosResponse = await ApiService.getData(`project-srv/project/getservice/${project_id}`);
    return header_response?.data?.data;
  };
  

  const handleGeneratePrintPDF = async (salaryConfigId?: string) => {
    try {
      const salaryConfigsToPrint = salaryConfigId
        ? [await getSalaryConfigById(salaryConfigId)]
        : await getAllSalaryConfigs();
  
      if (!salaryConfigsToPrint || salaryConfigsToPrint.length === 0) {
        alert('No salary configs found.');
        return;
      }
  
      const headerData = await getHeaderData();
      if (!headerData) {
        alert('Invalid project ID.');
        return;
      }
  
      const window_height = window.screen.height;
      const window_width = window.screen.width;
      const rootUrl = process.env.REACT_APP_BASE_API_URL;
  
      // let salary_info = '';
      let footer_info = '';
  
      // Generate salary info for each salary config
      
        // salary_info += `<div class='row'>
        //   <div class='col-md-12'>
        //     <h4 class='ml-3 mt-5 mb-5'>Salary Information</h4>
        //     <table class='table border mx-auto' style="width: 90%;">
        //       <thead style="background-color:#f1f0f3">
        //         <tr>
        //           <th>Manpower Role</th>
        //           <th>From Date</th>
        //           <th>To Date</th>
        //           <th>Person Count</th>
        //           <th>Salary Per Head</th>
        //           <th>Total Salary</th>
        //         </tr>
        //       </thead>
        //       <tbody>
        //         ${
        //           salaryConfigsToPrint.forEach((salaryinfo_data) => {
        //           salaryinfo_data.manpower_info.length > 0
        //             ? salaryinfo_data.manpower_info
        //                 .map(
        //                   (manpower_info) => `
        //                   <tr>
        //                     <td>${manpower_info.role}</td>
        //                     <td>${getFormattedDate(manpower_info.from_date.toString())}</td>
        //                     <td>${getFormattedDate(manpower_info.to_date.toString())}</td>
        //                     <td>${manpower_info.count}</td>
        //                     <td>${manpower_info.salary_per_head || ''}</td>
        //                     <td>${manpower_info.total_salary}</td>
        //                   </tr>`
        //                 )
        //                 .join('')
        //             : `
        //               <tr>
        //                 <td colspan="6">No Materials found</td>
        //               </tr>`
        //         })};
        //       </tbody>
        //       <tfoot style="background-color:#f1f0f3">
        //         <tr>
        //           <td colspan="4"></td>
        //           <td>Total Total Salary:</td>
        //           <td>${salaryinfo_data.total_manpower_salary}</td>
        //         </tr>               
        //       </tfoot>
        //     </table>
        //   </div>
        // </div>`;
        const salary_info = `
          <div class='row'>
            <div class='col-md-12'>
              <h4 class='ml-3 mt-5 mb-5'>Salary Information</h4>
              <table class='table border mx-auto' style="width: 90%;">
                <thead style="background-color:#f1f0f3">
                  <tr>
                    <th>Manpower Role</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Person Count</th>
                    <th>Salary Per Head</th>
                    <th>Total Salary</th>
                  </tr>
                </thead>
                <tbody>
                  ${
                    salaryConfigsToPrint.map(salaryinfo_data => 
                      salaryinfo_data.manpower_info.length > 0 
                        ? salaryinfo_data.manpower_info.map(manpower_info => `
                            <tr>
                              <td>${manpower_info.role}</td>
                              <td>${getFormattedDate(manpower_info.from_date.toString())}</td>
                              <td>${getFormattedDate(manpower_info.to_date.toString())}</td>
                              <td>${manpower_info.count}</td>
                              <td>${manpower_info.salary_per_head || ''}</td>
                              <td>${manpower_info.total_salary}</td>
                            </tr>
                          `).join('')
                        : `
                          <tr>
                            <td colspan="6">No Manpower found</td>
                          </tr>`
                    ).join('')
                  }
                </tbody>
                <tfoot style="background-color:#f1f0f3">
                  <tr>
                    <td colspan="4"></td>
                    <td>Total Salary:</td>
                    <td>${
                      salaryConfigsToPrint.reduce((total, salaryinfo_data) => 
                        total + salaryinfo_data.total_manpower_salary, 0
                      )
                    }</td>
                  </tr>               
                </tfoot>
              </table>
            </div>
          </div>
`;

// Insert the generated HTML into the DOM (assuming you have a container to hold this)
// document.getElementById('your-container-id').innerHTML = salary_info;

      
  
      footer_info += `<div class='row mt-5 d-flex justify-content-between' >
                        <div className='col-md-6'>
                          <p class='ml-5'> Date:</p>
                        </div>
                        <div className='col-md-6 ' class='mr-5'>
                          <p class='mr-5'>Signature </p>
                        </div>
                      </div>`;
                      const mywindow = window.open(
                                '',
                                'PRINT',
                                'height=' + window_height + ',width=' + window_width + 10
                              );
                              if (!mywindow || mywindow == null) {
                                alert('Something went wrong');
                              } else {
                                mywindow.document.write('<html><head>');
                                mywindow.document.write(
                                  '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css">' +
                                    '<script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js" > </script>' +
                                    '<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" > </script>' +
                                    '<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js" > </script>'
                                );
                                mywindow.document.write(
                                  '</head><body style="padding-left: 20px; padding-right: 20px; padding-bottom:20px">'
                                );
                                mywindow.document.write(
                                  '<div class="row"  id = "print-header" >' +
                                    ' <div className="col-md-4 " class="mt-3 mr-5 ml-3" > ' +
                                    '<img src = "' +headerData.logo +
                                    '" alt = "company logo" style = "width: 150px !important; height: 100px !important; max-width: none; float: left;"> ' +
                                    '</div>' +
                                    '<div className="col-md-8" class="ml-5">' +
                                    '<h1 style = "margin-bottom: 0px !important; font-size:70px ; color:#090265">' +
                                    headerData.service_name +
                                    '</h1>' +
                                    '<h3 style="color:090265;  margin-left: 100px;">' +
                                    headerData.first_name +
                                    ' ' +
                                    headerData.last_name +
                                    '</h3>' +
                                    '</div>' +
                                    '</div>' +
                                    '<div class="row " " id = "print-header"  >' +
                                    '<div  class="col-md-12 text-center ms-5" style="bac-color:#e1ddff; color:#090265" >' +
                                    '<ul class="d-flex justify-content-around" style="font-size:20px ; margin-top:10px;">' +
                                    '<li>' +
                                    'PLANINIG' +
                                    '</li>' +
                                    '<li>' +
                                    'ELEVATION' +
                                    '</li>' +
                                    '<li>' +
                                    'DESIGN' +
                                    '</li>' +
                                    '<li>' +
                                    'CONSTRUCTION' +
                                    '</li>' +
                                    '</ul>' +
                                    '</div>' +
                                    '<div  class="col-md-12 text-center">' +
                                    '<h4 style="color:090265">' +
                                    headerData.address +
                                    '</h4>' +
                                    '</div>' +
                                    '<div  class="col-md-12 text-center p-2 d-flex justify-content-between pl-4" style="background-color:#090265; color:white; "   >' +
                                    '<h4 class="pl-5">' +
                                    'Contact : ' +
                                    headerData.contact_number +
                                    '</h4>' +
                                    '<h4 class="pr-5">' +
                                    'Email : ' +
                                    headerData.email +
                                    '</h4>' +
                                    '<h4 class="pr-5">' +
                                    'GST : ' +
                                    headerData.gst_in +
                                    '</h4>' +
                                    '</div>' +
                                    
                                    '</div>'
                                );
  
        mywindow.document.write(salary_info);
        mywindow.document.write(footer_info);
  
        mywindow.document.write('</body></html>');
        mywindow.document.close();
        mywindow.focus();
      }
      if (mywindow != null) {
        setTimeout(function () {
          mywindow.print();
          mywindow.close();
        }, 1000);
      }
      return true;
    } catch (error) {
      const err = error as AxiosError;
      alert(getErrorMsg(err));
    }
  };
  


  return (
<div className="mt-8 flex flex-col max-w-xxl mx-auto shadow-lg rounded-lg bg-white">
<div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto">
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className="hover:underline whitespace-nowrap px-2"
    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className="hover:underline whitespace-nowrap  px-2"
    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className={`hover:underline whitespace-nowrap px-2 ${currentpage==='salaryconfig' ? 'text-base font-bold' : '' }`}
    >
      Salary Info
    </button>
  </div>
</div> 
      {/* <Breadcrumbs
        items={[
          { label: 'Project', path: `/projectinfo` },
          { label: ' SalaryConfig' }
        ]}
      /> */}
          <div className='p-4'>
      <div className="flex justify-between items-center mb-4 mt-5 flex-col md:flex-row">
        <h2 className="font-semibold text-lg md:text-xl">Salary Config </h2>
        <div className='flex  gap-3 mt-4 md:mt-0'>
        <button
          onClick={handleAddClient}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded text-s, md:text-sm">
          <FaPlus className="mr-2" />
          Add
        </button>
        <button
         onClick={() => handleGeneratePrintPDF()} 
          className="flex items-center px-4 py-2 bg-green-600 text-white rounded text-sm md:text-sm">
          <FaPrint className="mr-2" />
          Print All
        </button>
        </div>
       
      </div>


      <div className='overflow-x-auto'>
      <table className="table-auto w-full border">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-2 px-4 border-b text-center">S.No</th>
            <th className="py-2 px-4 border-b text-center">Date</th>
            <th className="py-2 px-4 border-b text-center whitespace-nowrap">Total Salary</th>
            <th className="py-2 px-4 border-b text-center">ACTIONS</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {loading ? (
            <tr key={'load'}>
              <td colSpan={3} className="text-center p-4">
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {salaryConfigs && salaryConfigs.length > 0 ? (
                salaryConfigs.map((salary, index) => (
                  <tr key={salary.id}>
                    <td className="  py-2 px-4 border-b text-center">{index + 1}</td>
                    <td className=" py-2 px-4 border-b text-center">{getFormattedDate(salary.createdAt.toString())}</td>
                    <td className=" py-2 px-4 border-b text-center">{formatRupeeValue(salary.total_manpower_salary)}</td>
                    <td className="  py-2 px-4 border-b text-center">
                      <button
                        className="text-blue-500 hover:text-blue-700 mr-1"
                        onClick={() => handleEdit(salary._id)}>
                        <FaEdit />
                      </button>
                      <button
                        className="text-red-500 hover:text-red-700 mr-1"
                        onClick={() => handleDelete(salary._id)}>
                        <FaTrash />
                      </button>
                      <button
                        className="text-blue-500 hover:text-red-700 mr-1"
                        onClick={() =>
                        handleGeneratePrintPDF(
                          salary._id
                        )
                        }>
                        <FaPrint />
                        </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center p-4">
                    No Data found
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
        {!loading && salaryConfigs && salaryConfigs.length > 0 && (
        <tfoot>
          <tr>
            <td></td>
            <td  className="py-2 px-4 text-center font-semibold">Total Salary:</td>
            <td className="py-2 px-4 text-center font-semibold">
              {salaryConfigs.reduce((total, data) => total + data.total_manpower_salary, 0)}
            </td>
            <td></td>
          </tr>
        </tfoot>
      )}
      </table>
      </div>
      </div>
    </div>
  );  
};

export default SalaryConfig;

