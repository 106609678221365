export const SendLoginRequestActionType = 'SEND_LOGIN_REQUEST'
export const SendLoginRequestSuccessActionType = 'SEND_LOGIN_REQUEST_SUCCESS'
export const SendLoginRequestFailureActionType = 'SEND_LOGIN_REQUEST_FAILURE'

export const SetCallbackLinkActionType = 'SET_CALLBACK_LINK_REQUEST'
export const RemoveCallbackLinkActionType = 'REMOVE_CALLBACK_LINK_REQUEST'

export const ResetLoginStateRequestActionType = 'RESET_LOGIN_STATE_REQUEST'
export const SendLogoutRequestActionType = 'SEND_LOGOUT_REQUEST'


export const GetDomainListRequestActionType = 'GET_DOMAIN_LIST_REQUEST'
export const GetDomainListRequestSuccessActionType = 'GET_DOMAIN_LIST_REQUEST_SUCCESS'
export const GetDomainListRequestFailureActionType = 'GET_DOMAIN_LIST_REQUEST_FAILURE'
