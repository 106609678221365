import { useMemo } from 'react';
import * as Constants from './constants';
import { bindActionCreators, Dispatch } from "redux";
import { Action, ActionCreator } from 'src/models/actions';
import { LoginFormModel } from 'src/models/loginModel';

export namespace LoginActions {

    // Log In
    export type sendLoginRequestAction = Action<typeof Constants.SendLoginRequestActionType, LoginFormModel>
    export type sendLoginRequestSuccessAction = Action<typeof Constants.SendLoginRequestSuccessActionType, any>;
    export type sendLoginRequestFailureAction = Action<typeof Constants.SendLoginRequestFailureActionType, any>;

    export function sendLoginRequest(P: LoginFormModel): sendLoginRequestAction {
        return ActionCreator(Constants.SendLoginRequestActionType, P);
    }
    export function sendLoginRequestSuccess(P: any): sendLoginRequestSuccessAction {
        return ActionCreator(Constants.SendLoginRequestSuccessActionType, P);
    }
    export function sendLoginRequestFailure(P: any): sendLoginRequestFailureAction {
        return ActionCreator(Constants.SendLoginRequestFailureActionType, P);
    }

    // Domain List
    export type getDomainListRequestAction = Action<typeof Constants.GetDomainListRequestActionType, null>
    export type getDomainListRequestSuccessAction = Action<typeof Constants.GetDomainListRequestSuccessActionType, any>;
    export type getDomainListRequestFailureAction = Action<typeof Constants.GetDomainListRequestFailureActionType, null>;

    export function getDomainListRequest(): getDomainListRequestAction {
        return ActionCreator(Constants.GetDomainListRequestActionType, null);
    }
    export function getDomainListRequestSuccess(P: any): getDomainListRequestSuccessAction {
        return ActionCreator(Constants.GetDomainListRequestSuccessActionType, P);
    }
    export function getDomainListRequestFailure(): getDomainListRequestFailureAction {
        return ActionCreator(Constants.GetDomainListRequestFailureActionType, null);
    }

    // Setting callback link
    export type setCallbackLinkRequestAction = Action<typeof Constants.SetCallbackLinkActionType, null>

    export function setCallbackLinkRequest(): setCallbackLinkRequestAction {
        return ActionCreator(Constants.SetCallbackLinkActionType, null);
    }

    // remove callback link
    export type removeCallbackLinkRequestAction = Action<typeof Constants.RemoveCallbackLinkActionType, null>

    export function removeCallbackLinkRequest(): removeCallbackLinkRequestAction {
        return ActionCreator(Constants.RemoveCallbackLinkActionType, null);
    }

    // Log Out
    export type sendLogoutRequestAction = Action<typeof Constants.SendLogoutRequestActionType, null>

    export function sendLogoutRequest(): sendLogoutRequestAction {
        return ActionCreator(Constants.SendLogoutRequestActionType, null);
    }

    // Reset state
    export type resetLoginStateRequestAction = Action<typeof Constants.ResetLoginStateRequestActionType, null>

    export function resetLoginStateRequest(): resetLoginStateRequestAction {
        return ActionCreator(Constants.ResetLoginStateRequestActionType, null);
    }



}

export type LoginActions = Omit<typeof LoginActions, 'Type'>;
export const useLoginActions = (dispatch: Dispatch) => {
    const { ...actions } = LoginActions;
    return useMemo(() => bindActionCreators(actions as any, dispatch), [dispatch]) as LoginActions;
};