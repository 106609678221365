import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import { RootState } from 'src/store/reducers/state';
import Loader from 'src/components/loader';
import { TenantInfoModel, TenantRole } from 'src/models/tenants/tenantsModel';
import { useTenantActions } from 'src/store/tenants/actions';
import makeAnimated from 'react-select/animated';
import Select from 'react-select';
import { getSidebarNames } from 'src/components/utilities/utils';

const AddTenantRole = () => {
  //Input Parameter
  const { id } = useParams<string>();
  const { tenant_id } = useParams<string>();
  //Dispatches
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const animatedComponents = makeAnimated();
  //Actions
  const tenantActions = useTenantActions(dispatch);
  const [selectedPermissions, setselectedPermissions] = useState<any[]>([]);
  const [errorsOnPermissions, setErrorsOnPermissions] = useState<boolean>(false);
  const allowedPermissions = getSidebarNames();
  //Getting values from RootState
  const { tenantRolesInfo, isTenantROleUpdated, isTenantRoleSaved, message, loading } = useSelector(
    (state: RootState) => {
      return {
        tenantRolesInfo: state.tenant.tenantRolesInfo,
        isTenantROleUpdated: state.tenant.isTenantROleUpdated,
        isTenantRoleSaved: state.tenant.isTenantRoleSaved,
        message: state.tenant.message,
        loading: state.tenant.loading
      };
    }
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<TenantRole>();

  const handleBackClick = () => {
    navigate(`/tenantroles/${tenant_id}`);
  };

  const onSubmit = (data: TenantRole) => {
    if (id) {
      data.id, (data._id = id);
    }
    if(tenant_id) {
        data.tenant_id = tenant_id;
    } else {
        navigate(`/tenantroles/${tenant_id}`);
    }
    selectedPermissions.length == 0 ? setErrorsOnPermissions(true) : setErrorsOnPermissions(false);
    data.permission = selectedPermissions.map(e => e.value);
    tenantActions.saveTenantRoleRequest(data);
  };
    const handleSelectPermissions = (data: any) => {
      setselectedPermissions(data);
    };

  

  useEffect(() => {
    const tenant_role_info = tenantRolesInfo.find((e) => e._id == id);
    if(id && !tenant_role_info) {
        navigate(`/tenantroles/${tenant_id}`);
    }
    reset(tenant_role_info);
    const permissionsSelected = allowedPermissions.map((val: any) => {
      if(tenant_role_info?.permission.includes(val.value)) {
        return val;
      }
       
    }) 
    if(permissionsSelected)
        setselectedPermissions(permissionsSelected)
  }, [id])

  useEffect(() => {
    if (isTenantROleUpdated || isTenantRoleSaved) {
      alert("Tenant role saved sucessfully");
      reset({} as TenantRole);
      navigate(`/tenantroles/${tenant_id}`);
    }
  }, [isTenantROleUpdated, isTenantRoleSaved]);
  useEffect(()=>{
    if(message){
      alert(message)
    }
  },[message])

  return (
    <>
      <div className="shadow p-5 border">
        <div className="mb-8">
          <h2 className="font-semibold my-1">Tenant Role</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/tenants">Home</Link>
            </li>
            <li>
              <Link to="/tenants">Tenants</Link>
            </li>
            <li>
              <Link to={`/tenantroles/${tenant_id}`}>Tenant Roles</Link>
            </li>
            <li className="active">{id ? <strong>Edit</strong> : <strong>Add</strong>}</li>
          </ol>
        </div>
        <div className="flex flex-col justify-center h-full">
          <div className="xl:flex">
            {loading ? (
              <Loader />
            ) : (
              <>
                <form>
              <div className="grid grid-cols-2 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <label className="block mb-1">Role key <code>*</code></label>
                  <input
                    type="text"
                    className="input-style"
                    id="role_key"
                    {...register('role_key', {
                      required: true,
                      maxLength: 500
                    })}
                  />
                  {errors.role_key?.type === 'required' && <p className="text-red-700">Required Field</p>}
                  {errors.role_key?.type === 'maxLength' && <p className="text-red-700">Max. length (500 letters) reached</p>}
                </div>
                <div className={`form-group`}>
                <label className="block mb-1">Permissions <code>*</code></label>
                    {allowedPermissions && (
                        <Select
                        options={allowedPermissions}
                        components={animatedComponents}
                        value={selectedPermissions}
                        className="w-full mr-2"
                        id="permission"
                        isMulti
                        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                        onChange={handleSelectPermissions}
                        isSearchable={true}
                        closeMenuOnSelect={false}
                        isLoading={allowedPermissions.length ? false : true}
                        />
                    )}
                    {errorsOnPermissions && <p className="text-red-700">Required Field</p>}
                </div>
              </div>
              <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                <div className={`form-group`}>
                  <label className="block mb-1">Description</label>
                  <textarea
                    className="input-style"
                    id="module"
                    {...register('role_description')}
                  ></textarea>
                </div>
              </div>
              <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                <button                  
                  className="px-6 btn-primary w-28 mr-2 ml-0 mt-0"
                  onClick={handleSubmit(onSubmit)} >
                  Save
                </button>
                <button
                  onClick={handleBackClick}
                  type="button"
                  className="mx-0 px-6 btn-primary discard-btn w-28 mt-0"
                  data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AddTenantRole;
