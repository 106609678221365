import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import Loader from 'src/components/loader';

interface OptionType {
  value: string;
  label: string;
}

interface StockReportPayload {
  project_id: string;
  material_id: string;
  from_date?: string;
  to_date?: string;
}

interface StockReportResponse {
  project_id: string;
  project_name: string;
  stock_material_info: StockMaterialInfo[];
}

interface StockMaterialInfo {
  material_id: string;
  material_name: string;
  stock_info: StockInfo[];
  total_stock_in: number;
  total_stock_out: number;
  remaining_stock: number;
}

interface StockInfo {
  stock_date: string;
  stock_in: number;
  stock_out: number;
}

const StockReport: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    control
  } = useForm<StockReportPayload>();

  const [materialOption, setMaterialOptions] = useState<OptionType[]>([]);
  const [projectOption, setProjectOptions] = useState<OptionType[]>([]);
  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState<StockReportResponse[]>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<OptionType | null>(null);
  const [selectedProject, setSelectedProject] = useState<OptionType | null>(null);

  const fetchProject = async () => {
    try {
      const projectResponse: AxiosResponse = await ApiService.getData('project-srv/project');
      const project = projectResponse.data?.data.map((e: any) => ({
        label: e.name,
        value: e._id
      }));
      if (
        projectResponse.data &&
        projectResponse.data?.code === 200 &&
        projectResponse.data?.success
      ) {
        setProjectOptions(project);
      }
    } catch (error) {
      alert(error);
    }
  };

  const fetchMaterials = async () => {
    try {
      const materialResponse: AxiosResponse = await ApiService.getData('services-srv/asset');
      const materials = materialResponse.data?.data.map((e: any) => ({
        label: e.material_name,
        value: e._id
      }));
      if (
        materialResponse.data &&
        materialResponse.data?.code === 200 &&
        materialResponse.data?.success
      ) {
        setMaterialOptions(materials);
        setValue('material_id', '');
      }
    } catch (error) {
      alert(error);
    }
  };

  const handleSelectMaterial = (data: OptionType | null) => {
    setSelectedMaterial(data);
    setValue('material_id', data?.value || '');
  };

  const handleSelectProject = (data: OptionType | null) => {
    setSelectedProject(data);
    setValue('project_id', data?.value || '');
  };

  useEffect(() => {
    fetchProject();
    fetchMaterials();
  }, []);

  const onSubmit = async (data: StockReportPayload) => {
    if (data.project_id === 'all' || selectedMaterial?.value === 'all') {
      data.project_id = '';
      data.material_id = '';
    } else {
      data.project_id = selectedProject?.value || '';
      data.material_id = selectedMaterial?.value || '';
    }

    try {
      const stockResponse: AxiosResponse = await ApiService.postData(
        'project-srv/report/stock',
        data
      );
      if (stockResponse.data && stockResponse.data?.code === 200 && stockResponse.data?.success) {
        setLoading(true);
        const stockValue = stockResponse.data?.data;
        setStock(stockValue);
      }
    } catch (error) {
      console.error('Error fetching stock report:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <h2 className="font-semibold mb-4">Stock Report</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
        
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div className="mb-4">
            <label className="block mb-1">Select Project</label>
            <Controller
              control={control}
              name="project_id"
              rules={{ required: 'Project is required' }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={[{ value: 'all', label: 'All Project' }, ...projectOption]}
                  value={selectedProject}
                  onChange={(value) => {
                    handleSelectProject(value);
                    field.onChange(value?.value || '');
                  }}
                  isSearchable
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              )}
            />
            {errors.project_id && <p className="text-red-700">{errors.project_id.message}</p>}
          </div>

          <div className="mb-4">
            <label className="block mb-1">Select Material</label>
            <Controller
              control={control}
              name="material_id"
              render={({ field }) => (
                <Select
                  {...field}
                  options={[{ value: 'all', label: 'All Material' }, ...materialOption]}
                  value={selectedMaterial}
                  onChange={(value) => {
                    handleSelectMaterial(value);
                    field.onChange(value?.value || '');
                  }}
                  isSearchable
                  className="react-select-container"
                  classNamePrefix="react-select"
                />
              )}
            />
            {errors.material_id && <p className="text-red-700">{errors.material_id.message}</p>}
          </div>
        </div>

        
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div>
            <label className="block mb-2 font-semibold" htmlFor="from_date">
              From Date
            </label>
            <input
              type="date"
              id="from_date"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('from_date')}
            />
            {errors.from_date && <p className="text-red-700 mt-1">{errors.from_date.message}</p>}
          </div>

          <div>
            <label className="block mb-2 font-semibold" htmlFor="to_date">
              To Date
            </label>
            <input
              type="date"
              id="to_date"
              className="border border-gray-300 p-2 rounded w-full"
              {...register('to_date')}
            />
            {errors.to_date && <p className="text-red-700 mt-1">{errors.to_date.message}</p>}
          </div>
        </div>

        <div className="flex justify-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
            Search
          </button>
        </div>
      </form>

      {loading ? (
        <Loader /> 
      ) : stock ? (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <table className="text-sm text-center text-gray-500 dark:text-gray-400 w-full border mb-8 table-auto">
            <thead className="text-xs text-gray-100 uppercase bg-gray-100 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <td className="text-gray-700 text-center font-semibold uppercase bg-gray-100 border">
                  Project details
                </td>
                <td
                  className="text-l text-center font-semibold text-gray-700 uppercase bg-gray-100 dark:bg-gray-00 dark:text-gray-400"
                  colSpan={100}>
                  Material Details
                </td>
              </tr>
            </thead>
            {stock.length > 0 ? (
              <tbody>
                {stock.map((data) => (
                  <React.Fragment key={data.project_id}>
                    <tr className="bg-white border dark:border-gray-700 text-center">
                      <td className="p-2 w-1/12 border">{data.project_name}</td>
                      {data.stock_material_info.map((material) => (
                        <React.Fragment key={material.material_id}>
                          <td className="p-2 w-1/12 border">
                            <table className="w-full border mb-8 mt-0">
                              <thead className="position margin-top-0">
                                <tr className="text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400 bg-gray-200 border">
                                  <th colSpan={3} className="p-2 border">
                                    {material.material_name}
                                  </th>
                                </tr>
                                <tr className="border">
                                  <th className="text-center">Date</th>
                                  <th className="text-green-600 border">Stock-In</th>
                                  <th className="text-center text-red-600 border">Stock-Out</th>
                                </tr>
                              </thead>
                              <tbody className="border">
                                {material.stock_info.map((stock, idx) => (
                                  <tr key={idx} className="border">
                                    <td>{new Date(stock.stock_date).toLocaleDateString()}</td>
                                    <td className="text-right p-2 border">{stock.stock_in}</td>
                                    <td className="text-right p-2 border">{stock.stock_out}</td>
                                  </tr>
                                ))}
                                <tr className="dark:text-gray-400 bg-gray-200 font-semibold">
                                  <td></td>
                                  <td className="text-right text-green-600 p-2">
                                    {material.total_stock_in}
                                  </td>
                                  <td className="text-right text-red-600 p-2">
                                    {material.total_stock_out}
                                  </td>
                                </tr>
                                <tr className="uppercase font-bold">
                                  <td colSpan={2} className="text-left p-2 text-blue-600">
                                    Remaining Stock:
                                  </td>
                                  <td className="text-right text-blue-600">
                                    {material.remaining_stock}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            ) : (
              <tfoot>
                <tr>
                  <td colSpan={100} className="text-center p-4 text-gray-500">
                    No data found
                  </td>
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      ) : (
        ""
      )}


    </div>
  );
};

export default StockReport;
