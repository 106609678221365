// import React, { useEffect, useState } from 'react';
// import { useForm, SubmitHandler, Controller } from 'react-hook-form';
// import { useNavigate, useParams } from 'react-router-dom';
// import { IMaterialUsage } from 'src/models/materialUsageModel';
// import { ApiService } from 'src/components/utilities/api.service';
// import { AxiosResponse } from 'axios';
// import Select from 'react-select';
// interface MaterialType {
//   name: string;
//   _id: string;
//   material_id: string;
//   material_name: string;
//   category: string;
//   unit: string; 
// }

// const AddMaterialUsage: React.FC = () => {
//   const navigate = useNavigate();
//   const { usage_id, project_id } = useParams<{ usage_id?: string; project_id?: string }>();

//   const {
    
//     register,
//     handleSubmit,
//     reset,
//     setValue,
//     control,
//     formState: { errors },
//   } = useForm<IMaterialUsage>();
//   const [materialOption, setMaterialOptions] = useState<MaterialType[]>([]);
//   const [availableStock, setAvailableStock] = useState<number>(0);
//   const [materialId, setMaterialId] = useState<string>();
//   const currentPage='materialUsage_info';


// const materialOptions = materialOption.map((material) => ({
//   value: material._id,
//   label: `${material.category} - ${material.material_name}`,
// }));


//   // Fetch materials for the dropdown
//   const fetchMaterials = async () => {
//     try {
//       // const result: AxiosResponse = await ApiService.getData(`services-srv/purchasematerial/purchase/${project_id}`);
//       const result: AxiosResponse = await ApiService.getData(`services-srv/asset`);
//       const materials: MaterialType[] = result.data?.data.map((item: any) => ({
//         ...item,
//         unit: item.unit, 
//       }));
//       setMaterialOptions(materials);
//     } catch (error) {
//       alert(error);
//     }
//   };

//   useEffect(() => {
//     fetchMaterials();
//   }, [project_id]);



//   const handleMaterialStock = async (material_id: string) => {
//     try {
//       setMaterialId(material_id);
//       const stockInput = { project_id: project_id as string, material_id };

//       const stockResult: AxiosResponse = await ApiService.postData(
//         'project-srv/material-usage/getstock',
//         stockInput
//       );

//       if (stockResult.data.data) {
//         const { stock_in, stock_out } = stockResult.data.data;
//         const available = stock_in - stock_out;

//         setAvailableStock(usage_id ? Number(availableStock) + available : available);
//       } else {
//         setAvailableStock(0);
//         alert('Please purchase this material');
//       }

//       // Fetch and set the unit
//       const selectedMaterial = materialOption.find((material) => material.material_id === material_id);
//       if (selectedMaterial && selectedMaterial.unit) {
//         setValue('unit', selectedMaterial.unit);
//       } 
//     } catch (error) {
//       setAvailableStock(0);
//       alert("Please purchase this material ");
//       console.error(error);
//     }
//   };

//   // Fetch the data by id and update
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await ApiService.getData(`project-srv/material-usage/getsingle/${usage_id}`);
//         const materialUsage = response.data.data;

//         setValue('material_id', materialUsage.material_id);
//         setValue('unit', materialUsage.unit);
//         setAvailableStock(materialUsage.no_of_quantity);
//         reset(materialUsage);

//         // Set date fields separately if they exist in the data
//         if (materialUsage.from_date) {
//           setValue('from_date', materialUsage.from_date.split('T')[0]);
//         }
//         if (materialUsage.to_date) {
//           setValue('to_date', materialUsage.to_date.split('T')[0]);
//         }

//         // Fetch stock and unit
//         await handleMaterialStock(materialUsage.material_id);
//       } catch (error) {
//         alert(error);
//       }
//     };

//     if (usage_id) {
//       fetchData();
//     }
//   }, [usage_id, reset, setValue,]);

//   // Submit form data
//   const onSubmit: SubmitHandler<IMaterialUsage> = async (data) => {
//     try {
//       if (availableStock == null || availableStock === 0 || availableStock < data.no_of_quantity) {
//         alert('Your stock value is less than required or empty');
//         return;
//       }
//       data.project_id = project_id as string;
//       const selectedMaterial = materialOption.find((material) => material.material_id === data.material_id);

//       if (selectedMaterial) {
//         data.material_name = selectedMaterial.material_name;
//         data.unit = selectedMaterial.unit; // Ensure unit is set
//       }

//       if (usage_id) {
//         data.id = usage_id;
//       }

//       const response: AxiosResponse = await ApiService.postData('project-srv/material-usage', data);

//       if (response.data && response.data.code === 200 && response.data.success) {
//         alert('Material Usage Saved successfully');
//       }
//       navigate(`/materialUsage_info/${project_id}`);
//       reset();
//     } catch (error) {
//       alert('Failed to submit form');
//       console.error(error);
//     }
//   };

//   const handleNavigation = (path: string) => {
//     navigate(`/${path}/${project_id}`);
//   };

//   return (
//     <div className="mt-8 max-w-xxl mx-auto shadow-lg rounded-lg bg-white">
//       <div className="flex w-full bg-blue-500 text-white justify-center overflow-x-auto mb-10">
//         <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">
//           <button
//             onClick={() => handleNavigation(`projectinfo`)}
//             className="hover:underline whitespace-nowrap px-2"
//           >
//             Project Info
//           </button>
//           <button
//             onClick={() => handleNavigation(`clientinfo`)}
//             className="hover:underline whitespace-nowrap px-2"
//           >
//             Client Info
//           </button>
//           <button
//             onClick={() => handleNavigation(`materialmappinginfo`)}
//             className="hover:underline whitespace-nowrap px-2"
//           >
//             Material Purchase
//           </button>
//           <button
//             onClick={() => handleNavigation(`materialUsage_info`)}
//             // className="hover:underline whitespace-nowrap px-2"
//             className={`hover:underline whitespace-nowrap  px-2 ${currentPage==='materialUsage_info' ? 'font-bold text-base' : ''}`}

//           >
//             Material Usage
//           </button>
//           <button
//             onClick={() => handleNavigation(`salaryexpendituretabel`)}
//             className="hover:underline whitespace-nowrap px-2"
//           >
//             Expenditure
//           </button>
//           <button
//             onClick={() => handleNavigation(`salaryconfig`)}
//             className="hover:underline whitespace-nowrap px-2"
//           >
//             Salary Info
//           </button>
//         </div>
//       </div>
//       <div className="container">
//         <p className="font-extrabold text-xl text-center mb-4 h4">Add Material Usage</p>
//       </div>
//       <div className="p-5">
//         <form onSubmit={handleSubmit(onSubmit)}>
//           <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-2 mb-4">
        
//            {/* <div className="mb-4">
//               <label className="block mb-1">
//                 Select Material <span className="text-red-700 ml-1">*</span>
//               </label>
//               <select
//                 className="input-style"
//                 {...register('material_id', { required: 'Material is required' })}
//                 onChange={(e) => handleMaterialStock(e.target.value)}
//               >
//                 <option value="">Select Material</option>
//                 {materialOption.map((material) => (
//                   <option key={material.material_id} value={material.material_id}>
//                     {material.material_name}
//                   </option>
//                 ))}
//               </select>
//               {errors.material_id && <p className="text-red-700">{errors.material_id.message}</p>}
//               {availableStock > 0 && (
//                 <span className="text-l m-6 text-green-600">Available Stock: {availableStock}</span>
//               )}
//             </div>   */}


//           <div className="mb-4">
//           <label className="block mb-1">
//                 Select Material <span className="text-red-700 ml-1">*</span>
//           </label>
//             <Select              
//               options={materialOptions}
//               // onChange={(selectedOption) => handleMaterialStock(selectedOption.value)}
//               onChange={(selectedOption) => {
//                 if (selectedOption) {
//                   handleMaterialStock(selectedOption.value);
//                 }
//               }}              
//               placeholder="Select Material"
//               ref={() => {
//                 register('material_id', { required: 'Material is required' });
//               }}             
//             />
//              {availableStock > 0 && (
//               <span className="text-l m-6 text-green-600">Available Stock: {availableStock}</span>
//             )}
//             </div>


//             <div className="mb-4">
//               <label className="block mb-1">
//                 From Date <span className="text-red-700 ml-1">*</span>
//               </label>
//               <input
//                 type="date"
//                 {...register('from_date', { required: 'From date is required' })}
//                 className="input-style"
//               />
//               {errors.from_date && <p className="text-red-700">{errors.from_date.message}</p>}
//             </div>

//             <div className="mb-4">
//               <label className="block mb-1">
//                 To Date <span className="text-red-700 ml-1">*</span>
//               </label>
//               <input
//                 type="date"
//                 {...register('to_date', { required: 'To date is required' })}
//                 className="input-style"
//               />
//               {errors.to_date && <p className="text-red-700">{errors.to_date.message}</p>}
//             </div>
//           </div>

//           <div className="grid grid-cols-2 gap-4 mb-4">
//             <div className="mb-4">
//               <label className="block mb-1">
//                 Quantity <span className="text-red-700 ml-1">*</span>
//               </label>
//               <input
//                 type="number"
//                 {...register('no_of_quantity', { required: 'Quantity is required' })}
//                 className="input-style"
//                 min={1}
//                 max={availableStock}
//               />
//               {errors.no_of_quantity && <p className="text-red-700">{errors.no_of_quantity.message}</p>}
//             </div>
//             <div className="mb-4">
//               <label className="block mb-1">
//                 Units <span className="text-red-700 ml-1">*</span>
//               </label>
//               <input
//                 type="text" // Changed to "text"
//                 {...register('unit', { required: 'Unit is required' })}
//                 className="input-style"
//                 readOnly // Make it read-only if necessary
//               />
//               {errors.unit && <p className="text-red-700">{errors.unit.message}</p>}
//             </div>
//           </div>

//           <div className="flex justify-center">
//             <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
//               {usage_id ? 'Update' : 'Save'}
//             </button>
//             <button
//               type="button"
//               className="px-6 btn-primary discard-btn w-28 mr-2 ml-0 mt-3"
//               onClick={() => navigate(`/materialUsage_info/${project_id}`)}
//             >
//               Cancel
//             </button>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default AddMaterialUsage;

import React, { useEffect, useState } from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { IMaterialUsage } from 'src/models/materialUsageModel';
import { ApiService } from 'src/components/utilities/api.service';
import { AxiosResponse } from 'axios';
import Select from 'react-select';
interface MaterialType {
  
  name: string;
  _id: string;
  material_id:string;
  material_name: string;
  category: string;
  unit : string;
}

const AddMaterialUsage: React.FC = () => {
  const navigate = useNavigate();
  const { usage_id } = useParams<string>();
  const { project_id } = useParams<string>();

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    formState: { errors }
  } = useForm<IMaterialUsage>();
  const [materialOption, setMaterialOptions] = useState<MaterialType[]>([]);
  const [availableStock, setAvailableStock] = useState<number>(0);
  const currentPage='materialUsage_info';

  const materialOptions = materialOption.map((material) => ({
    value: material._id,
    label: material.category + '-' + material.material_name,
  }));

  // Fetch materials for the dropdown
  const fetchMaterials = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData('services-srv/asset');
      const material = result.data?.data;
      setMaterialOptions(material);
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    fetchMaterials();
  }, []);

  // Fetch the data by id and update
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getData(
          `project-srv/material-usage/getsingle/${usage_id}`
        );
        const materialUsage = response.data.data;
        setValue('material_id', materialUsage.material_name);
        // Reset the form with the fetched data
        handleMaterialStock(materialUsage.material_id);
        setAvailableStock(materialUsage.no_of_quantity);
        reset(materialUsage);

        // Set date fields separately if they exist in the data
        if (materialUsage.from_date) {
          setValue('from_date', materialUsage.from_date.split('T')[0]);
        }
        if (materialUsage.to_date) {
          setValue('to_date', materialUsage.to_date.split('T')[0]);
        }
      } catch (error) {
        alert(error);
      }
    };

    if (usage_id) {
      fetchData();
    }
  }, [usage_id, reset, setValue]);

  const handleMaterialStock = async (material_id: string) => {
    try {
      const stockInput = { project_id: project_id as string, material_id };

      const result: AxiosResponse = await ApiService.postData(
        'project-srv/material-usage/getstock',
        stockInput
      );
      if (result.data.data) {
        const { stock_in, stock_out } = result.data.data;
        const available = stock_in - stock_out;
        if (usage_id) {
          setAvailableStock((prevState) => Number(prevState) + available);
        } else {
          setAvailableStock(available);
        }
      } else {
        alert('Please mapping this material');
        return false;
      }
      const selectedMaterial = materialOption.find((material) => material._id === material_id);
      if (selectedMaterial && selectedMaterial.unit) {
        setValue('unit', selectedMaterial.unit);
      } 


    } catch (error) {
      setAvailableStock(0);
      alert("Please purchase this material ");
    }
  };

  // Submit form data
  const onSubmit: SubmitHandler<IMaterialUsage> = async (data) => {
    try {
      if (availableStock == null || availableStock == 0 || availableStock < data.no_of_quantity) {
        alert('Your stock value less than or empty');
        return false;
      }
      data.project_id = project_id as string;
      const selectedMaterial = materialOption.find((material) => material._id === data.material_id);

      if (selectedMaterial) {
        data.material_name = selectedMaterial.category+"-"+selectedMaterial.material_name;
      }

      if (usage_id) {
        data.id = usage_id;
      }

      const response: AxiosResponse = await ApiService.postData('project-srv/material-usage', data);

      if (response.data && response.data?.code === 200 && response.data?.success) {
        alert('Material Usage Saved successfully');
      }
      navigate(`/materialUsage_info/${project_id}`);
      reset();
    } catch (error) {
      alert('Failed to submit form');
    }
  };
  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };

  return (
    <div className="mt-8 max-w-xxl mx-auto shadow-lg rounded-lg bg-white">
      <div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto mb-10">   
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">  
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className="hover:underline whitespace-nowrap px-2"
    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className="hover:underline whitespace-nowrap  px-2"
    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Salary Info
    </button>
  </div>
</div>
      <div className='container'>
        <p className='font-extrabold text-xl text-center mb-4 h4'>Add Material Usage</p>
      </div>
      <div className='p-5'>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 gap-2 mb-4">
          <div className="mb-4">
            <label className="block mb-1">
              Select Material <span className="text-red-700 ml-1">*</span>
            </label>
            {/* <select
              className="input-style"
              {...register('material_id', { required: 'material is required' })}
              onChange={(e) => handleMaterialStock(e.target.value)}>
              <option value="">Select Material</option>
              {materialOption.map((material) => (
                <option key={material._id} value={material._id}>
                  {material.category + '-' + material.material_name}
                </option>
              ))}
            </select> */}
          <Controller
          name="material_id"
          control={control}
          render={({ field, fieldState }) => (
            <>
            <Select
              {...field}  // This will automatically handle value and onChange
              options={materialOptions}
              placeholder="Select Material"
              onChange={(selectedOption) => {
                field.onChange(selectedOption?.value);  // Update the form state with selected value
                if (selectedOption) {
                  handleMaterialStock(selectedOption.value);  // Call your function if needed
                }
              }}
                    value={materialOptions.find(option => option.value === field.value)}  // Ensure the selected value shows up
                  />
                  {fieldState.error && (
                    <p className="text-red-700">{fieldState.error.message}</p>
                  )}
                      </>
                    )}
                  />

                {errors.material_id && <p className="text-red-700">{errors.material_id.message}</p>}
                {availableStock===0 || availableStock >= 0 ? (
                  <span className="text-l m-6  text-green-600">Available Stock : {availableStock}</span>
                ) : (
                  ''
                )}
          </div>
{/* 
    <div className="mb-4">
         <label className="block mb-1">
               Select Material <span className="text-red-700 ml-1">*</span>
         </label>
            <Select              
              options={materialOptions}
             // onChange={(selectedOption) => handleMaterialStock(selectedOption.value)}
              onChange={(selectedOption) => {
                if (selectedOption) {
                  handleMaterialStock(selectedOption.value);
                }
              }}              
              placeholder="Select Material"
              ref={() => {
                register('material_id', { required: 'Material is required' });
              }}             
            />
             {availableStock >= 0 && (
              <span className="text-l m-6 text-green-600">Available Stock: {availableStock}</span>
            )}
            </div> */}

          <div className="mb-4">
            <label className="block mb-1">
              From Date<span className="text-red-700 ml-1">*</span>
            </label>
            <input type="date" {...register('from_date',{ required: 'from_date is required' })} className="input-style" />
            {errors.from_date && <p className="text-red-700">{errors.from_date.message}</p>}
          </div>

          <div className="mb-4">
            <label className="block mb-1">
              To Date<span className="text-red-700 ml-1">*</span>
            </label>
            <input type="date" {...register('to_date',{ required: 'to_date is required' })} className="input-style" />
            {errors.to_date && <p className="text-red-700">{errors.to_date.message}</p>}
          </div>
        </div>

        <div className="grid grid-cols-2 gap-4 mb-4">
          <div className="mb-4">
            <label className="block mb-1">
              Quantity<span className="text-red-700 ml-1">*</span>
            </label>
            <input
              type="number"
              {...register('no_of_quantity', { required: 'quantity is required' })}
              className="input-style"
              // max={availableStock}
            />
            {errors.no_of_quantity && (
              <p className="text-red-700">{errors.no_of_quantity.message}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-1">
              Units <span className="text-red-700 ml-1">*</span>
            </label>
            <input
              type="string"
              {...register('unit', { required: 'unit is required' })}
              className="input-style"
            />
            {errors.unit && <p className="text-red-700">{errors.unit.message}</p>}
          </div>
        </div>

        <div className="flex justify-center ">
          <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
            {usage_id ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            className="px-6 btn-primary discard-btn w-28 mr-2 ml-0 mt-3"
            onClick={() => navigate(`/materialUsage_info/${project_id}`)}>
            Cancel
          </button>
        </div>
      </form>
      </div>
    </div>
  );
};

export default AddMaterialUsage;

