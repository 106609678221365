import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import MainLayoutRoutes from './components/common/layout/MainLayoutRoutes';
import './asset/style.scss';
import './App.css';
import Login from './components/login';
import { useDispatch, useSelector } from 'react-redux';
import { getItem, removeItem } from './components/utilities/utils';
import { RootState } from './store/reducers/state';
import { TenantAdminModel } from './models/usersModel';
import { ApiService } from './components/utilities/api.service';
const App = () => {
  const navigate = useNavigate();
  const { isCallbackLink } = useSelector((state: RootState) => {
    return {
      isCallbackLink: state.login.isCallbackLink
    };
  });
  useEffect(() => {
    if (isCallbackLink) {
      removeItem('token');
      removeItem('role');
      navigate('/login', { state: { from: location.pathname } });
    }
  }, [isCallbackLink]);
  
  const [userDetails, setUserDetails] = useState<TenantAdminModel | null>(null);
  // const [sideBarData, setSidebarData] = useState<MenuModel[]>();
  const [fileUrl, setFileUrl] = useState('');
  useEffect(() => {
    setUserDetails(JSON.parse(getItem('userData')));
    // setSidebarData(UserData);
  }, []);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const rootUrl = process.env.REACT_APP_BASE_API_URL;
        if (userDetails) {
          // Set document title
          document.title = userDetails.first_name ? `${userDetails.first_name}` : 'BizMaster';

          // Set favicon dynamically
          const favicon = document.getElementById('favicon') as HTMLLinkElement;
          if (favicon) {
            const faviconUrl = userDetails.userLogo ? userDetails.userLogo : './assets/img/biz_master_logo.png';
            favicon.href = faviconUrl;
          }
        }
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };

    if (userDetails) {
      fetchData();
    }
  }, [userDetails]);
  
  return (
    <div>
      <Routes>
        <Route path="/login?sa=:id" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<MainLayoutRoutes />} />
      </Routes>
    </div>
  );

}
  

export default App;
