import React, { useEffect, useState, memo } from 'react';
import UserData from '../../../components/utilities/sidebarData.json';
import AdminData from '../../../components/utilities/adminSidebarData.json';
import Menu from './components/Menu';
import { MenuModel } from 'src/models/sidebarModel';
import { useNavigate } from 'react-router-dom';
import { TenantAdminModel } from 'src/models/usersModel';
import { getItem, getUploadedFile } from 'src/components/utilities/utils';
import { ApiService } from 'src/components/utilities/api.service';
interface IProps {
  isPinned: boolean;
  handleSidebarPinned: React.MouseEventHandler<HTMLElement>;
  handleSidebarHover: () => void;
  isSidebarHovered: boolean;
  handleMobileMenu: () => void;
}

const Sidebar: React.FC<IProps> = ({
  isPinned,
  handleSidebarPinned,
  handleSidebarHover,
  isSidebarHovered,
  handleMobileMenu
}) => {
  const [menuId, setMenuId] = useState<number | string>();
  const [subMenuId, setSubMenuId] = useState<number | string>();
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [userDetails, setUserDetails] = useState<TenantAdminModel | null>(null);
  const [sideBarData, setSidebarData] = useState<MenuModel[]>();
  const [fileUrl, setFileUrl] = useState('');
  useEffect(() => {
    setUserDetails(JSON.parse(getItem('userData')));
    setSidebarData(UserData);
  }, []);
  const navigate = useNavigate();
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

 

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    
    if(userDetails?.roles[0] != 'Super_Admin' && userDetails?.permissions && userDetails?.permissions.length && sideBarData) {
      
      const results = sideBarData.reduce((acc: MenuModel[], menu: MenuModel) => {
        // Check if the current menu name is in input2
        if (userDetails?.permissions.includes(menu.route)) {
          acc.push(menu);
        } else {
          // Check subMenu items
          const filteredSubMenu = menu.subMenu.filter((subItem: any) =>
            userDetails?.permissions.includes(subItem.route)
          );
          if (filteredSubMenu.length > 0) {
            // Clone the menu object and replace the subMenu with the filtered one
            const menuCopy = { ...menu, subMenu: filteredSubMenu };
            acc.push(menuCopy);
          }
        }
        return acc;
      }, []);
      setSidebarData(results);
    }
  }, [userDetails])

  // fetch logo 
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getData(`tenant-srv/tenant/service/tenantId/${userDetails?.tenant_id}`);
        setFileUrl(response.data.data.logo);        
       
      } catch (error) {
        console.error('Error fetching service:', error);
      }
    };

    if (userDetails?.tenant_id) {
      fetchData();
    }
  }, [userDetails?.tenant_id]);


  const isMobile = width <= 768;
  const handleMenuClick = (id: number, subMenuLength: number, menuRoute: string) => {
    if (menuRoute !== '') {
      navigate(menuRoute);
    }
    if (subMenuLength === 0 && isMobile) {
      handleMobileMenu();
    }
    if (menuId === id) {
      setMenuId('');
    } else {
      setMenuId(id);
      setSubMenuId('');
    }
  };

  const handleSubMenuClick = (id: number) => {
    if (isMobile) {
      handleMobileMenu();
    }
    setSubMenuId(id);
  };

  return (
    <div>
      <aside
        id="sidebar"
        className={`sidebar`}
        onMouseEnter={
          !isPinned
            ? handleSidebarHover
            : () => {
                return null;
              }
        }
        onMouseLeave={
          !isPinned
            ? handleSidebarHover
            : () => {
                return null;
              }
        }>
        <div className="flex flex-col space-y-6">
          <nav>
            <div className="flex justify-between items-center mb-3">
              <img
               src={fileUrl}
               alt="Logo"
               className="ml-4 half-icon-logo smalltext-logo w-10 h-10 "
               
              />
              {/* <img
                src="../../assets/img/biz_master_logo.png"
                className="w-9 mx-auto half-icon-logo smalltext-logo"
              /> */}
              {/* <img src="../../assets/img/bizzmaster.png" className="text-logo w-50 h-12" /> */}
              <div className='flex'>
              <img
                    src={fileUrl?fileUrl:"../../assets/img/biz_master_logo.png"}
                    alt="Logo"
                    className="text-logo w-50 h-12"
                    
               />
               <b className='font-bold text-sm	 mt-2 ml-4'> {userDetails?.first_name}</b>
               </div>

              {/* <h1 className="font-bold text-black ">BIZ MASTER</h1> */}
              <div className="flex items-center">
                <img
                  src="../../assets/img/unpin-icon.svg"
                  onClick={handleSidebarPinned}
                  className="w-5 mb-1  cursor-pointer mr-4 unpin-icon"
                />

                <img
                  src="../../assets/img/pin-icon.svg"
                  onClick={handleSidebarPinned}
                  className="w-5 mb-1  cursor-pointer mr-4 hidden pin-icon"
                />
              </div>
            </div>

            <ul>
              {userDetails?.roles[0] == 'Super_Admin' &&
                AdminData &&
                AdminData.map((menus: MenuModel, index: number) => {
                  return (
                    <Menu
                      key={index}
                      index={index}
                      handleMenuClick={handleMenuClick}
                      menuId={menuId}
                      menuData={menus}
                      handleSubMenuClick={handleSubMenuClick}
                      subMenuId={subMenuId}
                      isSidebarHovered={isSidebarHovered}
                      isMobile={isMobile}
                    />
                  );
                })}
              {userDetails?.roles[0] != 'Super_Admin' &&
                sideBarData && sideBarData.length &&
                sideBarData.map((menus: MenuModel, index: number) => {
                  return (
                    <Menu
                      key={index}
                      index={index}
                      handleMenuClick={handleMenuClick}
                      menuId={menuId}
                      menuData={menus}
                      handleSubMenuClick={handleSubMenuClick}
                      subMenuId={subMenuId}
                      isSidebarHovered={isSidebarHovered}
                      isMobile={isMobile}
                    />
                  );
                })}
            </ul>
          </nav>
        </div>
      </aside>
    </div>
  );
};

export default memo(Sidebar);
