import { memo } from 'react';
import SubMenu from './SubMenu';
import { MenuPropsModel } from 'src/models/sidebarModel';

const Menu = ({
  index,
  handleMenuClick,
  menuId,
  menuData,
  handleSubMenuClick,
  subMenuId,
  isSidebarHovered,
  isMobile
}: MenuPropsModel) => {
  return (
    <li key={index}>
      {isSidebarHovered || isMobile ? (
        <span
          style={{ color: '#a6a4b0' }}
          className={`${
            menuData.heading === '' ? '' : 'ml-4 leading-10 font-semibold text-xs menu-h'
          }`}>
          {menuData.heading}
        </span>
      ) : !isMobile ? (
        <div>
          <i className={`${menuData.notHovered}`} style={{ color: '#a6a4b0' }}></i>
        </div>
      ) : (
        ''
      )}

      <span
        className={`side-menu cursor-pointer ${
          menuData.subMenu.length !== 0
            ? `${menuId === menuData.id ? 'active-menu' : ''}`
            : `${menuId === menuData.id ? 'active' : ''}`
        } `}
        onClick={() => handleMenuClick(menuData.id, menuData.subMenu.length, menuData.route)}>
        <i className={`${menuData.className}`}></i>

        <span className="text-sm ml-2">{menuData.name}</span>
        {menuData.subMenu.length !== 0 && <i className="fas fa-chevron-right mr-2"></i>}
      </span>

      <SubMenu
        menuId={menuId}
        menuData={menuData}
        handleSubMenuClick={handleSubMenuClick}
        subMenuId={subMenuId}
        isSidebarHovered={isSidebarHovered}
      />
    </li>
  );
};

export default memo(Menu);
