import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { ISalaryExpenditureModel } from 'src/models/salaryExpenditureModel';
import Breadcrumbs from '../BreadCrumbsComponent';
import { ExpentitureType } from './AddSalaryExpenditure';

const SalaryExpenditureTable: React.FC = () => {
  const navigate = useNavigate();
  const { project_id } = useParams<string>();
  const { salary_id } = useParams<string>();
  const [loading, setLoading] = useState(true);
  const [expenditure, setExpenditure] = useState<ISalaryExpenditureModel[]>([]);
  const currentPage='salaryexpendituretabel';

  const getExpenditure = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData(
        `project-srv/salary-expenditure/salary_info/${project_id}`
      );
      setExpenditure(result?.data?.data);
      setLoading(false);
    } catch (error) {
      alert(error);
    }
  };
  useEffect(() => {
    getExpenditure();
  }, []);

  const handleEdit = (salary_id: any) => {
    navigate(`/addsalaryexpenditure/${project_id}/${salary_id}`);
  };

  const handleDelete = async (id: any) => {
    if (window.confirm('Do you want to delete?')) {
      try {
        const response: AxiosResponse = await ApiService.deleteData(
          `project-srv/salary-expenditure/${id}`
        );
        if (response.data && response.data?.data) {
          alert('Data Deleted Successfully');

          getExpenditure();
        }
      } catch (error) {
        alert(error);
      }
    } else {
      return false;
    }
  };

  const handleAddExpenditure = () => {
    navigate(`/addsalaryexpenditure/${project_id}`);
  };
  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };

  return (
<div className="mt-8 flex flex-col max-w-xxl mx-auto shadow-lg rounded-lg bg-white">
<div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto">
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className="hover:underline whitespace-nowrap px-2"
    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className="hover:underline whitespace-nowrap  px-2"
    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className={`hover:underline whitespace-nowrap px-2 ${currentPage==='salaryexpendituretabel' ? 'text-base font-bold' : ''}`}
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Salary Info
    </button>
  </div>
</div> 
{/* <Breadcrumbs
        items={[
          { label: 'Project', path: `/projectinfo` },
          { label: ' Expenditure', path: `/salaryexpendituretabel/${project_id}` }
        ]}
      /> */}
  {/* <div className='p-4'>
      <div className="flex justify-between items-center mb-4 mt-5">
        <h2 className="font-semibold">Expenditure</h2>
        <button
          onClick={handleAddExpenditure}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
          <FaPlus className="mr-2" />
           <span className='hidden sm:inline'>Add New Expenditure</span> 
        </button>
      </div>
      <div className="overflow-x-auto">

        <table className="min-w-full bg-white">
          <thead className="bg-gray-100">
            <tr>
              <th className="py-2 px-4 border-b text-center">S.No</th>
              <th className="py-2 px-4 border-b text-center">Expenditure</th>
              <th className="py-2 px-4 border-b text-center whitespace-nowrap">From Date</th>
              <th className="py-2 px-4 border-b text-center whitespace-nowrap">To Date</th>
              <th className="py-2 px-4 border-b text-center">Amount</th>
              <th className="py-2 px-4 border-b text-center">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y">
            {loading ? (
              <tr key={'load'}>
                <td colSpan={3} className="text-center p-4">
                  <Loader />
                </td>
              </tr>
            ) : (
              <>
                {expenditure && expenditure.length > 0 ? (
                  expenditure.map((data, index) => (
                    <><tr key={data._id}>
                      <td className="py-2 px-4 border-b text-center">{index + 1}</td>
                      <td className="py-2 px-4 border-b text-center">
                        {data.expenditure_type === ExpentitureType.SALARY_EXPENDITURE
                          ? `Salary - ${data.manpower_name}`
                          : `Other - ${data.expenditure_name}`}
                      </td>

                      <td className="py-2 px-4 border-b text-center">
                        {new Date(data.from_date).toLocaleDateString()}
                      </td>
                      <td className="py-2 px-4 border-b text-center">
                        {new Date(data.to_date).toLocaleDateString()}
                      </td>
                      <td className="py-2 px-4 border-b text-center">{data.salary}</td>
                      <td className="p-2  border-b flex items-center justify-center gap-2 relative">
                        <button className="text-blue-500" onClick={() => handleEdit(data._id)}>
                          <FaEdit className="inline mr-1" />
                        </button>
                        <button className="text-red-500" onClick={() => handleDelete(data._id)}>
                          <FaTrash className="inline mr-1" />
                        </button>
                      </td>
                    </tr>
                    <tr>
                        <td colSpan={5}></td>
                        <td>{}</td>
                    </tr>
                    </>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6} className="text-center p-4">
                      No Expenditure found
                    </td>
                  </tr>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
      </div> */}
      <div className='p-4'>
  <div className="flex justify-between items-center mb-4 mt-5">
    <h2 className="font-semibold">Expenditure</h2>
    <button
      onClick={handleAddExpenditure}
      className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
      <FaPlus className="mr-2" />
      <span className='hidden sm:inline'>Add New Expenditure</span> 
    </button>
  </div>
  <div className="overflow-x-auto">
    <table className="min-w-full bg-white">
      <thead className="bg-gray-100">
        <tr>
          <th className="py-2 px-4 border-b text-center">S.No</th>
          <th className="py-2 px-4 border-b text-center">Expenditure</th>
          <th className="py-2 px-4 border-b text-center whitespace-nowrap">From Date</th>
          <th className="py-2 px-4 border-b text-center whitespace-nowrap">To Date</th>
          <th className="py-2 px-4 border-b text-center">Amount</th>
          <th className="py-2 px-4 border-b text-center">Actions</th>
        </tr>
      </thead>
      <tbody className="divide-y">
        {loading ? (
          <tr key={'load'}>
            <td colSpan={6} className="text-center p-4">
              <Loader />
            </td>
          </tr>
        ) : (
          <>
            {expenditure && expenditure.length > 0 ? (
              expenditure.map((data, index) => (
                <tr key={data._id}>
                  <td className="py-2 px-4 border-b text-center">{index + 1}</td>
                  <td className="py-2 px-4 border-b text-center">
                    {data.expenditure_type === ExpentitureType.SALARY_EXPENDITURE
                      ? `Salary - ${data.manpower_name}`
                      : `Other - ${data.expenditure_name}`}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {new Date(data.from_date).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 border-b text-center">
                    {new Date(data.to_date).toLocaleDateString()}
                  </td>
                  <td className="py-2 px-4 border-b text-center">{data.salary}</td>
                  <td className="p-2 border-b flex items-center justify-center gap-2">
                    <button className="text-blue-500" onClick={() => handleEdit(data._id)}>
                      <FaEdit className="inline mr-1" />
                    </button>
                    <button className="text-red-500" onClick={() => handleDelete(data._id)}>
                      <FaTrash className="inline mr-1" />
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center p-4">
                  No Expenditure found
                </td>
              </tr>
            )}
          </>
        )}
      </tbody>
      {!loading && expenditure && expenditure.length > 0 && (
        <tfoot>
          <tr>
            <td colSpan={4} className="py-2 px-4 text-right font-semibold">Total Salary:</td>
            <td className="py-2 px-4 text-center font-semibold">
              {expenditure.reduce((total, data) => total + data.salary, 0)}
            </td>
            <td></td>
          </tr>
        </tfoot>
      )}
    </table>
  </div>
</div>

    </div>
  );
};

export default SalaryExpenditureTable;
