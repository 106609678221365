import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { ApiService } from 'src/components/utilities/api.service';
import { AxiosResponse } from 'axios';
import { IServiceInfo } from 'src/models/service';
import Loader from 'src/components/loader';
import { getUploadedFile } from 'src/components/utilities/utils';

const ServiceInfo: React.FC = () => {
  const [services, setServices] = useState<IServiceInfo[]>();
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleEdit = (id: string) => {
    navigate(`/serviceadd/${id}`);
  };

  const handleDelete = async (id: string) => {
    if (confirm('Do you want to delete this item')) {
      try {
        const response = await ApiService.deleteData(`tenant-srv/tenant/service/${id}`);
        if (response.data && response.data?.data) {
          alert('Service deleted sucessfully');
          getServices();
        }
      } catch (error) {
        alert(error);
      }
    } else {
      return false;
    }
  };

  const handleAddService = () => {
    navigate('/serviceadd');
  };

  const getServices = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData('tenant-srv/tenant/service');
      if (result.data && result.data?.code === 200 && result.data?.success) {
        setServices(result?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getServices();
  }, []);

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="flex justify-between items-center mb-4 ">
        <h2 className="text-xl font-semibold">Services</h2>
        <button
          onClick={handleAddService}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
          <FaPlus className="mr-2" />
          Add New Service
        </button>
      </div>
      <table className="table-auto w-full border">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-2 text-center">S.No</th>
            <th className="p-2 text-center">COMPANY NAME</th>
            <th className="p-2 text-center">LOGO</th>
            <th className="p-2 text-center">ACTIONS</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {loading ? (
            <tr key={'load'}>
              <td colSpan={3} className="text-center p-4">
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {services && services.length > 0 ? (
                services.map((data, i) => (
                  <tr key={data._id}>
                    <td className="p-2 text-center">{i + 1}</td>
                    <td className="p-2 text-center">{data.name}</td>
                    <td className="p-2 text-center">
                      <img
                        src={getUploadedFile(data.logo)}
                        alt={data.name}
                        className="mx-auto"
                        style={{ maxWidth: '50px', maxHeight: '50px' }}
                      />
                    </td>
                    <td className="p-2 text-center">
                      <button className="text-blue-500" onClick={() => handleEdit(data._id)}>
                        <FaEdit className="inline mr-2" />
                      </button>
                      <button className="text-red-500 ml-2" onClick={() => handleDelete(data._id)}>
                        <FaTrash className="inline mr-2" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center p-4">
                    No services found
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ServiceInfo;
