import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaEdit, FaTrash, FaPlus } from 'react-icons/fa';
import { ApiService } from 'src/components/utilities/api.service';
import { AxiosResponse } from 'axios';
import Loader from 'src/components/loader';
import { IManpowerMapModel } from 'src/models/manpowerMapModel';

const ManpowerMappingTable: React.FC = () => {
  const { project_id } = useParams<string>();
  const [manpowermap, setManpowerMap] = useState<IManpowerMapModel[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const handleEdit = (id: any) => {
    navigate(`/addmanpowermap/${project_id}/${id}`);
  };

  const handleDelete = async (id: any) => {
    try {
      if (window.confirm('Do you want to delete?')) {
        await ApiService.deleteData(`project-srv/project/manpower_map/${id}`);
        getAllManpowerMap();
        alert('Data Deleted Successfully');
      }
    } catch (error) {
      console.error('Error deleting role:', error);
      alert('failed to delete role');
    }
  };

  const handleAddManpowerMap = () => {
    navigate(`/addmanpowermap/${project_id}`);
  };

  const getAllManpowerMap = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData(
        `project-srv/project/manpower_map_info/${project_id}`
      );
      setManpowerMap(result.data.data);
      setLoading(false);
    } catch (error) {
      console.error('Error getting Manpower map:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllManpowerMap();
  }, []);

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Manpower Mapping</h2>
        <button
          onClick={handleAddManpowerMap}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
          <FaPlus className="mr-2" />
          Add New Manpower
        </button>
      </div>
      <table className="table-auto w-full border">
        <thead className="bg-gray-50">
          <tr>
            <th className="p-2 text-center">S. No</th>
            <th className="p-2 text-center">Role</th>
            <th className="p-2 text-center">Manpower Name</th>
            <th className="p-2 text-center">Payable Amount</th>
            <th className="p-2 text-center">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {loading ? (
            <tr key={'load'}>
              <td colSpan={5} className="text-center p-4">
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {manpowermap.length > 0 ? (
                manpowermap.map((data, index) => (
                  <tr key={data._id}>
                    <td className="p-2 text-center">{index + 1}</td>
                    <td className="p-2 text-center">{data.role_name}</td>
                    <td className="p-2 text-center">{data.manpower_name}</td>
                    <td className="p-2 text-center">{data.payable_amount}</td>
                    <td className="p-2 text-center">
                      <button className="text-blue-500" onClick={() => handleEdit(data._id)}>
                        <FaEdit className="inline mr-2" />
                      </button>
                      <button className="text-red-500 ml-2" onClick={() => handleDelete(data._id)}>
                        <FaTrash className="inline mr-2" />
                      </button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={5} className="text-center p-4">
                    No Data found
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
        {!loading && manpowermap && manpowermap.length > 0 && (
        <tfoot>
          <tr>
            <td></td>
            <td  className="py-2 px-4 text-center font-semibold">Total Salary:</td>
            <td className="py-2 px-4 text-center font-semibold">
              {manpowermap.reduce((total, data) => total + data.payable_amount, 0)}
            </td>
            <td></td>
          </tr>
        </tfoot>
      )}
      </table>
    </div>
  );
};

export default ManpowerMappingTable;
