import { useNavigate } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import { IManpowerInfo } from 'src/models/manpower';
import { IRoleInfo } from 'src/models/role';

interface OptionType {
  value: string;
  label: string;
}

const ManpowerTable: React.FC = () => {
  const [data, setData] = useState<IManpowerInfo[]>([]);
  const [roles, setRoles] = useState<OptionType[]>([]);
  const navigate = useNavigate();

  const getData = async (): Promise<void> => {
    try {
      const result: AxiosResponse<{ data: IManpowerInfo[] }> = await ApiService.getData(
        `project-srv/manpower`
      );
      setData(result.data.data);
    } catch (error) {
      alert(error);
    }
  };

  const getRoleOptions = async (): Promise<void> => {
    try {
      const result: AxiosResponse<{ data: IRoleInfo[] }> = await ApiService.getData(
        'services-srv/manpower-role'
      );
      const options: OptionType[] = result.data.data.map((role) => ({
        value: role.id,
        label: role.role_name
      }));
      setRoles(options);
    } catch (error) {
      console.error('Error getting role options:', error);
    }
  };

  useEffect(() => {
    getData();
    getRoleOptions();
  }, []);

  const handleAddNew = () => {
    navigate('/manpower');
  };

  const handleEdit = (id: string) => {
    navigate(`/manpower/${id}`);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Do you want to delete?')) {
      try {
        const response:AxiosResponse= await ApiService.deleteData(`project-srv/manpower/${id}`);
        if(response.data && response.data?.code === 200 && response.data?.success) {
        getData();
        alert('Data Deleted Successfully');
        }
        else{
          alert('Failed to Delete the data')
        }
      } catch (error) {
        alert(error)
      }
    }
    else{
    alert('Delete Action Cancel')
    }
  };

  // Function to map role IDs to role names
  const getRoleNames = (roleIds: string[]): string => {
    return roleIds
      .map((id) => {
        const role = roles.find((role) => role.value === id);
        return role ? role.label : 'Unknown Role';
      })
      .join(', ');
  };

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Manpower</h2>
        <button
          onClick={handleAddNew}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
          <FaPlus className="mr-2" />
          Add New
        </button>
      </div>
      <div className="flex-grow overflow-auto">
      <table className="table-auto w-full border">
        <thead className="bg-gray-100">
          <tr>
            <th className="p-2 text-center">S.No</th>
            <th className="p-2 text-center">ROLE</th>
            <th className="p-2 text-center">NAME</th>
            <th className="p-2 text-center whitespace-nowrap">PHONE NUMBER</th>
            <th className="p-2 text-center">ACTIONS</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {data.map((item, index) => (
            <tr key={item.id}>
              <td className="p-2 text-center">{index + 1}</td>
              <td className="p-2 text-center">
                {getRoleNames(Array.isArray(item.role_id) ? item.role_id : [item.role_id])}
              </td>
              <td className="p-2 text-center">{item.name}</td>
              <td className="p-2 text-center">{item.mobile_number}</td>
              <td className="p-2 text-center">
                <button className="text-blue-500" onClick={() => handleEdit(item.id)}>
                  <FaEdit className="inline mr-2" />
                </button>
                <button className="text-red-500 ml-2" onClick={() => handleDelete(item.id)}>
                  <FaTrash className="inline mr-2" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default ManpowerTable;
