import React, { useEffect } from 'react';
import { compareDate, getCookieData } from '../utilities/utils';
import { useNavigate } from 'react-router-dom';
import { deleteCookies } from '../utilities/utils';
const AuthComponent = (components: any) => {
  const navigate = useNavigate();
  const loginDuration = new Date(getCookieData('RefreshCookie'));
  // const refreshDuration = new Date(getCookieData('RefreshTokenExpiryCookie'));
  useEffect(() => {
    if (!compareDate(new Date(), loginDuration)) {
      navigate('/login');
      localStorage.clear();
      deleteCookies();
    }
  }, [loginDuration]);
  return components;
};

export default AuthComponent;
