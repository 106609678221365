import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { LoginFormModel } from 'src/models/loginModel';
import { removeItem, errorMsg, setItem } from '../utilities/utils';
import ToastMessage from 'src/components/ToastContainer';
import { useLoginActions } from 'src/store/login/actions';
import { RootState } from 'src/store/reducers/state';
import FormFieldError from '../common/formFieldError';
import * as AppConstants from '../utilities/constants';
import { useSearchParams } from 'react-router-dom';
import { TenantAdminModel } from 'src/models/usersModel';
type locationState = {
  from: string;
};

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginAction = useLoginActions(dispatch);
  const location = useLocation();
  const { from } = (location.state as locationState) || {};
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginFormModel>();

  useEffect(() => {
    removeItem('token');
    removeItem('role');
  }, []);

  const [loadin,setLoading]=useState(false)
  const handleLogin = async(data: LoginFormModel) => {

    setLoading(true)
    try {
    if (data.user_name === 'BizMaster_Admin' && data.password_hash === 'BizMaster@123') {
      setItem('token', 'bizmasteradminportla');
      const userData: TenantAdminModel = {} as TenantAdminModel;
      (userData.first_name = 'Bizmaster Admin'),
        (userData.last_name = ''),
        (userData.email = ''),
        (userData.roles = ['Super_Admin']),
        (userData.logo = '');
      const date = new Date();

      const updateDate = new Date(date.getTime() + 3600 * 1000);
      document.cookie =
        'RefreshCookie  = ' + updateDate.toString() + '; expires = ' + updateDate.toString();
      localStorage.setItem('userData', JSON.stringify(userData));
      navigate('/tenants');
    } else {
      await loginAction.sendLoginRequest(data);
    }
  }
  catch(error){
    alert("Access denied")
  }
  finally {
    setLoading(false);
  }
  };
  const { loading, loginError, isLoggedIn, isCallbackLink, redirectLink } = useSelector(
    (state: RootState) => {
      return {
        loading: state.login.loading,
        loginError: state.login.error,
        isLoggedIn: state.login.isLoggedIn,
        isCallbackLink: state.login.isCallbackLink,
        redirectLink: state.login.redirectLink,
      };
    }
  );

  useEffect(() => {
    if (isLoggedIn) {
      const pageUrl = '/';
      navigate(`${redirectLink}`);
      loginAction.resetLoginStateRequest();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (Object.keys(loginError).length) {
      alert(loginError);
    }
  }, [loginError]);

  useEffect(() => {
    removeItem('token');
    removeItem('role');
    if (isCallbackLink) {
      loginAction.removeCallbackLinkRequest();
    }
  }, []);

  return (
    <div>
      <section className="bg-gray-100 h-screen">
        <div className="container px-6 h-full mx-auto">
          <div className="flex justify-center items-center h-full">
            <div className="xl:w-10/12 md:w-full">
              <div className="block bg-white shadow-lg">
                <div className="lg:flex lg:flex-wrap g-0">
                  <div className="lg:w-6/12 px-4 md:px-0">
                    <div className="md:p-10 p-5">
                      <div className="mb-10">
                        {/* <img
                          className="w-64 mb-8 ml-10"
                          src="../../assets/img/biz_master.png"
                          alt="logo"
                        /> */}
                        <h3 className="text-2xl font-semibold mb-2">Login</h3>
                        <p>Please use your username to sign-in to your account.</p>
                      </div>
                      <form onSubmit={handleSubmit(handleLogin)}>
                        <div className="mb-4">
                          <label className="mb-1 block">Username</label>
                          <input
                            type="text"
                            className="input-style"
                            id="user_name"
                            {...register('user_name', {
                              required: true,
                              maxLength: 200
                            })}
                          />
                          {errors.user_name?.type === 'required' && (
                            <FormFieldError message={AppConstants.requiredField} />
                          )}
                          {errors.user_name?.type === 'maxLength' && (
                            <FormFieldError message={AppConstants.maxLengthReached} />
                          )}
                        </div>
                        <div className="mb-4">
                          <label className="mb-1 block">Password</label>
                          <input
                            id="password_hash"
                            type="password"
                            className="input-style"
                            {...register('password_hash', { required: true })}
                          />
                          {errors.password_hash?.type === 'required' && (
                            <FormFieldError message={AppConstants.requiredField} />
                          )}
                          <div className="flex items-center justify-between my-4">
                          </div>
                        </div>
                        <div className="text-center mb-4">
                          <button className="btn-primary mb-3" type="submit">
                           {loadin?"Loading....":"Login"}
                          </button>
                        </div>
                        <div className="text-center">
                          {/* <p className="mb-0 mr-2">
                            New to our platform?
                            <a href="#" className="ml-2 site-clr">
                              Create an account
                            </a>
                          </p> */}
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    className="lg:w-6/12 bg-cover relative right-card-img"
                    style={{
                      backgroundImage: `url("../../assets/img/Login_image.png")`
                    }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;
