import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Loader from 'src/components/loader';
import { IQuotation } from 'src/models/Quotation';
import { RootState } from 'src/store/reducers/state';
import { useTenantActions } from 'src/store/tenants/actions';
import { ApiService } from '../utilities/api.service';
import { AxiosError, AxiosResponse } from 'axios';
import { formatDate, getErrorMsg, getFormattedDate } from '../utilities/utils';
import { FaEdit, FaPrint, FaTrash } from 'react-icons/fa';

const Quotation: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [quotations, setQuotations] = useState<IQuotation[]>([]);

  const handleSaveQuotation = (id: string, action: string) => {
    if (action === 'edit') navigate(`/addQuotation/${id}`);
    else if (action === 'add') navigate(`/addQuotation`);
  };

  const handletDeleteQuotation = async (id: string) => {
    if (confirm('Do you want to delete this item')) {
      try {
        const response = await ApiService.deleteData(`project-srv/generate-quotation/${id}`);
        if (response.data && response.data?.data) {
          alert('Quotation deleted sucessfully');
          getQuotationsInfo();
        }
      } catch (error) {
        alert(error);
      }
    } else {
      return false;
    }
  };

  const handleGeneratePrintPDF = async (quotation_id: string, project_id: string) => {
    try {
      const response: AxiosResponse = await ApiService.getData(
        `project-srv/generate-quotation/${quotation_id}`
      );
      if (!response || !response?.data || response?.data?.data == null) {
        alert('invalid id passed');
      }

      const header_response: AxiosResponse = await ApiService.getData(
        `project-srv/project/getservice/${project_id}`
      );
      if (!header_response || !header_response?.data || !header_response?.data?.data) {
        alert('Invalid id passed');
      }
      const headerData = header_response?.data?.data;
      const window_height = window.screen.height;
      const window_width = window.screen.width;
      const quotation_data: IQuotation = response?.data?.data;
      const rootUrl = process.env.REACT_APP_BASE_API_URL;
      let report_html = '';
      let quotation_details = '';
      let material_info = '';
      // let manpower_info = '';
      let additional_info = '';
      let footer_info = '';

      //render section header
      report_html += `<div class='row' style="margin-top: 10px;">
        <div class='col-md-12'>
          <div style="font-size: 22px;">
          <h2 class="text-center">Quotation for ${headerData.project_name}</h2>
          <p>Quoatation No.: ${quotation_data.quotation_no}</p>
           <p>Quotation Date: ${getFormattedDate(quotation_data.quotation_date.toString())}</p>
           <p>Quotation Due Date: ${getFormattedDate(quotation_data.quotation_date.toString())}</p>
        </div>
        </div>
      </div>
      `;
      quotation_details += `<div class='row  d-flex justify-content-between' style="margin-top: 20px;">
          <div className='col-md-6'>
          <h3 class='ml-3'>Quotation From</h3>
            <div style="font-size: 22px;">
              <p class='ml-3'> ${quotation_data.quotation_from.name? quotation_data.quotation_from?.name:""}</p>
              <p class='ml-3'> ${quotation_data.quotation_from.gst? quotation_data.quotation_from?.gst:""}</p>
              <p class='ml-3'> ${quotation_data.quotation_from.address?quotation_data.quotation_from?.address:""}</p>
              <p class='ml-3'> ${quotation_data.quotation_from.phone_no?quotation_data.quotation_from?.phone_no:""}</p>
            </div>  
          </div>
      
          <div className='col-md-6'>
          <div>
          <h3>Quotation To</h3>
            <div style="font-size: 22px;">
              <p> ${quotation_data.quotation_to.name? quotation_data.quotation_to?.name:""}</p>
              <p> ${quotation_data.quotation_to.gst? quotation_data.quotation_to?.gst:""}</p>
              <p> ${quotation_data.quotation_to.address?quotation_data.quotation_to?.address:""}</p>
              <p> ${quotation_data.quotation_to.phone_no?quotation_data.quotation_to?.phone_no:""}</p>
            </div>  
          </div>
          </div>
      
        </div>`;

      // Material Info Table
      material_info += `<div class='row' style="margin-top: 20px;">
          <div class='col-md-12'>
            <h4>Material Information</h4>
            <table class='table border mx-auto' style="width: 90%;">
              <thead style="background-color:#f1f0f3">
            <tr style="background-color:#f1f0f3">
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Unit</th>
                  <th>Amount</th>
                  <th>GST</th>
                  <th>Final Amount</th>
                </tr>
              </thead>
              <tbody>
                ${
                  quotation_data.matertial_info?.material_details.length > 0
                    ? quotation_data.matertial_info.material_details
                        .map(
                          (material) => `
                          <tr>
                            <td>${material.name}</td>
                            <td>${material.quantity}</td>
                            <td>${material.unit}</td>
                            <td>${material.amount}</td>
                            <td>${material.gst || ''}</td>
                            <td>${material.final_amount}</td>
                          </tr>`
                        )
                        .join('')
                    : `
                      <tr>
                        <td colspan="6">No Materials found</td>
                      </tr>`
                }
              </tbody>
              <tfoot style="background-color:#f1f0f3">
                <tr>
                  <td colspan="2"></td>
                  <td class='ml-3'>Total Amount:</td>
                  <td class='ml-3'>${quotation_data.matertial_info.total_amount}</td>
                  <td class='ml-3'>Total Final Amount:</td>
                  <td class='ml-3'>${quotation_data.matertial_info.total_final_amount}</td>
                </tr>
               
              </tfoot>
            </table>
          </div>
        </div>`;

      // // Manpower Info Table

      // manpower_info += `<div class='row'>
      //     <div class='col-md-12'>
      //       <h4>Manpower Information</h4>
      //       <table class='table border mx-auto' style="width: 90%;">
      //         <thead style="background-color:#f1f0f3">
      //           <tr>
      //             <th>Name</th>
      //             <th>Roles</th>
      //             <th>Amount</th>
      //           </tr>
      //         </thead>
      //         <tbody>
      //           ${
      //             quotation_data.manpower_info?.manpower_details.length > 0
      //               ? quotation_data.manpower_info.manpower_details
      //                   .map(
      //                     (manpower) => `
      //                     <tr>
      //                       <td>${manpower.name}</td>
      //                       <td>${manpower.roles}</td>
      //                       <td>${manpower.amount}</td>
      //                     </tr>`
      //                   )
      //                   .join('')
      //               : `
      //                 <tr>
      //                   <td colspan="3" class="text-center">No Manpower found</td>
      //                 </tr>`
      //           }
      //         </tbody>
      //         <tfoot style="background-color:#f1f0f3">
      //           <tr>
      //             <td colspan="2"></td>
      //             <td>Total Amount: ${quotation_data.manpower_info.total_amount}</td>
      //           </tr>
      //         </tfoot>
      //       </table>
      //     </div>
      //   </div>`;

      // Additional Info Table
      additional_info += `<div class='row' style="margin-top: 20px;">
      <div class='col-md-12'>
        <h4>Additional Information</h4>
        <table class='table mx-auto border' style="width: 90%;">
          <thead>
            <tr style="background-color:#f1f0f3">
              <th>Name</th>
              <th>Description</th>
              <th>Value</th>
            </tr>
          </thead>
           <tbody>
            ${
              quotation_data.additional_info?.additional_info_details.length > 0
                ? quotation_data.additional_info.additional_info_details
                    .map(
                      (info) => `
                <tr>
                  <td>${info.name}</td>
                  <td>${info.description}</td>
                  <td>${info.value}</td>
                </tr>`
                    )
                    .join('')
                : '<tr><td colspan="3" class="text-center">No Additional Information found</td></tr>'
            }
          </tbody>
          <tfoot style="background-color:#f1f0f3">
            <tr>
                <td></td>
                <td></td>
                <td>Total Value :${quotation_data.additional_info.total_value}</td>
            </tr>
          </tfoot>
        </table>
      </div>
      </div>`;

     
      footer_info += `
      <div class='row mt-5 d-flex justify-content-between'>
        <div class='col-md-6'>
          <p><b>Total Amount: ${quotation_data.total}</b></p>
          <p>Date: ${getFormattedDate(quotation_data.signed_date.toString())}</p>
        </div>
        <div class='col-md-6 text-right'>
          <p>Signature</p>
          ${quotation_data.signature ? `<img src="${quotation_data.signature}" alt="Signature" style="max-height: 100px;">` : ""}
        </div>
      </div>`;
    
      const mywindow = window.open(
        '',
        'PRINT',
        'height=' + window_height + ',width=' + window_width + 10
      );
      if (!mywindow || mywindow == null) {
        alert('Something went wrong');
      } else {
        mywindow.document.write('<html><head>');
        mywindow.document.write(
          '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css">' +
            '<script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js" > </script>' +
            '<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" > </script>' +
            '<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js" > </script>'
        );
        mywindow.document.write(
          '</head><body style="padding-left: 20px; padding-right: 20px; padding-bottom:20px">'
        );
        mywindow.document.write(
          '<div class="row"  id = "print-header" >' +
            ' <div className="col-md-4 " class="mt-3 mr-5 ml-3" > ' +
            '<img src = "' +headerData.logo +'" alt = "company logo" style = "width: 150px !important; height: 100px !important; max-width: none; float: left;"> ' +
            '</div>' +
            '<div className="col-md-8" class="ml-5">' +
            '<h1 style = "margin-bottom: 0px !important; font-size:70px ; color:#090265">' +
            headerData.service_name +
            '</h1>' +
            '<h3 style="color:090265;  margin-left: 100px;">' +
            headerData.first_name +
            ' ' +
            headerData.last_name +
            '</h3>' +
            '</div>' +
            '</div>' +
            '<div class="row " " id = "print-header"  >' +
            '<div  class="col-md-12 text-center ms-5" style="bac-color:#e1ddff; color:#090265" >' +
            '<ul class="d-flex justify-content-around" style="font-size:20px ; margin-top:10px;">' +
            '<li>' +
            'PLANINIG' +
            '</li>' +
            '<li>' +
            'ELEVATION' +
            '</li>' +
            '<li>' +
            'DESIGN' +
            '</li>' +
            '<li>' +
            'CONSTRUCTION' +
            '</li>' +
            '</ul>' +
            '</div>' +
            '<div  class="col-md-12 text-center">' +
            '<h4 style="color:090265">' +
            headerData.address +
            '</h4>' +
            '</div>' +
            '<div  class="col-md-12 text-center p-2 d-flex justify-content-between pl-4" style="background-color:#090265; color:white; "   >' +
            '<h4 class="pl-5">' +
            'Contact : ' +
            headerData.contact_number +
            '</h4>' +
            '<h4 class="pr-5">' +
            'Email : ' +
            headerData.email +
            '</h4>' +
            '<h4 class="pr-5">' +
            'GST : ' +
            headerData.gst_in +
            '</h4>' +
            '</div>' +
            '</div>'
        );

        mywindow.document.write(report_html);
        mywindow.document.write(quotation_details);
        mywindow.document.write(material_info);
        // mywindow.document.write(manpower_info);
        mywindow.document.write(additional_info);
        mywindow.document.write(footer_info);

        mywindow.document.write('</body></html>');
        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10
      }
      if (mywindow != null) {
        setTimeout(function () {
          mywindow.print();
          mywindow.close();
        }, 1000);
      }
      return true;
    } catch (error) {
      const err = error as AxiosError;
      alert(getErrorMsg(err));
    }
  };

  const getQuotationsInfo = async () => {
    setLoading(true);
    try {
      const response: AxiosResponse = await ApiService.getData('project-srv/generate-quotation');
      setQuotations(response?.data.data);
    } catch (error) {
      alert(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getQuotationsInfo();
  }, []);

  return (
    <>
      <div className="mt-4 members-list">
        <div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 flex justify-between items-center">
                <h2 className="font-semibold ">Quotations</h2>
                <div className="lg:flex items-center text-center">
                  <button
                    onClick={() => {
                      handleSaveQuotation('', 'add');
                    }}
                    type="button"
                    className="btn-primary w-auto whitespace-nowrap mt-0 mb-1 mx-auto">
                    Generate
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr key={'headers'}>
                        <th className="p-2">
                          <div className="font-semibold text-left whitespace-nowrap">S No.</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left whitespace-nowrap">Quotation No.</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left whitespace-nowrap">Quotation Date</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left whitespace-nowrap">Total cost</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left ">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {loading ? (
                        <tr key={'load'}>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {quotations.length ? (
                            <>
                              {quotations &&
                                quotations.map((quotation, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            <div className="text-left">{`${index + 1}`}</div>
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            <div className="text-left">{`${quotation.quotation_no}`}</div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="text-left">
                                            {getFormattedDate(quotation.quotation_date.toString())}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="text-left">{`${quotation.total}`}</div>
                                        </td>
                                        <td className="p-2 whitespace-nowrap">
                                          <button
                                            className="text-blue-500 hover:text-blue-700 mr-2"
                                            onClick={() =>
                                              handleSaveQuotation(quotation._id, 'edit')
                                            }>
                                            <FaEdit />
                                          </button>
                                          <button
                                            className="text-red-500 hover:text-red-700 mr-2"
                                            onClick={() => handletDeleteQuotation(quotation._id)}>
                                            <FaTrash />
                                          </button>
                                          <button
                                            className="text-blue-500 hover:text-red-700 mr-2"
                                            onClick={() =>
                                              handleGeneratePrintPDF(
                                                quotation._id,
                                                quotation.project_id
                                              )
                                            }>
                                            <FaPrint />
                                          </button>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <tr key={'no_records'}>
                              <td colSpan={5} className='text-center'>No Data Found</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Quotation;
