import { useEffect,  useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { FaCheck } from "react-icons/fa";
import Loader from 'src/components/loader';
import Select from 'react-select';

import {
  IAdditionalDetail,
  IAdditionalInfo,
  IClientInfo,
  IManpowerDetail,
  IManpowerQuotation,
  IMaterialDetail,
  IMaterialsQuotation,
  IQuotation
} from 'src/models/Quotation';
import { ApiService } from '../utilities/api.service';
import { AxiosResponse } from 'axios';
import { TenantAdminModel } from 'src/models/usersModel';
import { getItem } from '../utilities/utils';
import { IMaterial, IMaterialInfo } from 'src/models/materialModels';

interface OptionType {
  label: string;
  value: string;
  unit:string;
}

interface Material {
  gst: number;
}
interface FormValues {
  materials: Material[];
}

const AddQuotation = () => {
  //Input Parameter
  const { id } = useParams<string>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [projectId, setProjectId] = useState<null | string>(null);
  const [projects, setProjects] = useState<[{ label: string; value: string }]>([
    { label: 'Select Project', value: '' }
  ]);
  const [clients, setClients] = useState<[{ label: string; value: string }]>([
    { label: 'Select Project', value: '' }
  ]);
  const [showMaterialMap, setShowMaterialMap] = useState<boolean>(false);
  const [materialMapInfo, setMaterialMapInfo] = useState<IMaterialDetail[]>([]);
  const [manpowerMapInfo, setManpowerMapInfo] = useState<IManpowerDetail[]>([]);
  const [addtionalExpenditureInfo, setAddtionalExpenditureInfo] = useState<IAdditionalDetail[]>([]);
  const [clientsInfo, setClientsInfo] = useState<IClientInfo[]>([]);
  const [editedMaterialRow, seteditedMaterialRow] = useState<null | number>(null);
  const [editedManpowerRow, seteditedManpowerRow] = useState<null | number>(null);
  const [editedAdditionalExpenditureRow, setEditedAdditionalExpenditureRow] = useState<
    null | number
  >(null);
  const [addMaterialRow, setAddMaterialRow] = useState<null | number>(null);
  const [addManpowerRow, setAddManpowerRow] = useState<null | number>(null);
  const [addAdditionalExpenditureRow, setAddAdditionalExpenditureRow] = useState<null | number>(
    null
  );
  const [showManpowerMap, setShowManpowerMap] = useState<boolean>(false);
  const [showAdditionalExpenditure, setShowAdditionalExpenditure] = useState<boolean>(false);
  const [totalValues, setTotalValues] = useState<{
    totalMaterialAmount: number;
    totalManpowerAmount: number;
    totalExpenditureVal: number;
    totalMaterialFinalAmount: number;
  }>({
    totalMaterialAmount: 0,
    totalManpowerAmount: 0,
    totalExpenditureVal: 0,
    totalMaterialFinalAmount: 0
  });

  // const [logoUrl, setLogoUrl] = useState('');
  const [signUrl, setSignUrl] = useState('');
  const [materialOptions, setMaterialOption] = useState<OptionType[]>([]);


  const [selectedMaterial, setSelectedMaterial] = useState<OptionType | null>(null);
  const [userDetails, setUserDetails] = useState<TenantAdminModel | null>(null);

  
  useEffect(() => {
    setUserDetails(JSON.parse(getItem('userData')));
  }, []);



  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset
  } = useForm<IQuotation>();

  const materialMapForm = useForm<IMaterialDetail>();
  const manpowerMapForm = useForm<IManpowerDetail>();
  const additionalExpenditureForm = useForm<IAdditionalDetail>();
  const handleBackClick = () => {
    navigate('/quotation');
  };


  const fetchMaterials = async () => {
    try {
          const result: AxiosResponse<{ data: IMaterial[] }> = await ApiService.getData(
            `services-srv/asset`
          );

          const options = result.data.data.map((material) => ({
            value: material._id,
            label:material.category + '-' + material.material_name,
            unit:material.unit 
          }));
          setMaterialOption(options);
        } catch (error) {
      alert(error)
    }
  };

  useEffect(() => {
    fetchMaterials();
 
  }, []);

  const handleSignUpload = async (e: any) => {
    const file = e.target.files[0];
    if (file) {
      try {
        const result: AxiosResponse = await ApiService.uploadImage(
          'tenant-srv/tenant/upload',
          file
        );
        if (result.data && result.data?.code === 200 && result.data?.success) {
          setSignUrl(result.data.data);
        }
      } catch (error) {
        alert(error);
      }
    }
  };

  
  const onSubmit = async (data: IQuotation) => {
    try {
      // data.logo = logoUrl;
      data.signature = signUrl;
      data.matertial_info = {} as IMaterialsQuotation;
      data.matertial_info.total_amount = totalValues.totalMaterialAmount;
      data.matertial_info.total_final_amount = totalValues.totalMaterialFinalAmount;
      data.matertial_info.material_details = materialMapInfo;
      data.manpower_info = {} as IManpowerQuotation;
      data.manpower_info.total_amount = totalValues.totalManpowerAmount;
      data.manpower_info.manpower_details = manpowerMapInfo;
      data.additional_info = {} as IAdditionalInfo;
      data.additional_info.total_value = totalValues.totalExpenditureVal;
      data.additional_info.additional_info_details = addtionalExpenditureInfo;
      if (id) {
        data.id, (data._id = id);
      }
      const response: AxiosResponse = await ApiService.postData(
        'project-srv/generate-quotation',
        data
      );
      if (response.data && response.data?.code === 200 && response.data?.success) {
        alert('Quotation saved successfully');
        navigate('/quotation');
      }
    } catch (error) {
      alert(error);
    }
  };

  const getAllClients = async (id: string) => {
    try {
      const response: AxiosResponse = await ApiService.getData('project-srv/client');
      const getClients = response.data?.data.filter((e: any) => e.project_id == id);
      setClientsInfo(getClients);
      const clientsInfo = getClients.map((e: IClientInfo) => {
        return { label: e.name, value: e._id };
      });
      setClients(clientsInfo);
    } catch (error) {
      alert(error);
    }
  };

  const getAllProjects = async () => {
    try {
      const response: AxiosResponse = await ApiService.getData('project-srv/project');
      const projectsInfo = response.data?.data.map((e: any) => {
        return { label: e?.name, value: e?._id };
      });
      setProjects(projectsInfo);
    } catch (error) {
      alert(error);
    }
  };

  const getMaterialsByProject = async () => {
    try {
      const response: AxiosResponse = await ApiService.getData(
        `services-srv/purchasematerial/purchase/${projectId}`
      );
      let materialMapinfo: IMaterialDetail[] = [] as IMaterialDetail[];
      let totalAmount = 0;
      let totalFinalAmount = 0;
      if (response.data?.data.length) {
        materialMapinfo = response.data?.data.map((e: any) => {
          totalAmount = totalAmount + e?.purchase_amount;
          totalFinalAmount = totalFinalAmount + e?.final_amount;
          return {
            name: e?.material_name,
            quantity: e?.no_of_quantity,
            unit: e?.unit,
            amount: e?.purchase_amount,
            gst: e?.gst,
            gst_applicable: e?.gst_applicable,
            final_amount: e?.final_amount
          };
        });
        setTotalValues((prevState) => ({
          ...prevState,
          ['totalMaterialAmount']: prevState.totalExpenditureVal + totalAmount,
          ['totalMaterialFinalAmount']: prevState.totalMaterialFinalAmount + totalFinalAmount
        }));
        setMaterialMapInfo(materialMapinfo);
      }
    } catch (err) {
      alert(err);
    }
  };

  const [clientname,setClientName] = useState<string>('')
  useEffect(()=>{
    const getClientName = async () => {
      try {
        const result: AxiosResponse = await ApiService.getData(`project-srv/client/client_info/${projectId}`);
        // get serrvice info
        const response = await ApiService.getData(`tenant-srv/tenant/service/tenantId/${userDetails?.tenant_id}`);

        const name = result?.data?.data[0].name
        setClientName(name);
        setValue('clientName',name)
        setValue('quotation_to.address',result?.data?.data[0].address)
        setValue('quotation_to.phone_no',result?.data?.data[0].phone_no)
        setValue('quotation_to.name',name)

        setValue('quotation_from.name',response?.data.data.first_name)
        setValue('quotation_from.address',response?.data.data.location)
        setValue('quotation_from.phone_no',response?.data.data.contact_number)

      } catch (error) {
        alert(error);
      }
    };
    if(projectId) {getClientName()}
  },[projectId,setValue])



  const handleChangeProject = (e: any) => {
    setProjectId(e.target.value);
  };

  const [unit,setMatUnit]=useState<string>()
    const handleMaterialChange = (selectedMaterial: OptionType | null) => {
    setSelectedMaterial(selectedMaterial);
    const selectedMaterialData = materialOptions.find(
      (material) => material.value === selectedMaterial?.value
    );
   setMatUnit(selectedMaterialData?.unit)
  };
  
  const saveMaterialMap = (data: IMaterialDetail) => {
    data.name=selectedMaterial? selectedMaterial.label:""
    const selectedMaterialData = materialOptions.find(
      (material) => material.value === selectedMaterial?.value
    );
    data.unit = selectedMaterialData? selectedMaterialData.unit:""
    data.final_amount=final_amount

    setMaterialMapInfo((materialMapInfo) => [...materialMapInfo, data]);
    setTotalValues((prevState) => ({
      ...prevState,
      ['totalMaterialAmount']: Number(prevState.totalMaterialAmount) + Number(data.amount),
      ['totalMaterialFinalAmount']:
        Number(prevState.totalMaterialFinalAmount) + Number(data.final_amount)
    }));
    setAddMaterialRow(null);
  };



  const saveAdditionalExpenditureMap = (data: IAdditionalDetail) => {
    setAddtionalExpenditureInfo((additionalExpenditure) => [...additionalExpenditure, data]);
    setTotalValues((prevState) => ({
      ...prevState,
      ['totalExpenditureVal']: Number(prevState.totalExpenditureVal) + Number(data.value)
    }));
    setAddAdditionalExpenditureRow(null);
  };

  const handleDeleteMaterialRow = (index: number) => {
    const materialInfo = materialMapInfo.filter(
      (e: any, element_index: number) => index != element_index
    );
    const deleteMaterial = materialMapInfo.find(
      (e: any, element_index: number) => index == element_index
    );
    setTotalValues((prevState) => ({
      ...prevState,
      ['totalMaterialAmount']: prevState.totalMaterialAmount - Number(deleteMaterial?.amount),
      ['totalMaterialFinalAmount']:
        prevState.totalMaterialFinalAmount - Number(deleteMaterial?.final_amount)
    }));
    setMaterialMapInfo(materialInfo);
  };

  const handleUpdateMaterialRow = (index:number) => {
    setMaterialMapInfo((prevMaterialMapInfo) =>
      prevMaterialMapInfo.map((v, i) =>
        i === index
          ? {
              ...v,
              final_amount: calculateFinalAmount(v.amount, v.quantity, v.gst) // Recalculate final amount
            }
          : v
      )
    );
    const totalAmount = materialMapInfo.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(currentValue.amount);
    }, 0);

    const totalFinalAmount = materialMapInfo.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(currentValue.final_amount);
    }, 0);
    setTotalValues((prevState) => ({
      ...prevState,
      ['totalMaterialAmount']: totalAmount,
      ['totalMaterialFinalAmount']: totalFinalAmount
    }));
    seteditedMaterialRow(null);
  };
  const handleGstChange = (index: number, value: number) => {
    setMaterialMapInfo((prevMaterialMapInfo) =>
      prevMaterialMapInfo.map((v, i) =>
        i === index ? { ...v, gst: value, final_amount: calculateFinalAmount(v.amount, v.quantity, value) } : v
      )
    );
  };
    
const [amount, setAmount] = useState<number>(0);
const [quantity, setQuantity] = useState<number>(0);
const [gst, setGst] = useState<number>(0);
const [final_amount, setCalculatedFinalAmount] = useState<number>(0);
    // Handle changes in amount
  const handleNewAmountChange = (newAmount: number) => {
    setAmount(newAmount);
    const amountValue=calculateFinalAmount(newAmount, quantity, gst)
    setCalculatedFinalAmount(amountValue);

     // Recalculate final amount
  };

  // Handle changes in quantity
  const handleNewQuantityChange = (newQuantity: number) => {
    setQuantity(newQuantity);

    const quantityValue = calculateFinalAmount(amount, newQuantity, gst)
    setCalculatedFinalAmount(quantityValue);
  };

  // Handle changes in GST
  const handleNewGstChange = (newGst: number) => {
    setGst(newGst);
    const gstValue=calculateFinalAmount(amount, quantity, newGst)
    setCalculatedFinalAmount(gstValue);
  };

  // Handle amount or quantity change
  const handleAmountChange = (index: number, value: number) => {
    setMaterialMapInfo((prevMaterialMapInfo) =>
      prevMaterialMapInfo.map((v, i) =>
        i === index ? { ...v, amount: value, final_amount: calculateFinalAmount(value, v.quantity, v.gst) } : v
      )
    );
  };

  const [materialSelect,setMaterialSelect]=useState<OptionType | null>(null);
  const handleMaterialchange = (selectedMaterial: OptionType | null, index: number) => {
    setMaterialSelect(selectedMaterial);
    setMaterialMapInfo((prevMaterialMapInfo) =>
      prevMaterialMapInfo.map((v, i) =>
        i === index
          ? {
              ...v,
              name: selectedMaterial?.label || '',  // Set name to selected material's label
              unit: selectedMaterial?.unit || ''    // Set unit to selected material's unit
            }
          : v
      )
    );
  };
  
  const calculateFinalAmount = (amount: number, quantity: number, gst: number) => {
    const total = amount * quantity;
    const gstValue = (total * gst) / 100;
    return total + gstValue;
  };



  const handleUpdateAdditionalExpenditureRow = () => {
    const totalAmount = addtionalExpenditureInfo.reduce((accumulator, currentValue) => {
      return Number(accumulator) + Number(currentValue.value);
    }, 0);
    setTotalValues((prevState) => ({
      ...prevState,
      ['totalExpenditureVal']: totalAmount
    }));
    setEditedAdditionalExpenditureRow(null);
  };

  
  const handleDeleteAdditionalExpenditureRow = (index: number) => {
    const additionalExpenditure = addtionalExpenditureInfo.filter(
      (e: any, element_index: number) => index != element_index
    );
    const deletedadditionalExpenditure = addtionalExpenditureInfo.find(
      (e: any, element_index: number) => index == element_index
    );
    setTotalValues((prevState) => ({
      ...prevState,
      ['totalExpenditureVal']:
        prevState.totalExpenditureVal - Number(deletedadditionalExpenditure?.value)
    }));
    setAddtionalExpenditureInfo(additionalExpenditure);
  };

  const handleChangeAddMaterial = (e: any) => {
    if (!projectId) alert('Please select project');

    if (e?.target?.checked && projectId) {
      setShowMaterialMap(true);
      getMaterialsByProject();
    }
    if (!e?.target?.checked) {
      setTotalValues((prevState) => ({
        ...prevState,
        ['totalMaterialAmount']: 0,
        ['totalMaterialFinalAmount']: 0
      }));
      materialMapForm.reset({
        name: '',
        amount: 0,
        quantity: 0,
        unit: '',
        gst_applicable: false,
        gst: 0,
        final_amount: 0
      });
      setMaterialMapInfo([]);
      setShowMaterialMap(false);
    }
    return false;
  };

  const handleAddManpowerMap = (e: any) => {
    if (!projectId) alert('Please select project');
    if (!e?.target?.checked) {
      setTotalValues((prevState) => ({
        ...prevState,
        ['totalManpowerAmount']: 0
      }));
      manpowerMapForm.reset({
        name: '',
        amount: 0,
        roles: ''
      });
      setManpowerMapInfo([]);
      setShowManpowerMap(false);
    }
    if (e?.target?.checked && projectId) {
      setShowManpowerMap(true);
    }
    return false;
  };

  const handleAddAdditionalExpenditure = (e: any) => {
    if (!projectId) alert('Please select project');
    if (!e?.target?.checked) {
      setTotalValues((prevState) => ({
        ...prevState,
        ['totalExpenditureVal']: 0
      }));
      additionalExpenditureForm.reset({
        name: '',
        value: 0,
        description: ''
      });
      setShowAdditionalExpenditure(false);
    }
    if (e?.target?.checked && projectId) {
      setShowAdditionalExpenditure(true);
    }
    return false;
  };

  const handleAddMaterialRow = () => {
    setAddMaterialRow(materialMapInfo.length + 1);
    setSelectedMaterial(null),
    setQuantity(0),
    setGst(0),
    setAmount(0)
    setCalculatedFinalAmount(0)
   
    materialMapForm.reset({
      name: '',
      quantity: 0,
      unit: '',
      amount: 0
    });
  };

   const handleApplyGST = (index: number) => {
    const updateMaterialInfo = materialMapInfo.find((e: any, e_index: number) => e_index == index);
    setMaterialMapInfo((prevMaterialMapInfo) =>
      prevMaterialMapInfo.map((v, i) =>
        i === index
          ? {
              ...v,
              final_amount: Number(v.final_amount) + Number(v.final_amount) * (Number(v.gst) / 100),
              gst_applicable: true
            }
          : v
      )
    );
    const totalFinalAmount =
      (Number(updateMaterialInfo?.amount) * Number(updateMaterialInfo?.gst)) / 100;
    setTotalValues((prevState) => ({
      ...prevState,
      ['totalMaterialFinalAmount']: prevState.totalMaterialFinalAmount + totalFinalAmount
    }));
  };


 
  const handleAddAdditionalExpenditureRow = () => {
    setAddAdditionalExpenditureRow(addtionalExpenditureInfo.length + 1);
    additionalExpenditureForm.reset({
      name: '',
      description: '',
      value: 0
    });
  };

  const getQuotationById = async () => {
    try {
      const response = await ApiService.getData(`project-srv/generate-quotation/${id}`);
      if (response.data && response.data?.data && response.data?.code == 200) {
        setSignUrl(response.data.data.signature);        

        getAllClients(response.data?.data?.project_id);
        setProjectId(response.data?.data?.project_id);
        reset(response.data?.data);

        response.data?.data?.quotation_date &&
          setValue('quotation_date', (response.data?.data?.quotation_date).split('T')[0]);
        response.data?.data?.due_date &&
          setValue('due_date', (response.data?.data?.due_date).split('T')[0]);
        response.data?.data?.signed_date &&
          setValue('signed_date', (response.data?.data?.signed_date).split('T')[0]);
        setTotalValues(() => ({
          totalMaterialAmount: response.data?.data?.matertial_info?.total_amount
            ? response.data?.data?.matertial_info?.total_amount
            : 0,
          totalMaterialFinalAmount: response.data?.data?.matertial_info?.total_final_amount
            ? response.data?.data?.matertial_info?.total_final_amount
            : 0,
          totalManpowerAmount: response.data?.data?.manpower_info?.total_amount
            ? response.data?.data?.manpower_info?.total_amount
            : 0,
          totalExpenditureVal: response.data?.data?.additional_info?.total_value
            ? response.data?.data?.additional_info?.total_value
            : 0
        }));
        if (
          response.data?.data?.matertial_info?.material_details &&
          response.data?.data?.matertial_info?.material_details.length > 0
        ) {
          setShowMaterialMap(true);
          setMaterialMapInfo(response.data?.data?.matertial_info?.material_details);
        }
        if (
          response.data?.data?.manpower_info?.manpower_details &&
          response.data?.data?.manpower_info?.manpower_details.length > 0
        ) {
          setShowManpowerMap(true);
          setManpowerMapInfo(response.data?.data?.manpower_info?.manpower_details);
        }

        if (
          response.data?.data?.additional_info?.additional_info_details &&
          response.data?.data?.additional_info?.additional_info_details.length > 0
        ) {
          setShowAdditionalExpenditure(true);
          setAddtionalExpenditureInfo(
            response.data?.data?.additional_info?.additional_info_details
          );
        }
      } else {
        alert('Invalid id passed');
        navigate('/quotation');
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    setValue(
      'total',
      Number(
        totalValues.totalMaterialFinalAmount +
          totalValues.totalManpowerAmount +
          totalValues.totalExpenditureVal
      )
    );
  }, [totalValues]);

  useEffect(() => {
    getAllProjects();
  }, []);

  useEffect(() => {
    if (id) getQuotationById();
  }, [id]);

  return (
    <>
      <div className="shadow p-5 border flex-grow overflow-auto">
        <div className="mb-8">
          <h2 className="font-semibold my-1">Quotation</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/">Dashboard</Link>
            </li>
            <li>
              <Link to="/quotation">Quotations</Link>
            </li>
            <li className="active">{id ? <strong>Edit</strong> : <strong>Add</strong>}</li>
          </ol>
        </div>
        <div className="flex flex-col justify-center h-full">
          <div className="xl:flex">
            {loading ? (
              <Loader />
            ) : (
              <>
                {' '}
                <form>
                  
                  <div className="grid md:grid-cols-4 sm:grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>
                          Select Project <span className="text-red-700 ml-1">*</span>
                        </strong>
                      </label>
                      <select
                        {...register('project_id', { required: true })}
                        id="project_id"
                        onChange={handleChangeProject}
                        className='rounded'
                        style={{ width: '300px' }}>
                        <option value={''}>Select Project</option>
                        {projects &&
                          projects.map((values: any, index: any) => (
                            <option value={values.value} key={index}>
                              {' '}
                              {values.label}{' '}
                            </option>
                          ))}
                      </select>
                      {errors.project_id?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong> Client Name</strong>
                      </label>
                      <input
                        type="text"
                        className="input-style"
                        placeholder="Name"
                        value={clientname}
                        {...register('clientName', {
                          required: true
                        })}
                      />
                      {errors.clientName?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                      
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>
                          Quotation Date <span className="text-red-700 ml-1">*</span>
                        </strong>
                      </label>
                      <input
                        type="date"
                        className="input-style"
                        placeholder="Select Quotation date"
                        id="quotation_date"
                        {...register('quotation_date', { required: true })}
                        
                      />
                      {errors.quotation_date?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>Due Date</strong>
                      </label>
                      <input
                        type="date"
                        className="input-style"
                        placeholder="Select Due date"
                        id="due_date"
                        {...register('due_date', {
                          required: true
                        })}
                      />
                      {errors.due_date?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                    </div>
                  </div>

                  <div className="grid md:grid-cols-4 sm:grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>
                          Quotation No. <span className="text-red-700 ml-1">*</span>
                        </strong>
                      </label>
                      <input
                        type="text"
                        className="input-style"
                        placeholder="Enter Quotation no"
                        id="quotation_no"
                        {...register('quotation_no', {
                          required: true
                        })}
                      />
                      {errors.quotation_no?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                    </div>
                  </div>
                  <span>Quotation from</span>
                  <div className="grid md:grid-cols-4 sm:grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>
                          Name <span className="text-red-700 ml-1">*</span>
                        </strong>
                      </label>
                      <input
                        type="text"
                        className="input-style"
                        placeholder="Name"
                        {...register('quotation_from.name', {
                          required: true
                        })}
                      />
                      {errors.quotation_from?.name?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>Address</strong>
                      </label>
                      <textarea
                        className="input-style"
                        placeholder="Enter Address"
                        {...register('quotation_from.address')}></textarea>
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>Phone No.</strong>
                      </label>
                      <input
                        type="number"
                        className="input-style"
                        placeholder="Enter Phone no"
                        {...register('quotation_from.phone_no')}
                      />
                    </div>
                  </div>
                  <span>Quotation to</span>
                  <div className="grid md:grid-cols-4 sm:grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>
                          Name <span className="text-red-700 ml-1">*</span>
                        </strong>
                      </label>
                      <input
                        type="text"
                        className="input-style"
                        placeholder="Name"
                        {...register('quotation_to.name', {
                          required: true
                        })}
                      />
                      {errors.quotation_to?.name?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                    </div>
                   
                  
                  <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>Address</strong>
                      </label>
                      <textarea
                        className="input-style"
                        placeholder="Enter Address"
                        {...register('quotation_to.address')}></textarea>
                  </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>Phone No.</strong>
                      </label>
                      <input
                        type="number"
                        className="input-style"
                        placeholder="Enter Phone no"
                        {...register('quotation_to.phone_no')}
                      />
                    </div>
                    </div>
                  
                  {/* Mapterial mapping */}
                  <>
                    <div className="grid grid-cols-4 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                      <div className="form-group mb-4 mt-10">
                        <label className="block text-sm font-medium mb-1 whitespace-nowrap">Add Material</label>
                        <div className="form-check form-switch pl-0 mr-3 relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            role="switch"
                            checked={showMaterialMap}
                            id="showCourseCompletion"
                            onClick={handleChangeAddMaterial}
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                        </div>
                      </div>
                    </div>
                    {showMaterialMap && materialMapInfo && (
                      <>
                        <div className="flex-col justify-center flex-grow overflow-auto">
                          <div className="w-full mx-auto bg-white shadow-card rounded-lg relative flex-grow overflow-auto">
                            <table className="table-auto w-full border">
                              <thead className="text-xs font-semibold uppercase bg-gray-50">
                                <tr key={'headers'}>
                                  
                                  <th className="p-2">
                                    <div className="font-semibold text-left whitespace-nowrap ">S No.</div>
                                  </th>
                                  <th className="p-2">
                                    <div className="font-semibold text-left whitespace-nowrap">Material name</div>
                                  </th>
                                  <th className="p-2">
                                    <div className="font-semibold text-left whitespace-nowrap">No. of quantity</div>
                                  </th>
                                  <th className="p-2">
                                    <div className="font-semibold text-left whitespace-nowrap">Unit</div>
                                  </th>
                                  <th className="p-2">
                                    <div className="font-semibold text-left whitespace-nowrap">Amount</div>
                                  </th>
                                  <th className="p-2">
                                    <div className="font-semibold text-left whitespace-nowrap">GST (%)</div>
                                  </th>
                                  <th className="p-2">
                                    <div className="font-semibold text-left whitespace-nowrap">Final amount</div>
                                  </th>
                                  <th className="p-2">
                                    <div className="font-semibold text-left whitespace-nowrap">Actions</div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-sm divide-y divide-gray-100">
                                {materialMapInfo.map((material, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            <div className="text-left">{`${index + 1}`}</div>
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                             {editedMaterialRow === index ? (
                                              <Select
                                                value={materialOptions.find(option => option.label === material.name) || null}
                                                placeholder="Select Material"
                                                isClearable
                                                onChange={(option) => handleMaterialchange(option as OptionType, index)}  
                                                options={materialOptions}
                                                className='input-style'
                                                id={`role${index}`}
                                                required
                                              />
                                            ) : (
                                              <div className="text-left">{`${material.name}`}</div>
                                            )}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            {editedMaterialRow === index ? (
                                              <input
                                                type="number"
                                                onChange={(e) =>
                                                  setMaterialMapInfo((prevMaterialMapInfo) =>
                                                    prevMaterialMapInfo.map((v, i) =>
                                                      i === index
                                                        ? { ...v, quantity: Number(e.target.value) }
                                                        : v
                                                    )
                                                  )
                                                }
                                                id={`quantity${index}`}
                                                className="input-style"
                                                value={`${material.quantity}`}
                                                placeholder="Enter Quantity"
                                              />
                                            ) : (
                                              <div className="text-left">{`${material.quantity}`}</div>
                                            )}
                                          </div>
                                        </td>
                                        <td className="p-2">

                                          <div className="flex items-center grid sm:grid-cols-1">
                                          {editedMaterialRow === index ? (
                                                <input
                                                  type="text"
                                                  className="input-style"
                                                  value={material.unit}
                                                  id={`unit${index}`}
                                                  disabled  // Disable input as it's automatically populated
                                                  placeholder="Unit will auto-update"
                                                />
                                              ) : (
                                                <div className="text-left">{`${material.unit}`}</div>
                                              )}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            {editedMaterialRow === index ? (
                                              <input
                                              type="number"
                                                onChange={(e) => handleAmountChange(index, Number(e.target.value))}
                                                id={`amount${index}`}
                                                className="input-style"
                                                value={`${material.amount}`}
                                                placeholder="Enter Ampount"
                                              />
                                            ) : (
                                              <div className="text-left">{`${material.amount}`}</div>
                                            )}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                              {editedMaterialRow === index ? (
                                                  <select
                                                    id={`gst${index}`}
                                                    className="input-style"
                                                    value={material.gst}
                                                    onChange={(e) => handleGstChange(index, Number(e.target.value))}
                                                        >
                                                          <option value="">Select GST Rate</option>
                                                          <option value="0">0%</option>
                                                          <option value="5">5%</option>
                                                          <option value="12">12%</option>
                                                          <option value="18">18%</option>
                                                          <option value="28">28%</option>
                                                        </select>
                                                      ) : (
                                                        <div className="text-left">{`${material.gst}%`}</div>
                                                      )}
                                            
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            {editedMaterialRow === index ? (
                                              <input
                                              type="number"
                                              value={material.final_amount}
                                              className="input-style"
                                              // value={`${material.final_amount}`}
                                              placeholder="Enter Final amount"
                                              />
                                            ) : (
                                              <div className="text-left">{`${material.final_amount}`}</div>
                                            )}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            {editedMaterialRow === null && (
                                              <button
                                                type="button"
                                                onClick={() => seteditedMaterialRow(index)}
                                                className="action-menu w-auto">
                                                <i className="fas fa-edit w-6 text-left"></i>
                                                <span>Edit</span>
                                              </button>
                                            )}
                                            {editedMaterialRow !== null &&
                                              editedMaterialRow === index && (
                                                <button
                                                  type="button"
                                                  onClick={()=>handleUpdateMaterialRow(index)}

                                                  className="action-menu w-auto">
                                                  <i className="fas fa-edit w-6 text-left"></i>
                                                  <span>Update</span>
                                                </button>
                                              )}
                                            <button
                                              type="button"
                                              onClick={() => handleDeleteMaterialRow(index)}
                                              className="action-menu w-auto">
                                              <i className="fas fa-trash w-6 text-left"></i>
                                              <span>Delete</span>
                                            </button>
                                            {material.gst_applicable &&  (

                                              <button
                                                type="button"
                                                disabled
                                                className="action-menu w-auto">
                                                <i className="fas fa-check w-6 text-left"></i>
                                                <span>Applied GST</span>
                                              </button>
                                            )}
                                             
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                                {addMaterialRow && (
                                  <>
                                    <tr key={addMaterialRow}>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <div className="text-left">{`${addMaterialRow}`}</div>
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                         
                                        </div>
                                        <Select
                                            value={selectedMaterial}  
                                            placeholder="Select Material"
                                            isClearable
                                            {...materialMapForm.register('name')}
                                            onChange={(option) => handleMaterialChange(option as OptionType)}  
                                            options={materialOptions} 
                                            className=""
                                            required
                                      />

                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <input
                                            type="number"
                                            {...materialMapForm.register('quantity')}
                                            onChange={(e) => handleNewQuantityChange(Number(e.target.value))}

                                            className="input-style"
                                            placeholder="Enter quantity"
                                          />
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <input
                                            type="text"
                                            value={unit}
                                            {...materialMapForm.register('unit')}
                                            className="input-style"
                                            placeholder="Enter unit"
                                            readOnly
                                          />
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <input
                                            type="number"
                                            {...materialMapForm.register('amount', {
                                              required: true
                                            })}
                                            onChange={(e) => handleNewAmountChange(Number(e.target.value))}

                                            className="input-style"
                                            placeholder="Enter amount"
                                          />
                                        </div>
                                      </td>
                                      <td className="p-2">
                                      <select
                                              {...materialMapForm.register('gst', { required: 'GST rate is required' })}
                                              className="input-style"
                                                onChange={(e) => handleNewGstChange(Number(e.target.value))}

                                            >
                                              <option value="">Select GST Rate</option>
                                              <option value="0">0%</option>
                                              <option value="5">5%</option>
                                              <option value="12">12%</option>
                                              <option value="18">18%</option>
                                              <option value="28">28%</option>
                                     </select>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <input
                                            type="number"
                                            value={final_amount}

                                            {...materialMapForm.register('final_amount')}
                                            className="input-style"
                                            placeholder="Enter final amount"
                                          />
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <button
                                            type="button"
                                            onClick={materialMapForm.handleSubmit(saveMaterialMap)}
                                            className="action-menu w-auto">
                                            {/* <i className="fas fa-add w-6 text-left"></i> */}
                                            <div className='flex gap-2'> <FaCheck className='mt-1'/>Save</div>
                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setAddMaterialRow(null);
                                            }}
                                            className="action-menu w-auto">
                                            <i className="fas fa-trash w-6 text-left"></i>
                                            <span>Delete</span>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {materialMapInfo.length && (
                                  <tr key={materialMapInfo.length + 1}>
                                    <td className="p-2" colSpan={4}>
                                      <div className="items-center">
                                        <div className="text-right">
                                          <strong>Total</strong>
                                        </div>
                                      </div>
                                    </td>
                                    <td className="p-2" colSpan={2}>
                                      <div className="items-center">
                                        <div className="text-left">
                                          {totalValues.totalMaterialAmount}
                                        </div>
                                      </div>
                                    </td>
                                    <td className="p-2" colSpan={2}>
                                      <div className="items-center">
                                        <div className="text-left">
                                          {totalValues.totalMaterialFinalAmount}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <div className="lg:flex items-center text-center m-autoflex-wrap p-4 pb-6 pt-0 rounded-b-md">
                            <button
                              type="button"
                              disabled={addMaterialRow == null ? false : true}
                              onClick={handleAddMaterialRow}
                              className="btn-primary w-auto whitespace-nowrap mt-0 mb-1 mx-auto">
                              Add new material
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </>

                  <>
                    <div className="grid grid-cols-4 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                      <div className="form-group mb-4 mt-10">
                        <label className="block text-sm font-medium mb-1 whitespace-nowrap">
                          Add additional axpenditure
                        </label>
                        <div className="form-check form-switch pl-0 mr-3 relative">
                          <input
                            className="form-check-input switch-btn"
                            type="checkbox"
                            checked={showAdditionalExpenditure}
                            role="switch"
                            onClick={handleAddAdditionalExpenditure}
                          />
                          <i className="fas fa-check checkbox-tick hidden"></i>
                        </div>
                      </div>
                    </div>
                    {showAdditionalExpenditure && addtionalExpenditureInfo && (
                      <>
                        <div className="flex-col justify-center flex-grow overflow-auto">
                          <div className="w-full mx-auto bg-white shadow-card rounded-lg relative flex-grow overflow-auto">
                            <table className="table-auto w-full border">
                              <thead className="text-xs font-semibold uppercase bg-gray-50">
                                <tr key={'headers'}>
                                  <th className="p-2">
                                    <div className="font-semibold text-left">S No.</div>
                                  </th>
                                  <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Name</div>
                                  </th>
                                  <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Description</div>
                                  </th>
                                  <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Value</div>
                                  </th>
                                  <th className="p-2 whitespace-nowrap">
                                    <div className="font-semibold text-left">Actions</div>
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="text-sm divide-y divide-gray-100">
                                {addtionalExpenditureInfo.map((additional, index) => {
                                  return (
                                    <>
                                      <tr key={index}>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            <div className="text-left">{`${index + 1}`}</div>
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            {editedAdditionalExpenditureRow === index ? (
                                              <input
                                                type="text"
                                                onChange={(e) =>
                                                  setAddtionalExpenditureInfo(
                                                    (prevManpowerMapInfo) =>
                                                      prevManpowerMapInfo.map((v, i) =>
                                                        i === index
                                                          ? { ...v, name: e.target.value }
                                                          : v
                                                      )
                                                  )
                                                }
                                                className="input-style"
                                                value={`${additional.name}`}
                                                placeholder="Enter name"
                                                required
                                              />
                                            ) : (
                                              <div className="text-left">{`${additional.name}`}</div>
                                            )}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            {editedAdditionalExpenditureRow === index ? (
                                              <input
                                                type="text"
                                                onChange={(e) =>
                                                  setAddtionalExpenditureInfo(
                                                    (prevManpowerMapInfo) =>
                                                      prevManpowerMapInfo.map((v, i) =>
                                                        i === index
                                                          ? { ...v, description: e.target.value }
                                                          : v
                                                      )
                                                  )
                                                }
                                                className="input-style"
                                                value={`${additional.description}`}
                                                placeholder="Enter desciption"
                                              />
                                            ) : (
                                              <div className="text-left">{`${additional.description}`}</div>
                                            )}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            {editedAdditionalExpenditureRow === index ? (
                                              <input
                                                type="number"
                                                onChange={(e) =>
                                                  setAddtionalExpenditureInfo(
                                                    (prevManpowerMapInfo) =>
                                                      prevManpowerMapInfo.map((v, i) =>
                                                        i === index
                                                          ? { ...v, value: Number(e.target.value) }
                                                          : v
                                                      )
                                                  )
                                                }
                                                id={`amount${index}`}
                                                className="input-style"
                                                value={`${additional.value}`}
                                                placeholder="Enter value"
                                                required
                                              />
                                            ) : (
                                              <div className="text-left">{`${additional.value}`}</div>
                                            )}
                                          </div>
                                        </td>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            {editedAdditionalExpenditureRow === null && (
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  setEditedAdditionalExpenditureRow(index)
                                                }
                                                className="action-menu w-auto">
                                                <i className="fas fa-edit w-6 text-left"></i>
                                                <span>Edit</span>
                                              </button>
                                            )}
                                            {editedAdditionalExpenditureRow !== null &&
                                              editedAdditionalExpenditureRow === index && (
                                                <button
                                                  type="button"
                                                  onClick={handleUpdateAdditionalExpenditureRow}
                                                  className="action-menu w-auto">
                                                  <i className="fas fa-edit w-6 text-left"></i>
                                                  <span>Update</span>
                                                </button>
                                              )}
                                            <button
                                              type="button"
                                              onClick={() =>
                                                handleDeleteAdditionalExpenditureRow(index)
                                              }
                                              className="action-menu w-auto">
                                              <i className="fas fa-trash w-6 text-left"></i>
                                              <span>Delete</span>
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    </>
                                  );
                                })}
                                {addAdditionalExpenditureRow && (
                                  <>
                                    <tr key={addAdditionalExpenditureRow}>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <div className="text-left">{`${addAdditionalExpenditureRow}`}</div>
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <input
                                            type="text"
                                            {...additionalExpenditureForm.register('name', {
                                              required: true
                                            })}
                                            className="input-style"
                                            placeholder="Enter name"
                                          />
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <input
                                            type="text"
                                            {...additionalExpenditureForm.register('description')}
                                            className="input-style"
                                            placeholder="Enter description"
                                          />
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <input
                                            type="number"
                                            {...additionalExpenditureForm.register('value', {
                                              required: true
                                            })}
                                            className="input-style"
                                            placeholder="Enter value"
                                          />
                                        </div>
                                      </td>
                                      <td className="p-2">
                                        <div className="flex items-center">
                                          <button
                                            type="button"
                                            onClick={additionalExpenditureForm.handleSubmit(
                                              saveAdditionalExpenditureMap
                                            )}
                                            className="action-menu w-auto">
                                            {/* <i className="fas fa-add w-6 text-left"></i>
                                            <span>Save</span> */}
                                            <div className='flex gap-2'> <FaCheck className='mt-1'/>Save</div>

                                          </button>
                                          <button
                                            type="button"
                                            onClick={() => {
                                              setAddAdditionalExpenditureRow(null);
                                            }}
                                            className="action-menu w-auto">
                                            <i className="fas fa-trash w-6 text-left"></i>
                                            <span>Delete</span>
                                          </button>
                                        </div>
                                      </td>
                                    </tr>
                                  </>
                                )}
                                {addtionalExpenditureInfo.length && (
                                  <tr key={addtionalExpenditureInfo.length + 1}>
                                    <td className="p-2" colSpan={3}>
                                      <div className="items-center">
                                        <div className="text-right">Total</div>
                                      </div>
                                    </td>
                                    <td className="p-2" colSpan={2}>
                                      <div className="flex items-center">
                                        <div className="text-left">
                                          {totalValues.totalExpenditureVal}
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <br />
                          <div className="lg:flex items-center text-center m-autoflex-wrap p-4 pb-6 pt-0 rounded-b-md">
                            <button
                              type="button"
                              disabled={addAdditionalExpenditureRow == null ? false : true}
                              onClick={handleAddAdditionalExpenditureRow}
                              className="btn-primary w-auto whitespace-nowrap mt-0 mb-1 mx-auto">
                              Add new row
                            </button>
                          </div>
                        </div>
                      </>
                    )}
                  </>
                  <div className="grid md:grid-cols-3  sm:grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>
                          Total amount <span className="text-red-700 ml-1">*</span>
                        </strong>
                      </label>
                      <input
                        type="number"
                        className="input-style"
                        placeholder="Enter total"
                        {...register('total', {
                          required: true
                        })}
                      />
                      {errors.total?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>
                          Signed date <span className="text-red-700 ml-1">*</span>
                        </strong>
                      </label>
                      <input
                        type="date"
                        className="input-style"
                        placeholder="Enter total"
                        {...register('signed_date', {
                          required: true
                        })}
                      />
                      {errors.signed_date?.type === 'required' && (
                        <p className="text-red-700"> Required Field</p>
                      )}
                    </div>
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>Signature </strong>
                      </label>
                      <input
                        type="file"
                        className="input-style"
                        placeholder="Upload your signature"
                        {...register('signature')}
                        onChange={handleSignUpload}
                      />
                      {signUrl && (
                        <div>
                          <img
                            src={signUrl}
                            alt="Logo"
                            className="mb-2 mt-3"
                            style={{ maxHeight: '100px' }}
                          />
                        </div>
                    )}
                    </div>
                  </div>
                  <div className="grid grid-cols-1 xl:gap-4 lg:gap-4 md:gap-2 gap-1 mb-4">
                    <div className={`form-group`}>
                      <label className="block mb-1">
                        <strong>Terms & Conditions </strong>
                      </label>
                      <textarea
                        className="input-style"
                        placeholder="Enter terms &  Conditions"
                        {...register('terms_conditions')}></textarea>
                    </div>
                  </div>
                  <div className="modal-footer flex flex-shrink-0 flex-wrap items-center justify-center p-4 pb-6 pt-0 rounded-b-md">
                    <button
                      type="submit"
                      className="px-6 btn-primary w-28 mr-2 ml-0 mt-0"
                      onClick={handleSubmit(onSubmit)}>
                      Save
                    </button>
                    <button
                      onClick={handleBackClick}
                      type="button"
                      className="mx-0 px-6 btn-primary discard-btn w-28 mt-2"
                      data-bs-dismiss="modal">
                      Back
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default AddQuotation;
