import React, { useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Dashboard from '../../admin/dashboard';
import Footer from '../../common/footer';
import Header from '../../common/header';
import Sidebar from '../sidebar';
import NotFound from 'src/components/notFound';
import ProtectedRoutes from 'src/ProtectedRoutes';
import AuthComponent from 'src/components/HOC/AuthComponent';
import Tenants from 'src/components/admin/tenants';
import AddTenant from 'src/components/admin/tenants/AddTenant';
import Material from 'src/components/admin/materials/Material';
import Manpower from 'src/components/admin/manpower/Manpower';
import Quotation from 'src/components/project/Quotation';

import ManpowerTable from 'src/components/admin/manpower';

import MaterialMapping from 'src/components/MaterialMapping/MaterialMapping';
import MaterialMappingInfo from 'src/components/MaterialMapping';

import MaterialTable from 'src/components/admin/materials';
import TransactionTable from 'src/components/project/ViewTransaction/ViewTransaction';
import TransactionForm from 'src/components/project/ViewTransaction/TransactionForm';
import MaterialUsageTable from 'src/components/admin/materialUsage';
import MaterialUsageForm from 'src/components/admin/materialUsage/addMaterialUsage';
import StockReport from 'src/components/admin/stockReport/StockReport';

import TenantRoles from 'src/components/admin/tenants/tenantRoles';
import TenantAdmins from 'src/components/admin/tenants/tenantAdmins';
import AddTenantAdmin from 'src/components/admin/tenants/tenantAdmins/AddTenantAdmin';
import ServiceInfo from 'src/components/admin/service';
import AddService from 'src/components/admin/service/addService';
import AddProject from 'src/components/admin/project/AddProject';
import ProjectInfo from 'src/components/admin/project';

import ClientTable from 'src/components/project/client';
import Client from 'src/components/project/client/Client';
import AddQuotation from 'src/components/project/AddQuotation';
import InvoiceInfo from 'src/components/project/InvoiceInfo';
import AddInvoice from 'src/components/project/AddInvoice';
import Receipt from 'src/components/admin/receipt';
import AddReceipt from 'src/components/admin/receipt/AddReceipt';
import PLReport from 'src/components/Report/profitlossreport';

import AddManpowerMapping from 'src/components/admin/manpowerMapping/AddManpowerMap';

import ManpowerMappingTable from 'src/components/admin/manpowerMapping';
import RoleTable from 'src/components/admin/service/RoleTable';
import RoleForm from 'src/components/admin/service/Role';
import VendorForm from 'src/components/admin/service/vendor/AddVendor';
import VendorTable from 'src/components/admin/service/vendor/VendorTable';
import AddPersonalExpenditure from 'src/components/admin/personalExpenditure/AddPersonalExpenditure';
import PersonalExpenditureTable from 'src/components/admin/personalExpenditure/PersonalExpenditureTabel';
import AddSalaryExpenditure from 'src/components/admin/salaryExpenditure/AddSalaryExpenditure';
import SalaryExpenditureTable from 'src/components/admin/salaryExpenditure/SalaryExpenditureTable';
import SalaryReport from 'src/components/admin/salary report/SalaryReport';
import SalaryConfig from 'src/components/project/salaryconfig';
import AddSalaryConfig from 'src/components/project/salaryconfig/AddSalaryConfig';
import AddTenantRole from 'src/components/admin/tenants/tenantRoles/addTenantRole';
import UpdateSetting from 'src/components/admin/service/updateSettings';
import MoreActions from 'src/components/project/MoreAction';

import VendorAdd from 'src/components/admin/service'


//toggle class on hover for sidebar
interface RoleFormData {
  id?: string;
  name: string;
  description: string;
}

const MainLayoutRoutes: React.FC = () => {
  // Toggle class on hover for sidebar
  const [isSidebarHovered, setIsSidebarHovered] = useState(true);
  const handleSidebarHover = () => setIsSidebarHovered(!isSidebarHovered);
  const navigate = useNavigate();

  // Toggle class for Pin and Unpin main parent div
  const [isPinned, setIsPinned] = useState(true);

  const handleSidebarPinned = () => setIsPinned(!isPinned);

  // Toggle class for Mobile menu sidebar
  const [mobileSidebar, setMobileSidebar] = useState(false);
  const handleMobileMenu = () => setMobileSidebar(!mobileSidebar);

  // Back to top button when scroll
  const [visible, setVisible] = useState(false);
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    setVisible(scrolled > 300);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  const handleSettingsModal = () => {
    const isModalOpen = document.getElementsByClassName('tsettings-modal');
    if (isModalOpen.length) {
      document.body.classList.remove('tsettings-modal');
    } else {
      document.body.classList.add('tsettings-modal');
    }
  };
  return (
    <div
      className={`${isPinned ? 'pin' : 'unpin'} ${
        mobileSidebar ? 'bgoverlay' : 'bg-without-overlay'
      } ${isSidebarHovered ? 'show' : 'hide'} w-full flex justify-center`}>
      <div className="overlay hidden">
        <img
          src="../../assets/img/cross.svg"
          className="w-6 h-6 ml-auto mr-3 my-3 cursor-pointer"
          onClick={handleMobileMenu}
        />
      </div>

      <Sidebar
        isPinned={isPinned}
        handleSidebarPinned={handleSidebarPinned}
        handleSidebarHover={handleSidebarHover}
        isSidebarHovered={isSidebarHovered}
        handleMobileMenu={handleMobileMenu}
      />
      <div id="main" className="py-5 lg:px-8 md:px-8 px-4 mb-16">
        <Header handleMobileMenu={handleMobileMenu} />
        <div className="mt-16 pt-2">
          <Routes>
            <Route path="/" element={<ProtectedRoutes />}>
              <Route path="/" element={AuthComponent(<Dashboard />)} />
              <Route path="/*" element={AuthComponent(<NotFound />)} />
              <Route path="/tenants" element={AuthComponent(<Tenants />)} />
              <Route path="/addTenant" element={AuthComponent(<AddTenant />)} />
              <Route path="/materialinfo" element={AuthComponent(<MaterialTable />)} />
              <Route path="/material" element={AuthComponent(<Material />)} />
              <Route path="/material/:id" element={AuthComponent(<Material />)} />

              <Route path="/manpowerinfo" element={AuthComponent(<ManpowerTable />)} />
              <Route path="/manpower" element={AuthComponent(<Manpower />)} />
              <Route path="/manpower/:id" element={AuthComponent(<Manpower />)} />
              <Route path="/quotation" element={AuthComponent(<Quotation />)} />
              <Route path="/addQuotation" element={AuthComponent(<AddQuotation />)} />
              <Route path="/addQuotation/:id" element={AuthComponent(<AddQuotation />)} />

              <Route path="/clientinfo" element={AuthComponent(<ClientTable />)} />
              <Route path="/client" element={AuthComponent(<Client />)} />
              <Route path="/transactions" element={AuthComponent(<TransactionTable />)} />
              <Route path="/addtransaction" element={AuthComponent(<TransactionForm />)} />
              {/* Add material map */}
              <Route
                path="/materialmapping/:project_id"
                element={AuthComponent(<MaterialMapping />)}
              />
              {/* Edit material map */}
              <Route
                path="/materialmapping/:project_id/:id"
                element={AuthComponent(<MaterialMapping />)}
              />
              {/* Material map info table */}
              <Route
                path="/materialmappinginfo/:project_id"
                element={AuthComponent(<MaterialMappingInfo />)}
              />

              <Route path="/materialtable" element={AuthComponent(<MaterialTable />)} />
              <Route
                path="/manpowermappingtable"
                element={AuthComponent(<ManpowerMappingTable />)}
              />

              <Route path="/receipt" element={AuthComponent(<Receipt />)} />
              <Route path="/addreceipt" element={AuthComponent(<AddReceipt />)} />
              {/* edit receipt id */}
              <Route path="/addreceipt/:id" element={AuthComponent(<AddReceipt />)} />
              <Route path="/plreport" element={AuthComponent(<PLReport />)} />

              <Route path="/clientinfo/:project_id" element={AuthComponent(<ClientTable />)} />
              <Route path="/add_client/:project_id" element={AuthComponent(<Client />)} />
              <Route
                path="/update_client/:project_id/:client_id"
                element={AuthComponent(<Client />)}
              />

              <Route
                path="/transactions/:project_id/:client_id"
                element={AuthComponent(<TransactionTable />)}
              />

              <Route
                path="/add_transaction/:project_id/:client_id"
                element={AuthComponent(<TransactionForm />)}
              />
              <Route
                path="/update_transaction/:project_id/:client_id/:transaction_id"
                element={AuthComponent(<TransactionForm />)}
              />

              <Route
                path="/materialUsage_info/:project_id"
                element={AuthComponent(<MaterialUsageTable />)}
              />
              <Route
                path="/addMaterialUsage/:project_id"
                element={AuthComponent(<MaterialUsageForm />)}
              />
              <Route
                path="/addMaterialUsage/:project_id/:usage_id"
                element={AuthComponent(<MaterialUsageForm />)}
              />
              <Route path="/stockreport" element={AuthComponent(<StockReport />)} />

              <Route path="/materialmapping" element={AuthComponent(<MaterialMapping />)} />
              <Route path="/materialmappinginfo" element={AuthComponent(<MaterialMappingInfo />)} />
              <Route path="/materialtable" element={AuthComponent(<MaterialTable />)} />
              <Route
                path="/manpowermappingtable"
                element={AuthComponent(<ManpowerMappingTable />)}
              />

              <Route path="/addTenant/:id" element={AuthComponent(<AddTenant />)} />
              <Route path="/tenantroles/:tenantId" element={AuthComponent(<TenantRoles />)} />
              <Route path="/tenantadmins/:tenantId" element={AuthComponent(<TenantAdmins />)} />
              <Route path="/addTenantAdmin/:tenantId" element={AuthComponent(<AddTenantAdmin />)} />
              <Route
                path="/addTenantAdmin/:tenantId/:id"
                element={AuthComponent(<AddTenantAdmin />)}
              />

              <Route path="/serviceInfo" element={AuthComponent(<ServiceInfo />)} />
              <Route path="/serviceadd/:tenant_id" element={AuthComponent(<AddService />)} />
              <Route path="/serviceadd/:tenant_id/:id" element={AuthComponent(<AddService />)} />
              <Route path="/setting/:tenant_id" element={AuthComponent(<UpdateSetting />)} />

              <Route path="/projectadd" element={AuthComponent(<AddProject />)} />
              <Route path="/projectinfo" element={AuthComponent(<ProjectInfo />)} />
              <Route path="/role" element={AuthComponent(<RoleForm />)} />
              <Route path="/addvendor" element={AuthComponent(<VendorForm />)} />
              <Route path="/addvendor/:id" element={AuthComponent(<VendorForm />)} />
              <Route path="/vendorinfo" element={AuthComponent(<VendorTable />)} />


              <Route path="/invoiceinfo" element={AuthComponent(<InvoiceInfo />)} />
              <Route path="/addInvoice" element={AuthComponent(<AddInvoice />)} />

              <Route path="/addInvoice/:id" element={AuthComponent(<AddInvoice />)} />

              
              <Route path="/projectinfo/:id" element={AuthComponent(<ProjectInfo />)} />
              <Route path="/projectadd/:id" element={AuthComponent(<AddProject />)} />
              <Route path="/roleinfo" element={AuthComponent(<RoleTable />)} />
              <Route path="/roleadd" element={AuthComponent(<RoleForm />)} />
              <Route path="/roleadd/:id" element={AuthComponent(<RoleForm />)} />
              <Route
                path="/addmanpowermap/:project_id"
                element={AuthComponent(<AddManpowerMapping />)}
              />
              <Route
                path="/addmanpowermap/:project_id/:id"
                element={AuthComponent(<AddManpowerMapping />)}
              />

              <Route
                path="/manpowermappingtable/:project_id"
                element={AuthComponent(<ManpowerMappingTable />)}
              />
              <Route
                path="/addTenantAdmin/:tenantId/:id"
                element={AuthComponent(<AddTenantAdmin />)}
              />

              <Route
                path="/addpersonalexpenditure"
                element={AuthComponent(<AddPersonalExpenditure />)}
              />

              <Route
                path="/personalexpendituretable"
                element={AuthComponent(<PersonalExpenditureTable />)}
              />
              <Route path="/addpersonalexpenditure/:id" element={<AddPersonalExpenditure />} />

              {/* Salary expo table */}
              <Route
                path="/salaryexpendituretabel/:project_id"
                element={AuthComponent(<SalaryExpenditureTable />)}
              />

              {/* Add salary expo */}
              <Route
                path="/addsalaryexpenditure/:project_id"
                element={AuthComponent(<AddSalaryExpenditure />)}
              />
              {/* Edit salary expo */}
              <Route
                path="/addsalaryexpenditure/:project_id/:salary_id"
                element={AuthComponent(<AddSalaryExpenditure />)}
              />
              <Route path="/salaryreport" element={AuthComponent(<SalaryReport />)} />
              <Route
                path="/salaryconfig/:project_id"
                element={AuthComponent(<SalaryConfig />)}
              />
              <Route
                path="/add_salaryConfig/:project_id"
                element={AuthComponent(<AddSalaryConfig />)}
              />
              <Route
                path="/add_salaryConfig/:project_id/:id"
                element={AuthComponent(<AddSalaryConfig />)}
              />
              <Route
                path="/moreaction/:project_id"
                element={AuthComponent(<MoreActions />)}
              />
              <Route
                path="/moreaction/:project_id/id"
                element={AuthComponent(<MoreActions />)}
              />
              <Route
                path="/addtenantrole/:tenant_id/:id"
                element={AuthComponent(<AddTenantRole />)}
              />
              <Route
                path="/addtenantrole/:tenant_id"
                element={AuthComponent(<AddTenantRole />)}
              />
              
            </Route>
          </Routes>
        </div>
        <Footer />
        
        <div
          onClick={scrollToTop}
          style={{ display: visible ? 'inline' : 'none' }}
          className="p-2 flex-items justify-center shadow-card rounded-lg h-8 w-8 btn-primary animate-bounce backTotop">
          <img src="../../assets/img/up-arrow.svg" alt="up arrow" />
        </div>
      </div>
    </div>
  );
};

export default MainLayoutRoutes;
