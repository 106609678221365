import React from 'react';
import { Link, useLocation } from 'react-router-dom';

interface BreadcrumbsProps {
  items: { label: string; path?: string }[];
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items }) => {
  const location = useLocation();

  return (
    <nav className="flex mb-4   text-gray-500">
      {items.map((item, index) => {
        const isCurrent = location.pathname.startsWith(item.path ?? '');
        return (
          <React.Fragment key={index}>
            {item.path ? (
              <Link
                to={item.path}
                className={`hover:text-gray-700 ${isCurrent ? 'text-black font-bold' : ''}`}>
                {item.label}
              </Link>
            ) : (
              <span className="text-black font-bold">{item.label}</span>
            )}
            {index < items.length - 1 && <span className="mx-2">/</span>}
          </React.Fragment>
        );
      })}
    </nav>
  );
};

export default Breadcrumbs;
