import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from 'src/components/loader';
import { RootState } from 'src/store/reducers/state';
import ToastMessage from 'src/components/ToastContainer';
import { useTenantActions } from 'src/store/tenants/actions';
import TenantRoleModel from './tenantRoleModel';
import { errorMsg, successMsg } from 'src/components/utilities/utils';
import EditTenantRoleModel from './editTenantRoleModel';
import { FaEdit, FaPrint, FaTrash } from 'react-icons/fa';

const TenantRoles: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { tenantId } = useParams<string>();
  const [tenantRoleId, setTenantRoleId] = useState<string>();
  const tenantAction = useTenantActions(dispatch);
  // const { tenant_id } = useParams<string>();

  const {
    tenantRolesInfo,
    isTenantRoleSaved,
    isTenantROleUpdated,
    isTenantRoleDeleted,
    isTenantRoleLoading,
    message,
  } = useSelector((state: RootState) => {
    return {
        tenantRolesInfo: state.tenant.tenantRolesInfo,
        isTenantRoleSaved: state.tenant.isTenantRoleSaved,
        isTenantROleUpdated: state.tenant.isTenantROleUpdated,
        isTenantRoleDeleted: state.tenant.isTenantRoleDeleted,
        isTenantRoleLoading: state.tenant.isTenantRoleLoading,
        message: state.tenant.message
    };
  });
  
  useEffect(() => {
    if(tenantId) {
        tenantAction.getTenantRolesByTenantIdRequest(tenantId);
    }
    setTenantRoleId('');
  }, [isTenantROleUpdated, isTenantRoleDeleted, isTenantRoleSaved, tenantId]);

  const handleAddTenantRole = (id: string) => {
    if(id) navigate(`/addtenantrole/${tenantId}/${id}`)
    else navigate(`/addtenantrole/${tenantId}`)
  }

  const handleDeleteTenantRole = (id : string) => {
    const msg = "Do you want to delete this item?"
    if(confirm(msg)) {
        tenantAction.deleteTenantRoleRequest(id)
    } else {
        return false;
    }
    
  }

  return (
    <>
      <ToastMessage />
      <div className="mt-4 members-list">
        <div>
        <div className="mb-8">
          <h2 className="font-semibold my-1">Tenant</h2>
          <ol className="breadcrumb">
            <li>
              <Link to="/tenants">Home</Link>
            </li>
            <li>
              <Link to="/tenants">Tenants</Link>
            </li>
        
            <li>
            <Link to={`/tenantroles/${tenantId}`}>Roles</Link>

            </li>
            <li>
            <Link to={`/tenantadmins/${tenantId}`}>Admin</Link>

            </li>
            <li>
            <Link to={`/serviceadd/${tenantId}`}>Service</Link>

            </li>
          </ol>
        </div>
          <div className="flex flex-col justify-center h-full">
            <div className="w-full mx-auto bg-white shadow-card rounded-lg relative">
              <div className="px-5 py-4 border-b border-gray-100 md:flex justify-between items-center">
                <h2 className="font-semibold ">Tenant Roles</h2>
                <div className="lg:flex items-center text-center">
                  <button
                    type="button"
                    onClick={() => handleAddTenantRole('')}
                    className="px-6 btn-primary w-auto whitespace-nowrap mr-2 ml-0 mt-0">
                    <i className="fas fa-plus pr-1"></i>Add
                  </button>
                </div>
              </div>
              <div className="p-3">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full border">
                    <thead className="text-xs font-semibold uppercase bg-gray-50">
                      <tr>
                        <th className="p-2">
                          <div className="font-semibold text-left">Role key</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Permissions</div>
                        </th>
                        <th className="p-2">
                          <div className="font-semibold text-left">Actions</div>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-sm divide-y divide-gray-100">
                      {isTenantRoleLoading ? (
                        <tr>
                          <td>
                            <Loader />
                          </td>
                        </tr>
                      ) : (
                        <>
                          {tenantRolesInfo.length ? (
                            <>
                              {tenantRolesInfo &&
                                tenantRolesInfo.map((tenantRole, index) => {
                                  return (
                                    <>
                                      <tr
                                        key={index}
                                        style={{ cursor: 'pointer' }}>
                                        <td className="p-2">
                                          <div className="flex items-center">
                                            <div className="text-left">{`${tenantRole.role_key}`}</div>
                                          </div>
                                        </td>
                                        <td>
                                          <div className="text-left">{`${tenantRole.permission.toString()}`}</div>
                                        </td>
                                        <td className="p-2">
                                          <button
                                            className="text-blue-500 hover:text-blue-700 mr-2"
                                            onClick={() =>
                                              handleAddTenantRole(tenantRole._id)
                                            }>
                                            <FaEdit />
                                          </button>
                                          <button
                                            className="text-red-500 hover:text-red-700 mr-2"
                                            onClick={() => handleDeleteTenantRole(tenantRole._id)}>
                                            <FaTrash />
                                          </button>
                                        </td>
                                        {/* <td className="p-2">
                                          <div className="text-left">
                                            <div className="dropdown relative">
                                              <button
                                                className="dropdown-toggle"
                                                onClick={(e: any) => {
                                                  e.stopPropagation();
                                                }}
                                                type="button"
                                                id="action-dropdown"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                                <img
                                                  src="../../assets/img/dots-vertical.svg"
                                                  className="w-5 h-5"
                                                  onClick={(e: any) => {
                                                    e.stopPropagation();
                                                  }}
                                                />
                                              </button>
                                              <ul
                                                className="dropdown-menu dropdown-menu-ul"
                                                aria-labelledby="action-dropdown">
                                                <li>
                                                  <button
                                                    type="button"
                                                    onClick={() => handleAddTenantRole(tenantRole._id)}
                                                    className="action-menu flex items-center"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#editTenantRoleModel"
                                                    ><i className="fas fa-edit w-6 text-left"></i>
                                                    <span>Edit</span>
                                                  </button>
                                                </li>
                                                <li>
                                                  <span
                                                    className="action-menu flex items-center cursor-pointer text-red-500"
                                                    onClick={() => handleDeleteTenantRole(tenantRole._id)}
                                                    >
                                                    <i className="fas fa-trash w-6 text-left"></i>
                                                    <span>Delete</span>
                                                  </span>
                                                </li>
                                                
                                              </ul>
                                            </div>
                                          </div>
                                        </td> */}
                                      </tr>
                                    </>
                                  );
                                })}
                            </>
                          ) : (
                            <tr>
                              <td>No Data Found</td>
                            </tr>
                          )}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        {tenantId && <><TenantRoleModel tenantId={tenantId}/>
        { tenantRoleId && <EditTenantRoleModel tenantId={tenantId} id={tenantRoleId} /> } </>}
      </div>
    </>
    
  );
};

export default TenantRoles;
