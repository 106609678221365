import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaPlus, FaEdit, FaTrash, FaEye, FaEllipsisV, FaRupeeSign } from 'react-icons/fa';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';
import { IClientInfo } from 'src/models/clientModel';
import Loader from 'src/components/loader';
import Breadcrumbs from 'src/components/admin/BreadCrumbsComponent';

const ClientTable: React.FC = () => {
  const navigate = useNavigate();
  const { project_id } = useParams<string>();
  const [clients, setClients] = useState<IClientInfo[]>();
  const [loading, setLoading] = useState(true);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const currentPage = 'clientinfo';


  //  Update client info
  const handleEdit = (clientId: string) => {
    navigate(`/update_client/${project_id}/${clientId}`);
  };

  // delete client info

  const handleDelete = async (id: string) => {
    if (confirm('Do you want to delete this item')) {
      try {
        const response = await ApiService.deleteData(`project-srv/client/${id}`);

        if (response.data && response.data?.data) {
          alert('Client deleted sucessfully');
          getClientInfo();
        }
      } catch (error) {
        alert(error);
      }
    } else {
      return false;
    }
  };

  const handleViewTransaction = (clientId: string) => {
    navigate(`/transactions/${project_id}/${clientId}`); // Navigate to the transactions page
  };

  //  add new client
  const handleAddClient = () => {
    navigate(`/add_client/${project_id}`);
  };

  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };

  // get all clients by project
  const getClientInfo = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData(
        `project-srv/client/client_info/${project_id}`
      );
      if (result.data && result.data?.code === 200 && result.data?.success) {
        setClients(result?.data?.data);
        setLoading(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getClientInfo();
  }, []);

  return (

<div className="mt-8 flex flex-col max-w-xxl mx-auto shadow-lg rounded-lg bg-white">
  <div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto">
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"

    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className={`hover:underline whitespace-nowrap px-2 ${currentPage === 'clientinfo' ? 'font-bold text-base' : ''}`}

    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className="hover:underline whitespace-nowrap  px-2"
    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Salary Info
    </button>
  </div>
</div>

      {/* <Breadcrumbs
        items={[
          { label: 'Project', path: `/projectinfo` },
          { label: ' Clients', path: `/clientinfo/${project_id}` }
        ]}
      /> */}
    <div className='p-4'>
      <div className="flex justify-between items-center mb-4 mt-4">
        <h2 className="font-semibold  ">Clients </h2>
        <button
          onClick={handleAddClient}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded">
          <FaPlus className="mr-2" />
          <span className="hidden sm:inline">Add Client</span>  
        </button>
      </div>
      <div className="overflow-x-auto">
      <table className="table-auto w-full border">
        <thead className="bg-gray-100">
          <tr>
            <th className="py-2 px-4 border-b text-center">S.No</th>
            <th className="py-2 px-4 border-b text-center whitespace-nowrap">Client Name</th>
            <th className="py-2 px-4 border-b text-center whitespace-nowrap">Mobile Number</th>
            <th className="py-2 px-4 border-b text-center whitespace-nowrap">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {loading ? (
            <tr key={'load'}>
              <td colSpan={3} className="text-center p-4">
                <Loader />
              </td>
            </tr>
          ) : (
            <>
              {clients && clients.length > 0 ? (
                clients.map((client, index) => (
                  <tr key={client.id}>
                    <td className="  py-2 px-4 border-b text-center">{index + 1}</td>
                    <td className=" py-2 px-4 border-b text-center">{client.name}</td>
                    <td className=" py-2 px-4 border-b text-center">{client.phone_no}</td>
                    <td className="  py-2 px-4 border-b text-center">
                      <button
                        className="text-blue-500 hover:text-blue-700 mr-1"
                        onClick={() => handleEdit(client._id)}>
                        <FaEdit />
                      </button>
                      <button
                        className="text-red-500 hover:text-red-700 mr-1"
                        onClick={() => handleDelete(client._id)}>
                        <FaTrash />
                      </button>

                      <div className="relative inline-block text-left gap-1">
                        <button
                          className="text-gray-500 hover:text-gray-700"
                          onClick={() => handleViewTransaction(client._id)}>
                          <FaRupeeSign />
                        </button>
                        {/* {openDropdown === client._id && (
                          <div className="absolute right-0 z-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                            <div className="py-1" role="none">
                              <button
                                onClick={() => handleViewTransaction(client._id)}
                                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left">
                                View Transaction
                              </button>
                            </div>
                          </div>
                        )} */}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={3} className="text-center p-4">
                    No Clients found
                  </td>
                </tr>
              )}
            </>
          )}
        </tbody>
      </table>
      </div>
      </div>
    </div>
  );
};

export default ClientTable;
