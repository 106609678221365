import { AxiosResponse } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiService } from '../utilities/api.service';
import { useEffect, useState } from 'react';
import { IPurchaseMaterial } from 'src/models/materialmap';
import { ProfitLossResponse } from 'src/models/plreport';
import { StockReportResponse } from 'src/models/stockReportModel';
import { ISalaryConfig } from './salaryconfig';
import { ISalaryExpenditureModel } from 'src/models/salaryExpenditureModel';
import MaterialMappingInfo from '../MaterialMapping';


const MoreActions = () => {
  const navigate = useNavigate();
  const { project_id } = useParams(); 
  const [projectName, setProjectName] = useState<string>();
  const [TotalAmount, setTotalAmouont] = useState<number>();
  const [materialPurcase,setMaterialPurchase] = useState<IPurchaseMaterial[]>([]);
  const [otherExpenditure,setOtherExpenditure] = useState<ProfitLossResponse>();
  const [materialUsage,setMaterialUsage] = useState<StockReportResponse>();
  const [salaryAmount,setSalaryAmount] = useState<ISalaryConfig[]>([]);


  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };

  const handleCardClick = (path: string) => {
    navigate(`/${path}/${project_id}`);
  };

  const getProjectName = async () => {
    try {
      const result: AxiosResponse = await ApiService.getData(`project-srv/project/${project_id}`);
      setProjectName(result?.data.data.name);
      setTotalAmouont(result?.data.data.total_cost)

    } catch (error) {
      alert(error);
    }
  };

  // for calculate material purchase amount 
  // # start
  const getMaterialPurchaseAmount = async (): Promise<void> => {
    try {
      const result: AxiosResponse = await ApiService.getData(
        `services-srv/purchasematerial/purchase/${project_id}`
      );
      setMaterialPurchase(result.data.data);
    } catch (error) {
      console.error('Error getting services:', error);
    }
  };

  function calculateMaterialFinalAmount(materialPurcase: any){
   let tot=0;
   materialPurcase.map((data:any)=>{
    tot+=data.final_amount
   })
   return tot
  }
// #end

  // for calculate material purchase amount 
  // # start
  const getTotalSalaryAmount = async (): Promise<void> => {
    try {
    
      const result: AxiosResponse = await ApiService.getData(
        `project-srv/project/salaryconfig/project/${project_id}`
      );
      
      setSalaryAmount(result?.data?.data);
      
    } catch (error) {
      console.error('Error getting services:', error);
    }
  };

  function calculateTotalSalary(salary: any){
   let tot=0;
   salary.map((data:any)=>{
    tot+=data.total_manpower_salary
   })
   return tot
  }
// #end

// get Other expenditure Amount
// # start 
const getTotalOtherExpenditure = async (): Promise<void> => {
  try {
    const result: AxiosResponse = await ApiService.postData('project-srv/report/profit_loss', {project_id});
    setOtherExpenditure(result.data.data[0]);
    console.log(result?.data.data)
  } catch (error) {
    console.error('Error getting services:', error);
  }
};
// # end 

// get Material Usage Amount
// # start 
const getMaterialUsageAmount = async (): Promise<void> => {
  try {
     const materialusageResponse: AxiosResponse = await ApiService.postData('project-srv/report/stock',{project_id});
    setMaterialUsage(materialusageResponse.data.data[0]);
  } catch (error) {
    console.error('Error getting services:', error);
  }
};

// #end


useEffect(() => {
  getProjectName();
  getMaterialPurchaseAmount();
  getMaterialUsageAmount();
  getTotalSalaryAmount();
  getTotalOtherExpenditure();
}, []);

//  const calculateSalary = (salary:any)=>{
//   let tot=0
//  salary.

//  }

const [salaryExpenditure,setSalaryExpenditure]=useState<ISalaryExpenditureModel[]>([])
const handleGeneratePrintPDF = async () => {  
  try{
    const result: AxiosResponse = await ApiService.getData(
      `project-srv/project/salaryconfig/project/${project_id}`
    );
    // project-srv/project/salaryconfig/project/
    // setSalaryExpenditure(result?.data?.data);
    if (!result || !result?.data || result?.data?.data == null) {
      alert('invalid  project id passed');
    }
    
    // const total_manpower_salary_=calculateSalary(result?.data?.data)
    const header_response: AxiosResponse = await ApiService.getData(
      `project-srv/project/getservice/${project_id}`
    );
    if (!header_response || !header_response?.data || !header_response?.data?.data) {
      alert('Invalid id passed');
    }

    const stockResponse: AxiosResponse = await ApiService.postData(
      'project-srv/report/stock',{project_id}
    );
    
    const otherExpense: AxiosResponse = await ApiService.postData(
      'project-srv/report/profit_loss',{project_id}
    );
    if (!otherExpense || !otherExpense?.data || otherExpense?.data?.data == null) {
      alert('invalid  project id passed');
    }

    const headerData = header_response?.data?.data;
    const window_height = window.screen.height;
    const window_width = window.screen.width;
    const Salary_info: ISalaryConfig[] = result?.data?.data;
    const material_info:StockReportResponse =stockResponse?.data?.data[0]; 
    const other_expense:ProfitLossResponse =otherExpense?.data?.data[0]; 
    const rootUrl = process.env.REACT_APP_BASE_API_URL;
    let report_html = '';
    let salary_details = '';
    let material_info_ = '';
    // let manpower_info = '';
    let otherExpense_info = '';
    let footer_info = '';
    
    const totalExpenditure = 
    (materialUsage ? materialUsage.total_material_expenditure : 0) +
    calculateTotalSalary(salaryAmount) +
    (otherExpenditure ? otherExpenditure.total_other_expenditure : 0);
  

   

    const mywindow = window.open(
      '',
      'PRINT',
      'height=' + window_height + ',width=' + window_width + 10
    );
    if (!mywindow || mywindow == null) {
      alert('Something went wrong');
    } else {
      mywindow.document.write('<html><head>');
      mywindow.document.write(
        '<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/css/bootstrap.min.css">' +
          '<script src="https://cdn.jsdelivr.net/npm/jquery@3.5.1/dist/jquery.slim.min.js" > </script>' +
          '<script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" > </script>' +
          '<script src="https://cdn.jsdelivr.net/npm/bootstrap@4.6.1/dist/js/bootstrap.bundle.min.js" > </script>'
      );
      mywindow.document.write(
        '</head><body style="padding-left: 20px; padding-right: 20px; padding-bottom:20px">'
      );
      mywindow.document.write(
        '<div class="row"  id = "print-header" >' +
          ' <div className="col-md-4 " class="mt-3 mr-5 ml-3" > ' +
          '<img src = "' +headerData.logo +
          '" alt = "company logo" style = "width: 150px !important; height: 100px !important; max-width: none; float: left;"> ' +
          '</div>' +
          '<div className="col-md-8" class="ml-5">' +
          '<h1 style = "margin-bottom: 0px !important; font-size:70px ; color:#090265">' +
          headerData.service_name +
          '</h1>' +
          '<h3 style="color:090265;  margin-left: 100px;">' +
          headerData.first_name +
          ' ' +
          headerData.last_name +
          '</h3>' +
          '</div>' +
          '</div>' +
          '<div class="row " " id = "print-header"  >' +
          '<div  class="col-md-12 text-center ms-5" style="bac-color:#e1ddff; color:#090265" >' +
          '<ul class="d-flex justify-content-around" style="font-size:20px ; margin-top:10px;">' +
          '<li>' +
          'PLANINIG' +
          '</li>' +
          '<li>' +
          'ELEVATION' +
          '</li>' +
          '<li>' +
          'DESIGN' +
          '</li>' +
          '<li>' +
          'CONSTRUCTION' +
          '</li>' +
          '</ul>' +
          '</div>' +
          '<div  class="col-md-12 text-center">' +
          '<h4 style="color:090265">' +
          headerData.address +
          '</h4>' +
          '</div>' +
          '<div  class="col-md-12 text-center p-2 d-flex justify-content-between pl-4" style="background-color:#090265; color:white; "   >' +
          '<h4 class="pl-5">' +
          'Contact : ' +
          headerData.contact_number +
          '</h4>' +
          '<h4 class="pr-5">' +
          'Email : ' +
          headerData.email +
          '</h4>' +
          '<h4 class="pr-5">' +
          'GST : ' +
          headerData.gst_in +
          '</h4>' +
          '</div>' +
          
          '</div>'
      );

      //render section header
      report_html += `<div class='row' style="margin-top: 10px;">
        <div class='col-md-12'>
          <p class="text-center"><b> ${headerData.project_name} Report</b></p>
        </div>
      </div>
      `;

 salary_details = `
  <div class='row' style="margin-top: 10px;">
    <div class='col-md-12'>
      <h4 class="ml-2"> Salary Information</h4>
      <table class='table border mx-auto' style="width: 90%;">
        <thead style="background-color:#f1f0f3">
          <tr>
            <th>From Date</th>
            <th>To Date</th>
            <th>Role</th>
            <th>Count</th>
            <th>Salary per Head</th>
            <th>Total Salary</th>
          </tr>
        </thead>
        <tbody>
                        ${Salary_info.length > 0 
                          ? Salary_info.map((project) => 
                              project.manpower_info.map((manpower) => `
                                <tr>
                                  <td>${manpower.from_date ? new Date(manpower.from_date).toLocaleDateString() : 'N/A'}</td>
                                  <td>${manpower.to_date ? new Date(manpower.to_date).toLocaleDateString() : 'N/A'}</td>
                                  <td>${manpower.role || 'N/A'}</td>
                                  <td>${manpower.count}</td>
                                  <td>${manpower.salary_per_head}</td>
                                  <td>${manpower.total_salary}</td>
                                </tr>
                              `).join('')
                            ).join('')
                          : `
                            <tr>
                              <td colspan='6' class='text-center'>No salary information available</td>
                            </tr>
                          `
                        }
                        ${Salary_info.length > 0 
                          ? `
                            <tr>
                              <th colspan="5" class="py-2 px-4  font-semibold text-right">Total Salary:</th>
                              <td class="font-semibold">
                                
                                ${Salary_info.reduce((total, data) => total + data.total_manpower_salary, 0)}
                              </td>
                            </tr>
                          ` : ''
                        }
                      </tbody>

      </table>
    </div>
  </div>
`;

// Assuming result is your API response
// const Salary_info = result?.data?.data;

// Assuming result is your API response
// const Salary_info = result?.data?.data[0];

    otherExpense_info = `
    <div class='row' style="margin-top: 10px;">
      <div class='col-md-12'>
          <h4>Other Expenditure Information</h4>
          <table class='table border mx-auto' style="width: 90%;">
              <thead style="background-color:#f1f0f3">
                <tr>
                  <th>Name</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                ${other_expense.other_expenditure.length > 0 
                  ? other_expense.other_expenditure.map((data) => `
                    <tr>
                      <td>${data.name}</td>
                      <td>${data.from_date ? new Date(data.from_date).toLocaleDateString() : 'N/A'}</td>
                      <td>${data.to_date ? new Date(data.to_date).toLocaleDateString() : 'N/A'}</td>
                      <td>${data.amount}</td>
                    </tr>
                    
                  `).join('')
                  : "<tr><td colspan='4' class='text-center'>No other expenditures found</td></tr>"
                }
                <tr>
                    <th  colspan="3" class="text-right">Total Other Expenditure</th>
                    <td >${other_expense.total_other_expenditure}</td>
                </tr>
              </tbody>
          </table>
      </div>
  </div>
`;



      material_info_ +=`
          <div class='col-md-12'>
            <h4>Material Information</h4>
            <table class='table border mx-auto' style="width: 90%;">
              <thead style="background-color:#f1f0f3">
              <tr>
                <th colspan="7"  class="text-center">${material_info.project_name}</th>
              </tr>
                <tr>
                  <th>Material Name</th>
                  <th>per/Amount</th>
                  <th>date</th>
                  <th>Stock In</th>
                  <th>Stock Out</th>
                  <th>Final Amount</th>
                </tr>
                ${

                  material_info.stock_material_info.length > 0
                  ? material_info.stock_material_info.map((material) => 
                      `<tr>
                        <td><div class="mt-3">${material.material_name}</div></td>
                        <td><div class="mt-3">${material.purchase_amount}</div></td>
                        <td >
                          ${material.stock_info.map((data) =>
                            `<div class="mt-3" >${data.stock_date ? new Date(data.stock_date).toLocaleDateString() : 'N/A'}</div>`
                          ).join('')}
                          <p class="mt-3" style="font-size: 20px;color:#090265">Total </p>
                          <p class="mt-3" style="font-size: 20px;color:#090265">Remaining Stock </p>
                          
                        </td>
                        <td class="mt-3">
                          ${material.stock_info.map((data) =>
                            `<div class="mt-3">${data.stock_in}</div>`
                          ).join('')}
                          <p class="mt-3" style="font-size: 20px;color:#090265">${material.total_stock_in}</p>
                          <p class="mt-3" style="font-size: 20px;color:#090265">${material.remaining_stock}</p>

                        </td>
                        <td class="mt-3">
                          ${material.stock_info.map((data) =>
                            `<div class="mt-3">${data.stock_out}</div>`
                          ).join('')}
                          <p class="mt-3" style="font-size: 20px;color:#090265">${material.total_stock_out}</p>
                        </td>
                        <td>
                          <div class="mt-3" style="font-size: 20px;color:#090265">
                          ${material.final_amount}
                          </div>
                        </td>
                      </tr>
                  `
            ).join('')
                  : 
                   ` <tr>
                        <td colspan="6">No Materials found</td>
                      </tr>
                     `
               }
                  <tr>
                          <th colspan="5" class="text-right">Total Material Expenditure</th>
                          <td >${material_info.total_material_expenditure}</td>
                  </tr>
                 
              </thead>
              <tbody>
              <tr>
                </tr>
              </tbody>
            </table>
          </div>
      `
      footer_info += `<div class='row mt-5 d-flex justify-content-between' >
                          <div className='col-md-6'>
                          
                                  <p> <b>Total Budget : ${TotalAmount}</b></p>
                                
                          </div>
                          <div className='col-md-6 ' class="mr-5">
                                    <p><b>Total Expenditure </b></p>
                              
                                    <b>${totalExpenditure}</b>
                            </div>
                                                           
                          </div>`;
                        
      mywindow.document.write(report_html);
      mywindow.document.write(salary_details);
      mywindow.document.write(material_info_);
      // mywindow.document.write(manpower_info);
      mywindow.document.write(otherExpense_info);
      mywindow.document.write(footer_info);

      mywindow.document.write('</body></html>');
      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10
    }
    if (mywindow != null) {
      setTimeout(function () {
        mywindow.print();
        mywindow.close();
      }, 1000);
    }
    return true;

  }catch(error){
    alert(error)
  }
}

return (
  
<div className="mt-8 flex flex-col mx-auto items-center shadow-lg h-screen bg-white flex-grow overflow-auto">
<div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto">
<div className="flex space-x-4 min-w-full lg:min-w-0 lg:justify-center py-2 ">

    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>

    <button
      onClick={() => handleNavigation(`clientinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className="hover:underline whitespace-nowrap  px-2"
    >
      Material Purchase
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Salary Info
    </button>
  </div>
</div>

      <div className='container  flex justify-end'>
          <div className='mr-3'><button className='btn btn-primary '
          onClick={handleGeneratePrintPDF}
          
          >Print report</button></div>
          
      </div>
      <div className="flex flex-col ">
        <div className=" w-38 flex  grid sm:grid-cols-1 md:grid-cols-2 items-start">
          <p className="font-semibold text-gray text-sm lg:text-lg mt-5 mr-5 whitespace-nowrap ml-3 ">
            Project Name: <span className="text-green-600 ml-3 gap-3">{projectName}</span>
          </p>
         <p className="font-semibold text-gray text-sm lg:text-lg mt-5 mr-5 mb-3 whitespace-nowrap ml-3">
            Total Budget :<span className="text-green-600 ml-3">{TotalAmount}</span></p>
        </div>
        <div className='grid md:grid-cols-2 sm:grid-cols-1 '>
          <div className="flex flex-col left-px	">
              <p className='  font-bold p-2 items-start '>Material Purchase Amount: <span className=" ml-2 bg-green-100 p-1  text-center rounded"> ₹ {calculateMaterialFinalAmount(materialPurcase)}</span></p>
              <p className='  font-bold p-2 items-start'>Material Usage Amount: <span className="  ml-2 bg-red-100 p-1  text-center rounded"> ₹ {materialUsage ? materialUsage?.total_material_expenditure : 0}</span></p>
          </div>
          <div>
              <p className='  font-bold p-2'>Manpower Salary Amount: <span className="  ml-2 bg-blue-100 p-1  text-center rounded"> ₹ {calculateTotalSalary(salaryAmount)}</span></p>
              <p className='  font-bold p-2'>Other Expenditure Amount: <span className="bg-yellow-100 p-1  text-center rounded"> ₹ {otherExpenditure?.total_other_expenditure}</span></p>
          </div>
        </div>
        

      </div>

      {/* Cards */}
      <div className="grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-5 gap-4 mt-10">
        <div
          onClick={() => handleCardClick(`clientinfo`)}
          className="bg-white shadow-lg rounded-lg p-6 flex items-center justify-center text-center cursor-pointer"
        >
          <span>Client Info</span>
        </div>
        <div
          onClick={() => handleCardClick(`materialmappinginfo`)}
          className="bg-white shadow-lg rounded-lg p-6 flex items-center justify-center text-center cursor-pointer"
        >
          <span>Material Purchase</span>
        </div>
        <div
          onClick={() => handleCardClick(`materialUsage_info`)}
          className="bg-white shadow-lg rounded-lg p-6 flex items-center justify-center text-center cursor-pointer"
        >
          <span>Material Usage</span>
        </div>
        <div
          onClick={() => handleCardClick(`salaryexpendituretabel`)}
          className="bg-white shadow-lg rounded-lg p-6 flex items-center justify-center text-center cursor-pointer"
        >
          <span>Other Expenditure</span>
        </div>
        <div
          onClick={() => handleCardClick(`salaryconfig`)}
          className="bg-white shadow-lg rounded-lg p-6 flex items-center justify-center text-center cursor-pointer"
        >
          <span>Salary Info</span>
        </div>
      </div>
    </div>
  );
};

export default MoreActions;
