/* eslint-disable react/jsx-key */
import React, { useState, useEffect } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Select, { GroupBase } from 'react-select';
import { ApiService } from '../utilities/api.service';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import { IProjectInfo } from 'src/models/project';
import { ProfitLossPayload, ProfitLossResponse } from 'src/models/plreport';


interface ProjectOption {
  value: string;
  label: string;
}

const PLReport: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    register
  } = useForm<ProfitLossPayload>();
  const [reportDataList, setReportDataList] = useState<ProfitLossResponse[]>([]);
  
  const [projectOptions, setProjectOptions] = useState<ProjectOption[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<string>();

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const result: AxiosResponse<{ data: IProjectInfo[] }> = await ApiService.getData(
        'project-srv/project'
      );
      const options: ProjectOption[] = result.data?.data.map((project) => ({
        value: project.id,
        label: project.name
      }));

      // setProjectOptions(options);
      // const options = result.data?.data.map((project) => ({
      //   _id: project._id,
      //   id: project.id,
      //   name: project.name,

      // }));
      setProjectOptions(options);
      if (id) {
        const preselectedProject = options.find((option) => option.value === id);
        if (preselectedProject) {
          setValue('project_id', preselectedProject.value);
        }
      }
    } catch (error) {
      console.error('Error fetching project options:', error);
      toast.error('Error fetching project options.');
    }
  };

  const onSubmit: SubmitHandler<ProfitLossPayload> = async (data) => {
    if (data.project_id == 'all') {
      data.project_id = '';
    }
    try {
      const response: AxiosResponse<{
        success: boolean;
        code: number;
        data: ProfitLossResponse[];
      }> = await ApiService.postData('project-srv/report/profit_loss', data);
      console.log('API response:', response.data.data);

      if (response.data.data.length > 0) {
        setReportDataList(response.data.data);
      } else {
        setReportDataList([]);
        toast.info('No data available for the selected criteria.');
      }

      reset();
    } catch (error) {
      console.error('Error fetching PL report:', error);
      alert(error);
      toast.error('Error fetching PL report.');
    } finally {
      setLoading(false);
    }
  };
  // const totalBudget = reportDataList.reduce((sum, report) => sum + report.total_cost, 0);
  // const totalExpenditures = reportDataList.reduce((sum, report) => sum + report.total_expenditure, 0);
  // const totalRemainingBudget = reportDataList.reduce((sum, report) => sum + report.remaining_cost, 0);

  return (
    <div className="mt-4 max-w-xxl mx-auto p-4 shadow-lg rounded-lg bg-white">
      <h2 className="font-semibold mb-4">Profit Loss Report</h2>
      <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">

          <div className="mb-4 form-group">
            <label className="block mb-1">
              Select project <span className="text-red-700 ml-1">*</span>
            </label>
            {/* <select
              className="input-style"
              {...register('project_id', { required: 'Choose option is required' })}>
              <option value="">Select project</option>
              <option value="all">All project</option>
              {projectOptions.map((project) => (
                <option key={project._id} value={project._id}>
                  {project.name}
                </option>
              ))}
            </select>
            {errors.project_id && <p className="text-red-700">{errors.project_id.message}</p>} */}
            <Controller
              name="project_id"
              control={control}
              defaultValue=""
              render={() => (
                <Select<ProjectOption, false, GroupBase<ProjectOption>>
                  options={[{ value: 'all', label: 'All project' }, ...projectOptions]}
                  placeholder="Select Project"
                  isClearable
                  onChange={(selectedOption) => {
                    setValue('project_id', selectedOption?.value ?? '');
                  }}
                />
              )}
            />
            {errors.project_id && <p className="text-red-700">{errors.project_id.message}</p>}
          </div>

          <div className="mb-4 form-group">
            <label className="block mb-2 font-medium" htmlFor="fromDate">
              From Date *
            </label>
            <input
              type="date"
              {...register('from_date')}
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.from_date && <span className="text-red-500">{errors.from_date.message}</span>}
          </div>

          <div className="mb-4 form-group">
            <label className="block mb-2 font-medium" htmlFor="toDate">
              To Date *
            </label>
            <input
              type="date"
              {...register('to_date')}
              className="border border-gray-300 p-2 rounded w-full"
            />
            {errors.to_date && <span className="text-red-500">{errors.to_date.message}</span>}
          </div>
        </div>

        <div className="flex justify-center space-x-4 mt-4">
          <button
            type="submit"
            className={`px-4 py-2 ${loading ? 'bg-gray-500' : 'bg-blue-500'} text-white rounded`}>
            Search
          </button>
        </div>
      </form>

      {reportDataList.length > 0 ? (
        <div className="mt-8">
          <div className="mb-8">
            <div className="overflow-y-auto max-h-96 border">
              {/* scroll in table */}

              <table className="w-full border mb-8">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="border p-2">Project Details</th>
                    <th className="border p-2">Material Expenditure</th>
                    <th className="border p-2">Salary Expenditure</th>
                    <th className="border p-2">Other Expenditure</th>
                  </tr>
                </thead>

                {reportDataList.map((reportData) => (
                  <tbody>
                    <tr>
                      <td
                        className="border p-2"
                        rowSpan={
                          reportData.material_expenditure.length +
                          reportData.salary_expenditure.length +
                          2
                        }>
                        <h3 className="text-lg font-bold">{reportData.project_name}</h3>
                        {/* <p>{reportData.dateRange}</p> */}
                      </td>
                      <td className="border p-2">
                        <table className="w-full border">
                          <thead>
                            <tr>
                              <th className="border p-2">Material Name</th>
                              <th className="border p-2">Date</th>
                              <th className="border p-2">Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {reportData.material_expenditure.map((item, index) => (
                              <tr key={index}>
                                <td className="border p-2">{item.material_name}</td>
                                <td className="border p-2">
                                  {new Date(item.date).toLocaleDateString()}
                                </td>
                                <td className="border p-2">{item.amount}</td>
                              </tr>
                            ))}
                            <tr className="bg-gray-200">
                              <td className="border p-2 font-bold" colSpan={2}>
                                Total Material Expenditure
                              </td>
                              <td className="border p-2 font-bold">
                                {reportData.total_material_cost.toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="border p-2">
                        <table className="w-full border">
                          <thead>
                            <tr>
                              <th className="border p-2">Manpower</th>
                              <th className="border p-2">From</th>
                              <th className="border p-2">To</th>
                              <th className="border p-2">Amount</th>
                            </tr>
                          </thead>

                          <tbody>
                            {reportData.salary_expenditure.map((item, index) => (
                              <tr key={index}>
                                <td className="border p-2">{item.manpower_name}</td>
                                <td className="border p-2">
                                  {new Date(item.from_date).toLocaleDateString()}
                                </td>
                                <td className="border p-2">
                                  {new Date(item.to_date).toLocaleDateString()}
                                </td>
                                <td className="border p-2">{item.amount}</td>
                              </tr>
                            ))}
                            <tr className="bg-gray-200">
                              <td className="border p-2 font-bold" colSpan={3}>
                                Total Salary Expenditure
                              </td>
                              <td className="border p-2 font-bold">
                                {reportData.total_salary_cost.toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="border p-2">
                        <table className="w-full border">
                          <thead>
                            <tr>
                              <th className="border p-2">Name</th>
                              <th className="border p-2">From</th>
                              <th className="border p-2">To</th>
                              <th className="border p-2">Amount</th>
                            </tr>
                          </thead>

                          <tbody>
                            {reportData.other_expenditure.map((item, index) => (
                              <tr key={index}>
                                <td className="border p-2">{item.name}</td>
                                <td className="border p-2">
                                  {new Date(item.from_date).toLocaleDateString()}
                                </td>
                                <td className="border p-2">
                                  {new Date(item.to_date).toLocaleDateString()}
                                </td>
                                <td className="border p-2">{item.amount}</td>
                              </tr>
                            ))}
                            <tr className="bg-gray-200">
                              <td className="border p-2 font-bold" colSpan={3}>
                                Total Other Expenditure
                              </td>
                              <td className="border p-2 font-bold">
                                {reportData.total_other_expenditure.toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        <div className="mt-4 text-right">
                          <p className="font-bold">
                            <strong>Total Budget:</strong> {reportData.total_cost}
                          </p>
                          <p className="font-bold">
                            <strong>Total Expenditures:</strong> {reportData.total_expenditure}
                          </p>
                          <p className="font-bold">
                            <strong>Remaining Budget:</strong> {reportData.remaining_cost}
                          </p>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
                {/* <tr>
                <td className="border p-2 font-bold" colSpan={3}>
                  <div className="mt-4 text-right">
                    <p><strong>Total Budget:</strong> {totalBudget}</p>
                    <p><strong>Total Expenditures:</strong> {totalExpenditures}</p>
                    <p><strong>Remaining Budget:</strong> {totalRemainingBudget}</p>
                  </div>
                </td>
              </tr> */}
              </table>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-8">
          <p>No data available for the selected criteria.</p>
        </div>
      )}
    </div>
  );
};

export default PLReport;
