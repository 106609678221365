import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useLoginActions } from 'src/store/login/actions';
import { useNavigate } from 'react-router-dom';
import { getItem, deleteCookies } from 'src/components/utilities/utils';
import { TenantAdminModel } from 'src/models/usersModel';
import { AxiosResponse } from 'axios';
import { ApiService } from 'src/components/utilities/api.service';

interface IProps {
  handleMobileMenu: () => void;
}

const Header: React.FC<IProps> = ({ handleMobileMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [userDetails, setUserDetails] = useState<TenantAdminModel | null>(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const loginAction = useLoginActions(dispatch);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [serviceId, setServiceId] = useState<string>();

  useEffect(() => {
    const storedUserData = getItem('userData');
    if (storedUserData) {
      setUserDetails(JSON.parse(storedUserData));
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const getServiceId = async () => {
    if (!userDetails?.tenant_id) return;
    try {
      const result: AxiosResponse = await ApiService.getData(
        `tenant-srv/tenant/service/tenantId/${userDetails?.tenant_id}`
      );
      setServiceId(result.data.data._id);
    } catch (error) {
      console.error('Error getting services:', error);
    }
  };

  useEffect(() => {
    getServiceId();
  }, [userDetails]);

  const logoutClick = () => {
    loginAction.sendLogoutRequest();
    deleteCookies();
    navigate('/login');
  };

  const settingClick = () => {
    navigate(`/setting/${userDetails?.tenant_id}`);
  };

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  return (
    <>
      <header className="shadow px-4 py-3 rounded">
         <div className="flex justify-between items-center">
           <div className="flex items-center">
            <img
              src="../../assets/img/bars.svg"
              className="menu-icon w-9 hidden cursor-pointer menu-bars"
              onClick={handleMobileMenu}
            />
          </div>
          <div className="flex-items">
            {userDetails && (
                <div
                  className="flex items-center ml-3 cursor-pointer current-usr"
                  id="action-dropdown"
                  aria-expanded="false"
                  onClick={toggleDropdown}>
                  <div className="grid leading-tight">
                    <span className="font-semibold leading-none">
                      {userDetails.first_name + ' ' + userDetails.last_name}
                    </span>
                    <span className="text-right">
                      <small>{userDetails.roles.toString()}</small>
                    </span>
                  </div>
                  <div className="relative">
                    <img
                      src={`${
                        userDetails.logo ? userDetails.logo : '../../assets/img/user-png.png'
                      }`}
                      className="rounded-full w-10 h-10 object-cover ml-2"
                    />
                    <span className="h-3 w-3 rounded-full bg-green-500 absolute border-2 border-solid border-white active-usr"></span>
                  </div>
              <div className="relative mt-2" ref={dropdownRef}>

                {dropdownVisible && (
                  <ul className="absolute right-0 mt-2 w-38  sm:w-42 bg-white border border-gray-200 rounded shadow-lg transition-opacity duration-200">

                    <li className="cursor-pointer">
                      <div
                        className="block px-2 py-2 text-center md:w-40 sm:w-42 text-gray-700 hover:bg-gray-100"
                        onClick={settingClick}>
                        <span className="flex items-center justify-center">
                          <i className="fa-regular fa-user w-4 h-4 mr-2 "></i>
                            <span className='hidden sm:inline'> Profile</span>
                        </span>
                      </div>
                    </li>
                    <li className="cursor-pointer">
                      <span
                        className="block px-2 py-2 text-center text-gray-700 hover:bg-gray-100"
                        onClick={logoutClick}>
                        <span className="flex items-center justify-center">
                          <img src="../../assets/img/logout.svg" className="w-4 h-4 mr-2" />
                          Logout
                        </span>
                      </span>
                    </li>
                  </ul>
                )}
              </div>

            </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
