import axios, { AxiosResponse } from "axios";
const rootUrl = process.env.REACT_APP_BASE_API_URL;
import { getItem } from "./utils";
// import store from "src/store/store";
// import { LoginActions } from "src/store/login/actions";
// import { deleteCookies } from "./utils";
// import createAuthRefreshInterceptor from 'axios-auth-refresh';
// import { URLS } from "./endpoints";
// const refresh = axios.create();
let needToCancelRequest = false;
// const refreshAuthLogic = (failedRequest: any) =>
//     refresh.post(`${rootUrl}/${URLS.REFRESH_TOKEN.url}`, {
//         RefreshToken: getItem('refreshToken')
//     }).then((tokenRefreshResponse) => {
//         localStorage.setItem('token', tokenRefreshResponse.data.Result.AuthToken);
//         // localStorage.setItem('refreshToken', tokenRefreshResponse.data.Result.RefreshToken);
//         failedRequest.response.config.headers['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.Result.AuthToken;
//         return Promise.resolve();
//     }).catch((error) => {
//         if (error.response && (error.response.status === 401 || error.response.status === 500)) {
//             needToCancelRequest = true
//             store.dispatch(LoginActions.setCallbackLinkRequest())
//             localStorage.clear()
//             deleteCookies()
//         }
//     });
// Instantiate the interceptor
// createAuthRefreshInterceptor(axios, refreshAuthLogic);

function getAccessToken() {
  return localStorage.getItem("token");
}
axios.interceptors.request.use((request) => {
  if (needToCancelRequest) {
    const controller = new AbortController();
    const config = {
      signal: controller.signal,
    };
    return config;
  }
  if (request.url?.indexOf("s3-ap-southeast-2.amazonaws.com") == -1)
    request.headers["Authorization"] = `Bearer ${getAccessToken()}`;
  return request;
});

export class ApiService {
  public static async getData(url: string): Promise<AxiosResponse> {
    const config = {
      headers: { Authorization: `Bearer  ${getAccessToken()}` },
    };
    const response: AxiosResponse = await axios.get(
      `${rootUrl}/${url}`,
      config
    );
    return response;
  }

  public static async postData(
    url: string,
    data?: any
  ): Promise<AxiosResponse> {
    const config = {
      headers: { Authorization: `Bearer ${getItem("token")}` },
    };
    const response: AxiosResponse = await axios.post(
      `${rootUrl}/${url}`,
      data,
      config
    );
    return response;
  }

  public static async postDataTextType(
    url: string,
    data?: any
  ): Promise<AxiosResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${getItem("token")}`,
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
    };
    const response: AxiosResponse = await axios.post(
      `${rootUrl}/${url}`,
      data,
      config
    );
    return response;
  }

  public static async putData(url: string, data?: any): Promise<AxiosResponse> {
    const config = {
      headers: { Authorization: `Bearer ${getItem("token")}` },
    };
    const response: AxiosResponse = await axios.put(
      `${rootUrl}/${url}`,
      data,
      config
    );
    return response;
  }

  public static async patchDataWithContentType(
    url: string,
    data: any
  ): Promise<AxiosResponse> {
    const config = {
      headers: {
        Authorization: `Bearer ${getItem("token")}`,
        "Content-Type": "application/json",
      },
    };
    const response: AxiosResponse = await axios.patch(
      `${rootUrl}/${url}`,
      data,
      config
    );
    return response;
  }

  public static async patchData(
    url: string,
    data: any
  ): Promise<AxiosResponse> {
    const config = {
      headers: { Authorization: `Bearer ${getItem("token")}` },
    };
    const response: AxiosResponse = await axios.patch(
      `${rootUrl}/${url}`,
      data,
      config
    );
    return response;
  }
  public static async patchDatas(url: string): Promise<AxiosResponse> {
    const config = {
      headers: { Authorization: `Bearer ${getItem("token")}` },
    };
    const response: AxiosResponse = await axios.patch(
      `${rootUrl}/${url}`,
      config
    );
    return response;
  }

  public static async deleteData(url: string): Promise<AxiosResponse> {
    const config = {
      headers: { Authorization: `Bearer ${getItem("token")}` },
    };
    const response: AxiosResponse = await axios.delete(
      `${rootUrl}/${url}`,
      config
    );
    return response;
  }

  public static async getDataWithParams(
    url: string,
    data: any
  ): Promise<AxiosResponse> {
    const config = {
      headers: { Authorization: `Bearer ${getItem("token")}` },
      data: data,
    };
    const response: AxiosResponse = await axios.get(
      `${rootUrl}/${url}`,
      config
    );
    return response;
  }

  public static async postLoginData(
    url: string,
    data: any
  ): Promise<AxiosResponse> {
    needToCancelRequest = false;
    const config: any = {
      method: "post",
      url: `${rootUrl}/${url}`,
      data: data,
    };

    const response: AxiosResponse = await axios(config);
    return response;
  }

  public static async uploadImage(
    url: string,
    file: any
  ): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(`${rootUrl}/${url}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  }
}
