import { AxiosResponse } from 'axios';
import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ApiService } from 'src/components/utilities/api.service';
import { ITransactionInfo } from 'src/models/transactionModel';

const TransactionForm: React.FC = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors }
  } = useForm<ITransactionInfo>();

  const navigate = useNavigate();
  const { transaction_id, project_id, client_id } = useParams<{
    transaction_id: string;
    project_id: string;
    client_id: string;
  }>();
  const currentpage='clientinfo';	

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiService.getData(
          `project-srv/amount-transaction/${transaction_id}`
        );

        const fetchedData = response.data.data;
        reset(fetchedData);

        if (fetchedData.date) {
          setValue('date', fetchedData.date.split('T')[0]);
        }
      } catch (error) {
        alert(error);
      }
    };

    if (transaction_id) {
      fetchData();
    }
  }, [transaction_id, reset]);

  // Submit form data
  const onSubmit: SubmitHandler<ITransactionInfo> = async (data) => {
    data.client_id = client_id as string;
    data.project_id = project_id as string;

    try {
      if (transaction_id) {
        data.id = transaction_id;
        data._id = transaction_id;
      }

      const result: AxiosResponse = await ApiService.postData(
        'project-srv/amount-transaction',
        data
      );
      if (result.data && result.data?.code === 200 && result.data?.success) {
        alert('Transaction saved successfully');
        navigate(`/transactions/${project_id}/${client_id}`);
      }
    } catch (error) {
      alert('Failed to submit form');
    }

    reset();
  };

  const handleBackClick = () => {
    navigate(`/transactions/${project_id}/${client_id}`);
  };
  const handleNavigation = (path:string) => {
    navigate(`/${path}/${project_id}`);
  };

  return (
    <div className="mt-8 max-w-xxl mx-auto shadow-lg rounded-lg bg-white">
      {/* <ol className="breadcrumb my-1  ">
        <li>
          <Link to={`/projectinfo`}>Project</Link>
        </li>
        <li>
          <Link to={`/clientinfo/${project_id}`}>Client</Link>
        </li>
        <li>
          <Link to={`/transactions/${project_id}/${client_id}`}>Transaction</Link>
        </li>
        <li className="active">{transaction_id ? <strong>Edit</strong> : <strong>Add</strong>}</li>
      </ol>
      <h2 className="font-semibold mb-4 my-1">Transaction</h2> */}
<div className="flex w-full bg-blue-500 text-white flex justify-center overflow-x-auto">
    <div className="flex space-x-4 py-2 min-w-full lg:min-w-0 lg:justify-center">
  
    <button
      onClick={() => handleNavigation(`projectinfo`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Project Info
    </button>
    <button
      onClick={() => handleNavigation(`clientinfo`)}  
      className={`hover:underline whitespace-nowrap px-2 ${currentpage==='clientinfo' ? 'text-base font-bold' : '' }`}

    >
      Client Info
    </button>
    <button
      onClick={() => handleNavigation(`materialmappinginfo`)}
      className="hover:underline whitespace-nowrap  px-2"
    >
      Material Mapping
    </button>
    <button
      onClick={() => handleNavigation(`materialUsage_info`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Material Usage
    </button>
    <button
      onClick={() => handleNavigation(`salaryexpendituretabel`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Expenditure
    </button>
    <button
      onClick={() => handleNavigation(`salaryconfig`)}
      className="hover:underline whitespace-nowrap px-2"
    >
      Salary Info
    </button>
  </div>
</div> 
<div className='container mt-5'>
        <p className='font-extrabold text-xl text-center mb-4 h4'>Add Transaction</p>
      </div>
      <div className='p-4'>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-2 mb-4">
          <div className="form-group">
            <label className="block mb-1">
              Date<span className="text-red-700 ml-1">*</span>
            </label>
            <input
              type="date"
              className="input-style"
              {...register('date', { required: true })}
              placeholder="DD-MM-YYYY"
            />
            {errors.date?.type === 'required' && <p className="text-red-700">Required field</p>}
          </div>
          <div className="form-group">
            <label className="block mb-1">
              Amount<span className="text-red-700 ml-1">*</span>
            </label>
            <input
              type="number"
              className="input-style"
              {...register('given_amount', { required: true, min: 0 })}
            />
            {errors.given_amount && <p className="text-red-700">{errors.given_amount.message}</p>}
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 mb-4">
          <div className="form-group mb-4">
            <label className="block mb-1">Description</label>
            <textarea {...register('description')} className="input-style" />
            {errors.description && <p className="text-red-700">{errors.description.message}</p>}
          </div>
        </div>
        <div className="flex justify-center">
          <button type="submit" className="px-6 btn-primary w-28 mr-2 ml-0 mt-3">
            {transaction_id ? 'Update' : 'Save'}
          </button>
          <button
            type="button"
            className="px-6 btn-primary discard-btn w-28 mr-2 ml-0 mt-3"
            onClick={handleBackClick}>
            Cancel
          </button>
        </div>
      </form>
      </div>
    </div>
  );
};

export default TransactionForm;
